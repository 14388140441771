import {
	WebinarCollection,
	WebinarCollectionState,
	WebinarId,
	WebinarEntity_Some,
	WebinarEntityPatch_Some,
	WebinarId_Some,
	webinarCollectionDefault,
	webinarCollectionMutateIdOpts
} from '../collections/Webinar';
import * as actions from '../actions/Webinar';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Webinar reducer
 *
 * @export
 * @param {WebinarCollection} [state=webinarCollectionDefault]
 * @param {WebinarActionTypes} action
 * @returns
 */
export function webinarReducer(
	state: WebinarCollection = webinarCollectionDefault,
	action: actions.WebinarActionTypes
) {
	switch (action.type) {
		case actions.WEBINAR_MUTATE_ID:
			return collectionReducerActions.mutateId<WebinarCollection>(
				state,
				action.payload,
				webinarCollectionMutateIdOpts
			);
		case actions.WEBINAR_SET:
			return collectionReducerActions.set<WebinarCollection, WebinarId>(
				state,
				action.payload
			);
		case actions.WEBINAR_TOGGLE:
			return collectionReducerActions.toggle<WebinarCollection, WebinarId>(
				state,
				action.payload
			);
		case actions.WEBINAR_SELECT:
			return collectionReducerActions.select<WebinarCollection, WebinarId_Some>(
				state,
				action.payload
			);
		case actions.WEBINAR_DESELECT:
			return collectionReducerActions.deselect<
				WebinarCollection,
				WebinarId_Some
			>(state, action.payload);
		case actions.WEBINAR_UPSERT:
			return collectionReducerActions.upsert<
				WebinarCollection,
				WebinarEntity_Some
			>(state, action.payload);
		case actions.WEBINAR_PATCH:
			return collectionReducerActions.patch<
				WebinarCollection,
				WebinarEntityPatch_Some
			>(state, action.payload);
		case actions.WEBINAR_CHANGE:
			return collectionReducerActions.change<
				WebinarCollection,
				WebinarEntityPatch_Some
			>(state, action.payload);
		case actions.WEBINAR_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				WebinarCollection,
				WebinarId_Some
			>(state, action.payload);
		case actions.WEBINAR_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				WebinarCollection,
				WebinarId_Some
			>(state, action.payload);
		case actions.WEBINAR_DELETE:
			return collectionReducerActions.delete<WebinarCollection, WebinarId_Some>(
				state,
				action.payload
			);
		case actions.WEBINAR_UNDELETE:
			return collectionReducerActions.undelete<
				WebinarCollection,
				WebinarId_Some
			>(state, action.payload);
		case actions.WEBINAR_SETSTATE:
			return collectionReducerActions.setState<
				WebinarCollection,
				WebinarCollectionState
			>(state, action.payload.state);
		case actions.WEBINAR_PATCHSTATE:
			return collectionReducerActions.patchState<
				WebinarCollection,
				WebinarCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
