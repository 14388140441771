// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The resource type
 * @export
 * @enum {string}
 */
export enum ResourceType {
	Document = 'document',
	File = 'file',
	Image = 'image',
	Link = 'link'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesResourceType = ['document', 'file', 'image', 'link'];
