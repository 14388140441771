import {
	RouteCollection,
	RouteCollectionState,
	RouteId,
	RouteEntity_Some,
	RouteEntityPatch_Some,
	RouteId_Some,
	routeCollectionDefault,
	routeCollectionMutateIdOpts
} from '../collections/Route';
import * as actions from '../actions/Route';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Route reducer
 *
 * @export
 * @param {RouteCollection} [state=routeCollectionDefault]
 * @param {RouteActionTypes} action
 * @returns
 */
export function routeReducer(
	state: RouteCollection = routeCollectionDefault,
	action: actions.RouteActionTypes
) {
	switch (action.type) {
		case actions.ROUTE_MUTATE_ID:
			return collectionReducerActions.mutateId<RouteCollection>(
				state,
				action.payload,
				routeCollectionMutateIdOpts
			);
		case actions.ROUTE_SET:
			return collectionReducerActions.set<RouteCollection, RouteId>(
				state,
				action.payload
			);
		case actions.ROUTE_TOGGLE:
			return collectionReducerActions.toggle<RouteCollection, RouteId>(
				state,
				action.payload
			);
		case actions.ROUTE_SELECT:
			return collectionReducerActions.select<RouteCollection, RouteId_Some>(
				state,
				action.payload
			);
		case actions.ROUTE_DESELECT:
			return collectionReducerActions.deselect<RouteCollection, RouteId_Some>(
				state,
				action.payload
			);
		case actions.ROUTE_UPSERT:
			return collectionReducerActions.upsert<RouteCollection, RouteEntity_Some>(
				state,
				action.payload
			);
		case actions.ROUTE_PATCH:
			return collectionReducerActions.patch<
				RouteCollection,
				RouteEntityPatch_Some
			>(state, action.payload);
		case actions.ROUTE_CHANGE:
			return collectionReducerActions.change<
				RouteCollection,
				RouteEntityPatch_Some
			>(state, action.payload);
		case actions.ROUTE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				RouteCollection,
				RouteId_Some
			>(state, action.payload);
		case actions.ROUTE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				RouteCollection,
				RouteId_Some
			>(state, action.payload);
		case actions.ROUTE_DELETE:
			return collectionReducerActions.delete<RouteCollection, RouteId_Some>(
				state,
				action.payload
			);
		case actions.ROUTE_UNDELETE:
			return collectionReducerActions.undelete<RouteCollection, RouteId_Some>(
				state,
				action.payload
			);
		case actions.ROUTE_SETSTATE:
			return collectionReducerActions.setState<
				RouteCollection,
				RouteCollectionState
			>(state, action.payload.state);
		case actions.ROUTE_PATCHSTATE:
			return collectionReducerActions.patchState<
				RouteCollection,
				RouteCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
