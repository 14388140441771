import React, { useEffect, useState } from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	ProductContactEntity,
	ProductSessionEntity,
	ContactIndividualEntity,
	WebinarEntity,
	ContactOrganizationEntity,
	ResourceEntity,
	Categories,
	CategoryEntity
} from '../../store';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonBackButton,
	IonItem,
	IonLabel,
	IonBadge,
	IonButtons,
	IonToolbar,
	IonModal,
	IonContent,
	IonHeader,
	IonTitle
} from '@ionic/react';
import { Media } from '../../../app/ui/components';
import { download, documentOutline, playCircle } from 'ionicons/icons';
import { SpeakerListItem } from '../components/SpeakerListItem';

//LIB
import moment from 'moment';
import { saveAs } from 'file-saver';

const SessionView: React.FC<any> = viewProps => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const [showVideoModal, setShowVideoModal] = useState('');

	const currentEvent = ctx.virtual.event.active();
	const currentSession:
		| ProductSessionEntity
		| undefined = ctx.virtual.product.get_Session(
		viewProps.match?.params?.id || '',
		currentEvent?.id || ''
	);

	useEffect(() => {
		setShowVideoModal('');
	}, []);

	if (!currentSession) {
		return <>No session found.</>;
	}

	const currentAttendee = ctx.virtual.attendee.active();
	const attendeeProducts = currentAttendee?.products?.filter(
		ap => ap.i_.status === 'active'
	);
	const isAttending =
		attendeeProducts?.find(p => p.productId === currentSession.id) !==
		undefined;

	const allowAttendanceToAllSession = (currentEvent?.properties as any)?.app
		?.virtual?.generalSettings?.allowAttendanceToAllSession;

	//Get Speaker Information
	const sortContactProducts = (
		a: ProductContactEntity,
		b: ProductContactEntity
	) => {
		return (a.position ?? (0 as number)) > (b.position ?? (0 as number))
			? 1
			: (b.position ?? (0 as number)) > (a.position ?? (0 as number))
			? -1
			: 0;
	};
	const allSpeakerContactProducts:
		| ProductContactEntity[]
		| undefined = ctx.virtual.productContact.all_Speakers_By_ProductId(
		ctx,
		currentSession?.id
	);
	const speakersListItems = allSpeakerContactProducts
		?.sort(sortContactProducts)
		.map(
			(
				productContact: ProductContactEntity,
				index: number,
				productContactList: ProductContactEntity[]
			) => {
				let speaker:
					| ContactIndividualEntity
					| undefined = ctx.virtual.contact.get_Individual(
					productContact.contactId,
					currentEvent?.id || ''
				);
				if (speaker) {
					return (
						<SpeakerListItem
							key={'ListItem-' + speaker.id}
							productContact={productContact}
							speakerContact={speaker}
						/>
					);
				}
			}
		);

	//Get Webinar Information
	const allWebinars = ctx.virtual.webinar.all_By_Product(
		ctx,
		currentSession.id
	);
	const webinarListItems = allWebinars.map(
		(webinar: WebinarEntity, index: number, webinarList: WebinarEntity[]) => {
			if (webinar && (isAttending || allowAttendanceToAllSession)) {
				let displayLiveLink: boolean = ((webinar.start && webinar.end
					? moment().isBetween(moment(webinar.start), moment(webinar.end))
					: currentSession.start &&
					  moment().isBetween(
							moment(currentSession.start).subtract(10, 'minutes'),
							moment(currentSession.start)
					  )) &&
					webinar?.url &&
					webinar?.url !== '') as boolean;

				let displayRecording: boolean = ((webinar.recordingStart &&
				webinar.recordingEnd
					? moment().isBetween(
							moment(webinar.recordingStart),
							moment(webinar.recordingEnd)
					  )
					: currentSession.end &&
					  moment().isAfter(moment(currentSession.end))) &&
					webinar?.recordingUrl &&
					webinar?.recordingUrl !== '') as boolean;

				return (
					<IonItem
						key={webinar.id}
						hidden={!displayLiveLink && !displayRecording}
					>
						<IonButton
							hidden={!displayLiveLink}
							size="large"
							color="success"
							onClick={() => {
								if (currentEvent && currentAttendee && currentSession) {
									ctx.virtual.attendee.AttendProduct(
										ctx,
										currentEvent,
										currentAttendee,
										currentSession
									);
								}
								window.open(webinar?.url, '_blank');
							}}
						>
							{' '}
							Join Now{' '}
						</IonButton>
						<IonButton
							hidden={!displayRecording}
							size="large"
							color="primary"
							onClick={() => {
								if (currentEvent && currentAttendee && currentSession) {
									ctx.virtual.attendee.AttendProduct(
										ctx,
										currentEvent,
										currentAttendee,
										currentSession
									);
								}
								setShowVideoModal(webinar?.recordingUrl || '');
								//window.open(webinar?.recordingUrl, '_blank');
							}}
						>
							<IonIcon src={playCircle} />
							Watch Recording{' '}
						</IonButton>
					</IonItem>
				);
			}
		}
	);

	//Get SessionDocuments
	let allDocuments: ResourceEntity[] = ctx.virtual.resource.all_By_Object(
		currentSession.id
	);
	const documentListItems = allDocuments.map(
		(
			document: ResourceEntity,
			index: number,
			documentList: ResourceEntity[]
		) => {
			return (
				<IonItem
					key={'sessionDocument-' + document.id}
					button={true}
					onClick={() => {
						saveAs(
							'https://myconexsys.com/Uploads/FileManager/' + document.path,
							document.name
						);
					}}
				>
					<IonIcon src={documentOutline} />
					<IonLabel>{document.name}</IonLabel>
					<IonIcon class="ion-float-right" src={download} />
				</IonItem>
			);
		}
	);

	//Get Categories
	let allProductCategories: CategoryEntity[] = ctx.virtual.category.all_By_Product(
		ctx,
		currentSession.id
	);

	//Get Sponsor Data
	const allSponsorContactProducts:
		| ProductContactEntity[]
		| undefined = ctx.virtual.productContact.all_Sponsors_By_ProductId(
		ctx,
		currentSession?.id
	);
	const sponsorListItems = allSponsorContactProducts
		?.sort(sortContactProducts)
		.map(
			(
				productContact: ProductContactEntity,
				index: number,
				productContacts: ProductContactEntity[]
			) => {
				let sponsor:
					| ContactOrganizationEntity
					| undefined = ctx.virtual.contact.get_Exhibitor(
					productContact.contactId,
					currentEvent?.id || ''
				);
				if (sponsor) {
					let logoObj = sponsor.logo ? JSON.parse(sponsor.logo) : undefined;
					let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
					return (
						<IonCol sizeSm="6" sizeMd="4">
							<IonItem
								lines="none"
								style={{ display: 'inline-block' }}
								key={'sessionSponsor-' + sponsor.id}
								button={true}
								routerLink={'/exhibitor/' + sponsor.id}
							>
								{!logoPath || logoPath === '' ? (
									<IonLabel>{sponsor.name}</IonLabel>
								) : (
									<img style={{ height: '75px' }} src={logoPath} />
								)}
							</IonItem>
						</IonCol>
					);
				}
			}
		);

	let startingMessage: string = '';
	if (moment(currentSession.end).isBefore(moment())) {
		startingMessage = 'Ended ' + moment().to(moment(currentSession.end));
	} else if (
		moment(currentSession.start).isBefore(moment().clone().add(1, 'second'))
	) {
		startingMessage = 'Started ' + moment().to(moment(currentSession.start));
	} else {
		startingMessage = 'Begins ' + moment().to(moment(currentSession.start));
	}

	const hasRecording = allWebinars.some((webinar: WebinarEntity) => {
		if (webinar && (isAttending || allowAttendanceToAllSession)) {
			let displayRecording: boolean = ((webinar.recordingStart &&
			webinar.recordingEnd
				? moment().isBetween(
						moment(webinar.recordingStart),
						moment(webinar.recordingEnd)
				  )
				: currentSession.end && moment().isAfter(moment(currentSession.end))) &&
				webinar?.recordingUrl &&
				webinar?.recordingUrl !== '') as boolean;

			return displayRecording;
		} else {
			return false;
		}
	});

	return (
		<IonContent>
			<IonModal
				isOpen={showVideoModal != ''}
				onDidDismiss={() => setShowVideoModal('')}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{currentSession?.name}</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowVideoModal('');
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Media src={showVideoModal}></Media>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/home" />
				</IonButtons>
			</IonToolbar>
			<IonGrid key="SessionView">
				<IonRow>
					<IonCol size="12" sizeMd="12">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>{currentSession?.name}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<h2>
									{moment(currentSession?.start).format('LL, LT') +
										' - ' +
										moment(currentSession?.end).format('LT')}
								</h2>
								<h2
									hidden={
										!(currentSession?.properties as any).myconexsys?.CourseType
									}
									dangerouslySetInnerHTML={{
										__html:
											((currentSession?.properties as any).myconexsys
												?.CreditHours ?? '') +
											' ' +
											((currentSession?.properties as any).myconexsys
												?.CourseType ?? '')
									}}
								></h2>

								<h2>
									<i>{startingMessage}</i>
								</h2>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={
										!(
											attendeeProducts?.find(
												p => p.productId === currentSession.id
											) !== undefined
										)
									}
									color="secondary"
								>
									Registered
								</IonBadge>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={
										!currentSession?.end ||
										!moment().isAfter(moment(currentSession?.end)) ||
										hasRecording
									}
									color="danger"
								>
									Concluded
								</IonBadge>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={
										!currentSession?.end ||
										!moment().isAfter(moment(currentSession?.end)) ||
										!hasRecording
									}
									color="primary"
								>
									On Demand
								</IonBadge>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={true}
									color="primary"
								>
									Atteneded
								</IonBadge>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={
										!currentSession.start ||
										!moment().isBetween(
											moment(currentSession.start).subtract(1, 'hour'),
											moment(currentSession.start)
										)
									}
									color="warning"
								>
									Starting Soon
								</IonBadge>
								<IonBadge
									style={{ marginRight: '3px' }}
									hidden={
										!currentSession.start ||
										!moment().isBetween(
											moment(currentSession.start),
											moment(currentSession.end)
										)
									}
									color="success"
								>
									In Progress
								</IonBadge>
								{allProductCategories.map(
									(
										category: CategoryEntity,
										index: number,
										categoryList: CategoryEntity[]
									) => {
										return (
											<IonBadge style={{ marginRight: '3px' }} color="medium">
												{category.name}
											</IonBadge>
										);
									}
								)}
								<IonList
									lines="none"
									hidden={
										(allWebinars && allWebinars.length <= 0) ||
										(!isAttending && !allowAttendanceToAllSession)
									}
								>
									{webinarListItems}
								</IonList>

								<div hidden={!currentSession?.description}>
									<br />
									<b>Information</b>
									<br />
									<div
										dangerouslySetInnerHTML={{
											__html: currentSession?.description ?? ''
										}}
									></div>
									<br />
								</div>

								<IonList hidden={allDocuments && allDocuments.length <= 0}>
									<h2>Documents</h2>
									{documentListItems}
								</IonList>

								<IonList
									hidden={
										allSpeakerContactProducts &&
										allSpeakerContactProducts.length <= 0
									}
								>
									<h2>Speakers</h2>
									{speakersListItems}
								</IonList>

								<IonGrid
									hidden={
										allSponsorContactProducts &&
										allSponsorContactProducts.length <= 0
									}
								>
									<h2>Sponsored By</h2>
									{sponsorListItems}
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default SessionView;
