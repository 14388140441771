import {
	ContactRegistrationTypeCollection,
	ContactRegistrationTypeCollectionState,
	ContactRegistrationTypeId,
	ContactRegistrationTypeEntity_Some,
	ContactRegistrationTypeEntityPatch_Some,
	ContactRegistrationTypeId_Some,
	contactRegistrationTypeCollectionDefault,
	contactRegistrationTypeCollectionMutateIdOpts
} from '../collections/ContactRegistrationType';
import * as actions from '../actions/ContactRegistrationType';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * ContactRegistrationType reducer
 *
 * @export
 * @param {ContactRegistrationTypeCollection} [state=contactRegistrationTypeCollectionDefault]
 * @param {ContactRegistrationTypeActionTypes} action
 * @returns
 */
export function contactRegistrationTypeReducer(
	state: ContactRegistrationTypeCollection = contactRegistrationTypeCollectionDefault,
	action: actions.ContactRegistrationTypeActionTypes
) {
	switch (action.type) {
		case actions.CONTACTREGISTRATIONTYPE_MUTATE_ID:
			return collectionReducerActions.mutateId<
				ContactRegistrationTypeCollection
			>(state, action.payload, contactRegistrationTypeCollectionMutateIdOpts);
		case actions.CONTACTREGISTRATIONTYPE_SET:
			return collectionReducerActions.set<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_TOGGLE:
			return collectionReducerActions.toggle<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_SELECT:
			return collectionReducerActions.select<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_DESELECT:
			return collectionReducerActions.deselect<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_UPSERT:
			return collectionReducerActions.upsert<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeEntity_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_PATCH:
			return collectionReducerActions.patch<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeEntityPatch_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_CHANGE:
			return collectionReducerActions.change<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeEntityPatch_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_DELETE:
			return collectionReducerActions.delete<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_UNDELETE:
			return collectionReducerActions.undelete<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeId_Some
			>(state, action.payload);
		case actions.CONTACTREGISTRATIONTYPE_SETSTATE:
			return collectionReducerActions.setState<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeCollectionState
			>(state, action.payload.state);
		case actions.CONTACTREGISTRATIONTYPE_PATCHSTATE:
			return collectionReducerActions.patchState<
				ContactRegistrationTypeCollection,
				ContactRegistrationTypeCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
