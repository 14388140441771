import {
	MenuId_Some,
	MenuEntity_Some,
	MenuEntityPatch_Some,
	MenuCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const MENU_MUTATE_ID = '@app/MENU_MUTATE_ID';
export const MENU_SET = '@app/MENU_SET';
export const MENU_TOGGLE = '@app/MENU_TOGGLE';
export const MENU_SELECT = '@app/MENU_SELECT';
export const MENU_DESELECT = '@app/MENU_DESELECT';
export const MENU_UPSERT = '@app/MENU_UPSERT';
export const MENU_PATCH = '@app/MENU_PATCH';
export const MENU_CHANGE = '@app/MENU_CHANGE';
export const MENU_APPLY_CHANGES = '@app/MENU_APPLY_CHANGES';
export const MENU_CANCEL_CHANGES = '@app/MENU_CANCEL_CHANGES';
export const MENU_DELETE = '@app/MENU_DELETE';
export const MENU_UNDELETE = '@app/MENU_UNDELETE';
export const MENU_SETSTATE = '@app/MENU_SETSTATE';
export const MENU_PATCHSTATE = '@app/MENU_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface MenuAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface MenuAction_MutateId extends EntityAction_MutateId {
	type: typeof MENU_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active menu
 *
 * @interface MenuAction_Set
 * @extends {EntityAction_Set}
 */
export interface MenuAction_Set extends EntityAction_Set {
	type: typeof MENU_SET;
	payload?: EntityId;
}

/**
 * Toggle active menu
 *
 * @interface MenuAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface MenuAction_Toggle extends EntityAction_Toggle {
	type: typeof MENU_TOGGLE;
	payload?: EntityId;
}

/**
 * Select menu entities
 *
 * @interface MenuAction_Select
 * @extends {EntityAction_Select}
 */
interface MenuAction_Select extends EntityAction_Select {
	type: typeof MENU_SELECT;
	payload: MenuId_Some;
}

/**
 * Deselect menu entities
 *
 * @interface MenuAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface MenuAction_Deselect extends EntityAction_Deselect {
	type: typeof MENU_DESELECT;
	payload: MenuId_Some;
}

/**
 * Upsert menu entities
 *
 * @interface MenuAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface MenuAction_Upsert extends EntityAction_Upsert {
	type: typeof MENU_UPSERT;
	payload: MenuEntity_Some;
}

/**
 * Patch menu entities
 *
 * @interface MenuAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface MenuAction_Patch extends EntityAction_Patch {
	type: typeof MENU_PATCH;
	payload: MenuEntityPatch_Some;
}

/**
 * Change menu entities
 *
 * @interface MenuAction_Change
 * @extends {EntityAction_Change}
 */
interface MenuAction_Change extends EntityAction_Change {
	type: typeof MENU_CHANGE;
	payload: MenuEntityPatch_Some;
}

/**
 * Apply changes to menu entities
 *
 * @interface MenuAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface MenuAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof MENU_APPLY_CHANGES;
	payload: MenuId_Some;
}

/**
 * Cancel changes to menu entities
 *
 * @interface MenuAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface MenuAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof MENU_CANCEL_CHANGES;
	payload: MenuId_Some;
}

/**
 * Delete menu entities
 *
 * @interface MenuAction_Delete
 * @extends {EntityAction_Delete}
 */
interface MenuAction_Delete extends EntityAction_Delete {
	type: typeof MENU_DELETE;
	payload: MenuId_Some;
}

/**
 * Undelete menu entities
 *
 * @interface MenuAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface MenuAction_Undelete extends EntityAction_Undelete {
	type: typeof MENU_UNDELETE;
	payload: MenuId_Some;
}

/**
 * Set menu collection / entity state
 *
 * @interface MenuAction_SetState
 * @extends {EntityAction_SetState}
 */
interface MenuAction_SetState extends EntityAction_SetState {
	type: typeof MENU_SETSTATE;
}

/**
 * PAtch menu collection / entity state
 *
 * @interface MenuAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface MenuAction_PatchState extends EntityAction_PatchState {
	type: typeof MENU_PATCHSTATE;
}

/**
 * Export menu action types
 *
 * @export
 */
export type MenuActionTypes =
	| MenuAction_MutateId
	| MenuAction_MutateId
	| MenuAction_Set
	| MenuAction_Toggle
	| MenuAction_Select
	| MenuAction_Deselect
	| MenuAction_Upsert
	| MenuAction_Patch
	| MenuAction_Change
	| MenuAction_ApplyChanges
	| MenuAction_CancelChanges
	| MenuAction_Delete
	| MenuAction_Undelete
	| MenuAction_SetState
	| MenuAction_PatchState;

/**
 * Menu actions helper interface
 *
 * @export
 * @interface IMenuActions
 * @extends {EntityActions}
 */
export interface IMenuActions extends EntityActions {
	//customAction(ids: MenuId_Some): MenuAction_CustomAction;
}

/**
 * Menu actions helper
 *
 * @export
 * @class MenuActions
 * @implements {EntityBaseActions}
 */
export class MenuActions implements IMenuActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): MenuAction_MutateId {
		return { type: MENU_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): MenuAction_Set {
		return { type: MENU_SET, payload: id };
	}
	toggle(id?: EntityId): MenuAction_Toggle {
		return { type: MENU_TOGGLE, payload: id };
	}
	select(ids: MenuId_Some): MenuAction_Select {
		return {
			type: MENU_SELECT,
			payload: ids
		};
	}
	deselect(ids: MenuId_Some): MenuAction_Deselect {
		return {
			type: MENU_DESELECT,
			payload: ids
		};
	}
	upsert(entities: MenuEntity_Some): MenuAction_Upsert {
		return {
			type: MENU_UPSERT,
			payload: entities
		};
	}
	patch(entities: MenuEntityPatch_Some): MenuAction_Patch {
		return {
			type: MENU_PATCH,
			payload: entities
		};
	}
	change(entities: MenuEntityPatch_Some): MenuAction_Change {
		return {
			type: MENU_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: MenuId_Some): MenuAction_ApplyChanges {
		return {
			type: MENU_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: MenuId_Some): MenuAction_CancelChanges {
		return {
			type: MENU_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: MenuId_Some): MenuAction_Delete {
		return {
			type: MENU_DELETE,
			payload: ids
		};
	}
	undelete(ids: MenuId_Some): MenuAction_Undelete {
		return {
			type: MENU_UNDELETE,
			payload: ids
		};
	}
	setState(state: MenuCollectionState): MenuAction_SetState {
		return {
			type: MENU_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<MenuCollectionState>): MenuAction_PatchState {
		return {
			type: MENU_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the menu actions helper
 *
 * @export
 */
export const menuActions = new MenuActions();
