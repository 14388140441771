import * as models from '../models';
import * as actions from '../actions';
import _ from 'lodash';
import moment from 'moment';

/**
 * Entity base interface for managing actions and state
 *
 * @export
 * @interface EntityHelper
 */
export interface IEntityHelper extends actions.EntityBaseActions {
	collection: models.Collection & any;
	actions: actions.EntityActions | any;
	dispatch(action: models.ActionTypes): void;

	// Selectors
	active(): models.Entity | undefined;
	all(): models.Entities;
	clone(id: models.EntityId): models.Entity | undefined;
	clones(ids: models.EntityIds): models.Entities;
	deleted(ids?: models.EntityIds): models.Entities;
	get(id: models.EntityId): models.Entity | undefined;
	gets(ids: models.EntityIds): models.Entities;
	filter(filter: (entity: models.Entity) => any): models.Entities;
	filterDeleted(filter: (entity: models.Entity) => any): models.Entities;
	filterMutated(filter: (entity: models.Entity) => any): models.Entities;
	mutated(ids?: models.EntityIds): models.Entities;
	selected(ids?: models.EntityIds): models.Entities;
	state(id?: models.EntityId): models.CollectionState | undefined;
}

/**
 * Entity helper options interface
 *
 * @export
 * @interface EntityHelperOpts
 */
export interface EntityHelperOpts {}

export const entityHelperDefaultOpts: EntityHelperOpts = {};

/**
 * Entity helper
 *
 * @export
 * @class EntityHelper
 * @implements {IEntityHelper}
 */
export default class EntityHelper<
	Collection extends models.Collection,
	ActionTypes extends models.ActionTypes,
	EntityActions extends actions.EntityActions,
	Entity extends models.Entity,
	Entities extends models.Entities,
	Entity_Some extends models.Entity_Some,
	EntityPatch_Some extends models.EntityPatch_Some,
	EntityId extends models.EntityId,
	EntityIds extends models.EntityIds,
	EntityId_Some extends models.EntityId_Some,
	CollectionState extends models.CollectionState,
	Opts extends EntityHelperOpts
> implements IEntityHelper {
	constructor(
		collection: Collection,
		actions: EntityActions,
		dispatch: (action: ActionTypes) => void,
		opts: Opts = entityHelperDefaultOpts as Opts
	) {
		this.collection = collection;
		this.actions = actions;
		this.dispatch = dispatch;
		this.opts = opts;
	}

	collection: Collection;
	actions: EntityActions;
	dispatch: (action: ActionTypes) => void;
	opts: Opts;

	/**
	 * Get the active entity if one is set otherwise return undefined
	 *
	 * @returns {(Entity | undefined)}
	 * @memberof EntityHelper
	 */
	active(): Entity | undefined {
		return this.collection.byIds[this.collection.activeId || ''] as
			| Entity
			| undefined;
	}

	/**
	 * Return all entities in the collection as an array
	 *
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	all(): Entities {
		return this.collection.allIds.map(
			(id: string) => this.collection.byIds[id]
		) as Entities;
	}

	/**
	 * Get a cloned copy of an entity by id
	 *
	 * @param {EntityId} id
	 * @returns {(Entity | undefined)}
	 * @memberof EntityHelper
	 */
	clone(id: EntityId): Entity | undefined {
		return _.cloneDeep(this.collection.byIds[id || '']) as Entity | undefined;
	}

	/**
	 * Get cloned copies of entities by ids
	 *
	 * @param {EntityIds} ids
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	clones(ids: EntityIds): Entities {
		return (ids.map((id: string) =>
			_.cloneDeep(this.collection.byIds[id])
		) as unknown) as Entities;
	}

	/**
	 * Get all deleted entities in the collection as an array
	 *
	 * @param {EntityIds} [ids]
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	deleted(ids?: EntityIds): Entities {
		return ((ids || Object.keys(this.collection.cache)).map(
			(id: string) => this.collection.cache[id] as Entity
		) as unknown) as Entities;
	}

	/**
	 * Get an entity reference by id
	 *
	 * @param {EntityId} id
	 * @returns {(Entity | undefined)}
	 * @memberof EntityHelper
	 */
	get(id: EntityId): Entity | undefined {
		return this.collection.byIds[id || ''] as Entity | undefined;
	}

	/**
	 * Get entity references by ids
	 *
	 * @param {EntityIds} ids
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	gets(ids: EntityIds): Entities {
		return (ids.map(
			(id: string) => this.collection.byIds[id] as Entity
		) as unknown) as Entities;
	}

	/**
	 * Filter entities by a query filter function
	 * TODO: integrate mango selector query support
	 *
	 * @param {*} query
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	filter(filter: (entity: Entity) => boolean): Entities {
		return (this.all().filter(entity =>
			entity ? filter(entity as Entity) : false
		) as unknown) as Entities;
	}

	filterDeleted(filter: (entity: Entity) => boolean): Entities {
		return (this.deleted().filter(entity =>
			entity ? filter(entity as Entity) : false
		) as unknown) as Entities;
	}

	filterMutated(filter: (entity: Entity) => boolean): Entities {
		return (this.mutated().filter(entity =>
			entity ? filter(entity as Entity) : false
		) as unknown) as Entities;
	}

	/**
	 * Get all mutated entities in the collection as an array
	 *
	 * @param {EntityIds} [ids]
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	mutated(ids?: EntityIds): Entities {
		return ((ids || Object.keys(this.collection.mutation)).map(
			(id: string) => this.collection.mutation[id] as Entity
		) as unknown) as Entities;
	}

	/**
	 * Get all selected entities in the collection as an array
	 *
	 * @param {EntityIds} [ids]
	 * @returns {Entities}
	 * @memberof EntityHelper
	 */
	selected(ids?: EntityIds): Entities {
		return (this.collection.selectedIds
			.map((id: string): Entity => this.collection.byIds[id] as Entity)
			.filter(
				(entity: Entity) => !ids || ids.indexOf(entity.id) > -1
			) as unknown) as Entities;
	}

	/**
	 * Get an entity state by id
	 *
	 * @returns {(CollectionState | undefined)}
	 * @memberof EntityHelper
	 */
	state(): CollectionState {
		return this.collection.state as CollectionState;
	}

	/**
	 * Mutate (replace) and entity id with a new id and update all references within the collection
	 * TODO: update references in other collections...
	 *
	 * @param {EntityId} id
	 * @param {EntityId} newId
	 * @memberof EntityHelper
	 */
	mutateId(id: EntityId, newId: EntityId) {
		this.dispatch(this.actions.mutateId(id, newId) as ActionTypes);
	}

	/**
	 * Set the activeId in the collection
	 *
	 * @param {EntityId} [id]
	 * @memberof EntityHelper
	 */
	set(id?: EntityId) {
		this.dispatch(this.actions.set(id) as ActionTypes);
	}

	/**
	 * Toggle the activeId in the collection
	 *
	 * @param {EntityId} [id]
	 * @memberof EntityHelper
	 */
	toggle(id?: EntityId) {
		this.dispatch(this.actions.toggle(id) as ActionTypes);
	}

	/**
	 * Select entities by id, adding the id(s) to the collections selectedIds array
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	select(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.select(idOrIds) as ActionTypes);
	}

	/**
	 * Deselect entities by id, removing the id(s) from the collections selectedIds array
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	deselect(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.deselect(idOrIds) as ActionTypes);
	}

	/**
	 * Upsert (add / update) entities to the collection
	 *
	 * @param {Entity_Some} entityOrEntities
	 * @memberof EntityHelper
	 */
	upsert(entityOrEntities: Entity_Some) {
		this.dispatch(this.actions.upsert(entityOrEntities) as ActionTypes);
	}

	/**
	 * Patch (merge) entities to the collection
	 *
	 * @param {EntityPatch_Some} entityOrEntities
	 * @memberof EntityHelper
	 */
	patch(entityOrEntities: EntityPatch_Some) {
		this.dispatch(this.actions.patch(entityOrEntities) as ActionTypes);
	}

	/**
	 * Change (patch/mutate) entities by saving their current entity state as
	 * a mutation without mutating the originals. When changes are completed,
	 * call applyChanges([entity.id]) to apply the mutations to the orginal state,
	 * or call cancelChanges([entity.id]) to discard them and revert to the originals
	 *
	 * @param {EntityPatch_Some} entityOrEntities
	 * @memberof EntityHelper
	 */
	change(entityOrEntities: EntityPatch_Some) {
		this.dispatch(this.actions.change(entityOrEntities) as ActionTypes);
	}

	/**
	 * Apply changes (mutations) to update the orignal entities
	 * If the entities were deleted, they will be undeleted and the
	 * changes will be applied
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	applyChanges(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.applyChanges(idOrIds) as ActionTypes);
	}

	/**
	 * Cancel changes (mutations) and not update the original entities
	 * The mutations are permanently discarded
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	cancelChanges(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.cancelChanges(idOrIds) as ActionTypes);
	}

	/**
	 * Delete entities, moving them to the cache
	 * Cancel any changes (mutations) first and cache the original entities
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	delete(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.delete(idOrIds) as ActionTypes);
	}

	deleteFilter(filter: (entity: Entity) => boolean) {
		let ids = this.filter(filter).map(entity => entity.id);
		if (ids.length > 0) this.dispatch(this.actions.delete(ids) as ActionTypes);
	}

	/**
	 * Undelete entities, moving them out of the cache
	 *
	 * @param {EntityId_Some} idOrIds
	 * @memberof EntityHelper
	 */
	undelete(idOrIds: EntityId_Some) {
		this.dispatch(this.actions.undelete(idOrIds) as ActionTypes);
	}

	undeleteFilter(filter: (entity: Entity) => boolean) {
		let ids = this.filterDeleted(filter).map(entity => entity.id);
		if (ids.length > 0)
			this.dispatch(this.actions.undelete(ids) as ActionTypes);
	}

	/**
	 * Set collection state
	 *
	 * @param {(CollectionState)} state
	 * @memberof EntityHelper
	 */
	setState(state: CollectionState) {
		this.dispatch(this.actions.patchState(state) as ActionTypes);
	}

	/**
	 * Patch (merge) collection state
	 *
	 * @param {(Partial<CollectionState>)} statePatch
	 * @memberof EntityHelper
	 */
	patchState(statePatch: Partial<CollectionState>) {
		this.dispatch(this.actions.patchState(statePatch) as ActionTypes);
	}

	created(last?: boolean): Entity | any | undefined {
		return this.all().sort((a: Entity | any, b: Entity | any) =>
			a.created && b.created && moment(a.created).isAfter(moment(b.created))
				? last
					? -1
					: 1
				: last
				? 1
				: -1
		)[0];
	}

	modified(last?: boolean): Entity | any | undefined {
		return this.all().sort((a: Entity | any, b: Entity | any) =>
			a.modified && b.modified && moment(a.modified).isAfter(moment(b.modified))
				? last
					? -1
					: 1
				: last
				? 1
				: -1
		)[0];
	}

	// get last/newest entity attempt date for a given Api operation
	getLastAttempt(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Attempt,
			false,
			filter
		);
	}

	// get first/oldest entity 'attempt' date for a given Api operation
	getFirstAttempt(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Attempt,
			true,
			filter
		);
	}

	// get last/newest entity 'success' date for a given Api operation
	getLastSuccess(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Success,
			false,
			filter
		);
	}

	// get first/oldest entity 'success' date for a given Api operation
	getFirstSuccess(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Success,
			true,
			filter
		);
	}

	// get last/newest entity 'error' date for a given Api operation
	getLastError(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Error,
			false,
			filter
		);
	}

	// get first/oldest entity 'error' date for a given Api operation
	getFirstError(
		operationId: string,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		return this.getByOperationContextDate(
			operationId,
			models.State_ApiOperationContextTypes.Error,
			true,
			filter
		);
	}

	// get last/newest or first/oldest entity by comparing last contextual (attemp, success, error) api operation dates on all entities
	getByOperationContextDate(
		operationId: string,
		contextType: models.State_ApiOperationContextTypes,
		oldest?: boolean,
		filter?: any
	): { date: string | undefined; entity: Entity | undefined } {
		// get the operation contextual key where looking for 'attempt', 'success' or 'error'.  default to 'success'
		let contextKey: string =
			contextType === models.State_ApiOperationContextTypes.Attempt
				? 'attempt'
				: contextType === models.State_ApiOperationContextTypes.Error
				? 'error'
				: 'success';

		// get top entity (filtered OR all) sorted by last/newest or first/oldest for the contextual api operation dates where applicable
		let entity: Entity | any | undefined = this.all()
			.filter(filter || (() => true))
			.sort((a: Entity | any, b: Entity | any): number => {
				// assume any as Entity type
				a = a as Entity;
				b = b as Entity;

				// if 'a' entity doest not have the operation, keyed context or no 'last' date, then sort 'a' entity down
				if (
					!a.__properties?.api?.operations ||
					!(operationId in a.__properties?.api?.operations) ||
					!(contextKey in a.__properties?.api?.operations[operationId]) ||
					!a.__properties?.api?.operations[operationId][contextKey].last
				)
					return -1;

				// if 'b' entity doest not have the operation, keyed context or no 'last' date, then keep 'a' entity sorted up
				if (
					!b.__properties?.api?.operations ||
					!(operationId in b.__properties?.api?.operations) ||
					!(contextKey in b.__properties?.api?.operations[operationId]) ||
					!b.__properties?.api?.operations[operationId][contextKey].last
				)
					return 1;

				// ok we made it this far, so 'a' and 'b' entities both have the context and have last.on values, so lets compare their dates using moment
				// if in 'oldest' mode: then if 'a' is NOT after 'b', then 'a' is OLDER and therefore keep 'a' sorted up as OLDER than 'b'
				// else in 'newest' mode: then if 'a' is AFTER 'b', then 'a' is NEWER and therefore keep 'a' sorted up as NEWER than 'b'
				return moment(
					a.__properties?.api?.operations[operationId][contextKey].last
				).isAfter(
					moment(b.__properties?.api?.operations[operationId][contextKey].last)
				)
					? oldest
						? -1
						: 1
					: oldest
					? 1
					: -1;
			})[0];

		// if an entity was found (at last.on is the real deal) return the date for the operational context and the entity itself
		if (
			entity &&
			entity.__properties?.api?.operations &&
			operationId in entity.__properties?.api?.operations &&
			contextKey in entity.__properties?.api?.operations[operationId] &&
			entity.__properties?.api?.operations[operationId][contextKey].last
		) {
			return {
				date:
					entity.__properties?.api?.operations[operationId][contextKey].last,
				entity
			};
		}

		// otherwise no matching entity was found so return undefined date and entity
		return { date: undefined, entity: undefined };
	}
}
