import { Localize, translations } from '../../locales';
import { UserMenuConfigProps } from '../../../app/ui/containers/User/UserMenu';

export type UserMenuConfig = Localize<UserMenuConfigProps>;

export const userMenuConfig: UserMenuConfig = {
	en: {
		signIn: translations.signIn.en,
		manageUser: 'User profile',
		addUser: 'Add another user',
		signoutAll: 'Sign out',
		settings: 'Settings',
		viewAccount: 'My Accounts'
	},
	fr: {
		signIn: translations.signIn.fr,
		manageUser: 'Gestion comptes ',
		addUser: 'Ajouter un autre',
		signoutAll: 'Déconnectez-vous tous',
		settings: 'Paramètres de l',
		viewAccount: 'Mes comptes'
	}
};
