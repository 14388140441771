import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonToolbar,
	IonButtons,
	IonContent,
	IonButton,
	IonItemDivider,
	IonModal,
	IonTitle,
	IonBadge,
	IonSegment,
	IonSegmentButton,
	IonText,
	useIonRouter
} from '@ionic/react';
import {
	addOutline,
	downloadOutline,
	mailOpenOutline,
	peopleOutline
} from 'ionicons/icons';

import {
	AttendeeEntity,
	ContactIndividualEntity,
	ContactOrganizationEntity,
	EventEntity,
	ScheduleContactEntities,
	ScheduleContactEntity,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	ScheduleEntities,
	ScheduleEntity,
	ScheduleType
} from '../../store';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';
import AppointmentBooking from './AppointmentBooking';
import { MeetingStatusBadge } from '../components/MeetingStatusBadge';
import AddToCalendar from '@culturehq/add-to-calendar';

export interface MeetingsListProps {
	title?: string;
	content?: string;
}

export const MeetingsList: React.FC<MeetingsListProps> = props => {
	const ctx = useCtx<{}>({});
	const router = useIonRouter();

	//State Objects
	const [currentAttendee, setCurrentAttendee] = useState<
		AttendeeEntity | undefined
	>(undefined);

	const [currentEvent, setCurrentEvent] = useState<EventEntity | undefined>(
		undefined
	);
	const [showBookAppointmentModal, setShowBookAppointmentModal] = useState(
		false
	);
	const [currentTab, setCurrentTab] = useState('meetings');
	const [selectedSchedule, setSelectedSchedule] = useState<
		ScheduleEntity | undefined
	>(undefined);
	const [selectedExhibitorId, setSelectedExhibitorId] = useState<
		string | undefined
	>(undefined);

	const exhibitHallTitle = (currentEvent?.properties as any)?.app?.virtual?.mainMenu?.exhibitHall.en.title ?? 'Exhibit Hall';

	//use Effect Calls
	useEffect(() => {
		let event = ctx.virtual.event.active();
		let attendee = ctx.virtual.attendee.active();

		setCurrentAttendee(attendee);
		setCurrentEvent(event);

		if (event && attendee) {
			//Read all exhibitor scheduals and meetings
			ctx.virtual.scheduleContact.read(
				ctx,
				{ contactId: attendee.contactId },
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						let batchSize = 100;
						for (let i = 0; i < schedualIds.length; i += batchSize) {
							const batchSchedualIds = schedualIds.slice(i, i + batchSize);
							//read the meetings for the company
							if (batchSchedualIds.length > 0) {
								ctx.virtual.schedule.read(
									ctx,
									{
										ids: batchSchedualIds,
										type: ScheduleType.Meeting
									},
									(sEntities: ScheduleEntities) => {}
								);

								for (let i = 0; i < batchSchedualIds.length; i++) {
									ctx.virtual.scheduleContact.read(
										ctx,
										{ scheduleId: batchSchedualIds[i] },
										(entities: ScheduleContactEntities) => {
											if (entities && entities.length > 0) {
												let contactIds = entities.map(obj => obj.contactId);
												ctx.virtual.contact.readById(ctx, { ids: contactIds });
											}
										}
									);
								}
							}
						}
					}
				}
			);
		}
	}, []);

	const attendeeInvitations = ctx.virtual.schedule
		.all_ByAttendeeContact(
			ctx,
			currentAttendee?.contactId || '',
			[],
			[
				ScheduleContactStatusType.Accepted,
				ScheduleContactStatusType.Pending,
				ScheduleContactStatusType.Tentative
			]
		)
		.filter(x => x.start === null || x.start === undefined);

	const attendeeMeetings = ctx.virtual.schedule
		.all_ByAttendeeContact(
			ctx,
			currentAttendee?.contactId || '',
			[],
			[
				ScheduleContactStatusType.Accepted,
				ScheduleContactStatusType.Pending,
				ScheduleContactStatusType.Tentative
			]
		)
		.filter(x => x.start !== null && x.start !== undefined);

	const setViewMeeting = (
		schedule: ScheduleEntity,
		schedualOwnerContactId: string
	) => {
		setSelectedSchedule(schedule);
		setSelectedExhibitorId(schedualOwnerContactId);
		setShowBookAppointmentModal(true);
	};

	const attendeeInvitationItems = attendeeInvitations
		?.filter(x => {
			let ownerScheduleContact:
				| ScheduleContactEntity
				| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[x.id],
				[ScheduleContactRoleType.Organizer]
			)[0];

			let hasAlreadyBooked: boolean =
				ctx.virtual.schedule
					.all_ByAttendeeContactAndOrganizerContact(
						ctx,
						currentAttendee?.contactId || '',
						ownerScheduleContact?.contactId || '',
						[],
						[
							ScheduleContactStatusType.Accepted,
							ScheduleContactStatusType.Pending,
							ScheduleContactStatusType.Tentative
						]
					)
					.filter(y => y.start !== null && y.start !== undefined).length > 0;

			return !hasAlreadyBooked;
		})
		.map((schedule, index: number, scheduleList) => {
			let ownerScheduleContact:
				| ScheduleContactEntity
				| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Organizer]
			)[0];
			let ownerContact:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId || '',
				currentEvent?.id || ''
			);
			let participants: ScheduleContactEntities = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Attendee]
			);

			let currentScheduleContact:
				| ScheduleContactEntity
				| undefined = participants.find(
				x => x.contactId === currentAttendee?.contactId
			);
			let exhibitor:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId,
				currentEvent?.id || ''
			);

			if (currentScheduleContact) {
				return (
					<IonItem
						key={'meeting-item-div-' + schedule.id}
						detail={false}
						onClick={e => {
							e.stopPropagation();
						}}
					>
						<IonGrid>
							<IonRow>
								<IonCol>
									<IonText>
										<IonButton
											fill="clear"
											size="default"
											style={{ textTransform: 'none' }}
											onClick={e => {
												e.stopPropagation();
												router.push('/exhibitor/' + exhibitor?.id);
											}}
										>
											{ownerContact?.name}
										</IonButton>
									</IonText>
								</IonCol>
								<IonCol>Booth {exhibitor?.locations}</IonCol>
								<IonCol>
									{!schedule.start ? (
										<IonButton
											color={'tertiary'}
											onClick={e => {
												e.stopPropagation();
												setViewMeeting(schedule, exhibitor?.id || '');
											}}
										>
											Book A Meeting
										</IonButton>
									) : (
										<MeetingStatusBadge
											key={'schedulestatusbadge-' + currentScheduleContact.id}
											scheduleStatus={schedule.status}
											scheduleContactStatus={currentScheduleContact.status}
										></MeetingStatusBadge>
									)}
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
				);
			}
		});

	const attendeeMeetingItems = attendeeMeetings
		?.sort((a: ScheduleEntity, b: ScheduleEntity) => {
			if (!a.start) {
				return -1;
			} else {
				return moment(a.start).toDate() > moment(b.start).toDate()
					? 1
					: moment(b.start).toDate() > moment(a.start).toDate()
					? -1
					: 0;
			}
		})
		.map((schedule, index: number, scheduleList) => {
			let mainGroup = '';
			let subGroup = '';

			let mainGroupDate = moment(schedule.start).format('dddd MMMM DD, YYYY');
			let subGroupTime =
				moment(schedule.start).format('LT') +
				' - ' +
				moment(schedule.end).format('LT');
			if (index > 0) {
				let prevDate = moment(scheduleList[index - 1].start).format(
					'dddd MMMM DD, YYYY'
				);
				let prevTime =
					moment(scheduleList[index - 1].start).format('LT') +
					' - ' +
					moment(scheduleList[index - 1].end).format('LT');
				if ((mainGroupDate ?? '') != (prevDate ?? '')) {
					mainGroup = mainGroupDate ?? '';
				}
				if ((subGroupTime ?? '') != (prevTime ?? '')) {
					subGroup = subGroupTime ?? '';
				}
			} else {
				mainGroup = mainGroupDate ?? '';
				subGroup =
					(subGroupTime ?? '') +
					' ' +
					momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr();
			}

			let ownerScheduleContact:
				| ScheduleContactEntity
				| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Organizer]
			)[0];
			let ownerContact:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId || '',
				currentEvent?.id || ''
			);
			let participants: ScheduleContactEntities = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Attendee]
			);

			let currentScheduleContact:
				| ScheduleContactEntity
				| undefined = participants.find(
				x => x.contactId === currentAttendee?.contactId
			);
			let exhibitor:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId,
				currentEvent?.id || ''
			);

			if (currentScheduleContact) {
				return (
					<div key={'meeting-item-div-' + schedule.id}>
						{mainGroup !== '' ? (
							<IonItemDivider
								key={mainGroup}
								class="list-maingroup-header-event-schedule"
							>
								<IonLabel>{mainGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						{subGroup !== '' ? (
							<IonItemDivider key={subGroup} class="list-subgroup-header">
								<IonLabel>{subGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						<IonItem
							detail={true}
							button
							onClick={() => {
								setViewMeeting(schedule, exhibitor?.id || '');
							}}
						>
							<IonGrid>
								<IonRow>
									<IonCol>
										<IonLabel>{ownerContact?.name}</IonLabel>
									</IonCol>
									<IonCol>Booth {exhibitor?.locations}</IonCol>
									<IonCol hidden={true}>
										<AddToCalendar
											event={{
												name: 'Meeting with:',
												details: 'Meeting with:',
												location: '',
												startsAt: moment(schedule?.start).toISOString(),
												endsAt: moment(schedule?.end).toISOString()
											}}
										></AddToCalendar>
									</IonCol>
									<IonCol>
										<MeetingStatusBadge
											key={'schedulestatusbadge-' + currentScheduleContact.id}
											scheduleStatus={schedule.status}
											scheduleContactStatus={currentScheduleContact.status}
										></MeetingStatusBadge>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					</div>
				);
			}
		});

	const exportData = async () => {
		//setIsLoading(true);
		//setLoadingStatus('Exporting service data...');

		// All data is now loaded
		//setLoadingStatus('Building Data Set...');

		// All data is now loaded
		//setLoadingStatus('Generating File...');

		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet('Meetings');
		let cols = [
			{
				header: 'Company',
				key: 'company'
			},
			{
				header: 'Company Contact Name',
				key: 'name'
			},
			{
				header: 'Company Contact Email',
				key: 'email'
			},
			{
				header: 'Meeting With',
				key: 'host'
			},
			{
				header: 'Location',
				key: 'location'
			},
			{
				header: 'Start Time',
				key: 'start'
			},
			{
				header: 'End Time',
				key: 'end'
			}
		];

		worksheet.columns = cols;
		worksheet.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		attendeeMeetings
			?.sort((a: ScheduleEntity, b: ScheduleEntity) => {
				return moment(a.start).toDate() > moment(b.start).toDate()
					? 1
					: moment(b.start).toDate() > moment(a.start).toDate()
					? -1
					: 0;
			})
			.forEach(scheduleItem => {
				let ownerScheduleContact:
					| ScheduleContactEntity
					| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
					[scheduleItem.id],
					[ScheduleContactRoleType.Organizer]
				)[0];
				let hostScheduleContact:
					| ScheduleContactEntity
					| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
					[scheduleItem.id],
					[ScheduleContactRoleType.Host]
				)[0];
				let ownerContact:
					| ContactOrganizationEntity
					| undefined = ctx.virtual.contact.get_Exhibitor(
					ownerScheduleContact?.contactId || '',
					currentEvent?.id || ''
				);
				let hostContact:
					| ContactIndividualEntity
					| undefined = ctx.virtual.contact.get_Individual(
					hostScheduleContact?.contactId || '',
					currentEvent?.id || ''
				);
				worksheet.addRow(
					{
						company: ownerContact?.name || '',
						name:
							ownerContact?.contactFirstName ||
							'' + ' ' + ownerContact?.contactLastName ||
							'',
						email: ownerContact?.contactEmail || '',
						host: hostContact ? hostContact?.name || '' : '',
						location: 'Booth ' + ownerContact?.locations || '',
						start: moment(scheduleItem.start).format('LLL z'),
						end: moment(scheduleItem.end).format('LLL z')
					},
					''
				);
			});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], {
			type: fileType
		});

		saveAs(
			blob,
			(currentEvent!.name || 'MyMeetings') +
				'-Meetings-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		//setIsLoading(false);
		//setLoadingStatus('');
	};

	return (
		<>
			<IonModal
				isOpen={showBookAppointmentModal}
				onDidDismiss={() => setShowBookAppointmentModal(false)}
			>
				<IonToolbar>
					<IonTitle>Meet With </IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => setShowBookAppointmentModal(false)}>
							Close
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent>
					<AppointmentBooking
						exhibitorId={selectedExhibitorId}
						attendeeId={currentAttendee?.id}
					></AppointmentBooking>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonButtons
					slot="end"
					hidden={!attendeeMeetings || attendeeMeetings.length <= 0}
				>
					<IonButton
						onClick={(e: any) => {
							e.persist();
						}}
						routerLink="/exhibit-hall"
						hidden={
							currentAttendee &&
							ctx.virtual.attendee.isCompanyRepresentative(currentAttendee)
						}
					>
						<IonIcon slot="start" icon={addOutline} />
						Schedule Meeting
					</IonButton>
					<IonButton slot="end" onClick={exportData}>
						<IonIcon slot="start" icon={downloadOutline} />
						Export Meetings
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<IonSegment
				value={currentTab}
				onIonChange={e => setCurrentTab(e.detail.value || 'Leads')}
			>
				<IonSegmentButton value="meetings">
					<IonLabel>Meetings</IonLabel>
				</IonSegmentButton>
				<IonSegmentButton value="invitations">
					<IonLabel>
						Invitations{' '}
						<IonBadge hidden={(attendeeInvitationItems.length ?? 0) <= 0}>
							{attendeeInvitationItems.length ?? 0}
						</IonBadge>
					</IonLabel>
				</IonSegmentButton>
			</IonSegment>
			<IonContent>
				<div hidden={currentTab !== 'meetings'}>
					<IonList hidden={attendeeMeetingItems.length <= 0}>
						<IonItemDivider class="list-maingroup-header-event-schedule">
							<IonLabel>Meetings</IonLabel>
						</IonItemDivider>
						<IonItemDivider
							key={'subGroupInvitations'}
							class="list-subgroup-header"
						>
							<IonLabel style={{ color: '#3A3B3C' }}>
								Your approved and pending meetings
							</IonLabel>
						</IonItemDivider>
						{attendeeMeetingItems}
					</IonList>
					<div
						className="ion-text-center"
						hidden={attendeeMeetings && attendeeMeetings.length > 0}
					>
						<br />
						<h1>
							<IonIcon icon={peopleOutline} size="large" />
						</h1>
						<h4>
							You have no meetings currently scheduled. <br />
						</h4>
						<p>
							If you would like to book a meeting please go to
							the {exhibitHallTitle} area to view available options.
						</p>
						<IonButton routerLink="/exhibit-hall">
							View Available
						</IonButton>
					</div>
				</div>
				<div hidden={currentTab !== 'invitations'}>
					<IonList hidden={attendeeInvitationItems.length <= 0}>
						<IonItemDivider
							key={'mainGroupInvitations'}
							class="list-maingroup-header-event-schedule"
						>
							<IonLabel>Invitations</IonLabel>
						</IonItemDivider>
						<IonItemDivider
							key={'subGroupInvitations'}
							class="list-subgroup-header"
						>
							<IonLabel style={{ color: '#3A3B3C' }}>
								You have been invited to visit these exhibitors
							</IonLabel>
						</IonItemDivider>
						{attendeeInvitationItems}
					</IonList>
					<div
						className="ion-text-center"
						hidden={attendeeInvitationItems.length > 0}
					>
						<br />
						<h1>
							<IonIcon icon={mailOpenOutline} size="large" />
						</h1>
						<h4>
							You have no invitations currently.
							<br />
						</h4>
						<p>
							If you would like to book a meeting please go to
							the {exhibitHallTitle} area to view available options.
						</p>

						<IonButton routerLink="/exhibit-hall">
							View Available
						</IonButton>
					</div>
				</div>
			</IonContent>
		</>
	);
};
