// tslint:disable
/**
 * CONEXSYS ScheduleContact Portal API
 * The API for the CONEXSYS ScheduleContact Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * ScheduleContact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ScheduleContactHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ScheduleContactEntities, ScheduleContactEntity } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import {
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	Tags
} from '../models';
import { modifyEntity } from '../../../app/utils';

export enum ScheduleContactApiOperation {
	readScheduleContacts = 'readScheduleContacts',
	writeScheduleContacts = 'writeScheduleContacts'
}

export interface ReadScheduleContactsRequest {
	eventId: string;
	scheduleId?: string;
	contactId?: string;
	role?: ScheduleContactRoleType;
	status?: ScheduleContactStatusType;
	modifiedFrom?: string;
	tags?: Tags;
	ids?: string[];
	queryLimit?: number;
	queryBookmark?: string;
}

export interface WriteScheduleContactsRequest {
	eventId: string;
	scheduleContacts: ScheduleContactEntities;
}

/**
 * Read scheduleContacts
 */
export async function readScheduleContacts(
	ctx: UseCtx<any>,
	requestParameters: ReadScheduleContactsRequest
): Promise<ScheduleContactEntities> {
	const operationId = ScheduleContactApiOperation.readScheduleContacts;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		ScheduleContactEntities,
		ReadScheduleContactsRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: ScheduleContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	if (entities.length > 0) {
		// set each entity.__state.eventId to the eventId from the params
		entities.forEach((entity, i, a) => {
			a[i].__state = _.merge(entity.__state || {}, {
				eventId: requestParameters.eventId
			});
		});

		// patch entities with an api success data for the operation
		setEntitiesApiOperationState<
			ScheduleContactEntities,
			ScheduleContactHelper
		>(
			ctx.virtual.scheduleContact,
			entities,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.scheduleContact.upsert(entities);
	}

	// return the entities to the caller for direct reference
	return entities;
}
/**
 * Write Schedule Contacts
 */
export async function writeScheduleContacts(
	ctx: UseCtx<any>,
	requestParameters: WriteScheduleContactsRequest
): Promise<ScheduleContactEntities> {
	const operationId = ScheduleContactApiOperation.writeScheduleContacts,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.scheduleContacts.forEach(
		(entity: ScheduleContactEntity, i: number, a: ScheduleContactEntities) => {
			a[i] = modifyEntity<ScheduleContactEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<
		ScheduleContactEntities,
		WriteScheduleContactsRequest
	>(ctx, config.apiId, operationId, requestParameters, 'scheduleContacts');

	let entities: ScheduleContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ScheduleContactEntities, ScheduleContactHelper>(
		ctx.virtual.scheduleContact,
		entities,
		operationId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.virtual.scheduleContact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
