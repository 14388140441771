import {
	MenuCollection,
	MenuCollectionState,
	MenuId,
	MenuEntity_Some,
	MenuEntityPatch_Some,
	MenuId_Some,
	menuCollectionDefault,
	menuCollectionMutateIdOpts
} from '../collections/Menu';
import * as actions from '../actions/Menu';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Menu reducer
 *
 * @export
 * @param {MenuCollection} [state=menuCollectionDefault]
 * @param {MenuActionTypes} action
 * @returns
 */
export function menuReducer(
	state: MenuCollection = menuCollectionDefault,
	action: actions.MenuActionTypes
) {
	switch (action.type) {
		case actions.MENU_MUTATE_ID:
			return collectionReducerActions.mutateId<MenuCollection>(
				state,
				action.payload,
				menuCollectionMutateIdOpts
			);
		case actions.MENU_SET:
			return collectionReducerActions.set<MenuCollection, MenuId>(
				state,
				action.payload
			);
		case actions.MENU_TOGGLE:
			return collectionReducerActions.toggle<MenuCollection, MenuId>(
				state,
				action.payload
			);
		case actions.MENU_SELECT:
			return collectionReducerActions.select<MenuCollection, MenuId_Some>(
				state,
				action.payload
			);
		case actions.MENU_DESELECT:
			return collectionReducerActions.deselect<MenuCollection, MenuId_Some>(
				state,
				action.payload
			);
		case actions.MENU_UPSERT:
			return collectionReducerActions.upsert<MenuCollection, MenuEntity_Some>(
				state,
				action.payload
			);
		case actions.MENU_PATCH:
			return collectionReducerActions.patch<
				MenuCollection,
				MenuEntityPatch_Some
			>(state, action.payload);
		case actions.MENU_CHANGE:
			return collectionReducerActions.change<
				MenuCollection,
				MenuEntityPatch_Some
			>(state, action.payload);
		case actions.MENU_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<MenuCollection, MenuId_Some>(
				state,
				action.payload
			);
		case actions.MENU_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				MenuCollection,
				MenuId_Some
			>(state, action.payload);
		case actions.MENU_DELETE:
			return collectionReducerActions.delete<MenuCollection, MenuId_Some>(
				state,
				action.payload
			);
		case actions.MENU_UNDELETE:
			return collectionReducerActions.undelete<MenuCollection, MenuId_Some>(
				state,
				action.payload
			);
		case actions.MENU_SETSTATE:
			return collectionReducerActions.setState<
				MenuCollection,
				MenuCollectionState
			>(state, action.payload.state);
		case actions.MENU_PATCHSTATE:
			return collectionReducerActions.patchState<
				MenuCollection,
				MenuCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
