// tslint:disable
/**
 * CONEXSYS Virtual Portal API
 * The API for the CONEXSYS Virtual Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { AttendeeHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { AttendeeEntity } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';

export enum AttendeeApiOperation {
	authenticateAttendee = 'authenticateAttendee'
}

export interface AuthenticateAttendeeRequest {
	eventId: string;
	username: string;
	accessCode: string;
}

/**
 * Get all attendees for the token service
 * Get service attendees
 */
export async function authenticateAttendee(
	ctx: UseCtx<any>,
	requestParameters: AuthenticateAttendeeRequest
): Promise<AttendeeEntity | undefined> {
	const operationId = AttendeeApiOperation.authenticateAttendee;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		AttendeeEntity,
		AuthenticateAttendeeRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entity: AttendeeEntity | undefined = response.response.data;

	if (entity) {
		// set entity.__state.eventId to the eventId from the params
		entity.__state = _.merge(entity.__state || {}, {
			eventId: requestParameters.eventId
		});

		// patch entity with an api success data for the operation
		setEntitiesApiOperationState<AttendeeEntity, AttendeeHelper>(
			ctx.virtual.attendee,
			entity,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.attendee.upsert(entity);
	}

	// return the entity to the caller for direct reference
	return entity;
}
