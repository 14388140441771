import {
	QuestionCollection,
	QuestionCollectionState,
	QuestionId,
	QuestionEntity_Some,
	QuestionEntityPatch_Some,
	QuestionId_Some,
	questionCollectionDefault,
	questionCollectionMutateIdOpts
} from '../collections/Question';
import * as actions from '../actions/Question';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Question reducer
 *
 * @export
 * @param {QuestionCollection} [state=questionCollectionDefault]
 * @param {QuestionActionTypes} action
 * @returns
 */
export function questionReducer(
	state: QuestionCollection = questionCollectionDefault,
	action: actions.QuestionActionTypes
) {
	switch (action.type) {
		case actions.QUESTION_MUTATE_ID:
			return collectionReducerActions.mutateId<QuestionCollection>(
				state,
				action.payload,
				questionCollectionMutateIdOpts
			);
		case actions.QUESTION_SET:
			return collectionReducerActions.set<QuestionCollection, QuestionId>(
				state,
				action.payload
			);
		case actions.QUESTION_TOGGLE:
			return collectionReducerActions.toggle<QuestionCollection, QuestionId>(
				state,
				action.payload
			);
		case actions.QUESTION_SELECT:
			return collectionReducerActions.select<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_DESELECT:
			return collectionReducerActions.deselect<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_UPSERT:
			return collectionReducerActions.upsert<
				QuestionCollection,
				QuestionEntity_Some
			>(state, action.payload);
		case actions.QUESTION_PATCH:
			return collectionReducerActions.patch<
				QuestionCollection,
				QuestionEntityPatch_Some
			>(state, action.payload);
		case actions.QUESTION_CHANGE:
			return collectionReducerActions.change<
				QuestionCollection,
				QuestionEntityPatch_Some
			>(state, action.payload);
		case actions.QUESTION_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_DELETE:
			return collectionReducerActions.delete<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_UNDELETE:
			return collectionReducerActions.undelete<
				QuestionCollection,
				QuestionId_Some
			>(state, action.payload);
		case actions.QUESTION_SETSTATE:
			return collectionReducerActions.setState<
				QuestionCollection,
				QuestionCollectionState
			>(state, action.payload.state);
		case actions.QUESTION_PATCHSTATE:
			return collectionReducerActions.patchState<
				QuestionCollection,
				QuestionCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
