import {
	ScheduleContactCollection,
	ScheduleContactCollectionState,
	ScheduleContactId,
	ScheduleContactEntity_Some,
	ScheduleContactEntityPatch_Some,
	ScheduleContactId_Some,
	scheduleContactCollectionDefault,
	scheduleContactCollectionMutateIdOpts
} from '../collections/ScheduleContact';
import * as actions from '../actions/ScheduleContact';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * ScheduleContact reducer
 *
 * @export
 * @param {ScheduleContactCollection} [state=scheduleContactCollectionDefault]
 * @param {ScheduleContactActionTypes} action
 * @returns
 */
export function scheduleContactReducer(
	state: ScheduleContactCollection = scheduleContactCollectionDefault,
	action: actions.ScheduleContactActionTypes
) {
	switch (action.type) {
		case actions.SCHEDULECONTACT_MUTATE_ID:
			return collectionReducerActions.mutateId<ScheduleContactCollection>(
				state,
				action.payload,
				scheduleContactCollectionMutateIdOpts
			);
		case actions.SCHEDULECONTACT_SET:
			return collectionReducerActions.set<
				ScheduleContactCollection,
				ScheduleContactId
			>(state, action.payload);
		case actions.SCHEDULECONTACT_TOGGLE:
			return collectionReducerActions.toggle<
				ScheduleContactCollection,
				ScheduleContactId
			>(state, action.payload);
		case actions.SCHEDULECONTACT_SELECT:
			return collectionReducerActions.select<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_DESELECT:
			return collectionReducerActions.deselect<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_UPSERT:
			return collectionReducerActions.upsert<
				ScheduleContactCollection,
				ScheduleContactEntity_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_PATCH:
			return collectionReducerActions.patch<
				ScheduleContactCollection,
				ScheduleContactEntityPatch_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_CHANGE:
			return collectionReducerActions.change<
				ScheduleContactCollection,
				ScheduleContactEntityPatch_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_DELETE:
			return collectionReducerActions.delete<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_UNDELETE:
			return collectionReducerActions.undelete<
				ScheduleContactCollection,
				ScheduleContactId_Some
			>(state, action.payload);
		case actions.SCHEDULECONTACT_SETSTATE:
			return collectionReducerActions.setState<
				ScheduleContactCollection,
				ScheduleContactCollectionState
			>(state, action.payload.state);
		case actions.SCHEDULECONTACT_PATCHSTATE:
			return collectionReducerActions.patchState<
				ScheduleContactCollection,
				ScheduleContactCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
