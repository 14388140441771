// tslint:disable
/**
 * CONEXSYS Virtual ScheduleContact API
 *  # Overview CONEXSYS Virtual ScheduleContact API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Schedule Contact Role type
 * @export
 * @enum {string}
 */
export enum ScheduleContactRoleType {
	Organizer = 'organizer',
	Host = 'host',
	Moderator = 'moderator',
	Attendee = 'attendee'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesScheduleContactRoleType = [
	'organizer',
	'host',
	'moderator',
	'attendee'
];
