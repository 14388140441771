import {
	ProductCollection,
	ProductCollectionState,
	ProductId,
	ProductEntity_Some,
	ProductEntityPatch_Some,
	ProductId_Some,
	productCollectionDefault,
	productCollectionMutateIdOpts
} from '../collections/Product';
import * as actions from '../actions/Product';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Product reducer
 *
 * @export
 * @param {ProductCollection} [state=productCollectionDefault]
 * @param {ProductActionTypes} action
 * @returns
 */
export function productReducer(
	state: ProductCollection = productCollectionDefault,
	action: actions.ProductActionTypes
) {
	switch (action.type) {
		case actions.PRODUCT_MUTATE_ID:
			return collectionReducerActions.mutateId<ProductCollection>(
				state,
				action.payload,
				productCollectionMutateIdOpts
			);
		case actions.PRODUCT_SET:
			return collectionReducerActions.set<ProductCollection, ProductId>(
				state,
				action.payload
			);
		case actions.PRODUCT_TOGGLE:
			return collectionReducerActions.toggle<ProductCollection, ProductId>(
				state,
				action.payload
			);
		case actions.PRODUCT_SELECT:
			return collectionReducerActions.select<ProductCollection, ProductId_Some>(
				state,
				action.payload
			);
		case actions.PRODUCT_DESELECT:
			return collectionReducerActions.deselect<
				ProductCollection,
				ProductId_Some
			>(state, action.payload);
		case actions.PRODUCT_UPSERT:
			return collectionReducerActions.upsert<
				ProductCollection,
				ProductEntity_Some
			>(state, action.payload);
		case actions.PRODUCT_PATCH:
			return collectionReducerActions.patch<
				ProductCollection,
				ProductEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCT_CHANGE:
			return collectionReducerActions.change<
				ProductCollection,
				ProductEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ProductCollection,
				ProductId_Some
			>(state, action.payload);
		case actions.PRODUCT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ProductCollection,
				ProductId_Some
			>(state, action.payload);
		case actions.PRODUCT_DELETE:
			return collectionReducerActions.delete<ProductCollection, ProductId_Some>(
				state,
				action.payload
			);
		case actions.PRODUCT_UNDELETE:
			return collectionReducerActions.undelete<
				ProductCollection,
				ProductId_Some
			>(state, action.payload);
		case actions.PRODUCT_SETSTATE:
			return collectionReducerActions.setState<
				ProductCollection,
				ProductCollectionState
			>(state, action.payload.state);
		case actions.PRODUCT_PATCHSTATE:
			return collectionReducerActions.patchState<
				ProductCollection,
				ProductCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
