import {
	ProductId_Some,
	ProductEntity_Some,
	ProductEntityPatch_Some,
	ProductCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const PRODUCT_MUTATE_ID = '@app/PRODUCT_MUTATE_ID';
export const PRODUCT_SET = '@app/PRODUCT_SET';
export const PRODUCT_TOGGLE = '@app/PRODUCT_TOGGLE';
export const PRODUCT_SELECT = '@app/PRODUCT_SELECT';
export const PRODUCT_DESELECT = '@app/PRODUCT_DESELECT';
export const PRODUCT_UPSERT = '@app/PRODUCT_UPSERT';
export const PRODUCT_PATCH = '@app/PRODUCT_PATCH';
export const PRODUCT_CHANGE = '@app/PRODUCT_CHANGE';
export const PRODUCT_APPLY_CHANGES = '@app/PRODUCT_APPLY_CHANGES';
export const PRODUCT_CANCEL_CHANGES = '@app/PRODUCT_CANCEL_CHANGES';
export const PRODUCT_DELETE = '@app/PRODUCT_DELETE';
export const PRODUCT_UNDELETE = '@app/PRODUCT_UNDELETE';
export const PRODUCT_SETSTATE = '@app/PRODUCT_SETSTATE';
export const PRODUCT_PATCHSTATE = '@app/PRODUCT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ProductAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ProductAction_MutateId extends EntityAction_MutateId {
	type: typeof PRODUCT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active product
 *
 * @interface ProductAction_Set
 * @extends {EntityAction_Set}
 */
export interface ProductAction_Set extends EntityAction_Set {
	type: typeof PRODUCT_SET;
	payload?: EntityId;
}

/**
 * Toggle active product
 *
 * @interface ProductAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ProductAction_Toggle extends EntityAction_Toggle {
	type: typeof PRODUCT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select product entities
 *
 * @interface ProductAction_Select
 * @extends {EntityAction_Select}
 */
interface ProductAction_Select extends EntityAction_Select {
	type: typeof PRODUCT_SELECT;
	payload: ProductId_Some;
}

/**
 * Deselect product entities
 *
 * @interface ProductAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ProductAction_Deselect extends EntityAction_Deselect {
	type: typeof PRODUCT_DESELECT;
	payload: ProductId_Some;
}

/**
 * Upsert product entities
 *
 * @interface ProductAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ProductAction_Upsert extends EntityAction_Upsert {
	type: typeof PRODUCT_UPSERT;
	payload: ProductEntity_Some;
}

/**
 * Patch product entities
 *
 * @interface ProductAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ProductAction_Patch extends EntityAction_Patch {
	type: typeof PRODUCT_PATCH;
	payload: ProductEntityPatch_Some;
}

/**
 * Change product entities
 *
 * @interface ProductAction_Change
 * @extends {EntityAction_Change}
 */
interface ProductAction_Change extends EntityAction_Change {
	type: typeof PRODUCT_CHANGE;
	payload: ProductEntityPatch_Some;
}

/**
 * Apply changes to product entities
 *
 * @interface ProductAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ProductAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof PRODUCT_APPLY_CHANGES;
	payload: ProductId_Some;
}

/**
 * Cancel changes to product entities
 *
 * @interface ProductAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ProductAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof PRODUCT_CANCEL_CHANGES;
	payload: ProductId_Some;
}

/**
 * Delete product entities
 *
 * @interface ProductAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ProductAction_Delete extends EntityAction_Delete {
	type: typeof PRODUCT_DELETE;
	payload: ProductId_Some;
}

/**
 * Undelete product entities
 *
 * @interface ProductAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ProductAction_Undelete extends EntityAction_Undelete {
	type: typeof PRODUCT_UNDELETE;
	payload: ProductId_Some;
}

/**
 * Set product collection / entity state
 *
 * @interface ProductAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ProductAction_SetState extends EntityAction_SetState {
	type: typeof PRODUCT_SETSTATE;
}

/**
 * PAtch product collection / entity state
 *
 * @interface ProductAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ProductAction_PatchState extends EntityAction_PatchState {
	type: typeof PRODUCT_PATCHSTATE;
}

/**
 * Export product action types
 *
 * @export
 */
export type ProductActionTypes =
	| ProductAction_MutateId
	| ProductAction_MutateId
	| ProductAction_Set
	| ProductAction_Toggle
	| ProductAction_Select
	| ProductAction_Deselect
	| ProductAction_Upsert
	| ProductAction_Patch
	| ProductAction_Change
	| ProductAction_ApplyChanges
	| ProductAction_CancelChanges
	| ProductAction_Delete
	| ProductAction_Undelete
	| ProductAction_SetState
	| ProductAction_PatchState;

/**
 * Product actions helper interface
 *
 * @export
 * @interface IProductActions
 * @extends {EntityActions}
 */
export interface IProductActions extends EntityActions {
	//customAction(ids: ProductId_Some): ProductAction_CustomAction;
}

/**
 * Product actions helper
 *
 * @export
 * @class ProductActions
 * @implements {EntityBaseActions}
 */
export class ProductActions implements IProductActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ProductAction_MutateId {
		return { type: PRODUCT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ProductAction_Set {
		return { type: PRODUCT_SET, payload: id };
	}
	toggle(id?: EntityId): ProductAction_Toggle {
		return { type: PRODUCT_TOGGLE, payload: id };
	}
	select(ids: ProductId_Some): ProductAction_Select {
		return {
			type: PRODUCT_SELECT,
			payload: ids
		};
	}
	deselect(ids: ProductId_Some): ProductAction_Deselect {
		return {
			type: PRODUCT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ProductEntity_Some): ProductAction_Upsert {
		return {
			type: PRODUCT_UPSERT,
			payload: entities
		};
	}
	patch(entities: ProductEntityPatch_Some): ProductAction_Patch {
		return {
			type: PRODUCT_PATCH,
			payload: entities
		};
	}
	change(entities: ProductEntityPatch_Some): ProductAction_Change {
		return {
			type: PRODUCT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ProductId_Some): ProductAction_ApplyChanges {
		return {
			type: PRODUCT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ProductId_Some): ProductAction_CancelChanges {
		return {
			type: PRODUCT_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ProductId_Some): ProductAction_Delete {
		return {
			type: PRODUCT_DELETE,
			payload: ids
		};
	}
	undelete(ids: ProductId_Some): ProductAction_Undelete {
		return {
			type: PRODUCT_UNDELETE,
			payload: ids
		};
	}
	setState(state: ProductCollectionState): ProductAction_SetState {
		return {
			type: PRODUCT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ProductCollectionState>
	): ProductAction_PatchState {
		return {
			type: PRODUCT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the product actions helper
 *
 * @export
 */
export const productActions = new ProductActions();
