// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The product viewing type
 * @export
 * @enum {string}
 */
export enum ProductViewingType {
	NotSelected = 'not-selected',
	InPersonOnly = 'in-person-only',
	OnlineOnly = 'online-only',
	InPersonAndOnline = 'in-person-and-online'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesProductViewingType = [
	'not-selected',
	'in-person-only',
	'online-only',
	'in-person-and-online'
];
