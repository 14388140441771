import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getContactRegistrationTypeCollection,
	ContactRegistrationTypeId,
	ContactRegistrationTypeIds,
	ContactRegistrationTypeId_Some,
	ContactRegistrationTypeEntity,
	ContactRegistrationTypeEntities,
	ContactRegistrationTypeEntity_Some,
	ContactRegistrationTypeEntityPatch_Some,
	ContactRegistrationTypeCollection,
	ContactRegistrationTypeCollectionState,
	IContactRegistrationTypeActions,
	contactRegistrationTypeActions,
	ContactRegistrationTypeActionTypes
} from '..';
import {
	readContactRegistrationTypes,
	ReadContactRegistrationTypesRequest,
	ContactRegistrationTypeApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactRegistrationTypeExhibitorEntities,
	ContactRegistrationTypeSponsorEntities,
	RegistrationTypeEntities
} from '../collections';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as contactRegistrationType from '../contactRegistrationTypes/ContactRegistrationType';

/**
 * ContactRegistrationType helper interface
 *
 * @export
 * @interface IContactRegistrationTypeHelper
 * @extends {IEntityHelper}
 */
export interface IContactRegistrationTypeHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * ContactRegistrationType helper options interface
 *
 * @export
 * @interface ContactRegistrationTypeHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ContactRegistrationTypeHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const contactRegistrationTypeHelperOpts: ContactRegistrationTypeHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * ContactRegistrationType helper
 *
 * @export
 * @class ContactRegistrationTypeHelper
 * @extends {EntityHelper<ContactRegistrationTypeCollection, ContactRegistrationTypeActionTypes, ContactRegistrationTypeActions, ContactRegistrationTypeEntity, ContactRegistrationTypeEntities, ContactRegistrationTypeEntity_Some, ContactRegistrationTypeEntityPatch_Some, ContactRegistrationTypeId, ContactRegistrationTypeIds, ContactRegistrationTypeId_Some, ContactRegistrationTypeCollectionState, ContactRegistrationTypeHelperOpts>}
 * @implements {IContactRegistrationTypeHelper}
 */
export class ContactRegistrationTypeHelper
	extends EntityHelper<
		ContactRegistrationTypeCollection,
		ContactRegistrationTypeActionTypes,
		IContactRegistrationTypeActions,
		ContactRegistrationTypeEntity,
		ContactRegistrationTypeEntities,
		ContactRegistrationTypeEntity_Some,
		ContactRegistrationTypeEntityPatch_Some,
		ContactRegistrationTypeId,
		ContactRegistrationTypeIds,
		ContactRegistrationTypeId_Some,
		ContactRegistrationTypeCollectionState,
		ContactRegistrationTypeHelperOpts
	>
	implements IContactRegistrationTypeHelper {
	constructor() {
		super(
			useSelector(getContactRegistrationTypeCollection),
			contactRegistrationTypeActions,
			useDispatch(),
			contactRegistrationTypeHelperOpts
		);
		this.collection = useSelector(getContactRegistrationTypeCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ContactRegistrationTypeApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadContactRegistrationTypesRequest> = {},
		callback?: any
	): Promise<ContactRegistrationTypeEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(
				ContactRegistrationTypeApiOperation.readContactRegistrationTypes
			);
		let request: ReadContactRegistrationTypesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ContactRegistrationTypeEntities = await readContactRegistrationTypes(
			ctx,
			request
		)
			.then((entities: ContactRegistrationTypeEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async read_Exhibitor(
		ctx: UseCtx<any>,
		callback?: any
	): Promise<ContactRegistrationTypeExhibitorEntities> {
		if (!ctx.virtual.event.active()) return [];

		// get all Exhibitor registration types
		let registrationTypeIds: string[] = ctx.virtual.registrationType
			.all_Exhibitor(ctx)
			.map(entity => entity.id);
		if (registrationTypeIds.length === 0) return [];

		let request: ReadContactRegistrationTypesRequest = {
			eventId: ctx.virtual.event.active()?.id || '',
			registrationTypeIds
		};
		if (request.eventId === '') return [];

		let entities: ContactRegistrationTypeExhibitorEntities = await readContactRegistrationTypes(
			ctx,
			request
		)
			.then((entities: ContactRegistrationTypeExhibitorEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async read_Sponsor(
		ctx: UseCtx<any>,
		callback?: any
	): Promise<ContactRegistrationTypeSponsorEntities> {
		if (!ctx.virtual.event.active()) return [];

		// get all Sponsor registration types
		let registrationTypeIds: string[] = ctx.virtual.registrationType
			.all_Sponsor(ctx)
			.map(entity => entity.id);
		if (registrationTypeIds.length === 0) return [];

		let request: ReadContactRegistrationTypesRequest = {
			eventId: ctx.virtual.event.active()?.id || '',
			registrationTypeIds
		};
		if (request.eventId === '') return [];

		let entities: ContactRegistrationTypeSponsorEntities = await readContactRegistrationTypes(
			ctx,
			request
		)
			.then((entities: ContactRegistrationTypeSponsorEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): ContactRegistrationTypeEntities {
		return this.all().filter(isObjectStatusActive);
	}
}
