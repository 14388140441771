import {
	ProductContactId_Some,
	ProductContactEntity_Some,
	ProductContactEntityPatch_Some,
	ProductContactCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const PRODUCTCONTACT_MUTATE_ID = '@app/PRODUCTCONTACT_MUTATE_ID';
export const PRODUCTCONTACT_SET = '@app/PRODUCTCONTACT_SET';
export const PRODUCTCONTACT_TOGGLE = '@app/PRODUCTCONTACT_TOGGLE';
export const PRODUCTCONTACT_SELECT = '@app/PRODUCTCONTACT_SELECT';
export const PRODUCTCONTACT_DESELECT = '@app/PRODUCTCONTACT_DESELECT';
export const PRODUCTCONTACT_UPSERT = '@app/PRODUCTCONTACT_UPSERT';
export const PRODUCTCONTACT_PATCH = '@app/PRODUCTCONTACT_PATCH';
export const PRODUCTCONTACT_CHANGE = '@app/PRODUCTCONTACT_CHANGE';
export const PRODUCTCONTACT_APPLY_CHANGES = '@app/PRODUCTCONTACT_APPLY_CHANGES';
export const PRODUCTCONTACT_CANCEL_CHANGES =
	'@app/PRODUCTCONTACT_CANCEL_CHANGES';
export const PRODUCTCONTACT_DELETE = '@app/PRODUCTCONTACT_DELETE';
export const PRODUCTCONTACT_UNDELETE = '@app/PRODUCTCONTACT_UNDELETE';
export const PRODUCTCONTACT_SETSTATE = '@app/PRODUCTCONTACT_SETSTATE';
export const PRODUCTCONTACT_PATCHSTATE = '@app/PRODUCTCONTACT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ProductContactAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ProductContactAction_MutateId extends EntityAction_MutateId {
	type: typeof PRODUCTCONTACT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active productContact
 *
 * @interface ProductContactAction_Set
 * @extends {EntityAction_Set}
 */
export interface ProductContactAction_Set extends EntityAction_Set {
	type: typeof PRODUCTCONTACT_SET;
	payload?: EntityId;
}

/**
 * Toggle active productContact
 *
 * @interface ProductContactAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ProductContactAction_Toggle extends EntityAction_Toggle {
	type: typeof PRODUCTCONTACT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select productContact entities
 *
 * @interface ProductContactAction_Select
 * @extends {EntityAction_Select}
 */
interface ProductContactAction_Select extends EntityAction_Select {
	type: typeof PRODUCTCONTACT_SELECT;
	payload: ProductContactId_Some;
}

/**
 * Deselect productContact entities
 *
 * @interface ProductContactAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ProductContactAction_Deselect extends EntityAction_Deselect {
	type: typeof PRODUCTCONTACT_DESELECT;
	payload: ProductContactId_Some;
}

/**
 * Upsert productContact entities
 *
 * @interface ProductContactAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ProductContactAction_Upsert extends EntityAction_Upsert {
	type: typeof PRODUCTCONTACT_UPSERT;
	payload: ProductContactEntity_Some;
}

/**
 * Patch productContact entities
 *
 * @interface ProductContactAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ProductContactAction_Patch extends EntityAction_Patch {
	type: typeof PRODUCTCONTACT_PATCH;
	payload: ProductContactEntityPatch_Some;
}

/**
 * Change productContact entities
 *
 * @interface ProductContactAction_Change
 * @extends {EntityAction_Change}
 */
interface ProductContactAction_Change extends EntityAction_Change {
	type: typeof PRODUCTCONTACT_CHANGE;
	payload: ProductContactEntityPatch_Some;
}

/**
 * Apply changes to productContact entities
 *
 * @interface ProductContactAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ProductContactAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof PRODUCTCONTACT_APPLY_CHANGES;
	payload: ProductContactId_Some;
}

/**
 * Cancel changes to productContact entities
 *
 * @interface ProductContactAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ProductContactAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof PRODUCTCONTACT_CANCEL_CHANGES;
	payload: ProductContactId_Some;
}

/**
 * Delete productContact entities
 *
 * @interface ProductContactAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ProductContactAction_Delete extends EntityAction_Delete {
	type: typeof PRODUCTCONTACT_DELETE;
	payload: ProductContactId_Some;
}

/**
 * Undelete productContact entities
 *
 * @interface ProductContactAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ProductContactAction_Undelete extends EntityAction_Undelete {
	type: typeof PRODUCTCONTACT_UNDELETE;
	payload: ProductContactId_Some;
}

/**
 * Set productContact collection / entity state
 *
 * @interface ProductContactAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ProductContactAction_SetState extends EntityAction_SetState {
	type: typeof PRODUCTCONTACT_SETSTATE;
}

/**
 * PAtch productContact collection / entity state
 *
 * @interface ProductContactAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ProductContactAction_PatchState extends EntityAction_PatchState {
	type: typeof PRODUCTCONTACT_PATCHSTATE;
}

/**
 * Export productContact action types
 *
 * @export
 */
export type ProductContactActionTypes =
	| ProductContactAction_MutateId
	| ProductContactAction_MutateId
	| ProductContactAction_Set
	| ProductContactAction_Toggle
	| ProductContactAction_Select
	| ProductContactAction_Deselect
	| ProductContactAction_Upsert
	| ProductContactAction_Patch
	| ProductContactAction_Change
	| ProductContactAction_ApplyChanges
	| ProductContactAction_CancelChanges
	| ProductContactAction_Delete
	| ProductContactAction_Undelete
	| ProductContactAction_SetState
	| ProductContactAction_PatchState;

/**
 * ProductContact actions helper interface
 *
 * @export
 * @interface IProductContactActions
 * @extends {EntityActions}
 */
export interface IProductContactActions extends EntityActions {
	//customAction(ids: ProductContactId_Some): ProductContactAction_CustomAction;
}

/**
 * ProductContact actions helper
 *
 * @export
 * @class ProductContactActions
 * @implements {EntityBaseActions}
 */
export class ProductContactActions implements IProductContactActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ProductContactAction_MutateId {
		return { type: PRODUCTCONTACT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ProductContactAction_Set {
		return { type: PRODUCTCONTACT_SET, payload: id };
	}
	toggle(id?: EntityId): ProductContactAction_Toggle {
		return { type: PRODUCTCONTACT_TOGGLE, payload: id };
	}
	select(ids: ProductContactId_Some): ProductContactAction_Select {
		return {
			type: PRODUCTCONTACT_SELECT,
			payload: ids
		};
	}
	deselect(ids: ProductContactId_Some): ProductContactAction_Deselect {
		return {
			type: PRODUCTCONTACT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ProductContactEntity_Some): ProductContactAction_Upsert {
		return {
			type: PRODUCTCONTACT_UPSERT,
			payload: entities
		};
	}
	patch(entities: ProductContactEntityPatch_Some): ProductContactAction_Patch {
		return {
			type: PRODUCTCONTACT_PATCH,
			payload: entities
		};
	}
	change(
		entities: ProductContactEntityPatch_Some
	): ProductContactAction_Change {
		return {
			type: PRODUCTCONTACT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ProductContactId_Some): ProductContactAction_ApplyChanges {
		return {
			type: PRODUCTCONTACT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: ProductContactId_Some
	): ProductContactAction_CancelChanges {
		return {
			type: PRODUCTCONTACT_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ProductContactId_Some): ProductContactAction_Delete {
		return {
			type: PRODUCTCONTACT_DELETE,
			payload: ids
		};
	}
	undelete(ids: ProductContactId_Some): ProductContactAction_Undelete {
		return {
			type: PRODUCTCONTACT_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: ProductContactCollectionState
	): ProductContactAction_SetState {
		return {
			type: PRODUCTCONTACT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ProductContactCollectionState>
	): ProductContactAction_PatchState {
		return {
			type: PRODUCTCONTACT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the productContact actions helper
 *
 * @export
 */
export const productContactActions = new ProductContactActions();
