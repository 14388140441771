import React, { useState, useEffect } from 'react';

import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	ContactEntities,
	ContactOrganizationEntity
} from '../../../virtual/store';

//UI
import { IonLabel, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { peopleOutline } from 'ionicons/icons';
import { SponsorShowCaseListItem } from '../components/SponsorShowCaseListItem';

export interface SponsorShowcaseListProps {
	title?: string;
	content?: string;
}

export const SponsorShowcaseList: React.FC<SponsorShowcaseListProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});
	const currentEvent = ctx.virtual.event.active();
	const allSponsors: ContactOrganizationEntity[] = ctx.virtual.contact.all_Sponsor(
		currentEvent?.id || ''
	);

	useEffect(() => {
		ctx.virtual.contact.readOrganizations(
			ctx,
			{},
			(entities: ContactEntities) => {
				//console.log(`${entities.length} Organizations Loaded`);
			}
		);
	}, []);

	const sortSponsorsBySponsorLevel = (
		a: ContactOrganizationEntity,
		b: ContactOrganizationEntity
	) => {
		return ((a.properties as any).myconexsys?.SponsorLevelId as number) >
			((b.properties as any).myconexsys.SponsorLevelId as number)
			? 1
			: ((b.properties as any).myconexsys.SponsorLevelId as number) >
			  ((a.properties as any).myconexsys.SponsorLevelId as number)
			? -1
			: 0;
	};
	const sortSponsorsByName = (
		a: ContactOrganizationEntity,
		b: ContactOrganizationEntity
	) => {
		return (a.name || '') > (b.name || '')
			? 1
			: (b.name || '') > (a.name || '')
			? -1
			: 0;
	};

	const sponsorLevels: number[] = allSponsors
		.sort(sortSponsorsByName)
		.sort(sortSponsorsBySponsorLevel)
		.filter(
			(sponsor, i, arr) =>
				arr.findIndex(
					t =>
						(t.properties as any).myconexsys?.SponsorLevelId ===
						(sponsor.properties as any).myconexsys?.SponsorLevelId
				) === i
		)
		.map(x => (x.properties as any).myconexsys?.SponsorLevelId);

	let totalSponsors = allSponsors.length;
	const sponsorsListItems = allSponsors
		.sort(sortSponsorsByName)
		.sort(sortSponsorsBySponsorLevel)
		.map(
			(
				sponsor: ContactOrganizationEntity,
				index: number,
				sponsorList: ContactOrganizationEntity[]
			) => {
				let mainGroup = '';
				let sponsorLevel = sponsorLevels.findIndex(
					val => (sponsor.properties as any).myconexsys?.SponsorLevelId === val
				);

				if (
					currentEvent?.id === 'e827f70e-0f91-4bed-80e3-fa3cd5d1b818' ||
					currentEvent?.id === '779047f0-48ce-4444-bd8b-046e70c2541f'
				) {
					mainGroup = '';
					sponsorLevel = 1;
				}
				let mainGroupName =
					sponsorLevel === 0
						? 'Diamond Sponsor'
						: sponsorLevel === 1
						? 'Gold Sponsor'
						: sponsorLevel === 2
						? 'Silver Sponsor'
						: sponsorLevel === 3
						? 'Bronze Sponsor'
						: 'Additional Sponsor';

				if (index > 0) {
					let prevSponsorLevel = sponsorLevels.findIndex(
						val =>
							(sponsorList[index - 1].properties as any).myconexsys
								?.SponsorLevelId === val
					);
					let prevName =
						prevSponsorLevel === 0
							? 'Diamond Sponsor'
							: prevSponsorLevel === 1
							? 'Gold Sponsor'
							: prevSponsorLevel === 2
							? 'Silver Sponsor'
							: prevSponsorLevel === 3
							? 'Bronze Sponsor'
							: 'Additional Sponsor';

					if ((mainGroupName ?? '') != (prevName ?? '')) {
						mainGroup = mainGroupName ?? '';

						if (
							currentEvent?.id === 'e827f70e-0f91-4bed-80e3-fa3cd5d1b818' ||
							currentEvent?.id === '779047f0-48ce-4444-bd8b-046e70c2541f'
						) {
							mainGroup = '';
							sponsorLevel = 1;
						}
					}
				} else {
					mainGroup = mainGroupName ?? '';
					if (
						currentEvent?.id === 'e827f70e-0f91-4bed-80e3-fa3cd5d1b818' ||
						currentEvent?.id === '779047f0-48ce-4444-bd8b-046e70c2541f'
					) {
						mainGroup = '';
						sponsorLevel = 1;
					}
				}

				return (
					<>
						{mainGroup !== '' ? (
							<IonCol style={{ background: 'none' }} key={mainGroup} size="12">
								<IonLabel>
									<h1>{mainGroup}</h1>
								</IonLabel>
							</IonCol>
						) : (
							''
						)}
						<IonCol
							key={'col-' + sponsor.name}
							size="12"
							sizeMd={
								sponsorLevel === 0 ? '12' : sponsorLevel === 1 ? '6' : '4'
							}
						>
							<SponsorShowCaseListItem
								key={'ExhibitHallListItem-' + sponsor.id}
								sponsor={sponsor}
							/>
						</IonCol>
					</>
				);
			}
		);

	return (
		<>
			{totalSponsors <= 0 ? (
				<IonLabel class="ion-text-center">
					<br />
					<h1>
						<IonIcon icon={peopleOutline} size="large" />
					</h1>
					<h2>No Sponsors found.</h2>
				</IonLabel>
			) : (
				<IonGrid>
					<IonRow>{sponsorsListItems}</IonRow>
				</IonGrid>
			)}
		</>
	);
};
