import React from 'react';
import { IonLabel, IonItem } from '@ionic/react';
import { Avatar } from '../../../app/ui/components/Avatar';

import {
	ContactEntity,
	ContactIndividualEntity,
	ProductContactEntity
} from '../../store';

export interface SpeakerListItemProps {
	speakerContact: ContactIndividualEntity;
	productContact?: ProductContactEntity;
}

export const SpeakerListItem: React.FC<SpeakerListItemProps> = props => {
	let imgObj = props.speakerContact.image
		? JSON.parse(props.speakerContact.image)
		: undefined;
	let imgPath = imgObj?.originalFilePath ?? imgObj?.OriginalFilePath;
	return (
		<IonItem
			detail={true}
			key={props.speakerContact.id}
			routerLink={'/speaker/' + props.speakerContact.id}
			button
		>
			<Avatar
				style={{ width: '70px', height: '70px' }}
				name={
					props.speakerContact.firstName + ' ' + props.speakerContact.lastName
				}
				size="100%"
				src={imgPath}
			/>

			<IonLabel style={{ display: 'inline-block', marginLeft: '20px' }}>
				<h2>
					{props.speakerContact.salutation} {props.speakerContact.firstName}{' '}
					{props.speakerContact.lastName}
				</h2>
				<p>{props.speakerContact.title}</p>
				<p>{props.speakerContact.organization}</p>
				<p>{props.productContact?.role}</p>
			</IonLabel>
		</IonItem>
	);
};
