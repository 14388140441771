import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getRegistrationGroupCollection,
	RegistrationGroupId,
	RegistrationGroupIds,
	RegistrationGroupId_Some,
	RegistrationGroupEntity,
	RegistrationGroupEntities,
	RegistrationGroupEntity_Some,
	RegistrationGroupEntityPatch_Some,
	RegistrationGroupCollection,
	RegistrationGroupCollectionState,
	IRegistrationGroupActions,
	registrationGroupActions,
	RegistrationGroupActionTypes
} from '..';
import {
	readRegistrationGroups,
	ReadRegistrationGroupsRequest,
	RegistrationGroupApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	isRegistrationGroupAttendeeEntity,
	RegistrationGroupAttendeeEntity,
	isRegistrationGroupExhibitorEntity,
	RegistrationGroupExhibitorEntity,
	isRegistrationGroupSpeakerEntity,
	RegistrationGroupSpeakerEntity,
	isRegistrationGroupSponsorEntity,
	RegistrationGroupSponsorEntity
} from '../collections';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as registrationGroup from '../registrationGroups/RegistrationGroup';

/**
 * RegistrationGroup helper interface
 *
 * @export
 * @interface IRegistrationGroupHelper
 * @extends {IEntityHelper}
 */
export interface IRegistrationGroupHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * RegistrationGroup helper options interface
 *
 * @export
 * @interface RegistrationGroupHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface RegistrationGroupHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const registrationGroupHelperOpts: RegistrationGroupHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * RegistrationGroup helper
 *
 * @export
 * @class RegistrationGroupHelper
 * @extends {EntityHelper<RegistrationGroupCollection, RegistrationGroupActionTypes, RegistrationGroupActions, RegistrationGroupEntity, RegistrationGroupEntities, RegistrationGroupEntity_Some, RegistrationGroupEntityPatch_Some, RegistrationGroupId, RegistrationGroupIds, RegistrationGroupId_Some, RegistrationGroupCollectionState, RegistrationGroupHelperOpts>}
 * @implements {IRegistrationGroupHelper}
 */
export class RegistrationGroupHelper
	extends EntityHelper<
		RegistrationGroupCollection,
		RegistrationGroupActionTypes,
		IRegistrationGroupActions,
		RegistrationGroupEntity,
		RegistrationGroupEntities,
		RegistrationGroupEntity_Some,
		RegistrationGroupEntityPatch_Some,
		RegistrationGroupId,
		RegistrationGroupIds,
		RegistrationGroupId_Some,
		RegistrationGroupCollectionState,
		RegistrationGroupHelperOpts
	>
	implements IRegistrationGroupHelper {
	constructor() {
		super(
			useSelector(getRegistrationGroupCollection),
			registrationGroupActions,
			useDispatch(),
			registrationGroupHelperOpts
		);
		this.collection = useSelector(getRegistrationGroupCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: RegistrationGroupApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadRegistrationGroupsRequest> = {},
		callback?: any
	): Promise<RegistrationGroupEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(RegistrationGroupApiOperation.readRegistrationGroups);
		let request: ReadRegistrationGroupsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: RegistrationGroupEntities = await readRegistrationGroups(
			ctx,
			request
		)
			.then((entities: RegistrationGroupEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): RegistrationGroupEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_Attendee(): RegistrationGroupAttendeeEntity[] {
		return this.all_Active().filter(isRegistrationGroupAttendeeEntity);
	}

	all_Exhibitor(): RegistrationGroupExhibitorEntity[] {
		return this.all_Active().filter(isRegistrationGroupExhibitorEntity);
	}

	all_Speaker(): RegistrationGroupSpeakerEntity[] {
		return this.all_Active().filter(isRegistrationGroupSpeakerEntity);
	}

	all_Sponsor(): RegistrationGroupSponsorEntity[] {
		return this.all_Active().filter(isRegistrationGroupSponsorEntity);
	}
}
