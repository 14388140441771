import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonCard,
	IonCardContent,
	IonContent,
	IonText,
	IonLoading
} from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import { MeetingAddToCalendar } from '../components/MeetingAddToCalendar';

import {
	ContactEntities,
	ScheduleContactEntity,
	ScheduleContactEntities,
	ScheduleEntities,
	ScheduleContactStatusType,
	ScheduleEntity,
	ScheduleStatusType,
	ContactEntity,
	EventEntity,
	ContactIndividualEntity,
	ContactOrganizationEntity,
	ScheduleContactRoleType
} from '../../store';

import './ExhibitorView.css';
import moment from 'moment';
import momenttimezone from 'moment-timezone';

const MeetingAction: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});

	const [initialized, setInitialized] = useState(false);
	const [initializing, setInitializing] = useState(false);
	const [isProcessed, setIsProcessed] = useState(false);
	const [error, setError] = useState('');
	const [stateSchedule, setStateSchedule] = useState<ScheduleEntity>();
	const [stateScheduleContact, setStateScheduleContact] = useState<
		ScheduleContactEntity
	>();
	const [stateAttendeeContact, setStateAttendeeContact] = useState<
		ContactIndividualEntity
	>();
	const [stateExhibitorContact, setStateExhibitorContact] = useState<
		ContactOrganizationEntity
	>();
	const [stateEvent, setStateEvent] = useState<EventEntity>();

	const action: string = viewProps.match?.params?.action ?? '';
	const scheduleContactId: string =
		viewProps.match?.params?.scheduleContactId ?? '';
	let activeEventId = ctx.virtual.event.active()?.id;

	const eventId: string = (
		viewProps.match?.params?.eventId ?? ('' as string)
	).toLowerCase();

	const init = async () => {
		if (initializing) return;
		setInitializing(true);
		await initEvent();
		setInitializing(false);
		setInitialized(true);
	};

	const initEvent = async () => {
		if (eventId !== activeEventId) {
			ctx.virtual.event.set(undefined, ctx);

			// load the event data
			await ctx.virtual.event.read(
				ctx,
				{ eventId },
				(event: EventEntity | undefined) => {
					if (!event || !event.id) {
						setIsProcessed(true);
						setError('Unable to load the event');
					} else {
						// set the active event
						ctx.virtual.event.set(event.id, ctx);
					}
				}
			);
		}
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		init();
		if (activeEventId && activeEventId === eventId) {
			let event: EventEntity | undefined = ctx.virtual.event.active();
			if (event && action && scheduleContactId) {
				try {
					let scheduleContact: ScheduleContactEntity;
					let schedule: ScheduleEntity;
					let attendeeContact: ContactIndividualEntity;
					let exhibitorContact: ContactOrganizationEntity;

					// Get Schedule Contact
					ctx.virtual.scheduleContact.read(
						ctx,
						{
							ids: [scheduleContactId]
						},
						(scEntities: ScheduleContactEntities) => {
							if (scEntities && scEntities.length > 0) {
								scheduleContact = scEntities[0];
								setStateScheduleContact(scheduleContact);

								// Get All scheduleContacts for Schedule
								ctx.virtual.scheduleContact.read(
									ctx,
									{
										scheduleId: scheduleContact.scheduleId
									},
									(scEntities: ScheduleContactEntities) => {
										if (scEntities && scEntities.length > 0) {
											let contactIds = scEntities.map(x => x.contactId);

											// Get All Contacts for Schedule
											ctx.virtual.contact.readById(
												ctx,
												{
													ids: contactIds
												},
												(entities: ContactEntities) => {
													if (entities && entities.length > 0) {
														let ownerContactId = scEntities.find(
															x => x.role === ScheduleContactRoleType.Organizer
														)?.contactId;
														exhibitorContact = entities.find(
															y => y.id === ownerContactId
														) as ContactOrganizationEntity;

														attendeeContact = entities.find(
															y => y.id === scheduleContact.contactId
														) as ContactIndividualEntity;

														setStateExhibitorContact(exhibitorContact);
														setStateAttendeeContact(attendeeContact);

														//Get Schedule
														ctx.virtual.schedule.read(
															ctx,
															{
																ids: [scheduleContact.scheduleId]
															},
															(sEntities: ScheduleEntities) => {
																if (sEntities && sEntities.length > 0) {
																	schedule = sEntities[0];
																	setStateSchedule(schedule);

																	if (
																		scheduleContact.status !==
																		ScheduleContactStatusType.Cancelled
																	) {
																		if (action === 'accept') {
																			//Update Record Status
																			let ogStatus = scheduleContact.status;
																			//Update ScheduleContact
																			scheduleContact.status =
																				ScheduleContactStatusType.Accepted;
																			ctx.virtual.scheduleContact.write(ctx, {
																				scheduleContacts: [scheduleContact]
																			});

																			//update timeslot/schedule item
																			schedule.status =
																				ScheduleStatusType.Confirmed;
																			schedule.name = '';
																			ctx.virtual.schedule.write(ctx, {
																				schedules: [schedule]
																			});

																			if (
																				event &&
																				attendeeContact &&
																				exhibitorContact &&
																				ogStatus !=
																					ScheduleContactStatusType.Accepted
																			)
																				ctx.virtual.scheduleContact.sendMeetingApprovedEmail(
																					ctx,
																					event,
																					schedule,
																					scheduleContact,
																					attendeeContact,
																					exhibitorContact,
																					attendeeContact?.email ?? ''
																				);
																			setStateScheduleContact(scheduleContact);
																			setStateSchedule(schedule);
																		} else if (action === 'decline') {
																			let ogStatus = scheduleContact.status;

																			//Update ScheduleContact
																			scheduleContact.status =
																				ScheduleContactStatusType.Declined;
																			ctx.virtual.scheduleContact.write(ctx, {
																				scheduleContacts: [scheduleContact]
																			});

																			//update timeslot/schedule item
																			schedule.status =
																				ScheduleStatusType.Available;
																			schedule.name = '';
																			ctx.virtual.schedule.write(ctx, {
																				schedules: [schedule]
																			});

																			if (
																				event &&
																				attendeeContact &&
																				exhibitorContact &&
																				ogStatus !=
																					ScheduleContactStatusType.Declined
																			)
																				ctx.virtual.scheduleContact.sendMeetingDeclinedEmail(
																					ctx,
																					event,
																					schedule,
																					scheduleContact,
																					attendeeContact,
																					exhibitorContact,
																					attendeeContact?.email ?? ''
																				);
																			setStateScheduleContact(scheduleContact);
																			setStateSchedule(schedule);
																		}

																		setIsProcessed(true);
																	} else {
																		setIsProcessed(true);
																	}
																} else {
																	setIsProcessed(true);
																	setError('Error Processing request.');
																}
															}
														);
													} else {
														setIsProcessed(true);
														setError('Error Processing request.');
													}
												}
											);
										} else {
											setIsProcessed(true);
											setError('Error Processing request.');
										}
									}
								);
							} else {
								setIsProcessed(true);
								setError('Error Processing request.');
							}
						}
					);
				} catch (error) {
					setIsProcessed(true);
					setError('Error Processing request.');
				}
			} else {
				setIsProcessed(true);
				setError('Error Processing request.');
			}
		}
	}, [eventId, activeEventId]);

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol offsetMd="2" size="12" sizeMd="8">
						{error !== '' ? (
							<IonText color="danger">
								<h2>{error}</h2>
								<div>Please try again or contact support</div>
							</IonText>
						) : isProcessed ? (
							<IonCard style={{ padding: '10%', minHeight: '580px' }}>
								<IonCardContent>
									<h1
										style={{
											fontFamily:
												"'Circular-AddEvent', Lato, 'Open Sans',sans-serif",
											fontSize: '34px',
											fontWeight: 700,
											lineHeight: '110%',
											color: '#1d3054'
										}}
									>
										Meeting:{' '}
										{stateScheduleContact?.status ==
										ScheduleContactStatusType.Accepted
											? 'Accepted'
											: stateScheduleContact?.status ==
													ScheduleContactStatusType.Declined ||
											  stateScheduleContact?.status ==
													ScheduleContactStatusType.Rejected
											? 'Declined'
											: stateScheduleContact?.status ==
													ScheduleContactStatusType.Pending ||
											  stateScheduleContact?.status ==
													ScheduleContactStatusType.Tentative
											? 'Pending Approval'
											: stateScheduleContact?.status ==
											  ScheduleContactStatusType.Cancelled
											? 'Cancelled'
											: stateScheduleContact?.status}
									</h1>
									<br />{' '}
									<span style={{ fontSize: '16px', lineHeight: '24px' }}>
										Meeting with{' '}
										{(stateExhibitorContact?.name?.trim() ?? '') +
											' and ' +
											(stateAttendeeContact?.firstName ?? '') +
											' ' +
											(stateAttendeeContact?.lastName ?? '') +
											' ' +
											(stateAttendeeContact?.organization ?? '')}
									</span>
									<br /> <br />{' '}
									<span style={{ fontSize: '16px', lineHeight: '24px' }}>
										<IonIcon
											slot="start"
											style={{
												fontSize: '24px',
												color: '#0aa700',
												lineHeight: '36px'
											}}
											icon={timeOutline}
										></IonIcon>{' '}
										{moment(stateSchedule?.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
									</span>
									<br />
									<br />
									<div>
										<MeetingAddToCalendar
											schedule={stateSchedule}
											event={ctx.virtual.event.active()}
											attendeeContact={stateAttendeeContact}
											exhibitorContact={stateExhibitorContact}
											hidden={
												stateScheduleContact?.status !==
													ScheduleContactStatusType.Accepted &&
												stateScheduleContact?.status !==
													ScheduleContactStatusType.Pending &&
												stateScheduleContact?.status !==
													ScheduleContactStatusType.Tentative
											}
										></MeetingAddToCalendar>
									</div>
								</IonCardContent>
							</IonCard>
						) : (
							<IonLoading
								cssClass="my-custom-class"
								isOpen={!isProcessed}
								message={'<h2>Loading</h2>Please wait...'}
							/>
						)}
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default MeetingAction;
