import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
//Data
import { useLocaleConfig, UserEntity } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import { AttendeeEntity, ContactIndividualEntity } from '../../store';
import { EventEntity } from '../../store';

//UI
import { IonLoading, IonText, IonContent } from '@ionic/react';

const LoginView: React.FC<any> = viewProps => {
	const [initialized, setInitialized] = useState(false);
	const [initializing, setInitializing] = useState(false);
	const [authIsProcessing, setAuthIsProcessing] = useState(false);
	const [authIsProcessed, setAuthIsProcessed] = useState(false);
	const [authError, setAuthError] = useState('');

	const {
		config: [config],
		userHelper
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const history = useHistory();
	let activeEventId = ctx.virtual.event.active()?.id;

	const eventId: string = (
		viewProps.match?.params?.eventId ?? ('' as string)
	).toLowerCase();

	const userName: string = viewProps.match?.params?.userName ?? ('' as string);

	const accessCode: string =
		viewProps.match?.params?.accessCode ?? ('' as string);

	const page: string = viewProps.match?.params?.page ?? ('' as string);

	const pageRecordId: string =
		viewProps.match?.params?.pageRecordId ?? ('' as string);

	const init = async () => {
		if (initializing) return;
		setInitializing(true);
		userHelper.signout(userHelper.collection.allIds);
		userHelper.set(undefined);
		ctx.virtual.attendee.set(undefined);
		await initEvent();
		setInitializing(false);
		setInitialized(true);
	};

	const initEvent = async () => {
		if (eventId !== activeEventId) {
			ctx.virtual.event.set(undefined, ctx);

			// load the event data
			await ctx.virtual.event.read(
				ctx,
				{ eventId },
				(event: EventEntity | undefined) => {
					if (!event || !event.id) {
						setAuthError('Unable to load the event');
					} else {
						// set the active event
						ctx.virtual.event.set(event.id, ctx);
					}
				}
			);
		}
	};

	const authenticate = async () => {
		let event: EventEntity | undefined = ctx.virtual.event.active();
		if (event && userName && accessCode) {
			try {
				let un = atob(decodeURIComponent(userName));
				let ac = atob(decodeURIComponent(accessCode));
				if (ctx.virtual.event.isActiveEventOpen()) {
					ctx.virtual.attendee.authenticate(
						ctx,
						{
							eventId: event.id,
							username: un,
							accessCode: ac
						},
						(attendee: AttendeeEntity | undefined) => {
							if (event && attendee && attendee.id !== '') {
								let contact = attendee.contact as ContactIndividualEntity;
								let user: UserEntity = {
									id: attendee.id,
									contactId: attendee.contactId,
									attendeeId: attendee.id,
									eventId: eventId,
									name: contact.name ?? '',
									firstName: contact.firstName,
									lastName: contact.lastName,
									middleName: contact.middleName,
									nickname: contact.preferredName,
									email: contact.email,
									emailVerified: true,
									locale: 'en',
									phoneVerified: false,
									auth: {},
									security: [
										'user',
										'read:virtualEvents',
										'write:virtualEvents',
										'read:virtualUsers',
										'write:virtualUsers'
									]
								};

								// upsert new or updated user
								userHelper.upsert(user);

								// set the active user
								userHelper.set(user.id);
								ctx.virtual.attendee.set(user.attendeeId);
								ctx.virtual.attendee.AttendEvent(ctx, event, attendee);

								if (
									!(event?.properties as any)?.app?.virtual?.mainMenu?.home
										?.visible ||
									page !== ''
								) {
									if (
										(event?.properties as any)?.app?.virtual?.mainMenu?.agenda
											?.visible &&
										(page === '' || page === 'agenda')
									) {
										history.push('/agenda');
									} else if (
										(event?.properties as any)?.app?.virtual?.mainMenu?.schedule
											?.visible &&
										(page === '' || page === 'schedule')
									) {
										history.push('/event-schedule');
									} else if (
										(event?.properties as any)?.app?.virtual?.mainMenu?.meetings
											?.visible &&
										(page === '' || page === 'meetings')
									) {
										let idParam = pageRecordId !== '' ? '/' + pageRecordId : '';
										history.push('/meetings' + idParam);
									} else if (
										(event?.properties as any)?.app?.virtual?.mainMenu
											?.exhibitHall?.visible &&
										(page === '' || page === 'exhibithall')
									) {
										history.push('/exhibit-hall');
									} else if (
										(event?.properties as any)?.app?.virtual?.mainMenu
											?.sponsorShowcase?.visible &&
										(page === '' || page === 'sponsors')
									) {
										history.push('/sponsors');
									} else if (
										(event?.properties as any)?.app?.virtual?.mainMenu?.speakers
											?.visible &&
										(page === '' || page === 'speakers')
									) {
										history.push('/speakers');
									} else {
										history.push('/home');
									}
								} else {
									history.push('/home');
								}

								setAuthIsProcessed(true);
							} else {
								// logout the auth session for the current user
								// signout all user accounts from the app user collection
								userHelper.signout(userHelper.collection.allIds);
								userHelper.set(undefined);

								ctx.virtual.attendee.set(undefined);

								setAuthError('Access Denied');
								if (eventId) {
									history.push('/event/' + eventId);
								}
							}
							setAuthIsProcessing(false);
						}
					);
				} else {
					// logout the auth session for the current user
					// signout all user accounts from the app user collection
					userHelper.signout(userHelper.collection.allIds);
					userHelper.set(undefined);

					ctx.virtual.attendee.set(undefined);

					setAuthError('Access Denied');
					if (eventId) {
						history.push('/event/' + eventId);
					}
				}
			} catch (e) {
				setAuthError('Authentication Error');
			}
		}
	};

	useEffect(() => {
		init();
		//AutoLogin
		if (activeEventId && activeEventId === eventId && userName && accessCode) {
			authenticate();
		}
	}, [eventId, activeEventId]);

	if (!eventId || eventId === '') setAuthError('Event unknown');

	return (
		<IonContent>
			{authError !== '' ? (
				<IonText color="danger">
					<h2>{authError}</h2>
					<div>Please go back and try again or contact support</div>
				</IonText>
			) : !initialized || initializing ? (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={true}
					message={'<h2>Loading</h2>Please wait...'}
					duration={30000}
				/>
			) : authIsProcessing ? (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={true}
					message={'<h2>Authenticating</h2>Please wait...'}
					duration={30000}
				/>
			) : authIsProcessed ? (
				<IonText>
					Redirecting to <Link to="/home">Home page</Link>
				</IonText>
			) : (
				<>
					<IonText>Authenticating</IonText>
					<IonLoading
						cssClass="my-custom-class"
						isOpen={true}
						message={'<h2>Authenticating</h2>Please wait...'}
						duration={30000}
					/>
				</>
			)}
		</IonContent>
	);
};

export default LoginView;
