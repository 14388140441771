import React from 'react';

import appViews from '../app/ui/views';
import virtualViews from '../virtual/ui/views';

const views: { [app: string]: { [view: string]: React.FC } } = {
	app: appViews,
	virtual: virtualViews
};

export default views;
