// tslint:disable
/**
 * CONEXSYS Virtual ScheduleContact API
 *  # Overview CONEXSYS Virtual ScheduleContact API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Schedule Contact ObjectStatus type
 * @export
 * @enum {string}
 */
export enum ScheduleContactStatusType {
	Pending = 'pending',
	Accepted = 'accepted',
	Tentative = 'tentative',
	Declined = 'declined',
	Rejected = 'rejected',
	Cancelled = 'cancelled'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesScheduleContactStatusType = [
	'pending',
	'accepted',
	'tentative',
	'declined',
	'rejected',
	'declined'
];
