import * as classes from './';

export * from './Answer';
export * from './Attendee';
export * from './Contact';
export * from './ContactRegistrationType';
export * from './Event';
export * from './Fee';
export * from './Product';
export * from './ProductContact';
export * from './ProductWebinar';
export * from './ProductCategory';
export * from './Question';
export * from './RegistrationGroup';
export * from './RegistrationType';
export * from './Resource';
export * from './Schedule';
export * from './ScheduleContact';
export * from './Webinar';
export * from './Category';

export interface VirtualHelpers {
	answer: classes.AnswerHelper;
	attendee: classes.AttendeeHelper;
	contact: classes.ContactHelper;
	contactRegistrationType: classes.ContactRegistrationTypeHelper;
	event: classes.EventHelper;
	fee: classes.FeeHelper;
	product: classes.ProductHelper;
	productContact: classes.ProductContactHelper;
	productWebinar: classes.ProductWebinarHelper;
	productCategory: classes.ProductCategoryHelper;
	question: classes.QuestionHelper;
	registrationGroup: classes.RegistrationGroupHelper;
	registrationType: classes.RegistrationTypeHelper;
	resource: classes.ResourceHelper;
	schedule: classes.ScheduleHelper;
	scheduleContact: classes.ScheduleContactHelper;
	webinar: classes.WebinarHelper;
	category: classes.CategoryHelper;
}
