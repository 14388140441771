import { config } from '../../config';

let saving: boolean = false;
let latestState: any;

// only save state every 1 seconds at most
export function saveToLocalStorage(state: any) {
	try {
		latestState = JSON.stringify(state);
		if (!saving) {
			saving = true;
			setTimeout(saveLatestStateToLocalStorage, 1000);
		}
	} catch (e) {
		saving = false;
		console.log(state);
		console.error(e);
	}
}

function saveLatestStateToLocalStorage() {
	try {
		localStorage.setItem('state', latestState);
		localStorage.setItem('version', config.version);
		saving = false;
		//console.log('Latest state saved to local storage');
	} catch (e) {
		saving = false;
		console.log('Unable to save latest state');
		console.error(e);
	}
}

export function loadFromLocalStorage() {
	try {
		const serializedState: any = localStorage.getItem('state');
		return serializedState === null ? undefined : JSON.parse(serializedState);
	} catch (e) {
		console.error(e);
	}
}
