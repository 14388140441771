import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonToolbar,
	IonButtons,
	IonContent,
	IonButton,
	IonItemDivider,
	IonModal,
	IonTitle,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonSegment,
	IonSegmentButton,
	IonToggle
} from '@ionic/react';
import { downloadOutline, peopleOutline, radio } from 'ionicons/icons';

import {
	AttendeeEntity,
	ContactEntity,
	ContactIndividualEntity,
	ContactOrganizationEntity,
	EventEntity,
	ScheduleContactEntities,
	ScheduleContactEntity,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	ScheduleEntities,
	ScheduleEntity,
	ScheduleStatusType,
	ScheduleType
} from '../../store';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';
import AppointmentDetails from './AppointmentDetails';
import { MeetingStatusBadge } from '../components/MeetingStatusBadge';
import AddToCalendar from '@culturehq/add-to-calendar';

export interface MeetingsListExhibitorProps {
	title?: string;
	content?: string;
}

export const MeetingsListExhibitor: React.FC<MeetingsListExhibitorProps> = props => {
	const ctx = useCtx<{}>({});

	const [currentAttendee, setCurrentAttendee] = useState<
		AttendeeEntity | undefined
	>(undefined);

	const [currentEvent, setCurrentEvent] = useState<EventEntity | undefined>(
		undefined
	);
	const [showBookAppointmentModal, setShowBookAppointmentModal] = useState(
		false
	);

	const [selectedSchedule, setSelectedSchedule] = useState<
		ScheduleEntity | undefined
	>(undefined);
	const [selectedExhibitorId, setSelectedExhibitorId] = useState<
		string | undefined
	>(undefined);

	useEffect(() => {
		let event = ctx.virtual.event.active();
		let attendee = ctx.virtual.attendee.active();

		setCurrentAttendee(attendee);
		setCurrentEvent(event);

		if (event && attendee) {
			//Read all exhibitor scheduals and meetings
			ctx.virtual.scheduleContact.read(
				ctx,
				{ contactId: attendee.contactId },
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						ctx.virtual.schedule.read(
							ctx,
							{ ids: schedualIds, type: ScheduleType.Meeting },
							(entities: ScheduleEntities) => {}
						);

						for (let i = 0; i < schedualIds.length; i++) {
							ctx.virtual.scheduleContact.read(
								ctx,
								{ scheduleId: schedualIds[i] },
								(entities: ScheduleContactEntities) => {
									if (entities && entities.length > 0) {
										let contactIds = entities.map(obj => obj.contactId);
										ctx.virtual.contact.readById(ctx, {
											ids: contactIds
										});
									}
								}
							);
						}
					}
				}
			);
		}
	}, []);

	const attendeeMeetings = ctx.virtual.schedule
		.all_ByHostContact(
			ctx,
			currentAttendee?.contactId || '',
			[],
			[
				ScheduleContactStatusType.Accepted,
				ScheduleContactStatusType.Pending,
				ScheduleContactStatusType.Tentative
			]
		)
		.filter(x => x.start !== null && x.start !== undefined);

	const setViewMeeting = (
		schedule: ScheduleEntity,
		schedualOwnerContactId: string
	) => {
		setSelectedSchedule(schedule);
		setSelectedExhibitorId(schedualOwnerContactId);
		setShowBookAppointmentModal(true);
	};

	const attendeeMeetingItems = attendeeMeetings
		?.sort((a: ScheduleEntity, b: ScheduleEntity) => {
			if (!a.start) {
				return -1;
			} else {
				return moment(a.start).toDate() > moment(b.start).toDate()
					? 1
					: moment(b.start).toDate() > moment(a.start).toDate()
					? -1
					: 0;
			}
		})
		.map((schedule, index: number, scheduleList) => {
			let mainGroup = '';
			let subGroup = '';

			let mainGroupDate = moment(schedule.start).format('dddd MMMM DD, YYYY');
			let subGroupTime =
				moment(schedule.start).format('LT') +
				' - ' +
				moment(schedule.end).format('LT');
			if (index > 0) {
				let prevDate = moment(scheduleList[index - 1].start).format(
					'dddd MMMM DD, YYYY'
				);
				let prevTime =
					moment(scheduleList[index - 1].start).format('LT') +
					' - ' +
					moment(scheduleList[index - 1].end).format('LT');
				if ((mainGroupDate ?? '') != (prevDate ?? '')) {
					mainGroup = mainGroupDate ?? '';
				}
				if ((subGroupTime ?? '') != (prevTime ?? '')) {
					subGroup = subGroupTime ?? '';
				}
			} else {
				mainGroup = mainGroupDate ?? '';
				subGroup =
					(subGroupTime ?? '') +
					' ' +
					momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr();
			}

			let ownerScheduleContact:
				| ScheduleContactEntity
				| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Organizer]
			)[0];
			let ownerContact:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId || '',
				currentEvent?.id || ''
			);
			let participants: ScheduleContactEntities = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Host]
			);

			let atendees: ScheduleContactEntities = ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[schedule.id],
				[ScheduleContactRoleType.Attendee],
				[
					ScheduleContactStatusType.Accepted,
					ScheduleContactStatusType.Pending,
					ScheduleContactStatusType.Tentative
				]
			);

			let currentScheduleContact:
				| ScheduleContactEntity
				| undefined = participants.find(
				x => x.contactId === currentAttendee?.contactId
			);
			let exhibitor:
				| ContactOrganizationEntity
				| undefined = ctx.virtual.contact.get_Exhibitor(
				ownerScheduleContact?.contactId,
				currentEvent?.id || ''
			);
			let attendee:
				| ContactEntity
				| undefined = ctx.virtual.contact.get_Individual(
				atendees[0]?.contactId,
				currentEvent?.id || ''
			);

			if (currentScheduleContact) {
				return (
					<div key={'meeting-item-div-' + schedule.id}>
						{mainGroup !== '' ? (
							<IonItemDivider
								key={mainGroup}
								class="list-maingroup-header-event-schedule"
							>
								<IonLabel>{mainGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						{subGroup !== '' ? (
							<IonItemDivider key={subGroup} class="list-subgroup-header">
								<IonLabel>{subGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						<IonItem
							detail={true}
							button
							onClick={() => {
								setViewMeeting(schedule, attendee?.id || '');
							}}
						>
							<IonGrid>
								<IonRow>
									<IonCol>
										<IonLabel>
											{attendee?.name ?? 'Not booked'} <br />
											{attendee?.organization}
										</IonLabel>
									</IonCol>
									<IonCol>
										<IonCol>Booth {exhibitor?.locations}</IonCol>
									</IonCol>
									<IonCol hidden={true}>
										<AddToCalendar
											event={{
												name: 'Meeting with:',
												details: 'Meeting with:',
												location: '',
												startsAt: moment(schedule?.start).toISOString(),
												endsAt: moment(schedule?.end).toISOString()
											}}
										></AddToCalendar>
									</IonCol>
									<IonCol>
										<MeetingStatusBadge
											key={'schedulestatusbadge-' + currentScheduleContact.id}
											scheduleStatus={schedule.status}
											scheduleContactStatus={currentScheduleContact.status}
										></MeetingStatusBadge>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					</div>
				);
			}
		});

	const exportData = async () => {
		//setIsLoading(true);
		//setLoadingStatus('Exporting service data...');

		// All data is now loaded
		//setLoadingStatus('Building Data Set...');

		// All data is now loaded
		//setLoadingStatus('Generating File...');

		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet('Meetings');
		let cols = [
			{
				header: 'Contact Name',
				key: 'name'
			},
			{
				header: 'Company',
				key: 'company'
			},
			{
				header: 'Location',
				key: 'location'
			},
			{
				header: 'Start Time',
				key: 'start'
			},
			{
				header: 'End Time',
				key: 'end'
			},
			{
				header: 'status',
				key: 'status'
			}
		];

		worksheet.columns = cols;
		worksheet.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		attendeeMeetings
			?.sort((a: ScheduleEntity, b: ScheduleEntity) => {
				return moment(a.start).toDate() > moment(b.start).toDate()
					? 1
					: moment(b.start).toDate() > moment(a.start).toDate()
					? -1
					: 0;
			})
			.forEach(scheduleItem => {
				let ownerScheduleContact:
					| ScheduleContactEntity
					| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
					[scheduleItem.id],
					[ScheduleContactRoleType.Organizer]
				)[0];
				let ownerContact:
					| ContactOrganizationEntity
					| undefined = ctx.virtual.contact.get_Exhibitor(
					ownerScheduleContact?.contactId || '',
					currentEvent?.id || ''
				);
				let attendeecheduleContact:
					| ScheduleContactEntity
					| undefined = ctx.virtual.scheduleContact.all_ScheduleParticipants(
					[scheduleItem.id],
					[ScheduleContactRoleType.Attendee]
				)[0];
				let attendeeContact:
					| ContactIndividualEntity
					| undefined = ctx.virtual.contact.get_Individual(
					attendeecheduleContact?.contactId || '',
					currentEvent?.id || ''
				);
				worksheet.addRow(
					{
						company: attendeeContact?.organization || '',
						name: attendeeContact?.name || 'Not Booked',
						location: 'Booth ' + ownerContact?.locations || '',
						start: moment(scheduleItem.start).format('LLL z'),
						end: moment(scheduleItem.end).format('LLL z'),
						status:
							scheduleItem.status === ScheduleStatusType.Pending ||
							scheduleItem.status === ScheduleStatusType.Requested
								? 'Pending Approval'
								: scheduleItem.status === ScheduleStatusType.Declined
								? 'Declined'
								: scheduleItem.status === ScheduleStatusType.Cancelled
								? 'Cancelled'
								: scheduleItem.status === ScheduleStatusType.Available
								? 'Available'
								: 'Approved'
					},
					''
				);
			});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], {
			type: fileType
		});

		saveAs(
			blob,
			(currentEvent!.name || 'MyMeetings') +
				'-Meetings-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		//setIsLoading(false);
		//setLoadingStatus('');
	};

	return (
		<>
			<IonModal
				isOpen={showBookAppointmentModal}
				onDidDismiss={() => setShowBookAppointmentModal(false)}
			>
				<IonToolbar>
					<IonTitle>Meeting With </IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => setShowBookAppointmentModal(false)}>
							Close
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent>
					<AppointmentDetails
						schedual={selectedSchedule}
						attendeeId={currentAttendee?.id ?? ''}
					></AppointmentDetails>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonButtons
					slot="end"
					hidden={!attendeeMeetings || attendeeMeetings.length <= 0}
				>
					<IonButton slot="end" onClick={exportData}>
						<IonIcon slot="start" icon={downloadOutline} />
						Export Meetings
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<IonContent>
				<IonList hidden={attendeeMeetingItems.length <= 0}>
					<IonItemDivider class="list-maingroup-header-event-schedule">
						<IonLabel>Meetings</IonLabel>
					</IonItemDivider>
					<IonItemDivider
						key={'subGroupInvitations'}
						class="list-subgroup-header"
					>
						<IonLabel>Your scheduled meetings</IonLabel>
					</IonItemDivider>
					{attendeeMeetingItems}
				</IonList>
				<div
					className="ion-text-center"
					hidden={attendeeMeetings && attendeeMeetings.length > 0}
				>
					<br />
					<h1>
						<IonIcon icon={peopleOutline} size="large" />
					</h1>

					<h4>You have no meetings currently scheduled or requested.</h4>
				</div>
			</IonContent>
		</>
	);
};
