import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { feeConfig } from '../../../config/virtual/Fee';
import { Fee } from '../models';

/**
 * Fee entity interface
 *
 * @export
 * @interface FeeEntity
 * @extends {storage.Entity}
 */
export interface FeeEntity extends storage.Entity, Fee {}

/**
 * Fee entities array
 *
 * @export
 */
export type FeeEntities = FeeEntity[];

/**
 * Some fee entities: one entity or an array of entities
 *
 * @export
 */
export type FeeEntity_Some = FeeEntity | FeeEntities;

/**
 * Fee entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface FeeEntityPatch
 * @extends {storage.EntityPatch<FeeEntity>}
 */
export type FeeEntityPatch = storage.EntityPatcher<FeeEntity>;

/**
 * Fee entity patches array
 *
 * @export
 */
export type FeeEntitiesPatch = FeeEntityPatch[];

/**
 * Some fee entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type FeeEntityPatch_Some = FeeEntityPatch | FeeEntitiesPatch;

/**
 * Fee entity id
 *
 * @export
 */
export type FeeId = storage.EntityId;

/**
 * Fee entity ids
 *
 * @export
 */
export type FeeIds = storage.EntityIds;

/**
 * Some fee entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type FeeId_Some = FeeId | FeeIds;

/**
 * Fee entities object by id
 *
 * @export
 * @interface FeeEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface FeeEntitiesObject extends storage.EntitiesObject {
	[id: string]: FeeEntity;
}

/**
 * Fee entity state
 *
 * @export
 * @interface FeeEntityState
 * @extends {storage.EntityState}
 */
export interface FeeEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Fee store collection
 *
 * @export
 * @interface FeeStoreCollection
 * @extends {storage.Store}
 */
export interface FeeStoreCollection extends storage.Store {
	fee: FeeCollection;
}

/**
 * Fee collection properties
 *
 * @export
 * @interface FeeCollection
 * @extends {Collection}
 */
export interface FeeCollection extends storage.Collection {
	byIds: FeeEntitiesObject;
	mutation: FeeEntitiesObject;
	cache: FeeEntitiesObject;
	state: FeeCollectionState;
}

/**
 * Fee collection state
 *
 * @export
 * @interface FeeCollectionState
 * @extends {storage.CollectionState}
 */
export interface FeeCollectionState extends storage.CollectionState {}

// custom fee collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const feeCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const feeCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const feeCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const feeCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: feeCollectionIdStringProps,
	idArrayProps: feeCollectionIdArrayProps,
	idEntityProps: feeCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Fee collection default values, initialize collection with fee entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const feeCollectionDefault: FeeCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as FeeCollection),
		...{
			activeId: feeConfig.activeId || feeConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	feeConfig.entities
);

/**
 * Fee store collection default values
 *
 * @export
 * @constant
 */
export const feeStoreCollectionDefault: FeeStoreCollection = {
	fee: feeCollectionDefault
};

/**
 * Fee store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getFeeCollection = (state: any) => state.virtual.fee;
