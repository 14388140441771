import React, { useState, useMemo } from 'react';
import {
	IonContent,
	IonPage,
	useIonViewWillEnter,
	useIonViewWillLeave,
	useIonViewDidLeave,
	useIonViewDidEnter
} from '@ionic/react';
import views from '../../../config/views';
import { menuConfig } from '../../../config/app/Menu';
import { Header } from '../components';
import {
	RouteEntity,
	RouteMenuInstance,
	useMenu,
	MenuStateInstances
} from '../../store';
import { MenuConfigProps } from '../../ui/components/Menu';
import { Redirect } from 'react-router';
import { useCtx } from '../../../config/hooks';

export interface RouteViewProps {
	route: RouteEntity;
	viewProps: any;
}
export const RouteView: React.FC<RouteViewProps> = props => {
	const [routeHandled, setRouteHandled] = useState(false);
	const ctx = useCtx<{}>({});

	let viewPath = props.route.view ? props.route.view.split('.') : [];
	if (viewPath.length > 0)
		viewPath =
			viewPath.length > 0
				? viewPath.length === 1
					? ['app', viewPath[0]]
					: viewPath
				: [];
	let view =
		viewPath[0] in views && viewPath[1] in views[viewPath[0]]
			? views[viewPath[0]][viewPath[1]]
			: undefined;

	const { menuHelper, activeUser, locale, getLocaleConfigs } = useMenu();

	let authorized = true;
	let redirect = null;

	const checkRouteSecurity = (route: RouteEntity) => {
		// if there are security scopes on the item, ensure the user has at least one of them
		if (route.security) {
			let allow = false,
				deny = false;
			if (activeUser?.security) {
				route.security?.allow?.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) allow = true;
				});
				route.security?.deny?.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) deny = true;
				});
			} else {
				if (
					route.security?.deny !== undefined &&
					route.security?.deny?.indexOf('User') == -1
				)
					allow = true;
				if (
					route.security?.deny !== undefined &&
					route.security?.deny?.indexOf('User') > -1
				)
					deny = true;
			}
			authorized = allow && !deny;
		}
		redirect =
			authorized &&
			props.route.security?.allowRedirect &&
			typeof props.route.security?.allowRedirect === 'string'
				? props.route.security?.allowRedirect
				: !authorized &&
				  props.route.security?.denyRedirect &&
				  typeof props.route.security?.denyRedirect === 'string'
				? props.route.security?.denyRedirect
				: authorized
				? null
				: '/';
	};

	const setRouteMenus = (route: RouteEntity) => {
		let instances: MenuStateInstances = {};
		// set the menu state to the route menu config
		if (route.menu) {
			// configure each menu instanceId for the route
			for (let instanceId in route.menu) {
				let instance: RouteMenuInstance = route.menu[instanceId];

				let config: MenuConfigProps = {};

				// merge in each route instance tags localized config from left to right
				if (instance.tags)
					instance.tags.forEach(tag => {
						const [cfg] = getLocaleConfigs<MenuConfigProps>(
							locale,
							menuConfig.instances[instanceId][tag]
						);
						if (cfg) config = { ...config, ...cfg };
					});

				// merge in the route instance defined config, potentially overriding tag configs
				if (instance.config) config = { ...config, ...instance.config };

				// if no instance config keys are set for the route menu instance, disable the menu
				if (Object.keys(config).length === 0) config = { disabled: true };

				// add the instance config to the route menu instances
				instances = { ...instances, [instanceId]: config };
			}
		}
		// patch the menu state.instances with the route menu instance configuration
		menuHelper.patchState({ instances });
	};

	checkRouteSecurity(props.route);

	useIonViewDidEnter(() => {
		if (!routeHandled) {
			checkRouteSecurity(props.route);
			setRouteMenus(props.route);
			setRouteHandled(true);
		}
	});

	useIonViewWillLeave(() => {
		if (routeHandled) setRouteHandled(false);
	});

	return redirect != null ? (
		<Redirect to={redirect} />
	) : (
		<IonPage>
			<Header
				disabled={props.route.header?.disabled}
				title={props.route.header?.title}
				currentRoute={props.route.id}
			/>
			{view ? React.createElement(view, props.viewProps) : <></>}
		</IonPage>
	);
};
