import React from 'react';
import {
	IonLabel,
	IonText,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonImg,
	IonButton
} from '@ionic/react';
import { useCtx } from '../../../config/hooks';
import { ContactOrganizationEntity } from '../../../virtual/store';
import { Avatar } from '../../../app/ui/components/Avatar';

export interface SponsorShowCaseListItemProps {
	sponsor: ContactOrganizationEntity;
}

export const SponsorShowCaseListItem: React.FC<SponsorShowCaseListItemProps> = props => {
	let logoObj = props.sponsor.logo ? JSON.parse(props.sponsor.logo) : undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
	return (
		<IonCard style={{ minHeight: '180px' }}>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol size="4">
							{!logoPath ? (
								<Avatar name={props.sponsor.name} size="100%" />
							) : (
								<img style={{ maxHeight: '100px' }} src={logoPath} />
							)}
						</IonCol>
						<IonCol size="8">
							<IonText>
								<h2>{props.sponsor.name}</h2>
								<p>
									{(props.sponsor.description ?? '').substr(0, 100) +
										((props.sponsor.description ?? '').length > 100
											? ' ... '
											: '')}
								</p>
								<IonButton
									routerLink={'/exhibitor/' + props.sponsor.id}
									size="small"
								>
									More Info..
								</IonButton>
							</IonText>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
