import React, { useState, useEffect } from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonLabel,
	IonList,
	IonItem,
	IonBackButton,
	IonText,
	IonToolbar,
	IonButtons,
	IonModal,
	IonContent,
	IonHeader,
	IonTitle,
	IonButton
} from '@ionic/react';
import {
	logoTwitter,
	logoFacebook,
	logoLinkedin,
	mail,
	globe,
	download,
	documentOutline
} from 'ionicons/icons';
import { Media } from '../../../app/ui/components';
import { Avatar } from '../../../app/ui/components/Avatar';
import {
	ContactIndividualEntity,
	ProductSessionEntity,
	EventEntity,
	AttendeeEntity
} from '../../store';
import moment from 'moment';
import { EventScheduleListItem } from '../components/EventScheduleListItem';

const SpeakerView: React.FC<any> = viewProps => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const [showVideoModal, setShowVideoModal] = useState('');

	useEffect(() => {
		setShowVideoModal('');
	}, []);

	const onWebinarLinkClick = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
		}
	};
	const onWebinarWatchRecordingLinkClicked = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined,
		url?: string
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
			setShowVideoModal(url || '');
		}
	};

	const currentEvent = ctx.virtual.event.active();
	const currentAttendee = ctx.virtual.attendee.active();
	const attendeeProducts = currentAttendee?.products?.filter(
		ap => ap.i_.status === 'active'
	);
	const currentSpeaker:
		| ContactIndividualEntity
		| undefined = ctx.virtual.contact.get_Speaker(
		viewProps.match?.params?.id ?? '',
		currentEvent?.id || ''
	);

	if (!currentSpeaker) {
		return <>No session found.</>;
	}

	//Get Speaker Information
	const sortSpeakerProducts = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return moment(a.start).toDate() > moment(b.start).toDate()
			? 1
			: moment(b.start).toDate() > moment(a.start).toDate()
			? -1
			: 0;
	};

	const allowAttendanceToAllSession = (currentEvent?.properties as any)?.app
		?.virtual?.generalSettings?.allowAttendanceToAllSession;

	const allSpeakerSessions:
		| ProductSessionEntity[]
		| undefined = ctx.virtual.product.all_Session_By_SpeakerId(
		ctx,
		currentSpeaker?.id,
		currentEvent?.id || ''
	);
	const sessionsListItems = allSpeakerSessions
		.sort(sortSpeakerProducts)
		.map((session: ProductSessionEntity) => {
			let webinars = ctx.virtual.webinar.all_By_Product(ctx, session.id);
			return (
				<EventScheduleListItem
					key={session.id}
					session={session}
					isAttending={
						attendeeProducts?.find(p => p.productId === session.id) !==
						undefined
					}
					hasAttened={false}
					webinars={webinars}
					allowAttendanceToAllSession={allowAttendanceToAllSession}
					onWebinarLinkClick={() => {
						onWebinarLinkClick(currentEvent, currentAttendee, session);
					}}
					onWebinarWatchRecordingLinkClick={(url?: string) => {
						onWebinarWatchRecordingLinkClicked(
							currentEvent,
							currentAttendee,
							session,
							url
						);
					}}
				/>
			);
		});

	//Get SessionDocuments
	let allDocuments = (currentSpeaker.properties as any)?.myconexsys
		?.SessionSlidesHandouts
		? JSON.parse(
				(currentSpeaker.properties as any)?.myconexsys?.SessionSlidesHandouts
		  )
		: undefined;
	const documentListItems = allDocuments?.map(
		(document: any, index: number, documentList: any[]) => {
			return (
				<IonItem
					key={'sessionDocument-' + document.id}
					button={true}
					onClick={() => {
						saveAs(document.OriginalFilePath, document.Name);
					}}
				>
					<IonIcon src={documentOutline} />
					<IonLabel>{document.Name}</IonLabel>
					<IonIcon class="ion-float-right" src={download} />
				</IonItem>
			);
		}
	);

	let imgObj = currentSpeaker.image
		? JSON.parse(currentSpeaker.image)
		: undefined;
	let imgPath = imgObj?.OriginalFilePath;

	return (
		<IonContent>
			<IonModal
				isOpen={showVideoModal != ''}
				onDidDismiss={() => setShowVideoModal('')}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle></IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowVideoModal('');
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Media src={showVideoModal}></Media>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/home" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol size="12" sizeMd="12">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<Avatar
										name={
											currentSpeaker.firstName + ' ' + currentSpeaker.lastName
										}
										style={{
											display: 'inline-block',
											height: '120px',
											width: '120px'
										}}
										size="120px"
										src={imgPath}
									/>

									<IonLabel
										style={{ display: 'inline-block', marginLeft: '20px' }}
									>
										<h1>
											{currentSpeaker.salutation} {currentSpeaker.firstName}{' '}
											{currentSpeaker.lastName}
										</h1>
										<h2>{currentSpeaker.title}</h2>
										<h2>{currentSpeaker.organization}</h2>
										<h2 hidden={true}>{currentSpeaker.email}</h2>
									</IonLabel>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonText
									hidden={
										(!currentSpeaker.achievements ||
											currentSpeaker.achievements?.trim() === '') &&
										(!currentSpeaker.note || currentSpeaker.note?.trim() === '')
									}
								>
									<h2>Biography</h2>
									<p>
										<div
											dangerouslySetInnerHTML={{
												__html:
													currentSpeaker.achievements ??
													currentSpeaker.note ??
													''
											}}
										></div>
									</p>
									<br />
								</IonText>
								<IonList
									hidden={!allSpeakerSessions || allSpeakerSessions.length <= 0}
								>
									<h2>Speaking At</h2>
									{sessionsListItems}
								</IonList>
								<IonList hidden={!allDocuments || allDocuments.length <= 0}>
									<h2>Documents</h2>
									{documentListItems}
								</IonList>
								<h2
									hidden={
										(!currentSpeaker.website ||
											currentSpeaker.website === '') &&
										(!currentSpeaker.twitter ||
											currentSpeaker.twitter === '') &&
										(!currentSpeaker.facebook ||
											currentSpeaker.facebook === '') &&
										(!currentSpeaker.linkedIn ||
											currentSpeaker.linkedIn === '') &&
										(!currentSpeaker.website || currentSpeaker.website === '')
									}
								>
									Contact
								</h2>
								<IonList>
									<IonItem
										detail={false}
										hidden={
											true ||
											!currentSpeaker?.email ||
											currentSpeaker?.email === ''
										}
										button={true}
										onClick={e => {
											window.location.href =
												'mailto:' + currentSpeaker.email ?? '';
										}}
									>
										<IonLabel>Email </IonLabel>
										<IonIcon src={mail} />
									</IonItem>
									<IonItem
										detail={false}
										hidden={
											!currentSpeaker.twitter || currentSpeaker.twitter === ''
										}
										button={true}
										onClick={e => {
											window.open(currentSpeaker.twitter, '_blank');
										}}
									>
										<IonLabel>Twitter</IonLabel>
										<IonIcon src={logoTwitter} />
									</IonItem>
									<IonItem
										detail={false}
										hidden={
											!currentSpeaker.facebook || currentSpeaker.facebook === ''
										}
										button={true}
										onClick={e => {
											window.open(currentSpeaker.facebook, '_blank');
										}}
									>
										<IonLabel>Facebook</IonLabel>
										<IonIcon src={logoFacebook} />
									</IonItem>
									<IonItem
										detail={false}
										hidden={
											!currentSpeaker.linkedIn || currentSpeaker.linkedIn === ''
										}
										button={true}
										onClick={e => {
											window.open(currentSpeaker.linkedIn, '_blank');
										}}
									>
										<IonLabel>LinkedIn</IonLabel>
										<IonIcon src={logoLinkedin} />
									</IonItem>
									<IonItem
										detail={false}
										hidden={
											!currentSpeaker.website || currentSpeaker.website === ''
										}
										button={true}
										onClick={e => {
											window.open(
												(currentSpeaker.website?.includes('http')
													? ''
													: 'http://') + currentSpeaker.website,
												'_blank'
											);
										}}
									>
										<IonLabel>Website</IonLabel>
										<IonIcon src={globe} />
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default SpeakerView;
