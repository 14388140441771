import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getScheduleContactCollection,
	ScheduleContactId,
	ScheduleContactIds,
	ScheduleContactId_Some,
	ScheduleContactEntity,
	ScheduleContactEntities,
	ScheduleContactEntity_Some,
	ScheduleContactEntityPatch_Some,
	ScheduleContactCollection,
	ScheduleContactCollectionState,
	IScheduleContactActions,
	scheduleContactActions,
	ScheduleContactActionTypes,
	EventEntity,
	ScheduleEntity,
	ContactOrganizationEntity,
	AttendeeEntity,
	ContactIndividualEntity
} from '..';
import {
	readScheduleContacts,
	ReadScheduleContactsRequest,
	ScheduleContactApiOperation,
	writeScheduleContacts,
	WriteScheduleContactsRequest
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactIndividual,
	isObjectStatusActive,
	ObjectStatus,
	ScheduleContactRoleType,
	ScheduleContactStatusType
} from '../models';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as scheduleContact from '../scheduleContacts/ScheduleContact';

/**
 * ScheduleContact helper interface
 *
 * @export
 * @interface IScheduleContactHelper
 * @extends {IEntityHelper}
 */
export interface IScheduleContactHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * ScheduleContact helper options interface
 *
 * @export
 * @interface ScheduleContactHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ScheduleContactHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const scheduleContactHelperOpts: ScheduleContactHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * ScheduleContact helper
 *
 * @export
 * @class ScheduleContactHelper
 * @extends {EntityHelper<ScheduleContactCollection, ScheduleContactActionTypes, ScheduleContactActions, ScheduleContactEntity, ScheduleContactEntities, ScheduleContactEntity_Some, ScheduleContactEntityPatch_Some, ScheduleContactId, ScheduleContactIds, ScheduleContactId_Some, ScheduleContactCollectionState, ScheduleContactHelperOpts>}
 * @implements {IScheduleContactHelper}
 */
export class ScheduleContactHelper
	extends EntityHelper<
		ScheduleContactCollection,
		ScheduleContactActionTypes,
		IScheduleContactActions,
		ScheduleContactEntity,
		ScheduleContactEntities,
		ScheduleContactEntity_Some,
		ScheduleContactEntityPatch_Some,
		ScheduleContactId,
		ScheduleContactIds,
		ScheduleContactId_Some,
		ScheduleContactCollectionState,
		ScheduleContactHelperOpts
	>
	implements IScheduleContactHelper {
	constructor() {
		super(
			useSelector(getScheduleContactCollection),
			scheduleContactActions,
			useDispatch(),
			scheduleContactHelperOpts
		);
		this.collection = useSelector(getScheduleContactCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ScheduleContactApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadScheduleContactsRequest> = {},
		callback?: any
	): Promise<ScheduleContactEntities> {
		if (!ctx.virtual.event.active()) return [];
		/*params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ScheduleContactApiOperation.readScheduleContacts);*/
		let request: ReadScheduleContactsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ScheduleContactEntities = await readScheduleContacts(
			ctx,
			request
		)
			.then((entities: ScheduleContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteScheduleContactsRequest> = {},
		callback?: any
	): Promise<ScheduleContactEntities> {
		//if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.virtual.event.active()) return [];
		if (!params.scheduleContacts) return [];

		let request: WriteScheduleContactsRequest = {
			...params,
			...{
				scheduleContacts: params.scheduleContacts,
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];
		let entities: ScheduleContactEntities = await writeScheduleContacts(
			ctx,
			request
		)
			.then((entities: ScheduleContactEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let scheduleContact = this.get(id);
		if (!scheduleContact) return false;

		scheduleContact.i_.status = ObjectStatus.Deleted;

		let scheduleContacts = await this.write(ctx, {
			scheduleContacts: [scheduleContact]
		});

		return scheduleContacts.length > 0;
	}

	all_Active(): ScheduleContactEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_ByOrganizerContact(contactId: string): ScheduleContactEntities {
		return this.all_ByContactAndRoles(contactId, [
			ScheduleContactRoleType.Organizer
		]);
	}

	all_ByContactAndRoles(
		contactId: string,
		roles: ScheduleContactRoleType[]
	): ScheduleContactEntities {
		return this.all_Active().filter(
			s => s.contactId === contactId && roles?.includes(s.role)
		);
	}

	all_ScheduleParticipants(
		scheduleIds: string[],
		roles?: ScheduleContactRoleType[],
		contactStatuses?: ScheduleContactStatusType[]
	): ScheduleContactEntities {
		if (!roles || roles.length <= 0) {
			roles = [
				ScheduleContactRoleType.Attendee,
				ScheduleContactRoleType.Moderator,
				ScheduleContactRoleType.Organizer,
				ScheduleContactRoleType.Host
			];
		}
		if (!contactStatuses || contactStatuses.length <= 0) {
			contactStatuses = [
				ScheduleContactStatusType.Accepted,
				ScheduleContactStatusType.Pending,
				ScheduleContactStatusType.Tentative
			];
		}

		return this.all_Active().filter(
			s =>
				scheduleIds.includes(s.scheduleId) &&
				roles?.includes(s.role) &&
				contactStatuses?.includes(s.status)
		);
	}

	async sendMeetingRequestEmail(
		ctx: UseCtx<any>,
		event: EventEntity,
		schedule: ScheduleEntity,
		attendeeScheduleContact: ScheduleContactEntity,
		attendee: AttendeeEntity,
		owner: ContactOrganizationEntity,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = event.name || '';
			let attendeeContact: ContactIndividualEntity = attendee.contact as ContactIndividualEntity;
			const response = await ctx.app.api.sendEmail({
				to,
				cc,
				bcc,
				subject:
					'Invitation: Meeting with ' +
						owner?.name?.trim() +
						' and ' +
						attendeeContact?.firstName ??
					'' + ' ' + attendeeContact?.lastName ??
					'' + ' @ ' + moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr(),
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img width="650" src="https://myeventapp.conexsys.com/assets/img/MyMeetings2.png" />
          <br />
          <h3>
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
			<p>
              When: ${moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
			</p>
			<p>
              Where: ${owner?.name?.trim() + ', Booth ' + owner?.locations}
			</p>
			<p>
              Who: ${attendeeContact?.name ?? ''}, ${
					attendeeContact?.organization ?? ''
				}
			</p>
			<p>
              Guests: ${attendeeContact?.name ?? ''} and ${owner?.name ?? ''} 
			</p>
			<p>
              Notes: ${attendeeScheduleContact?.note ?? ''} 
			</p>
          </div>
        </td>
      </tr>
	  <tr>
		<td>
			<table cellspacing="0" cellpadding="0"> 
				<tr>
					<td align="center" width="310" height="40">
						<span>
								<!--[if mso]>
								<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://myeventapp.conexsys.com/meetingaction/${
									event.id
								}/accept/${
					attendeeScheduleContact.id
				}" style="height:40px;v-text-anchor:middle;width:200px;" arcsize="10%" stroke="f" fillcolor="#3880ff">
									<w:anchorlock/>
									<center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:bold;">
									Accept
									</center>
								</v:roundrect>
								<![endif]-->
								<![if !mso]>
								<table cellspacing="0" cellpadding="0"> <tr> 
								<td align="center" width="200" height="35" bgcolor="#3880ff" style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; color: #ffffff; display: block;">
									<a href="https://myeventapp.conexsys.com/meetingaction/${event.id}/accept/${
					attendeeScheduleContact.id
				}" style="font-size:16px; font-weight: bold; font-family:sans-serif; text-decoration: none; line-height:40px; width:100%; display:inline-block">
									<span style="color: #ffffff;">
									Accept
									</span>
									</a>
								</td> 
								</tr> </table> 
								<![endif]>
							</span>
							</td>
							<td align="center" width="310" height="40">
							<span>
						<!--[if mso]>
						<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://myeventapp.conexsys.com/meetingaction/${
							event.id
						}/decline/${
					attendeeScheduleContact.id
				}" style="height:40px;v-text-anchor:middle;width:200px;" arcsize="10%" stroke="f" fillcolor="#f04141">
							<w:anchorlock/>
							<center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:bold;">
							Decline
							</center>
						</v:roundrect>
						<![endif]-->
						<![if !mso]>
						<table cellspacing="0" cellpadding="0"> <tr> 
						<td align="center" width="200" height="35" bgcolor="#f04141" style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; color: #ffffff; display: block;">
							<a href="https://myeventapp.conexsys.com/meetingaction/${event.id}/decline/${
					attendeeScheduleContact.id
				}" style="font-size:16px; font-weight: bold; font-family:sans-serif; text-decoration: none; line-height:40px; width:100%; display:inline-block">
							<span style="color: #ffffff;">
							Decline
							</span>
							</a>
						</td> 
						</tr> </table> 
						<![endif]>
					</span>
					</td>
				</tr>
			</table> 
			<br />
		</td>
	  </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async sendMeetingCancellationEmail(
		ctx: UseCtx<any>,
		event: EventEntity,
		schedule: ScheduleEntity,
		attendeeScheduleContact: ScheduleContactEntity,
		attendeeContact: ContactIndividualEntity,
		owner: ContactOrganizationEntity,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = event.name || '';
			const response = await ctx.app.api.sendEmail({
				to,
				cc,
				bcc,
				subject:
					'Cancelled Meeting: Meeting with  ' +
						owner?.name?.trim() +
						' and ' +
						attendeeContact?.firstName ??
					'' + ' ' + attendeeContact?.lastName ??
					'' + ' @ ' + moment(schedule.start).format('LLLL'),
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img width="650" src="https://myeventapp.conexsys.com/assets/img/MyMeetings2.png" />
          <br />
          <h3>
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
		    <p>
			This meeting has been cancelled and removed from your calendar.
			</p>
			<p>
              When: ${moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
			</p>
			<p>
              Guests: ${attendeeContact?.name ?? ''} and ${owner?.name ?? ''} 
			</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async sendMeetingApprovedEmail(
		ctx: UseCtx<any>,
		event: EventEntity,
		schedule: ScheduleEntity,
		attendeeScheduleContact: ScheduleContactEntity,
		attendeeContact: ContactIndividualEntity,
		owner: ContactOrganizationEntity,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = event.name || '';
			const response = await ctx.app.api.sendEmail({
				to,
				cc,
				bcc,
				subject:
					'Accepted: Meeting with ' +
						owner?.name?.trim() +
						' and ' +
						attendeeContact?.firstName ??
					'' + ' ' + attendeeContact?.lastName ??
					'' + ' @ ' + moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr(),
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img width="650" src="https://myeventapp.conexsys.com/assets/img/MyMeetings2.png" />
          <br />
          <h3>
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
		  	<p>
               ${
									owner?.contactEmail?.trim() ?? ''
								} from ${owner?.name?.trim()} has accepted this invitation.
			</p>
			<p>
              When: ${moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
			</p>
			<p>
              Where: ${owner?.name?.trim() + ', Booth ' + owner?.locations}
			</p>
			<p>
              Guests: ${attendeeContact?.name ?? ''} <br /> ${
					owner?.name?.trim() + ', ' + (owner?.contactEmail?.trim() ?? '')
				}
			</p>
          </div>
        </td>
      </tr>
	  <tr>
		<td>
			<table cellspacing="0" cellpadding="0"> 
				<tr>
					<td align="center" width="650" height="40">
							<span>
						<!--[if mso]>
						<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://myeventapp.conexsys.com/meetingaction/${
							event.id
						}/view/${
					attendeeScheduleContact.id
				}" style="height:40px;v-text-anchor:middle;width:280px;" arcsize="10%" stroke="f" fillcolor="#717BEC">
							<w:anchorlock/>
							<center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:bold;">
							Add To My Calender
							</center>
						</v:roundrect>
						<![endif]-->
						<![if !mso]>
						<table cellspacing="0" cellpadding="0"> <tr> 
						<td align="center" width="280" height="35" bgcolor="#717BEC" style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; color: #ffffff; display: block;">
							<a href="https://myeventapp.conexsys.com/meetingaction/${event.id}/view/${
					attendeeScheduleContact.id
				}" style="font-size:16px; font-weight: bold; font-family:sans-serif; text-decoration: none; line-height:40px; width:100%; display:inline-block">
							<span style="color: #ffffff;">
							Add To My Calender
							</span>
							</a>
						</td> 
						</tr> </table> 
						<![endif]>
					</span>
					</td>
				</tr>
			</table> 
			<br />
		</td>
	  </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async sendMeetingDeclinedEmail(
		ctx: UseCtx<any>,
		event: EventEntity,
		schedule: ScheduleEntity,
		attendeeScheduleContact: ScheduleContactEntity,
		attendeeContact: ContactIndividualEntity,
		owner: ContactOrganizationEntity,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = event.name || '';
			const response = await ctx.app.api.sendEmail({
				to,
				cc,
				bcc,
				subject:
					'Declined: Meeting with ' +
						owner?.name?.trim() +
						' and ' +
						attendeeContact?.firstName ??
					'' + ' ' + attendeeContact?.lastName ??
					'' + ' @ ' + moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr(),
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img width="650" src="https://myeventapp.conexsys.com/assets/img/MyMeetings2.png" />
          <br />
          <h3>
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
		  	<p>
               ${
									owner?.contactEmail?.trim() ?? ''
								} from ${owner?.name?.trim()} has declined this invitation.
			</p>
			<p>
              When: ${moment(schedule.start).format('LLLL') + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
			</p>
			<p>
              Guests: ${attendeeContact?.name ?? ''} <br /> ${
					owner?.name?.trim() + ', ' + (owner?.contactEmail?.trim() ?? '')
				}
			</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}
}
