import MainView from './MainView';
import LoginView from './LoginView';
import AgendaView from './AgendaView';
import SessionView from './SessionView';
import EventScheduleView from './EventScheduleView';
import ExhibitHallView from './ExhibitHallView';
import ExhibitorView from './ExhibitorView';
import SpeakersView from './SpeakersView';
import SpeakerView from './SpeakerView';
import SponsorsView from './SponsorsView';
import AboutView from './AboutView';
import FAQView from './FAQView';
import ContactUsView from './ContactUsView';
import AuthView from './AuthView';
import MeetingsView from './MeetingsView';
import MeetingActionView from './MeetingActionView';

const views: { [key: string]: React.FC } = {
	MainView,
	LoginView,
	AgendaView,
	SessionView,
	EventScheduleView,
	ExhibitHallView,
	ExhibitorView,
	SpeakersView,
	SpeakerView,
	SponsorsView,
	AboutView,
	FAQView,
	ContactUsView,
	AuthView,
	MeetingsView,
	MeetingActionView
};

export default views;
