import { combineReducers } from 'redux';

import { answerReducer } from './Answer';
import { attendeeReducer } from './Attendee';
import { contactReducer } from './Contact';
import { contactRegistrationTypeReducer } from './ContactRegistrationType';
import { eventReducer } from './Event';
import { feeReducer } from './Fee';
import { productReducer } from './Product';
import { productContactReducer } from './ProductContact';
import { productWebinarReducer } from './ProductWebinar';
import { productCategoryReducer } from './ProductCategory';
import { questionReducer } from './Question';
import { registrationGroupReducer } from './RegistrationGroup';
import { registrationTypeReducer } from './RegistrationType';
import { resourceReducer } from './Resource';
import { scheduleReducer } from './Schedule';
import { scheduleContactReducer } from './ScheduleContact';
import { webinarReducer } from './Webinar';
import { categoryReducer } from './Category';

export const reducers = combineReducers({
	answer: answerReducer,
	attendee: attendeeReducer,
	contact: contactReducer,
	contactRegistrationType: contactRegistrationTypeReducer,
	event: eventReducer,
	fee: feeReducer,
	product: productReducer,
	productContact: productContactReducer,
	productWebinar: productWebinarReducer,
	productCategory: productCategoryReducer,
	question: questionReducer,
	registrationGroup: registrationGroupReducer,
	registrationType: registrationTypeReducer,
	resource: resourceReducer,
	schedule: scheduleReducer,
	scheduleContact: scheduleContactReducer,
	webinar: webinarReducer,
	category: categoryReducer
});

export * from './Answer';
export * from './Attendee';
export * from './Contact';
export * from './ContactRegistrationType';
export * from './Event';
export * from './Fee';
export * from './Product';
export * from './ProductContact';
export * from './ProductWebinar';
export * from './ProductCategory';
export * from './Question';
export * from './RegistrationGroup';
export * from './RegistrationType';
export * from './Resource';
export * from './Schedule';
export * from './ScheduleContact';
export * from './Webinar';
export * from './Category';
