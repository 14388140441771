import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getScheduleCollection,
	ScheduleId,
	ScheduleIds,
	ScheduleId_Some,
	ScheduleEntity,
	ScheduleEntities,
	ScheduleEntity_Some,
	ScheduleEntityPatch_Some,
	ScheduleCollection,
	ScheduleCollectionState,
	IScheduleActions,
	scheduleActions,
	ScheduleActionTypes,
	ScheduleContactEntity,
	ScheduleContactEntities
} from '..';
import {
	readSchedules,
	ReadSchedulesRequest,
	ScheduleApiOperation,
	writeSchedules,
	WriteSchedulesRequest
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	isObjectStatusActive,
	ObjectStatus,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	ScheduleStatusType
} from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as schedule from '../schedules/Schedule';

/**
 * Schedule helper interface
 *
 * @export
 * @interface IScheduleHelper
 * @extends {IEntityHelper}
 */
export interface IScheduleHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Schedule helper options interface
 *
 * @export
 * @interface ScheduleHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ScheduleHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const scheduleHelperOpts: ScheduleHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Schedule helper
 *
 * @export
 * @class ScheduleHelper
 * @extends {EntityHelper<ScheduleCollection, ScheduleActionTypes, ScheduleActions, ScheduleEntity, ScheduleEntities, ScheduleEntity_Some, ScheduleEntityPatch_Some, ScheduleId, ScheduleIds, ScheduleId_Some, ScheduleCollectionState, ScheduleHelperOpts>}
 * @implements {IScheduleHelper}
 */
export class ScheduleHelper
	extends EntityHelper<
		ScheduleCollection,
		ScheduleActionTypes,
		IScheduleActions,
		ScheduleEntity,
		ScheduleEntities,
		ScheduleEntity_Some,
		ScheduleEntityPatch_Some,
		ScheduleId,
		ScheduleIds,
		ScheduleId_Some,
		ScheduleCollectionState,
		ScheduleHelperOpts
	>
	implements IScheduleHelper {
	constructor() {
		super(
			useSelector(getScheduleCollection),
			scheduleActions,
			useDispatch(),
			scheduleHelperOpts
		);
		this.collection = useSelector(getScheduleCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ScheduleApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadSchedulesRequest> = {},
		callback?: any
	): Promise<ScheduleEntities> {
		if (!ctx.virtual.event.active()) return [];
		/*params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ScheduleApiOperation.readSchedules);*/
		let request: ReadSchedulesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ScheduleEntities = await readSchedules(ctx, request)
			.then((entities: ScheduleEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteSchedulesRequest> = {},
		callback?: any
	): Promise<ScheduleEntities> {
		//if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.virtual.event.active()) return [];
		if (!params.schedules) return [];
		let request: WriteSchedulesRequest = {
			...params,
			...{
				schedules: params.schedules,
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ScheduleEntities = await writeSchedules(ctx, request)
			.then((entities: ScheduleEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let schedule = this.get(id);
		if (!schedule) return false;

		schedule.i_.status = ObjectStatus.Deleted;

		let schedules = await this.write(ctx, {
			schedules: [schedule]
		});

		return schedules.length > 0;
	}

	all_Active(): ScheduleEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_ByOrganizerContact(
		ctx: UseCtx<any>,
		contactId: string
	): ScheduleEntities {
		let scheduleContact: ScheduleContactEntities = ctx.virtual.scheduleContact.all_ByOrganizerContact(
			contactId
		);

		let scheduleIds: string[] = scheduleContact.map(s => s.scheduleId);

		return this.all_Active().filter(s => scheduleIds.includes(s.id));
	}

	all_ByAttendeeContact(
		ctx: UseCtx<any>,
		contactId: string,
		scheduleStatuses?: ScheduleStatusType[],
		contactStatuses?: ScheduleContactStatusType[]
	): ScheduleEntities {
		let scheduleContact: ScheduleContactEntities = ctx.virtual.scheduleContact
			.all_ByContactAndRoles(contactId, [ScheduleContactRoleType.Attendee])
			.filter(
				x =>
					!contactStatuses ||
					contactStatuses.length <= 0 ||
					contactStatuses?.includes(x.status)
			);

		let scheduleIds: string[] = scheduleContact.map(s => s.scheduleId);
		return this.all_Active()
			.filter(s => scheduleIds.includes(s.id))
			.filter(
				x =>
					!scheduleStatuses ||
					scheduleStatuses.length <= 0 ||
					scheduleStatuses?.includes(x.status)
			);
	}

	all_ByHostContact(
		ctx: UseCtx<any>,
		contactId: string,
		scheduleStatuses?: ScheduleStatusType[],
		contactStatuses?: ScheduleContactStatusType[]
	): ScheduleEntities {
		let scheduleContact: ScheduleContactEntities = ctx.virtual.scheduleContact
			.all_ByContactAndRoles(contactId, [ScheduleContactRoleType.Host])
			.filter(
				x =>
					!contactStatuses ||
					contactStatuses.length <= 0 ||
					contactStatuses?.includes(x.status)
			);

		let scheduleIds: string[] = scheduleContact.map(s => s.scheduleId);
		return this.all_Active()
			.filter(s => scheduleIds.includes(s.id))
			.filter(
				x =>
					!scheduleStatuses ||
					scheduleStatuses.length <= 0 ||
					scheduleStatuses?.includes(x.status)
			);
	}

	all_ByAttendeeContactAndOrganizerContact(
		ctx: UseCtx<any>,
		attendeeContactId: string,
		organizerContactId: string,
		scheduleStatuses?: ScheduleStatusType[],
		contactStatuses?: ScheduleContactStatusType[]
	): ScheduleEntities {
		let organizerSchedules: ScheduleEntities = this.all_ByOrganizerContact(
			ctx,
			organizerContactId
		)
			.filter(
				x =>
					!scheduleStatuses ||
					scheduleStatuses.length <= 0 ||
					scheduleStatuses?.includes(x.status)
			)
			.filter(
				s =>
					ctx.virtual.scheduleContact
						.all_ScheduleParticipants([s.id])
						.filter(
							x =>
								!contactStatuses ||
								contactStatuses.length <= 0 ||
								contactStatuses?.includes(x.status)
						)
						.findIndex(c => c.contactId === attendeeContactId) >= 0
			);

		return organizerSchedules;
	}
}
