import {
	RegistrationGroupCollection,
	RegistrationGroupCollectionState,
	RegistrationGroupId,
	RegistrationGroupEntity_Some,
	RegistrationGroupEntityPatch_Some,
	RegistrationGroupId_Some,
	registrationGroupCollectionDefault,
	registrationGroupCollectionMutateIdOpts
} from '../collections/RegistrationGroup';
import * as actions from '../actions/RegistrationGroup';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * RegistrationGroup reducer
 *
 * @export
 * @param {RegistrationGroupCollection} [state=registrationGroupCollectionDefault]
 * @param {RegistrationGroupActionTypes} action
 * @returns
 */
export function registrationGroupReducer(
	state: RegistrationGroupCollection = registrationGroupCollectionDefault,
	action: actions.RegistrationGroupActionTypes
) {
	switch (action.type) {
		case actions.REGISTRATIONGROUP_MUTATE_ID:
			return collectionReducerActions.mutateId<RegistrationGroupCollection>(
				state,
				action.payload,
				registrationGroupCollectionMutateIdOpts
			);
		case actions.REGISTRATIONGROUP_SET:
			return collectionReducerActions.set<
				RegistrationGroupCollection,
				RegistrationGroupId
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_TOGGLE:
			return collectionReducerActions.toggle<
				RegistrationGroupCollection,
				RegistrationGroupId
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_SELECT:
			return collectionReducerActions.select<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_DESELECT:
			return collectionReducerActions.deselect<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_UPSERT:
			return collectionReducerActions.upsert<
				RegistrationGroupCollection,
				RegistrationGroupEntity_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_PATCH:
			return collectionReducerActions.patch<
				RegistrationGroupCollection,
				RegistrationGroupEntityPatch_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_CHANGE:
			return collectionReducerActions.change<
				RegistrationGroupCollection,
				RegistrationGroupEntityPatch_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_DELETE:
			return collectionReducerActions.delete<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_UNDELETE:
			return collectionReducerActions.undelete<
				RegistrationGroupCollection,
				RegistrationGroupId_Some
			>(state, action.payload);
		case actions.REGISTRATIONGROUP_SETSTATE:
			return collectionReducerActions.setState<
				RegistrationGroupCollection,
				RegistrationGroupCollectionState
			>(state, action.payload.state);
		case actions.REGISTRATIONGROUP_PATCHSTATE:
			return collectionReducerActions.patchState<
				RegistrationGroupCollection,
				RegistrationGroupCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
