import { FeeEntities } from '../../virtual/store/collections/Fee';

export interface FeeConfig {
	entities: FeeEntities;
	activeId?: string;
}

export const feeConfig: FeeConfig = {
	entities: []
};
