import {
	AuthId_Some,
	AuthEntity_Some,
	AuthEntityPatch_Some,
	AuthCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const AUTH_MUTATE_ID = '@app/AUTH_MUTATE_ID';
export const AUTH_SET = '@app/AUTH_SET';
export const AUTH_TOGGLE = '@app/AUTH_TOGGLE';
export const AUTH_SELECT = '@app/AUTH_SELECT';
export const AUTH_DESELECT = '@app/AUTH_DESELECT';
export const AUTH_UPSERT = '@app/AUTH_UPSERT';
export const AUTH_PATCH = '@app/AUTH_PATCH';
export const AUTH_CHANGE = '@app/AUTH_CHANGE';
export const AUTH_APPLY_CHANGES = '@app/AUTH_APPLY_CHANGES';
export const AUTH_CANCEL_CHANGES = '@app/AUTH_CANCEL_CHANGES';
export const AUTH_DELETE = '@app/AUTH_DELETE';
export const AUTH_UNDELETE = '@app/AUTH_UNDELETE';
export const AUTH_SETSTATE = '@app/AUTH_SETSTATE';
export const AUTH_PATCHSTATE = '@app/AUTH_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface AuthAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface AuthAction_MutateId extends EntityAction_MutateId {
	type: typeof AUTH_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active auth
 *
 * @interface AuthAction_Set
 * @extends {EntityAction_Set}
 */
export interface AuthAction_Set extends EntityAction_Set {
	type: typeof AUTH_SET;
	payload?: EntityId;
}

/**
 * Toggle active auth
 *
 * @interface AuthAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface AuthAction_Toggle extends EntityAction_Toggle {
	type: typeof AUTH_TOGGLE;
	payload?: EntityId;
}

/**
 * Select auth entities
 *
 * @interface AuthAction_Select
 * @extends {EntityAction_Select}
 */
interface AuthAction_Select extends EntityAction_Select {
	type: typeof AUTH_SELECT;
	payload: AuthId_Some;
}

/**
 * Deselect auth entities
 *
 * @interface AuthAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface AuthAction_Deselect extends EntityAction_Deselect {
	type: typeof AUTH_DESELECT;
	payload: AuthId_Some;
}

/**
 * Upsert auth entities
 *
 * @interface AuthAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface AuthAction_Upsert extends EntityAction_Upsert {
	type: typeof AUTH_UPSERT;
	payload: AuthEntity_Some;
}

/**
 * Patch auth entities
 *
 * @interface AuthAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface AuthAction_Patch extends EntityAction_Patch {
	type: typeof AUTH_PATCH;
	payload: AuthEntityPatch_Some;
}

/**
 * Change auth entities
 *
 * @interface AuthAction_Change
 * @extends {EntityAction_Change}
 */
interface AuthAction_Change extends EntityAction_Change {
	type: typeof AUTH_CHANGE;
	payload: AuthEntityPatch_Some;
}

/**
 * Apply changes to auth entities
 *
 * @interface AuthAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface AuthAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof AUTH_APPLY_CHANGES;
	payload: AuthId_Some;
}

/**
 * Cancel changes to auth entities
 *
 * @interface AuthAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface AuthAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof AUTH_CANCEL_CHANGES;
	payload: AuthId_Some;
}

/**
 * Delete auth entities
 *
 * @interface AuthAction_Delete
 * @extends {EntityAction_Delete}
 */
interface AuthAction_Delete extends EntityAction_Delete {
	type: typeof AUTH_DELETE;
	payload: AuthId_Some;
}

/**
 * Undelete auth entities
 *
 * @interface AuthAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface AuthAction_Undelete extends EntityAction_Undelete {
	type: typeof AUTH_UNDELETE;
	payload: AuthId_Some;
}

/**
 * Set auth collection / entity state
 *
 * @interface AuthAction_SetState
 * @extends {EntityAction_SetState}
 */
interface AuthAction_SetState extends EntityAction_SetState {
	type: typeof AUTH_SETSTATE;
}

/**
 * PAtch auth collection / entity state
 *
 * @interface AuthAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface AuthAction_PatchState extends EntityAction_PatchState {
	type: typeof AUTH_PATCHSTATE;
}

/**
 * Export auth action types
 *
 * @export
 */
export type AuthActionTypes =
	| AuthAction_MutateId
	| AuthAction_MutateId
	| AuthAction_Set
	| AuthAction_Toggle
	| AuthAction_Select
	| AuthAction_Deselect
	| AuthAction_Upsert
	| AuthAction_Patch
	| AuthAction_Change
	| AuthAction_ApplyChanges
	| AuthAction_CancelChanges
	| AuthAction_Delete
	| AuthAction_Undelete
	| AuthAction_SetState
	| AuthAction_PatchState;

/**
 * Auth actions helper interface
 *
 * @export
 * @interface IAuthActions
 * @extends {EntityActions}
 */
export interface IAuthActions extends EntityActions {
	//customAction(ids: AuthId_Some): AuthAction_CustomAction;
}

/**
 * Auth actions helper
 *
 * @export
 * @class AuthActions
 * @implements {EntityBaseActions}
 */
export class AuthActions implements IAuthActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): AuthAction_MutateId {
		return { type: AUTH_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): AuthAction_Set {
		return { type: AUTH_SET, payload: id };
	}
	toggle(id?: EntityId): AuthAction_Toggle {
		return { type: AUTH_TOGGLE, payload: id };
	}
	select(ids: AuthId_Some): AuthAction_Select {
		return {
			type: AUTH_SELECT,
			payload: ids
		};
	}
	deselect(ids: AuthId_Some): AuthAction_Deselect {
		return {
			type: AUTH_DESELECT,
			payload: ids
		};
	}
	upsert(entities: AuthEntity_Some): AuthAction_Upsert {
		return {
			type: AUTH_UPSERT,
			payload: entities
		};
	}
	patch(entities: AuthEntityPatch_Some): AuthAction_Patch {
		return {
			type: AUTH_PATCH,
			payload: entities
		};
	}
	change(entities: AuthEntityPatch_Some): AuthAction_Change {
		return {
			type: AUTH_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: AuthId_Some): AuthAction_ApplyChanges {
		return {
			type: AUTH_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: AuthId_Some): AuthAction_CancelChanges {
		return {
			type: AUTH_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: AuthId_Some): AuthAction_Delete {
		return {
			type: AUTH_DELETE,
			payload: ids
		};
	}
	undelete(ids: AuthId_Some): AuthAction_Undelete {
		return {
			type: AUTH_UNDELETE,
			payload: ids
		};
	}
	setState(state: AuthCollectionState): AuthAction_SetState {
		return {
			type: AUTH_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<AuthCollectionState>): AuthAction_PatchState {
		return {
			type: AUTH_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the auth actions helper
 *
 * @export
 */
export const authActions = new AuthActions();
