import React from 'react';
import { IonApp, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '@culturehq/add-to-calendar/dist/styles.css';

/* Theme variables */
import './ui/theme/variables.css';

/* CSS config */
import '../config/css/main.css';

import { AppData, MainMenu, MainRoutes } from './ui/components';

import { useUser } from './store';

import { appConfig } from '../config/app';

import { useApiData } from '../virtual/store/init';

import { Chat, ChatType } from './ui/components/Chat';

import { useEvent } from '../virtual/store';

setupIonicReact({
	mode: 'md'
});

const App: React.FC = () => {
	const { activeUser } = useUser();

	setupIonicReact({
		mode: 'md'
	});

	// get the theme in order of presidence,  active user settings, default user settings, first theme in app config
	const theme =
		activeUser?.settings?.theme ||
		Object.keys(appConfig.themes)[0] ||
		appConfig.user.settings.default.theme ||
		'';

	useApiData();

	return (
		<IonApp className={appConfig.themes[theme]?.className || ''}>
			<AppData>
				<IonReactRouter>
					<IonSplitPane contentId="main">
						<MainMenu />
						<MainRoutes />
					</IonSplitPane>
				</IonReactRouter>
			</AppData>
			<Chat></Chat>
		</IonApp>
	);
};

export default App;
