import {
	ProductWebinarId_Some,
	ProductWebinarEntity_Some,
	ProductWebinarEntityPatch_Some,
	ProductWebinarCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const PRODUCTWEBINAR_MUTATE_ID = '@app/PRODUCTWEBINAR_MUTATE_ID';
export const PRODUCTWEBINAR_SET = '@app/PRODUCTWEBINAR_SET';
export const PRODUCTWEBINAR_TOGGLE = '@app/PRODUCTWEBINAR_TOGGLE';
export const PRODUCTWEBINAR_SELECT = '@app/PRODUCTWEBINAR_SELECT';
export const PRODUCTWEBINAR_DESELECT = '@app/PRODUCTWEBINAR_DESELECT';
export const PRODUCTWEBINAR_UPSERT = '@app/PRODUCTWEBINAR_UPSERT';
export const PRODUCTWEBINAR_PATCH = '@app/PRODUCTWEBINAR_PATCH';
export const PRODUCTWEBINAR_CHANGE = '@app/PRODUCTWEBINAR_CHANGE';
export const PRODUCTWEBINAR_APPLY_CHANGES = '@app/PRODUCTWEBINAR_APPLY_CHANGES';
export const PRODUCTWEBINAR_CANCEL_CHANGES =
	'@app/PRODUCTWEBINAR_CANCEL_CHANGES';
export const PRODUCTWEBINAR_DELETE = '@app/PRODUCTWEBINAR_DELETE';
export const PRODUCTWEBINAR_UNDELETE = '@app/PRODUCTWEBINAR_UNDELETE';
export const PRODUCTWEBINAR_SETSTATE = '@app/PRODUCTWEBINAR_SETSTATE';
export const PRODUCTWEBINAR_PATCHSTATE = '@app/PRODUCTWEBINAR_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ProductWebinarAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ProductWebinarAction_MutateId extends EntityAction_MutateId {
	type: typeof PRODUCTWEBINAR_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active productWebinar
 *
 * @interface ProductWebinarAction_Set
 * @extends {EntityAction_Set}
 */
export interface ProductWebinarAction_Set extends EntityAction_Set {
	type: typeof PRODUCTWEBINAR_SET;
	payload?: EntityId;
}

/**
 * Toggle active productWebinar
 *
 * @interface ProductWebinarAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ProductWebinarAction_Toggle extends EntityAction_Toggle {
	type: typeof PRODUCTWEBINAR_TOGGLE;
	payload?: EntityId;
}

/**
 * Select productWebinar entities
 *
 * @interface ProductWebinarAction_Select
 * @extends {EntityAction_Select}
 */
interface ProductWebinarAction_Select extends EntityAction_Select {
	type: typeof PRODUCTWEBINAR_SELECT;
	payload: ProductWebinarId_Some;
}

/**
 * Deselect productWebinar entities
 *
 * @interface ProductWebinarAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ProductWebinarAction_Deselect extends EntityAction_Deselect {
	type: typeof PRODUCTWEBINAR_DESELECT;
	payload: ProductWebinarId_Some;
}

/**
 * Upsert productWebinar entities
 *
 * @interface ProductWebinarAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ProductWebinarAction_Upsert extends EntityAction_Upsert {
	type: typeof PRODUCTWEBINAR_UPSERT;
	payload: ProductWebinarEntity_Some;
}

/**
 * Patch productWebinar entities
 *
 * @interface ProductWebinarAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ProductWebinarAction_Patch extends EntityAction_Patch {
	type: typeof PRODUCTWEBINAR_PATCH;
	payload: ProductWebinarEntityPatch_Some;
}

/**
 * Change productWebinar entities
 *
 * @interface ProductWebinarAction_Change
 * @extends {EntityAction_Change}
 */
interface ProductWebinarAction_Change extends EntityAction_Change {
	type: typeof PRODUCTWEBINAR_CHANGE;
	payload: ProductWebinarEntityPatch_Some;
}

/**
 * Apply changes to productWebinar entities
 *
 * @interface ProductWebinarAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ProductWebinarAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof PRODUCTWEBINAR_APPLY_CHANGES;
	payload: ProductWebinarId_Some;
}

/**
 * Cancel changes to productWebinar entities
 *
 * @interface ProductWebinarAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ProductWebinarAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof PRODUCTWEBINAR_CANCEL_CHANGES;
	payload: ProductWebinarId_Some;
}

/**
 * Delete productWebinar entities
 *
 * @interface ProductWebinarAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ProductWebinarAction_Delete extends EntityAction_Delete {
	type: typeof PRODUCTWEBINAR_DELETE;
	payload: ProductWebinarId_Some;
}

/**
 * Undelete productWebinar entities
 *
 * @interface ProductWebinarAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ProductWebinarAction_Undelete extends EntityAction_Undelete {
	type: typeof PRODUCTWEBINAR_UNDELETE;
	payload: ProductWebinarId_Some;
}

/**
 * Set productWebinar collection / entity state
 *
 * @interface ProductWebinarAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ProductWebinarAction_SetState extends EntityAction_SetState {
	type: typeof PRODUCTWEBINAR_SETSTATE;
}

/**
 * PAtch productWebinar collection / entity state
 *
 * @interface ProductWebinarAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ProductWebinarAction_PatchState extends EntityAction_PatchState {
	type: typeof PRODUCTWEBINAR_PATCHSTATE;
}

/**
 * Export productWebinar action types
 *
 * @export
 */
export type ProductWebinarActionTypes =
	| ProductWebinarAction_MutateId
	| ProductWebinarAction_MutateId
	| ProductWebinarAction_Set
	| ProductWebinarAction_Toggle
	| ProductWebinarAction_Select
	| ProductWebinarAction_Deselect
	| ProductWebinarAction_Upsert
	| ProductWebinarAction_Patch
	| ProductWebinarAction_Change
	| ProductWebinarAction_ApplyChanges
	| ProductWebinarAction_CancelChanges
	| ProductWebinarAction_Delete
	| ProductWebinarAction_Undelete
	| ProductWebinarAction_SetState
	| ProductWebinarAction_PatchState;

/**
 * ProductWebinar actions helper interface
 *
 * @export
 * @interface IProductWebinarActions
 * @extends {EntityActions}
 */
export interface IProductWebinarActions extends EntityActions {
	//customAction(ids: ProductWebinarId_Some): ProductWebinarAction_CustomAction;
}

/**
 * ProductWebinar actions helper
 *
 * @export
 * @class ProductWebinarActions
 * @implements {EntityBaseActions}
 */
export class ProductWebinarActions implements IProductWebinarActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ProductWebinarAction_MutateId {
		return { type: PRODUCTWEBINAR_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ProductWebinarAction_Set {
		return { type: PRODUCTWEBINAR_SET, payload: id };
	}
	toggle(id?: EntityId): ProductWebinarAction_Toggle {
		return { type: PRODUCTWEBINAR_TOGGLE, payload: id };
	}
	select(ids: ProductWebinarId_Some): ProductWebinarAction_Select {
		return {
			type: PRODUCTWEBINAR_SELECT,
			payload: ids
		};
	}
	deselect(ids: ProductWebinarId_Some): ProductWebinarAction_Deselect {
		return {
			type: PRODUCTWEBINAR_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ProductWebinarEntity_Some): ProductWebinarAction_Upsert {
		return {
			type: PRODUCTWEBINAR_UPSERT,
			payload: entities
		};
	}
	patch(entities: ProductWebinarEntityPatch_Some): ProductWebinarAction_Patch {
		return {
			type: PRODUCTWEBINAR_PATCH,
			payload: entities
		};
	}
	change(
		entities: ProductWebinarEntityPatch_Some
	): ProductWebinarAction_Change {
		return {
			type: PRODUCTWEBINAR_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ProductWebinarId_Some): ProductWebinarAction_ApplyChanges {
		return {
			type: PRODUCTWEBINAR_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: ProductWebinarId_Some
	): ProductWebinarAction_CancelChanges {
		return {
			type: PRODUCTWEBINAR_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ProductWebinarId_Some): ProductWebinarAction_Delete {
		return {
			type: PRODUCTWEBINAR_DELETE,
			payload: ids
		};
	}
	undelete(ids: ProductWebinarId_Some): ProductWebinarAction_Undelete {
		return {
			type: PRODUCTWEBINAR_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: ProductWebinarCollectionState
	): ProductWebinarAction_SetState {
		return {
			type: PRODUCTWEBINAR_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ProductWebinarCollectionState>
	): ProductWebinarAction_PatchState {
		return {
			type: PRODUCTWEBINAR_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the productWebinar actions helper
 *
 * @export
 */
export const productWebinarActions = new ProductWebinarActions();
