import React from 'react';

import {
	ContactIndividualEntity,
	ContactOrganizationEntity,
	EventEntity,
	ScheduleEntity
} from '../../store';

import AddToCalendar from '@culturehq/add-to-calendar';
import moment from 'moment';

export interface MeetingAddToCalendarProps {
	schedule: ScheduleEntity | undefined;
	event: EventEntity | undefined;
	exhibitorContact: ContactOrganizationEntity | undefined;
	attendeeContact: ContactIndividualEntity | undefined;
	hidden?: boolean;
}

export const MeetingAddToCalendar: React.FC<MeetingAddToCalendarProps> = props => {
	return (
		<div hidden={props.hidden ?? false}>
			<AddToCalendar
				event={{
					name:
						'Meeting with ' +
						(props.exhibitorContact?.name?.trim() ?? '') +
						' and ' +
						(props.attendeeContact?.firstName ?? '') +
						' ' +
						(props.attendeeContact?.lastName ?? '') +
						' @ ' +
						moment(props.schedule?.start).format('LLLL ZZ'),
					details:
						'Event: ' + props.event?.name + props.exhibitorContact?.locations
							? ' <br /> Location: Booth ' + props.exhibitorContact?.locations
							: '',
					location: props.exhibitorContact?.locations
						? 'Booth ' + props.exhibitorContact?.locations
						: '',
					startsAt: moment(props.schedule?.start).toISOString(),
					endsAt: moment(props.schedule?.end).toISOString()
				}}
			></AddToCalendar>
		</div>
	);
};
