// tslint:disable
/**
 * CONEXSYS Schedule Portal API
 * The API for the CONEXSYS Schedule Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * Schedule: support@conexsys.com
 *
 * SCHEDULE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ScheduleHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ScheduleEntities, ScheduleEntity } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { ScheduleStatusType, ScheduleType, Tags } from '../models';
import { modifyEntity } from '../../../app/utils';

export enum ScheduleApiOperation {
	readSchedules = 'readSchedules',
	writeSchedules = 'writeSchedules'
}

export interface ReadSchedulesRequest {
	eventId: string;
	type?: ScheduleType;
	status?: ScheduleStatusType;
	modifiedFrom?: string;
	tags?: Tags;
	ids?: string[];
	queryLimit?: number;
	queryBookmark?: string;
}

export interface WriteSchedulesRequest {
	eventId: string;
	schedules: ScheduleEntities;
}

/**
 * Read schedules
 */
export async function readSchedules(
	ctx: UseCtx<any>,
	requestParameters: ReadSchedulesRequest
): Promise<ScheduleEntities> {
	const operationId = ScheduleApiOperation.readSchedules;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ScheduleEntities, ReadSchedulesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: ScheduleEntities | undefined = response.response.data;
	if (!entities) entities = [];

	if (entities.length > 0) {
		// set each entity.__state.eventId to the eventId from the params
		entities.forEach((entity, i, a) => {
			a[i].__state = _.merge(entity.__state || {}, {
				eventId: requestParameters.eventId
			});
		});

		// patch entities with an api success data for the operation
		setEntitiesApiOperationState<ScheduleEntities, ScheduleHelper>(
			ctx.virtual.schedule,
			entities,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.schedule.upsert(entities);
	}

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Schedules
 */
export async function writeSchedules(
	ctx: UseCtx<any>,
	requestParameters: WriteSchedulesRequest
): Promise<ScheduleEntities> {
	const operationId = ScheduleApiOperation.writeSchedules,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.schedules.forEach(
		(entity: ScheduleEntity, i: number, a: ScheduleEntities) => {
			a[i] = modifyEntity<ScheduleEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<ScheduleEntities, WriteSchedulesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'schedules'
	);

	let entities: ScheduleEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ScheduleEntities, ScheduleHelper>(
		ctx.virtual.schedule,
		entities,
		operationId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.virtual.schedule.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
