import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getWebinarCollection,
	WebinarId,
	WebinarIds,
	WebinarId_Some,
	WebinarEntity,
	WebinarEntities,
	WebinarEntity_Some,
	WebinarEntityPatch_Some,
	WebinarCollection,
	WebinarCollectionState,
	IWebinarActions,
	webinarActions,
	WebinarActionTypes
} from '..';
import {
	readWebinars,
	ReadWebinarsRequest,
	WebinarApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as webinar from '../webinars/Webinar';

/**
 * Webinar helper interface
 *
 * @export
 * @interface IWebinarHelper
 * @extends {IEntityHelper}
 */
export interface IWebinarHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Webinar helper options interface
 *
 * @export
 * @interface WebinarHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface WebinarHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const webinarHelperOpts: WebinarHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Webinar helper
 *
 * @export
 * @class WebinarHelper
 * @extends {EntityHelper<WebinarCollection, WebinarActionTypes, WebinarActions, WebinarEntity, WebinarEntities, WebinarEntity_Some, WebinarEntityPatch_Some, WebinarId, WebinarIds, WebinarId_Some, WebinarCollectionState, WebinarHelperOpts>}
 * @implements {IWebinarHelper}
 */
export class WebinarHelper
	extends EntityHelper<
		WebinarCollection,
		WebinarActionTypes,
		IWebinarActions,
		WebinarEntity,
		WebinarEntities,
		WebinarEntity_Some,
		WebinarEntityPatch_Some,
		WebinarId,
		WebinarIds,
		WebinarId_Some,
		WebinarCollectionState,
		WebinarHelperOpts
	>
	implements IWebinarHelper {
	constructor() {
		super(
			useSelector(getWebinarCollection),
			webinarActions,
			useDispatch(),
			webinarHelperOpts
		);
		this.collection = useSelector(getWebinarCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: WebinarApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadWebinarsRequest> = {},
		callback?: any
	): Promise<WebinarEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom || this.lastSuccess(WebinarApiOperation.readWebinars);
		let request: ReadWebinarsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: WebinarEntities = await readWebinars(ctx, request)
			.then((entities: WebinarEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): WebinarEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_By_Product(ctx: UseCtx<any>, productId: string): WebinarEntities {
		let productWebinars = ctx.virtual.productWebinar
			.all_By_ProductId(productId)
			?.map(x => x.webinarId);
		if (productWebinars && productWebinars.length > 0) {
			return this.all_Active().filter(w => productWebinars.includes(w.id));
		} else {
			return [];
		}
	}
}
