import {
	AttendeeId_Some,
	AttendeeEntity_Some,
	AttendeeEntityPatch_Some,
	AttendeeCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const ATTENDEE_MUTATE_ID = '@app/ATTENDEE_MUTATE_ID';
export const ATTENDEE_SET = '@app/ATTENDEE_SET';
export const ATTENDEE_TOGGLE = '@app/ATTENDEE_TOGGLE';
export const ATTENDEE_SELECT = '@app/ATTENDEE_SELECT';
export const ATTENDEE_DESELECT = '@app/ATTENDEE_DESELECT';
export const ATTENDEE_UPSERT = '@app/ATTENDEE_UPSERT';
export const ATTENDEE_PATCH = '@app/ATTENDEE_PATCH';
export const ATTENDEE_CHANGE = '@app/ATTENDEE_CHANGE';
export const ATTENDEE_APPLY_CHANGES = '@app/ATTENDEE_APPLY_CHANGES';
export const ATTENDEE_CANCEL_CHANGES = '@app/ATTENDEE_CANCEL_CHANGES';
export const ATTENDEE_DELETE = '@app/ATTENDEE_DELETE';
export const ATTENDEE_UNDELETE = '@app/ATTENDEE_UNDELETE';
export const ATTENDEE_SETSTATE = '@app/ATTENDEE_SETSTATE';
export const ATTENDEE_PATCHSTATE = '@app/ATTENDEE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface AttendeeAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface AttendeeAction_MutateId extends EntityAction_MutateId {
	type: typeof ATTENDEE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active attendee
 *
 * @interface AttendeeAction_Set
 * @extends {EntityAction_Set}
 */
export interface AttendeeAction_Set extends EntityAction_Set {
	type: typeof ATTENDEE_SET;
	payload?: EntityId;
}

/**
 * Toggle active attendee
 *
 * @interface AttendeeAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface AttendeeAction_Toggle extends EntityAction_Toggle {
	type: typeof ATTENDEE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select attendee entities
 *
 * @interface AttendeeAction_Select
 * @extends {EntityAction_Select}
 */
interface AttendeeAction_Select extends EntityAction_Select {
	type: typeof ATTENDEE_SELECT;
	payload: AttendeeId_Some;
}

/**
 * Deselect attendee entities
 *
 * @interface AttendeeAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface AttendeeAction_Deselect extends EntityAction_Deselect {
	type: typeof ATTENDEE_DESELECT;
	payload: AttendeeId_Some;
}

/**
 * Upsert attendee entities
 *
 * @interface AttendeeAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface AttendeeAction_Upsert extends EntityAction_Upsert {
	type: typeof ATTENDEE_UPSERT;
	payload: AttendeeEntity_Some;
}

/**
 * Patch attendee entities
 *
 * @interface AttendeeAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface AttendeeAction_Patch extends EntityAction_Patch {
	type: typeof ATTENDEE_PATCH;
	payload: AttendeeEntityPatch_Some;
}

/**
 * Change attendee entities
 *
 * @interface AttendeeAction_Change
 * @extends {EntityAction_Change}
 */
interface AttendeeAction_Change extends EntityAction_Change {
	type: typeof ATTENDEE_CHANGE;
	payload: AttendeeEntityPatch_Some;
}

/**
 * Apply changes to attendee entities
 *
 * @interface AttendeeAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface AttendeeAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof ATTENDEE_APPLY_CHANGES;
	payload: AttendeeId_Some;
}

/**
 * Cancel changes to attendee entities
 *
 * @interface AttendeeAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface AttendeeAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof ATTENDEE_CANCEL_CHANGES;
	payload: AttendeeId_Some;
}

/**
 * Delete attendee entities
 *
 * @interface AttendeeAction_Delete
 * @extends {EntityAction_Delete}
 */
interface AttendeeAction_Delete extends EntityAction_Delete {
	type: typeof ATTENDEE_DELETE;
	payload: AttendeeId_Some;
}

/**
 * Undelete attendee entities
 *
 * @interface AttendeeAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface AttendeeAction_Undelete extends EntityAction_Undelete {
	type: typeof ATTENDEE_UNDELETE;
	payload: AttendeeId_Some;
}

/**
 * Set attendee collection / entity state
 *
 * @interface AttendeeAction_SetState
 * @extends {EntityAction_SetState}
 */
interface AttendeeAction_SetState extends EntityAction_SetState {
	type: typeof ATTENDEE_SETSTATE;
}

/**
 * PAtch attendee collection / entity state
 *
 * @interface AttendeeAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface AttendeeAction_PatchState extends EntityAction_PatchState {
	type: typeof ATTENDEE_PATCHSTATE;
}

/**
 * Export attendee action types
 *
 * @export
 */
export type AttendeeActionTypes =
	| AttendeeAction_MutateId
	| AttendeeAction_MutateId
	| AttendeeAction_Set
	| AttendeeAction_Toggle
	| AttendeeAction_Select
	| AttendeeAction_Deselect
	| AttendeeAction_Upsert
	| AttendeeAction_Patch
	| AttendeeAction_Change
	| AttendeeAction_ApplyChanges
	| AttendeeAction_CancelChanges
	| AttendeeAction_Delete
	| AttendeeAction_Undelete
	| AttendeeAction_SetState
	| AttendeeAction_PatchState;

/**
 * Attendee actions helper interface
 *
 * @export
 * @interface IAttendeeActions
 * @extends {EntityActions}
 */
export interface IAttendeeActions extends EntityActions {
	//customAction(ids: AttendeeId_Some): AttendeeAction_CustomAction;
}

/**
 * Attendee actions helper
 *
 * @export
 * @class AttendeeActions
 * @implements {EntityBaseActions}
 */
export class AttendeeActions implements IAttendeeActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): AttendeeAction_MutateId {
		return { type: ATTENDEE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): AttendeeAction_Set {
		return { type: ATTENDEE_SET, payload: id };
	}
	toggle(id?: EntityId): AttendeeAction_Toggle {
		return { type: ATTENDEE_TOGGLE, payload: id };
	}
	select(ids: AttendeeId_Some): AttendeeAction_Select {
		return {
			type: ATTENDEE_SELECT,
			payload: ids
		};
	}
	deselect(ids: AttendeeId_Some): AttendeeAction_Deselect {
		return {
			type: ATTENDEE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: AttendeeEntity_Some): AttendeeAction_Upsert {
		return {
			type: ATTENDEE_UPSERT,
			payload: entities
		};
	}
	patch(entities: AttendeeEntityPatch_Some): AttendeeAction_Patch {
		return {
			type: ATTENDEE_PATCH,
			payload: entities
		};
	}
	change(entities: AttendeeEntityPatch_Some): AttendeeAction_Change {
		return {
			type: ATTENDEE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: AttendeeId_Some): AttendeeAction_ApplyChanges {
		return {
			type: ATTENDEE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: AttendeeId_Some): AttendeeAction_CancelChanges {
		return {
			type: ATTENDEE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: AttendeeId_Some): AttendeeAction_Delete {
		return {
			type: ATTENDEE_DELETE,
			payload: ids
		};
	}
	undelete(ids: AttendeeId_Some): AttendeeAction_Undelete {
		return {
			type: ATTENDEE_UNDELETE,
			payload: ids
		};
	}
	setState(state: AttendeeCollectionState): AttendeeAction_SetState {
		return {
			type: ATTENDEE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<AttendeeCollectionState>
	): AttendeeAction_PatchState {
		return {
			type: ATTENDEE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the attendee actions helper
 *
 * @export
 */
export const attendeeActions = new AttendeeActions();
