import {
	CategoryCollection,
	CategoryCollectionState,
	CategoryId,
	CategoryEntity_Some,
	CategoryEntityPatch_Some,
	CategoryId_Some,
	categoryCollectionDefault,
	categoryCollectionMutateIdOpts
} from '../collections/Category';
import * as actions from '../actions/Category';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Category reducer
 *
 * @export
 * @param {CategoryCollection} [state=categoryCollectionDefault]
 * @param {CategoryActionTypes} action
 * @returns
 */
export function categoryReducer(
	state: CategoryCollection = categoryCollectionDefault,
	action: actions.CategoryActionTypes
) {
	switch (action.type) {
		case actions.CATEGORY_MUTATE_ID:
			return collectionReducerActions.mutateId<CategoryCollection>(
				state,
				action.payload,
				categoryCollectionMutateIdOpts
			);
		case actions.CATEGORY_SET:
			return collectionReducerActions.set<CategoryCollection, CategoryId>(
				state,
				action.payload
			);
		case actions.CATEGORY_TOGGLE:
			return collectionReducerActions.toggle<CategoryCollection, CategoryId>(
				state,
				action.payload
			);
		case actions.CATEGORY_SELECT:
			return collectionReducerActions.select<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_DESELECT:
			return collectionReducerActions.deselect<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_UPSERT:
			return collectionReducerActions.upsert<
				CategoryCollection,
				CategoryEntity_Some
			>(state, action.payload);
		case actions.CATEGORY_PATCH:
			return collectionReducerActions.patch<
				CategoryCollection,
				CategoryEntityPatch_Some
			>(state, action.payload);
		case actions.CATEGORY_CHANGE:
			return collectionReducerActions.change<
				CategoryCollection,
				CategoryEntityPatch_Some
			>(state, action.payload);
		case actions.CATEGORY_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_DELETE:
			return collectionReducerActions.delete<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_UNDELETE:
			return collectionReducerActions.undelete<
				CategoryCollection,
				CategoryId_Some
			>(state, action.payload);
		case actions.CATEGORY_SETSTATE:
			return collectionReducerActions.setState<
				CategoryCollection,
				CategoryCollectionState
			>(state, action.payload.state);
		case actions.CATEGORY_PATCHSTATE:
			return collectionReducerActions.patchState<
				CategoryCollection,
				CategoryCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
