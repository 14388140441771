import React from 'react';

//DATA
import { useLocaleConfig } from '../../store';

//Style
import './Media.css';

export interface MediaConfigProps {
	placeholder: string;
}

export interface MediaOptions {
	width?: string;
	height?: string;
}

export interface MediaProps {
	class?: string;
	src: string;
	options?: MediaOptions;
}

export const Media: React.FC<MediaProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<MediaConfigProps>();

	const is_url = (str: string) => {
		let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		if (regexp.test(str)) {
			return true;
		} else {
			return false;
		}
	};
	const matchYoutubeUrl = (url: string) => {
		if (is_url(url)) {
			let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|attribution_link\?a=.+?watch.+?v(?:%|=)))((\w|-){11})(?:\S+)?$/;
			url = decodeURIComponent(url);
			return url.match(p) ? RegExp.$1 : false;
		}
		return false;
	};

	const matchVimeoUrl = (url: string) => {
		//if (is_url(url)) {
		let p = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
		url = decodeURIComponent(url);
		return p.test(url);
		//}
		//return false;
	};

	const matchDirectVideoUrl = (url: string) => {
		//if (is_url(url)) {
		let p = /(http|https)*?\.mp4/;
		url = decodeURIComponent(url);
		return p.test(url);
		//}
		//return false;
	};
	const embededYouTube = (videoCode: string) => {
		return (
			<div className="mediaWrapper">
				<iframe
					className="mediaIFrame"
					src={'https://www.youtube.com/embed/' + videoCode}
					frameBorder={0}
				></iframe>
			</div>
		);
	};
	const embededVimeo = (videoUrl: string) => {
		return (
			<div className="mediaWrapper">
				<iframe
					className="mediaIFrame"
					src={videoUrl}
					allow="autoplay; fullscreen; picture-in-picture"
				></iframe>
			</div>
		);
	};
	const embededDirectVideo = (videoUrl: string) => {
		return (
			<div className="mediaWrapper">
				<video className="mediaIFrame" controls>
					<source src={videoUrl} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		);
	};

	const isIframe = (iframeHtml: string) => {
		return iframeHtml.match(/(<iframe.+?<\/iframe>)/g);
	};

	const youTubeVideoCode = matchYoutubeUrl(props.src);

	if (youTubeVideoCode) {
		return embededYouTube(youTubeVideoCode);
	} else if (matchVimeoUrl(props.src)) {
		console.log(props.src);
		return embededVimeo(props.src);
	} else if (matchDirectVideoUrl(props.src)) {
		return embededDirectVideo(props.src);
	} else if (isIframe(props.src)) {
		return (
			<div
				dangerouslySetInnerHTML={{
					__html: props.src
				}}
			></div>
		);
	} else if (is_url(props.src)) {
		return (
			<div className="mediaWrapper">
				<iframe
					className="mediaIFrame"
					src={props.src}
					allow="autoplay; fullscreen; picture-in-picture"
				></iframe>
			</div>
		);
	} else {
		return <></>;
	}
};
