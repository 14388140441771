import React from 'react';
import { Menu } from '../components';
import { useCtx } from '../../../config/hooks';

export const MainMenu: React.FC = () => {
	const ctx = useCtx<{}>({});
	const currentEvent = ctx.virtual.event.active();
	const currentAttendee = ctx.virtual.attendee.active();

	return (
		<Menu
			contentId="main"
			instanceId="main"
			id="menu-main"
			defaultTags={['default']}
			customization={(currentEvent?.properties as any)?.app?.virtual?.mainMenu}
			activeAttendee={currentAttendee}
		/>
	);
};
