// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Contact type. Individual or Organization
 * @export
 * @enum {string}
 */
export enum ContactType {
	Individual = 'individual',
	Organization = 'organization'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesContactType = ['individual', 'organization'];
