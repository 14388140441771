// tslint:disable
/**
 * CONEXSYS Answer Portal API
 * The API for the CONEXSYS Answer Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * Answer: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { AnswerHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { AnswerEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';

export enum AnswerApiOperation {
	readAnswers = 'readAnswers'
}

export interface ReadAnswersRequest {
	eventId: string;
	modifiedFrom?: string;
	ids?: string[];
	queryLimit?: number;
	queryBookmark?: string;
}

/**
 * Read answers
 */
export async function readAnswers(
	ctx: UseCtx<any>,
	requestParameters: ReadAnswersRequest
): Promise<AnswerEntities> {
	const operationId = AnswerApiOperation.readAnswers;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<AnswerEntities, ReadAnswersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: AnswerEntities | undefined = response.response.data;
	if (!entities) entities = [];

	if (entities.length > 0) {
		// set each entity.__state.eventId to the eventId from the params
		entities.forEach((entity, i, a) => {
			a[i].__state = _.merge(entity.__state || {}, {
				eventId: requestParameters.eventId
			});
		});

		// patch entities with an api success data for the operation
		setEntitiesApiOperationState<AnswerEntities, AnswerHelper>(
			ctx.virtual.answer,
			entities,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.answer.upsert(entities);
	}

	// return the entities to the caller for direct reference
	return entities;
}
