import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getProductWebinarCollection,
	ProductWebinarId,
	ProductWebinarIds,
	ProductWebinarId_Some,
	ProductWebinarEntity,
	ProductWebinarEntities,
	ProductWebinarEntity_Some,
	ProductWebinarEntityPatch_Some,
	ProductWebinarCollection,
	ProductWebinarCollectionState,
	IProductWebinarActions,
	productWebinarActions,
	ProductWebinarActionTypes
} from '..';
import {
	readProductWebinars,
	ReadProductWebinarsRequest,
	ProductWebinarApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as productWebinar from '../productWebinars/ProductWebinar';

/**
 * ProductWebinar helper interface
 *
 * @export
 * @interface IProductWebinarHelper
 * @extends {IEntityHelper}
 */
export interface IProductWebinarHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * ProductWebinar helper options interface
 *
 * @export
 * @interface ProductWebinarHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ProductWebinarHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const productWebinarHelperOpts: ProductWebinarHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * ProductWebinar helper
 *
 * @export
 * @class ProductWebinarHelper
 * @extends {EntityHelper<ProductWebinarCollection, ProductWebinarActionTypes, ProductWebinarActions, ProductWebinarEntity, ProductWebinarEntities, ProductWebinarEntity_Some, ProductWebinarEntityPatch_Some, ProductWebinarId, ProductWebinarIds, ProductWebinarId_Some, ProductWebinarCollectionState, ProductWebinarHelperOpts>}
 * @implements {IProductWebinarHelper}
 */
export class ProductWebinarHelper
	extends EntityHelper<
		ProductWebinarCollection,
		ProductWebinarActionTypes,
		IProductWebinarActions,
		ProductWebinarEntity,
		ProductWebinarEntities,
		ProductWebinarEntity_Some,
		ProductWebinarEntityPatch_Some,
		ProductWebinarId,
		ProductWebinarIds,
		ProductWebinarId_Some,
		ProductWebinarCollectionState,
		ProductWebinarHelperOpts
	>
	implements IProductWebinarHelper {
	constructor() {
		super(
			useSelector(getProductWebinarCollection),
			productWebinarActions,
			useDispatch(),
			productWebinarHelperOpts
		);
		this.collection = useSelector(getProductWebinarCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ProductWebinarApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadProductWebinarsRequest> = {},
		callback?: any
	): Promise<ProductWebinarEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ProductWebinarApiOperation.readProductWebinars);
		let request: ReadProductWebinarsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ProductWebinarEntities = await readProductWebinars(
			ctx,
			request
		)
			.then((entities: ProductWebinarEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): ProductWebinarEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_By_ProductId(productId: string): ProductWebinarEntity[] {
		return this.all_Active().filter(pw => pw.productId === productId);
	}
}
