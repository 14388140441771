import React from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';

//UI
import { EventScheduleList } from '../containers/EventScheduleList';

const EventScheduleView: React.FC = () => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});

	return <EventScheduleList key="EventScheduleList1" />;
};

export default EventScheduleView;
