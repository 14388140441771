import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getResourceCollection,
	ResourceId,
	ResourceIds,
	ResourceId_Some,
	ResourceEntity,
	ResourceEntities,
	ResourceEntity_Some,
	ResourceEntityPatch_Some,
	ResourceCollection,
	ResourceCollectionState,
	IResourceActions,
	resourceActions,
	ResourceActionTypes
} from '..';
import {
	readResources,
	ReadResourcesRequest,
	ResourceApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	isResourceDocumentEntity,
	ResourceDocumentEntity,
	isResourceFileEntity,
	ResourceFileEntity,
	isResourceImageEntity,
	ResourceImageEntity,
	isResourceLinkEntity,
	ResourceLinkEntity
} from '../collections';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as resource from '../resources/Resource';

/**
 * Resource helper interface
 *
 * @export
 * @interface IResourceHelper
 * @extends {IEntityHelper}
 */
export interface IResourceHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Resource helper options interface
 *
 * @export
 * @interface ResourceHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ResourceHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const resourceHelperOpts: ResourceHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Resource helper
 *
 * @export
 * @class ResourceHelper
 * @extends {EntityHelper<ResourceCollection, ResourceActionTypes, ResourceActions, ResourceEntity, ResourceEntities, ResourceEntity_Some, ResourceEntityPatch_Some, ResourceId, ResourceIds, ResourceId_Some, ResourceCollectionState, ResourceHelperOpts>}
 * @implements {IResourceHelper}
 */
export class ResourceHelper
	extends EntityHelper<
		ResourceCollection,
		ResourceActionTypes,
		IResourceActions,
		ResourceEntity,
		ResourceEntities,
		ResourceEntity_Some,
		ResourceEntityPatch_Some,
		ResourceId,
		ResourceIds,
		ResourceId_Some,
		ResourceCollectionState,
		ResourceHelperOpts
	>
	implements IResourceHelper {
	constructor() {
		super(
			useSelector(getResourceCollection),
			resourceActions,
			useDispatch(),
			resourceHelperOpts
		);
		this.collection = useSelector(getResourceCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ResourceApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadResourcesRequest> = {},
		callback?: any
	): Promise<ResourceEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ResourceApiOperation.readResources);
		let request: ReadResourcesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ResourceEntities = await readResources(ctx, request)
			.then((entities: ResourceEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): ResourceEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_Document(): ResourceDocumentEntity[] {
		return this.all_Active().filter(isResourceDocumentEntity);
	}

	all_File(): ResourceFileEntity[] {
		return this.all_Active().filter(isResourceFileEntity);
	}

	all_Image(): ResourceImageEntity[] {
		return this.all_Active().filter(isResourceImageEntity);
	}

	all_Link(): ResourceLinkEntity[] {
		return this.all_Active().filter(isResourceLinkEntity);
	}

	all_By_Object(objectId: string): ResourceEntity[] {
		return this.all_Active().filter(c => c.objectIds?.includes(objectId));
	}
}
