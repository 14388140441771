import {
	AnswerId_Some,
	AnswerEntity_Some,
	AnswerEntityPatch_Some,
	AnswerCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const ANSWER_MUTATE_ID = '@app/ANSWER_MUTATE_ID';
export const ANSWER_SET = '@app/ANSWER_SET';
export const ANSWER_TOGGLE = '@app/ANSWER_TOGGLE';
export const ANSWER_SELECT = '@app/ANSWER_SELECT';
export const ANSWER_DESELECT = '@app/ANSWER_DESELECT';
export const ANSWER_UPSERT = '@app/ANSWER_UPSERT';
export const ANSWER_PATCH = '@app/ANSWER_PATCH';
export const ANSWER_CHANGE = '@app/ANSWER_CHANGE';
export const ANSWER_APPLY_CHANGES = '@app/ANSWER_APPLY_CHANGES';
export const ANSWER_CANCEL_CHANGES = '@app/ANSWER_CANCEL_CHANGES';
export const ANSWER_DELETE = '@app/ANSWER_DELETE';
export const ANSWER_UNDELETE = '@app/ANSWER_UNDELETE';
export const ANSWER_SETSTATE = '@app/ANSWER_SETSTATE';
export const ANSWER_PATCHSTATE = '@app/ANSWER_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface AnswerAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface AnswerAction_MutateId extends EntityAction_MutateId {
	type: typeof ANSWER_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active answer
 *
 * @interface AnswerAction_Set
 * @extends {EntityAction_Set}
 */
export interface AnswerAction_Set extends EntityAction_Set {
	type: typeof ANSWER_SET;
	payload?: EntityId;
}

/**
 * Toggle active answer
 *
 * @interface AnswerAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface AnswerAction_Toggle extends EntityAction_Toggle {
	type: typeof ANSWER_TOGGLE;
	payload?: EntityId;
}

/**
 * Select answer entities
 *
 * @interface AnswerAction_Select
 * @extends {EntityAction_Select}
 */
interface AnswerAction_Select extends EntityAction_Select {
	type: typeof ANSWER_SELECT;
	payload: AnswerId_Some;
}

/**
 * Deselect answer entities
 *
 * @interface AnswerAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface AnswerAction_Deselect extends EntityAction_Deselect {
	type: typeof ANSWER_DESELECT;
	payload: AnswerId_Some;
}

/**
 * Upsert answer entities
 *
 * @interface AnswerAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface AnswerAction_Upsert extends EntityAction_Upsert {
	type: typeof ANSWER_UPSERT;
	payload: AnswerEntity_Some;
}

/**
 * Patch answer entities
 *
 * @interface AnswerAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface AnswerAction_Patch extends EntityAction_Patch {
	type: typeof ANSWER_PATCH;
	payload: AnswerEntityPatch_Some;
}

/**
 * Change answer entities
 *
 * @interface AnswerAction_Change
 * @extends {EntityAction_Change}
 */
interface AnswerAction_Change extends EntityAction_Change {
	type: typeof ANSWER_CHANGE;
	payload: AnswerEntityPatch_Some;
}

/**
 * Apply changes to answer entities
 *
 * @interface AnswerAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface AnswerAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof ANSWER_APPLY_CHANGES;
	payload: AnswerId_Some;
}

/**
 * Cancel changes to answer entities
 *
 * @interface AnswerAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface AnswerAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof ANSWER_CANCEL_CHANGES;
	payload: AnswerId_Some;
}

/**
 * Delete answer entities
 *
 * @interface AnswerAction_Delete
 * @extends {EntityAction_Delete}
 */
interface AnswerAction_Delete extends EntityAction_Delete {
	type: typeof ANSWER_DELETE;
	payload: AnswerId_Some;
}

/**
 * Undelete answer entities
 *
 * @interface AnswerAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface AnswerAction_Undelete extends EntityAction_Undelete {
	type: typeof ANSWER_UNDELETE;
	payload: AnswerId_Some;
}

/**
 * Set answer collection / entity state
 *
 * @interface AnswerAction_SetState
 * @extends {EntityAction_SetState}
 */
interface AnswerAction_SetState extends EntityAction_SetState {
	type: typeof ANSWER_SETSTATE;
}

/**
 * PAtch answer collection / entity state
 *
 * @interface AnswerAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface AnswerAction_PatchState extends EntityAction_PatchState {
	type: typeof ANSWER_PATCHSTATE;
}

/**
 * Export answer action types
 *
 * @export
 */
export type AnswerActionTypes =
	| AnswerAction_MutateId
	| AnswerAction_MutateId
	| AnswerAction_Set
	| AnswerAction_Toggle
	| AnswerAction_Select
	| AnswerAction_Deselect
	| AnswerAction_Upsert
	| AnswerAction_Patch
	| AnswerAction_Change
	| AnswerAction_ApplyChanges
	| AnswerAction_CancelChanges
	| AnswerAction_Delete
	| AnswerAction_Undelete
	| AnswerAction_SetState
	| AnswerAction_PatchState;

/**
 * Answer actions helper interface
 *
 * @export
 * @interface IAnswerActions
 * @extends {EntityActions}
 */
export interface IAnswerActions extends EntityActions {
	//customAction(ids: AnswerId_Some): AnswerAction_CustomAction;
}

/**
 * Answer actions helper
 *
 * @export
 * @class AnswerActions
 * @implements {EntityBaseActions}
 */
export class AnswerActions implements IAnswerActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): AnswerAction_MutateId {
		return { type: ANSWER_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): AnswerAction_Set {
		return { type: ANSWER_SET, payload: id };
	}
	toggle(id?: EntityId): AnswerAction_Toggle {
		return { type: ANSWER_TOGGLE, payload: id };
	}
	select(ids: AnswerId_Some): AnswerAction_Select {
		return {
			type: ANSWER_SELECT,
			payload: ids
		};
	}
	deselect(ids: AnswerId_Some): AnswerAction_Deselect {
		return {
			type: ANSWER_DESELECT,
			payload: ids
		};
	}
	upsert(entities: AnswerEntity_Some): AnswerAction_Upsert {
		return {
			type: ANSWER_UPSERT,
			payload: entities
		};
	}
	patch(entities: AnswerEntityPatch_Some): AnswerAction_Patch {
		return {
			type: ANSWER_PATCH,
			payload: entities
		};
	}
	change(entities: AnswerEntityPatch_Some): AnswerAction_Change {
		return {
			type: ANSWER_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: AnswerId_Some): AnswerAction_ApplyChanges {
		return {
			type: ANSWER_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: AnswerId_Some): AnswerAction_CancelChanges {
		return {
			type: ANSWER_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: AnswerId_Some): AnswerAction_Delete {
		return {
			type: ANSWER_DELETE,
			payload: ids
		};
	}
	undelete(ids: AnswerId_Some): AnswerAction_Undelete {
		return {
			type: ANSWER_UNDELETE,
			payload: ids
		};
	}
	setState(state: AnswerCollectionState): AnswerAction_SetState {
		return {
			type: ANSWER_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<AnswerCollectionState>
	): AnswerAction_PatchState {
		return {
			type: ANSWER_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the answer actions helper
 *
 * @export
 */
export const answerActions = new AnswerActions();
