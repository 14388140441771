import { apiConfig } from '../app/Api';
import { Api } from '../../app/store/models/Api';

export interface VirtualConfig {
	apiId: string;
	security: {
		default: string[];
	};
}

export const virtualConfig: VirtualConfig = {
	apiId: 'virtual-api',
	security: {
		default: [
			'read:virtualEvents',
			'write:virtualEvents',
			'read:virtualUsers',
			'write:virtualUsers'
		]
	}
};
