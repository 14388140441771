import React from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';

//UI
import { IonContent } from '@ionic/react';
import { EventScheduleList } from '../containers/EventScheduleList';
import { AgendaHappeningNow } from '../containers/AgendaHappeningNow';

const AgendaView: React.FC = () => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});

	return (
		<EventScheduleList attendeeProductsOnly={true} key="EventScheduleList2" />
	);
};

export default AgendaView;
