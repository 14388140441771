import { ResourceEntities } from '../../virtual/store/collections/Resource';

export interface ResourceConfig {
	entities: ResourceEntities;
	activeId?: string;
}

export const resourceConfig: ResourceConfig = {
	entities: []
};
