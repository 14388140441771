import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Data
import { useLocaleConfig, UserEntity } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	AttendeeEntity,
	ContactIndividualEntity
} from '../../../virtual/store';
import { EventEntity } from '../../../virtual/store';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonInput,
	IonButton,
	IonCardSubtitle,
	IonSpinner,
	IonText,
	IonLoading,
	IonContent
} from '@ionic/react';

const LoginView: React.FC<any> = viewProps => {
	const [initialized, setInitialized] = useState(false);
	const [initializing, setInitializing] = useState(false);
	const [authIsProcessing, setAuthIsProcessing] = useState(false);
	const [authIsProcessed, setAuthIsProcessed] = useState(false);
	const [showFormError, setShowFormError] = useState(false);
	const [loginFormData, setLoginFormData] = useState({
		email: '',
		registrationCode: '',
		formErrors: { email: '', registrationCode: '', loginMessage: '' }
	});
	const {
		config: [config],
		activeUser,
		userHelper
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const history = useHistory();
	let activeEventId = ctx.virtual.event.active()?.id;

	const eventId: string = (
		viewProps.match?.params?.eventId ?? ('' as string)
	).toLowerCase();

	const userName: string = viewProps.match?.params?.userName ?? ('' as string);

	const accessCode: string =
		viewProps.match?.params?.accessCode ?? ('' as string);

	const init = async () => {
		if (initializing) return;
		setInitializing(true);
		userHelper.signout(userHelper.collection.allIds);
		userHelper.set(undefined);
		ctx.virtual.attendee.set(undefined);
		await initEvent();
		setInitializing(false);
		setInitialized(true);

		//AutoLogin
		if (userName && accessCode) {
			let un = atob(decodeURIComponent(userName));
			let ac = atob(decodeURIComponent(accessCode));
			login(un, ac);
		}
	};

	const initEvent = async () => {
		if (eventId !== activeEventId) {
			ctx.virtual.event.set(undefined, ctx);
			// load the event data
			await ctx.virtual.event.read(
				ctx,
				{ eventId },
				(event: EventEntity | undefined) => {
					if (!event) {
						console.log('Unable to load the event');
					} else {
						// set the active event
						ctx.virtual.event.set(event.id, ctx);
					}
				}
			);
		}
	};

	//http://localhost:8100/main/92CEEC99-13FB-4C88-8BC1-98E3230C8C85/josh@conexsys.com/329145
	const login = (
		userName: string | undefined,
		accessCode: string | undefined
	) => {
		let email = userName ?? loginFormData.email;
		let emailValid =
			(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)?.length ?? 0) > 0;
		let registrationCode = accessCode ?? loginFormData.registrationCode;
		let registrationCodeValid = registrationCode.length >= 5;
		let emailMessage = emailValid ? '' : 'Email is an invalid.';
		let registrationCodeMessage = registrationCodeValid
			? ''
			: 'Registration Code is invalid';

		if (
			emailValid &&
			email != '' &&
			registrationCodeValid &&
			registrationCode != ''
		) {
			setShowFormError(false);
			setAuthIsProcessing(true);
			ctx.virtual.attendee.authenticate(
				ctx,
				{
					eventId: eventId,
					username: email,
					accessCode: registrationCode
				},
				(attendee: AttendeeEntity | undefined) => {
					if (attendee && attendee.id !== '') {
						let contact = attendee.contact as ContactIndividualEntity;
						let user: UserEntity = {
							id: attendee.id,
							contactId: attendee.contactId,
							attendeeId: attendee.id,
							eventId: eventId,
							name: contact.name ?? '',
							firstName: contact.firstName,
							lastName: contact.lastName,
							middleName: contact.middleName,
							nickname: contact.preferredName,
							email: contact.email,
							emailVerified: true,
							locale: 'en',
							phoneVerified: false,
							auth: {},
							security: [
								'user',
								'read:virtualEvents',
								'write:virtualEvents',
								'read:virtualUsers',
								'write:virtualUsers'
							]
						};

						// upsert new or updated user
						userHelper.upsert(user);

						// set the active user
						userHelper.set(user.id);
						ctx.virtual.attendee.set(user.attendeeId);
						let event = ctx.virtual.event.active();
						if (event !== undefined)
							ctx.virtual.attendee.AttendEvent(ctx, event, attendee);

						setAuthIsProcessed(true);

						if (
							!(event?.properties as any)?.app?.virtual?.mainMenu?.home?.visible
						) {
							if (user.attendeeId === '2abf1d0d-e332-4896-9b3a-b854f56774e6') {
								history.push('/exhibit-hall');
							} else if (
								(event?.properties as any)?.app?.virtual?.mainMenu?.agenda
									?.visible
							) {
								history.push('/agenda');
							} else if (
								(event?.properties as any)?.app?.virtual?.mainMenu?.schedule
									?.visible
							) {
								history.push('/event-schedule');
							} else if (
								(event?.properties as any)?.app?.virtual?.mainMenu?.exhibitHall
									?.visible
							) {
								history.push('/exhibit-hall');
							} else if (
								(event?.properties as any)?.app?.virtual?.mainMenu
									?.sponsorShowcase?.visible
							) {
								history.push('/sponsors');
							} else if (
								(event?.properties as any)?.app?.virtual?.mainMenu?.speakers
									?.visible
							) {
								history.push('/speakers');
							} else {
								history.push('/home');
							}
						} else {
							history.push('/home');
						}
					} else {
						setLoginFormData({
							...loginFormData,
							formErrors: {
								email: '',
								registrationCode: '',
								loginMessage:
									'Login Failed. Please review your entries and try again.'
							}
						});
						setShowFormError(true);
					}
					setAuthIsProcessing(false);
				}
			);
		} else {
			setShowFormError(true);
			setLoginFormData({
				...loginFormData,
				formErrors: {
					email: emailMessage,
					registrationCode: registrationCodeMessage,
					loginMessage: ''
				}
			});
		}
	};

	const handleInput = (e: any) => {
		let targetValue =
			!('checked' in e.target) || e.target.checked ? e.target.value : '';
		let targetName: string = e.target.name;
		setLoginFormData({ ...loginFormData, [targetName]: targetValue });
	};

	useEffect(() => {
		init();
	}, [eventId, activeEventId]);

	if (!eventId || eventId === '') {
		//window.location.href = 'https://conexsys.com';
		return <>Event unknown</>;
	}

	return (
		<IonContent>
			{!initialized || initializing ? (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={initializing}
					message={'<h2>Loading</h2>Please wait...'}
					duration={2000}
				/>
			) : authIsProcessing ? (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={authIsProcessing}
					message={'<h2>Authenticating</h2>Please wait...'}
					duration={3000}
				/>
			) : !authIsProcessed ? (
				!ctx.virtual.event.isActiveEventOpen() ? (
					<IonCard>
						<IonCardHeader></IonCardHeader>
						<IonCardContent>
							<div
								dangerouslySetInnerHTML={{
									__html:
										(ctx.virtual.event.active()?.properties as any)?.app
											?.virtual?.pages.closed.en.closedMessage ??
										'<h2>Event Closed</h2>'
								}}
							></div>
						</IonCardContent>
					</IonCard>
				) : (
					<IonGrid style={{ margin: '0px', padding: '0px' }}>
						<IonRow class="ion-justify-content-center">
							<IonCol size="12" sizeMd="6">
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>Login</IonCardTitle>
										<IonCardSubtitle>
											You must be registered to participate.
											<br />
											Please login using your email and registration code.
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonItem
														hidden={!showFormError}
														lines="none"
														color="danger"
													>
														{loginFormData.formErrors.email}
														{loginFormData.formErrors.email !== '' &&
														loginFormData.formErrors.registrationCode !== '' ? (
															<br />
														) : (
															''
														)}
														{loginFormData.formErrors.registrationCode}
														{loginFormData.formErrors.loginMessage}
													</IonItem>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol size="10">
													<IonItem>
														<IonLabel position="floating">
															Email Address
														</IonLabel>
														<IonInput
															type="text"
															required
															onIonChange={handleInput}
															name="email"
														></IonInput>
													</IonItem>
													<IonItem>
														<IonLabel position="floating">
															Registration Code
														</IonLabel>
														<IonInput
															type="text"
															required
															onIonChange={handleInput}
															name="registrationCode"
														></IonInput>
													</IonItem>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													{!authIsProcessing ? (
														<IonButton
															onClick={() => login(undefined, undefined)}
														>
															LOGIN
														</IonButton>
													) : (
														<>
															<IonSpinner slot="start" />
															<IonText>Authenticating...</IonText>
														</>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
					</IonGrid>
				)
			) : (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={true}
					message={'<h2>Loading</h2>Please wait...'}
					duration={2000}
				/>
			)}
		</IonContent>
	);
};

export default LoginView;
