import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getProductCollection,
	ProductId,
	ProductIds,
	ProductId_Some,
	ProductEntity,
	ProductEntities,
	ProductEntity_Some,
	ProductEntityPatch_Some,
	ProductCollection,
	ProductCollectionState,
	IProductActions,
	productActions,
	ProductActionTypes
} from '..';
import {
	readProducts,
	ReadProductsRequest,
	ProductApiOperation
} from '../apis';
import { useCtx, UseCtx } from '../../../config/hooks';
import {
	isProductOtherEntity,
	ProductOtherEntity,
	isProductRegistrationEntity,
	ProductRegistrationEntity,
	isProductSessionEntity,
	ProductSessionEntity,
	ProductSessionEntityFilter
} from '../collections';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as product from '../products/Product';
import moment from 'moment';
import { isObjectStatusActive } from '../models';

/**
 * Product helper interface
 *
 * @export
 * @interface IProductHelper
 * @extends {IEntityHelper}
 */
export interface IProductHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Product helper options interface
 *
 * @export
 * @interface ProductHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ProductHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const productHelperOpts: ProductHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Product helper
 *
 * @export
 * @class ProductHelper
 * @extends {EntityHelper<ProductCollection, ProductActionTypes, ProductActions, ProductEntity, ProductEntities, ProductEntity_Some, ProductEntityPatch_Some, ProductId, ProductIds, ProductId_Some, ProductCollectionState, ProductHelperOpts>}
 * @implements {IProductHelper}
 */
export class ProductHelper
	extends EntityHelper<
		ProductCollection,
		ProductActionTypes,
		IProductActions,
		ProductEntity,
		ProductEntities,
		ProductEntity_Some,
		ProductEntityPatch_Some,
		ProductId,
		ProductIds,
		ProductId_Some,
		ProductCollectionState,
		ProductHelperOpts
	>
	implements IProductHelper {
	constructor() {
		super(
			useSelector(getProductCollection),
			productActions,
			useDispatch(),
			productHelperOpts
		);
		this.collection = useSelector(getProductCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ProductApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadProductsRequest> = {},
		callback?: any
	): Promise<ProductEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom || this.lastSuccess(ProductApiOperation.readProducts);

		let request: ReadProductsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ProductEntities = await readProducts(ctx, request)
			.then((entities: ProductEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(eventId: string): ProductEntity[] {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(
				prod =>
					prod.registrationStatus !== 'closed' &&
					prod.__state?.eventId === eventId
			);
	}

	all_Other(eventId: string): ProductOtherEntity[] {
		return this.all_Active(eventId).filter(isProductOtherEntity);
	}

	all_Registration(eventId: string): ProductRegistrationEntity[] {
		return this.all_Active(eventId).filter(isProductRegistrationEntity);
	}

	all_Session(eventId: string): ProductSessionEntity[] {
		return this.all_Active(eventId).filter(isProductSessionEntity);
	}

	get_Session(id: string, eventId: string): ProductSessionEntity | undefined {
		return this.all_Active(eventId)
			.filter(isProductSessionEntity)
			.find(product => product.id === id);
	}

	all_SessionByFilter(
		ctx: UseCtx<any>,
		filter: ProductSessionEntityFilter,
		eventId: string
	): ProductSessionEntity[] {
		return this.all_Session(eventId).filter(session => {
			let match = 0,
				matches = 0;
			if (filter.keyWords && filter.keyWords !== '') {
				for (let keyword of filter.keyWords.toLowerCase().split(' ')) {
					if (keyword === '') break;
					match++;
					if (
						(session?.name &&
							session?.name.toLowerCase().trim().indexOf(keyword) > -1) ||
						(session?.description &&
							session?.description?.toLowerCase().trim().indexOf(keyword) >
								-1) ||
						(session?.code &&
							session?.code?.toLowerCase().trim().indexOf(keyword) > -1)
					) {
						matches++;
					}
				}
			}
			if (session.start && filter.startDates && filter.startDates.length > 0) {
				match++;
				matches += filter.startDates.find(v =>
					moment(session.start).isSame(v, 'day')
				)
					? 1
					: 0;
			}
			if (session.end && filter.endDates && filter.endDates.length > 0) {
				match++;
				matches += filter.endDates.find(v =>
					moment(session.end).isSame(v, 'day')
				)
					? 1
					: 0;
			}

			if (filter.categories && filter.categories.length > 0) {
				let categories = ctx.virtual.productCategory.all_By_ProductId(
					session.id
				);
				match++;
				matches += filter.categories.find(v =>
					categories?.find(s => s.categoryId === v)
				)
					? 1
					: 0;
			}

			if (filter.speakers && filter.speakers.length > 0) {
				let speakers = ctx.virtual.productContact.all_Speakers_By_ProductId(
					ctx,
					session.id
				);
				match++;
				matches += filter.speakers.find(v => speakers?.find(s => s.id === v))
					? 1
					: 0;
			}

			if (session.location && filter.locations && filter.locations.length > 0) {
				match++;
				matches += filter.locations.find(v => session.location === v) ? 1 : 0;
			}

			if (!filter.viewingTypes || filter.viewingTypes?.length <= 0) {
				filter.viewingTypes = [
					'online-only',
					'in-person-and-online',
					'not-selected'
				];
			}
			if (filter.viewingTypes && filter.viewingTypes.length > 0) {
				match++;
				matches += filter.viewingTypes.find(v => v == session.viewingType)
					? 1
					: 0;
			}

			return match === matches;
		});
	}

	all_Session_By_SpeakerId(
		ctx: UseCtx<any>,
		speakerId: string,
		eventId: string
	): ProductSessionEntity[] {
		let speakerSessions = ctx.virtual.productContact.all_ProductId_By_SpeakerId(
			speakerId
		);
		return this.all_Active(eventId)
			.filter(isProductSessionEntity)
			.filter(p => speakerSessions?.find(s => s.productId === p.id));
	}
}
