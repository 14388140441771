import React, { useState, useEffect } from 'react';
import {
	IonText,
	IonIcon,
	IonSpinner,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonLabel
} from '@ionic/react';
import * as icons from 'ionicons/icons';

import { UserAccountCard } from '../components';

import { useAuth } from '../../store';

const AuthorizeView: React.FC = () => {
	const [authIsProcessing, setAuthIsProcessing] = useState(false);
	const [authIsProcessed, setAuthIsProcessed] = useState(false);

	const { authHelper, activeAuth, userHelper, activeUser } = useAuth();

	//on init
	useEffect(() => {
		// check for authorize callback access_token and state variables in the incomming window location href
		// if existing handle the auth redirect callback if not already handled
		if (
			activeAuth &&
			window.location.href.includes('access_token=') &&
			window.location.href.includes('state=') &&
			!authIsProcessed
		) {
			setAuthIsProcessing(true);
			authHelper.handleRedirectCallback(userHelper.digestAuth.bind(userHelper));
			setAuthIsProcessing(false);
			setAuthIsProcessed(true);
		}
	}, []);

	return !activeAuth ? (
		<IonText>
			No auth configured
			<IonIcon color="danger" icon={icons.warningOutline} />
		</IonText>
	) : authIsProcessing ? (
		<IonText>
			Authorizing
			<IonSpinner />
		</IonText>
	) : !activeUser ? (
		<IonText>
			<IonButton
				class="text-transform-none"
				color="medium"
				fill="outline"
				onClick={() => {
					authHelper.loginWithRedirect();
				}}
			>
				<IonIcon
					className="ion-hide-sm-down"
					slot="end"
					icon={icons.logInOutline}
				/>
				<IonLabel>Sign In</IonLabel>
			</IonButton>
		</IonText>
	) : (
		<IonGrid>
			<IonRow>
				<IonCol>
					{activeUser.auth[activeAuth.id].isAuthenticated ? (
						<IonText>
							Authenticated
							<IonIcon color="success" icon={icons.checkmarkCircleOutline} />
						</IonText>
					) : (
						<IonText>
							Not Authenticated
							<IonIcon color="warning" icon={icons.warningOutline} />
						</IonText>
					)}
				</IonCol>
			</IonRow>

			<IonRow>
				<IonCol>
					<UserAccountCard finish={() => {}} />
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default AuthorizeView;
