import {
	ContactRegistrationTypeId_Some,
	ContactRegistrationTypeEntity_Some,
	ContactRegistrationTypeEntityPatch_Some,
	ContactRegistrationTypeCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const CONTACTREGISTRATIONTYPE_MUTATE_ID =
	'@app/CONTACTREGISTRATIONTYPE_MUTATE_ID';
export const CONTACTREGISTRATIONTYPE_SET = '@app/CONTACTREGISTRATIONTYPE_SET';
export const CONTACTREGISTRATIONTYPE_TOGGLE =
	'@app/CONTACTREGISTRATIONTYPE_TOGGLE';
export const CONTACTREGISTRATIONTYPE_SELECT =
	'@app/CONTACTREGISTRATIONTYPE_SELECT';
export const CONTACTREGISTRATIONTYPE_DESELECT =
	'@app/CONTACTREGISTRATIONTYPE_DESELECT';
export const CONTACTREGISTRATIONTYPE_UPSERT =
	'@app/CONTACTREGISTRATIONTYPE_UPSERT';
export const CONTACTREGISTRATIONTYPE_PATCH =
	'@app/CONTACTREGISTRATIONTYPE_PATCH';
export const CONTACTREGISTRATIONTYPE_CHANGE =
	'@app/CONTACTREGISTRATIONTYPE_CHANGE';
export const CONTACTREGISTRATIONTYPE_APPLY_CHANGES =
	'@app/CONTACTREGISTRATIONTYPE_APPLY_CHANGES';
export const CONTACTREGISTRATIONTYPE_CANCEL_CHANGES =
	'@app/CONTACTREGISTRATIONTYPE_CANCEL_CHANGES';
export const CONTACTREGISTRATIONTYPE_DELETE =
	'@app/CONTACTREGISTRATIONTYPE_DELETE';
export const CONTACTREGISTRATIONTYPE_UNDELETE =
	'@app/CONTACTREGISTRATIONTYPE_UNDELETE';
export const CONTACTREGISTRATIONTYPE_SETSTATE =
	'@app/CONTACTREGISTRATIONTYPE_SETSTATE';
export const CONTACTREGISTRATIONTYPE_PATCHSTATE =
	'@app/CONTACTREGISTRATIONTYPE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ContactRegistrationTypeAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ContactRegistrationTypeAction_MutateId extends EntityAction_MutateId {
	type: typeof CONTACTREGISTRATIONTYPE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active contactRegistrationType
 *
 * @interface ContactRegistrationTypeAction_Set
 * @extends {EntityAction_Set}
 */
export interface ContactRegistrationTypeAction_Set extends EntityAction_Set {
	type: typeof CONTACTREGISTRATIONTYPE_SET;
	payload?: EntityId;
}

/**
 * Toggle active contactRegistrationType
 *
 * @interface ContactRegistrationTypeAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ContactRegistrationTypeAction_Toggle
	extends EntityAction_Toggle {
	type: typeof CONTACTREGISTRATIONTYPE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Select
 * @extends {EntityAction_Select}
 */
interface ContactRegistrationTypeAction_Select extends EntityAction_Select {
	type: typeof CONTACTREGISTRATIONTYPE_SELECT;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Deselect contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ContactRegistrationTypeAction_Deselect extends EntityAction_Deselect {
	type: typeof CONTACTREGISTRATIONTYPE_DESELECT;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Upsert contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ContactRegistrationTypeAction_Upsert extends EntityAction_Upsert {
	type: typeof CONTACTREGISTRATIONTYPE_UPSERT;
	payload: ContactRegistrationTypeEntity_Some;
}

/**
 * Patch contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ContactRegistrationTypeAction_Patch extends EntityAction_Patch {
	type: typeof CONTACTREGISTRATIONTYPE_PATCH;
	payload: ContactRegistrationTypeEntityPatch_Some;
}

/**
 * Change contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Change
 * @extends {EntityAction_Change}
 */
interface ContactRegistrationTypeAction_Change extends EntityAction_Change {
	type: typeof CONTACTREGISTRATIONTYPE_CHANGE;
	payload: ContactRegistrationTypeEntityPatch_Some;
}

/**
 * Apply changes to contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ContactRegistrationTypeAction_ApplyChanges
	extends EntityAction_ApplyChanges {
	type: typeof CONTACTREGISTRATIONTYPE_APPLY_CHANGES;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Cancel changes to contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ContactRegistrationTypeAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof CONTACTREGISTRATIONTYPE_CANCEL_CHANGES;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Delete contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ContactRegistrationTypeAction_Delete extends EntityAction_Delete {
	type: typeof CONTACTREGISTRATIONTYPE_DELETE;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Undelete contactRegistrationType entities
 *
 * @interface ContactRegistrationTypeAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ContactRegistrationTypeAction_Undelete extends EntityAction_Undelete {
	type: typeof CONTACTREGISTRATIONTYPE_UNDELETE;
	payload: ContactRegistrationTypeId_Some;
}

/**
 * Set contactRegistrationType collection / entity state
 *
 * @interface ContactRegistrationTypeAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ContactRegistrationTypeAction_SetState extends EntityAction_SetState {
	type: typeof CONTACTREGISTRATIONTYPE_SETSTATE;
}

/**
 * PAtch contactRegistrationType collection / entity state
 *
 * @interface ContactRegistrationTypeAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ContactRegistrationTypeAction_PatchState
	extends EntityAction_PatchState {
	type: typeof CONTACTREGISTRATIONTYPE_PATCHSTATE;
}

/**
 * Export contactRegistrationType action types
 *
 * @export
 */
export type ContactRegistrationTypeActionTypes =
	| ContactRegistrationTypeAction_MutateId
	| ContactRegistrationTypeAction_MutateId
	| ContactRegistrationTypeAction_Set
	| ContactRegistrationTypeAction_Toggle
	| ContactRegistrationTypeAction_Select
	| ContactRegistrationTypeAction_Deselect
	| ContactRegistrationTypeAction_Upsert
	| ContactRegistrationTypeAction_Patch
	| ContactRegistrationTypeAction_Change
	| ContactRegistrationTypeAction_ApplyChanges
	| ContactRegistrationTypeAction_CancelChanges
	| ContactRegistrationTypeAction_Delete
	| ContactRegistrationTypeAction_Undelete
	| ContactRegistrationTypeAction_SetState
	| ContactRegistrationTypeAction_PatchState;

/**
 * ContactRegistrationType actions helper interface
 *
 * @export
 * @interface IContactRegistrationTypeActions
 * @extends {EntityActions}
 */
export interface IContactRegistrationTypeActions extends EntityActions {
	//customAction(ids: ContactRegistrationTypeId_Some): ContactRegistrationTypeAction_CustomAction;
}

/**
 * ContactRegistrationType actions helper
 *
 * @export
 * @class ContactRegistrationTypeActions
 * @implements {EntityBaseActions}
 */
export class ContactRegistrationTypeActions
	implements IContactRegistrationTypeActions {
	constructor() {}

	mutateId(
		id: EntityId,
		newId: EntityId
	): ContactRegistrationTypeAction_MutateId {
		return { type: CONTACTREGISTRATIONTYPE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ContactRegistrationTypeAction_Set {
		return { type: CONTACTREGISTRATIONTYPE_SET, payload: id };
	}
	toggle(id?: EntityId): ContactRegistrationTypeAction_Toggle {
		return { type: CONTACTREGISTRATIONTYPE_TOGGLE, payload: id };
	}
	select(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_Select {
		return {
			type: CONTACTREGISTRATIONTYPE_SELECT,
			payload: ids
		};
	}
	deselect(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_Deselect {
		return {
			type: CONTACTREGISTRATIONTYPE_DESELECT,
			payload: ids
		};
	}
	upsert(
		entities: ContactRegistrationTypeEntity_Some
	): ContactRegistrationTypeAction_Upsert {
		return {
			type: CONTACTREGISTRATIONTYPE_UPSERT,
			payload: entities
		};
	}
	patch(
		entities: ContactRegistrationTypeEntityPatch_Some
	): ContactRegistrationTypeAction_Patch {
		return {
			type: CONTACTREGISTRATIONTYPE_PATCH,
			payload: entities
		};
	}
	change(
		entities: ContactRegistrationTypeEntityPatch_Some
	): ContactRegistrationTypeAction_Change {
		return {
			type: CONTACTREGISTRATIONTYPE_CHANGE,
			payload: entities
		};
	}
	applyChanges(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_ApplyChanges {
		return {
			type: CONTACTREGISTRATIONTYPE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_CancelChanges {
		return {
			type: CONTACTREGISTRATIONTYPE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_Delete {
		return {
			type: CONTACTREGISTRATIONTYPE_DELETE,
			payload: ids
		};
	}
	undelete(
		ids: ContactRegistrationTypeId_Some
	): ContactRegistrationTypeAction_Undelete {
		return {
			type: CONTACTREGISTRATIONTYPE_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: ContactRegistrationTypeCollectionState
	): ContactRegistrationTypeAction_SetState {
		return {
			type: CONTACTREGISTRATIONTYPE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ContactRegistrationTypeCollectionState>
	): ContactRegistrationTypeAction_PatchState {
		return {
			type: CONTACTREGISTRATIONTYPE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the contactRegistrationType actions helper
 *
 * @export
 */
export const contactRegistrationTypeActions = new ContactRegistrationTypeActions();
