import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getQuestionCollection,
	QuestionId,
	QuestionIds,
	QuestionId_Some,
	QuestionEntity,
	QuestionEntities,
	QuestionEntity_Some,
	QuestionEntityPatch_Some,
	QuestionCollection,
	QuestionCollectionState,
	IQuestionActions,
	questionActions,
	QuestionActionTypes
} from '..';
import {
	readQuestions,
	ReadQuestionsRequest,
	QuestionApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as question from '../questions/Question';

/**
 * Question helper interface
 *
 * @export
 * @interface IQuestionHelper
 * @extends {IEntityHelper}
 */
export interface IQuestionHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Question helper options interface
 *
 * @export
 * @interface QuestionHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface QuestionHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const questionHelperOpts: QuestionHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Question helper
 *
 * @export
 * @class QuestionHelper
 * @extends {EntityHelper<QuestionCollection, QuestionActionTypes, QuestionActions, QuestionEntity, QuestionEntities, QuestionEntity_Some, QuestionEntityPatch_Some, QuestionId, QuestionIds, QuestionId_Some, QuestionCollectionState, QuestionHelperOpts>}
 * @implements {IQuestionHelper}
 */
export class QuestionHelper
	extends EntityHelper<
		QuestionCollection,
		QuestionActionTypes,
		IQuestionActions,
		QuestionEntity,
		QuestionEntities,
		QuestionEntity_Some,
		QuestionEntityPatch_Some,
		QuestionId,
		QuestionIds,
		QuestionId_Some,
		QuestionCollectionState,
		QuestionHelperOpts
	>
	implements IQuestionHelper {
	constructor() {
		super(
			useSelector(getQuestionCollection),
			questionActions,
			useDispatch(),
			questionHelperOpts
		);
		this.collection = useSelector(getQuestionCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: QuestionApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadQuestionsRequest> = {},
		callback?: any
	): Promise<QuestionEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(QuestionApiOperation.readQuestions);
		let request: ReadQuestionsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: QuestionEntities = await readQuestions(ctx, request)
			.then((entities: QuestionEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): QuestionEntities {
		return this.all().filter(isObjectStatusActive);
	}
}
