import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { registrationGroupConfig } from '../../../config/virtual/RegistrationGroup';
import {
	RegistrationGroupAttendee,
	RegistrationGroupExhibitor,
	RegistrationGroupSpeaker,
	RegistrationGroupSponsor,
	RegistrationGroupType
} from '../models';

/**
 * RegistrationGroup 'attendee' entity interface
 *
 * @export
 * @interface RegistrationGroupAttendeeEntity
 * @extends {storage.Entity}
 */
export interface RegistrationGroupAttendeeEntity
	extends storage.Entity,
		RegistrationGroupAttendee {}

export const isRegistrationGroupAttendeeEntity = (
	object: RegistrationGroupEntity
): object is RegistrationGroupAttendeeEntity => {
	return (
		(object as RegistrationGroupAttendeeEntity).type ===
		RegistrationGroupType.Attendee
	);
};

/**
 * RegistrationGroup 'exhibitor' entity interface
 *
 * @export
 * @interface RegistrationGroupExhibitorEntity
 * @extends {storage.Entity}
 */
export interface RegistrationGroupExhibitorEntity
	extends storage.Entity,
		RegistrationGroupExhibitor {}

export const isRegistrationGroupExhibitorEntity = (
	object: RegistrationGroupEntity
): object is RegistrationGroupExhibitorEntity => {
	return (
		(object as RegistrationGroupExhibitorEntity).type ===
		RegistrationGroupType.Exhibitor
	);
};

/**
 * RegistrationGroup 'speaker' entity interface
 *
 * @export
 * @interface RegistrationGroupSpeakerEntity
 * @extends {storage.Entity}
 */
export interface RegistrationGroupSpeakerEntity
	extends storage.Entity,
		RegistrationGroupSpeaker {}

export const isRegistrationGroupSpeakerEntity = (
	object: RegistrationGroupEntity
): object is RegistrationGroupSpeakerEntity => {
	return (
		(object as RegistrationGroupSpeakerEntity).type ===
		RegistrationGroupType.Speaker
	);
};

/**
 * RegistrationGroup 'sponsor' entity interface
 *
 * @export
 * @interface RegistrationGroupSponsorEntity
 * @extends {storage.Entity}
 */
export interface RegistrationGroupSponsorEntity
	extends storage.Entity,
		RegistrationGroupSponsor {}

export const isRegistrationGroupSponsorEntity = (
	object: RegistrationGroupEntity
): object is RegistrationGroupSponsorEntity => {
	return (
		(object as RegistrationGroupSponsorEntity).type ===
		RegistrationGroupType.Sponsor
	);
};

/**
 * RegistrationGroup entity type
 *
 * @export
 */
export type RegistrationGroupEntity =
	| RegistrationGroupAttendeeEntity
	| RegistrationGroupExhibitorEntity
	| RegistrationGroupSpeakerEntity
	| RegistrationGroupSponsorEntity;

/**
 * RegistrationGroup entities array
 *
 * @export
 */
export type RegistrationGroupEntities = RegistrationGroupEntity[];

/**
 * Some registrationGroup entities: one entity or an array of entities
 *
 * @export
 */
export type RegistrationGroupEntity_Some =
	| RegistrationGroupEntity
	| RegistrationGroupEntities;

/**
 * RegistrationGroup entity patch interface.  Keeps 'id' required and all attendee keys optional
 *
 * @export
 * @interface RegistrationGroupEntityPatch
 * @extends {storage.EntityPatch<RegistrationGroupEntity>}
 */
export type RegistrationGroupEntityPatch = storage.EntityPatcher<
	RegistrationGroupEntity
>;

/**
 * RegistrationGroup entity patches array
 *
 * @export
 */
export type RegistrationGroupEntitiesPatch = RegistrationGroupEntityPatch[];

/**
 * Some registrationGroup entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type RegistrationGroupEntityPatch_Some =
	| RegistrationGroupEntityPatch
	| RegistrationGroupEntitiesPatch;

/**
 * RegistrationGroup entity id
 *
 * @export
 */
export type RegistrationGroupId = storage.EntityId;

/**
 * RegistrationGroup entity ids
 *
 * @export
 */
export type RegistrationGroupIds = storage.EntityIds;

/**
 * Some registrationGroup entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type RegistrationGroupId_Some =
	| RegistrationGroupId
	| RegistrationGroupIds;

/**
 * RegistrationGroup entities object by id
 *
 * @export
 * @interface RegistrationGroupEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface RegistrationGroupEntitiesObject
	extends storage.EntitiesObject {
	[id: string]: RegistrationGroupEntity;
}

/**
 * RegistrationGroup entity state
 *
 * @export
 * @interface RegistrationGroupEntityState
 * @extends {storage.EntityState}
 */
export interface RegistrationGroupEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * RegistrationGroup store collection
 *
 * @export
 * @interface RegistrationGroupStoreCollection
 * @extends {storage.Store}
 */
export interface RegistrationGroupStoreCollection extends storage.Store {
	registrationGroup: RegistrationGroupCollection;
}

/**
 * RegistrationGroup collection properties
 *
 * @export
 * @interface RegistrationGroupCollection
 * @extends {Collection}
 */
export interface RegistrationGroupCollection extends storage.Collection {
	byIds: RegistrationGroupEntitiesObject;
	mutation: RegistrationGroupEntitiesObject;
	cache: RegistrationGroupEntitiesObject;
	state: RegistrationGroupCollectionState;
}

/**
 * RegistrationGroup collection state
 *
 * @export
 * @interface RegistrationGroupCollectionState
 * @extends {storage.CollectionState}
 */
export interface RegistrationGroupCollectionState
	extends storage.CollectionState {}

// custom registrationGroup collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const registrationGroupCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const registrationGroupCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const registrationGroupCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const registrationGroupCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: registrationGroupCollectionIdStringProps,
	idArrayProps: registrationGroupCollectionIdArrayProps,
	idEntityProps: registrationGroupCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * RegistrationGroup collection default values, initialize collection with registrationGroup entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const registrationGroupCollectionDefault: RegistrationGroupCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as RegistrationGroupCollection),
		...{
			activeId:
				registrationGroupConfig.activeId ||
				registrationGroupConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	registrationGroupConfig.entities
);

/**
 * RegistrationGroup store collection default values
 *
 * @export
 * @constant
 */
export const registrationGroupStoreCollectionDefault: RegistrationGroupStoreCollection = {
	registrationGroup: registrationGroupCollectionDefault
};

/**
 * RegistrationGroup store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getRegistrationGroupCollection = (state: any) =>
	state.virtual.registrationGroup;
