import { ProductWebinarEntities } from '../../virtual/store/collections/ProductWebinar';

export interface ProductWebinarConfig {
	entities: ProductWebinarEntities;
	activeId?: string;
}

export const productWebinarConfig: ProductWebinarConfig = {
	entities: []
};
