import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getFeeCollection,
	FeeId,
	FeeIds,
	FeeId_Some,
	FeeEntity,
	FeeEntities,
	FeeEntity_Some,
	FeeEntityPatch_Some,
	FeeCollection,
	FeeCollectionState,
	IFeeActions,
	feeActions,
	FeeActionTypes
} from '..';
import { readFees, ReadFeesRequest, FeeApiOperation } from '../apis';
import { UseCtx } from '../../../config/hooks';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as fee from '../fees/Fee';

/**
 * Fee helper interface
 *
 * @export
 * @interface IFeeHelper
 * @extends {IEntityHelper}
 */
export interface IFeeHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Fee helper options interface
 *
 * @export
 * @interface FeeHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface FeeHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const feeHelperOpts: FeeHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Fee helper
 *
 * @export
 * @class FeeHelper
 * @extends {EntityHelper<FeeCollection, FeeActionTypes, FeeActions, FeeEntity, FeeEntities, FeeEntity_Some, FeeEntityPatch_Some, FeeId, FeeIds, FeeId_Some, FeeCollectionState, FeeHelperOpts>}
 * @implements {IFeeHelper}
 */
export class FeeHelper
	extends EntityHelper<
		FeeCollection,
		FeeActionTypes,
		IFeeActions,
		FeeEntity,
		FeeEntities,
		FeeEntity_Some,
		FeeEntityPatch_Some,
		FeeId,
		FeeIds,
		FeeId_Some,
		FeeCollectionState,
		FeeHelperOpts
	>
	implements IFeeHelper {
	constructor() {
		super(
			useSelector(getFeeCollection),
			feeActions,
			useDispatch(),
			feeHelperOpts
		);
		this.collection = useSelector(getFeeCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: FeeApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadFeesRequest> = {},
		callback?: any
	): Promise<FeeEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom || this.lastSuccess(FeeApiOperation.readFees);
		let request: ReadFeesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: FeeEntities = await readFees(ctx, request)
			.then((entities: FeeEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}
}
