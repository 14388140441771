// tslint:disable
/**
 * CONEXSYS ContactRegistrationType Portal API
 * The API for the CONEXSYS ContactRegistrationType Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * ContactRegistrationType: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ContactRegistrationTypeHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import {
	ContactRegistrationTypeEntities,
	ContactRegistrationTypeEntity
} from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';

export enum ContactRegistrationTypeApiOperation {
	readContactRegistrationTypes = 'readContactRegistrationTypes'
}

export interface ReadContactRegistrationTypesRequest {
	eventId: string;
	modifiedFrom?: string;
	ids?: string[];
	contactId?: string;
	contactIds?: string[];
	registrationTypeId?: string;
	registrationTypeIds?: string[];
	queryLimit?: number;
	queryBookmark?: string;
}

/**
 * Read contactRegistrationTypes
 */
export async function readContactRegistrationTypes(
	ctx: UseCtx<any>,
	requestParameters: ReadContactRegistrationTypesRequest
): Promise<ContactRegistrationTypeEntities> {
	const operationId =
		ContactRegistrationTypeApiOperation.readContactRegistrationTypes;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		ContactRegistrationTypeEntities,
		ReadContactRegistrationTypesRequest
	>(ctx, config.apiId, operationId, requestParameters);

	// upsert the entities to the local store
	ctx.virtual.contactRegistrationType.upsert(response.response.data);

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<
		ContactRegistrationTypeEntity,
		ContactRegistrationTypeHelper
	>(
		ctx.virtual.contactRegistrationType,
		response.response.data,
		operationId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// return the entities to the caller for direct reference
	return response.response.data;
}
