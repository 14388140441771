import {
	AnswerCollection,
	AnswerCollectionState,
	AnswerId,
	AnswerEntity_Some,
	AnswerEntityPatch_Some,
	AnswerId_Some,
	answerCollectionDefault,
	answerCollectionMutateIdOpts
} from '../collections/Answer';
import * as actions from '../actions/Answer';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Answer reducer
 *
 * @export
 * @param {AnswerCollection} [state=answerCollectionDefault]
 * @param {AnswerActionTypes} action
 * @returns
 */
export function answerReducer(
	state: AnswerCollection = answerCollectionDefault,
	action: actions.AnswerActionTypes
) {
	switch (action.type) {
		case actions.ANSWER_MUTATE_ID:
			return collectionReducerActions.mutateId<AnswerCollection>(
				state,
				action.payload,
				answerCollectionMutateIdOpts
			);
		case actions.ANSWER_SET:
			return collectionReducerActions.set<AnswerCollection, AnswerId>(
				state,
				action.payload
			);
		case actions.ANSWER_TOGGLE:
			return collectionReducerActions.toggle<AnswerCollection, AnswerId>(
				state,
				action.payload
			);
		case actions.ANSWER_SELECT:
			return collectionReducerActions.select<AnswerCollection, AnswerId_Some>(
				state,
				action.payload
			);
		case actions.ANSWER_DESELECT:
			return collectionReducerActions.deselect<AnswerCollection, AnswerId_Some>(
				state,
				action.payload
			);
		case actions.ANSWER_UPSERT:
			return collectionReducerActions.upsert<
				AnswerCollection,
				AnswerEntity_Some
			>(state, action.payload);
		case actions.ANSWER_PATCH:
			return collectionReducerActions.patch<
				AnswerCollection,
				AnswerEntityPatch_Some
			>(state, action.payload);
		case actions.ANSWER_CHANGE:
			return collectionReducerActions.change<
				AnswerCollection,
				AnswerEntityPatch_Some
			>(state, action.payload);
		case actions.ANSWER_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				AnswerCollection,
				AnswerId_Some
			>(state, action.payload);
		case actions.ANSWER_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				AnswerCollection,
				AnswerId_Some
			>(state, action.payload);
		case actions.ANSWER_DELETE:
			return collectionReducerActions.delete<AnswerCollection, AnswerId_Some>(
				state,
				action.payload
			);
		case actions.ANSWER_UNDELETE:
			return collectionReducerActions.undelete<AnswerCollection, AnswerId_Some>(
				state,
				action.payload
			);
		case actions.ANSWER_SETSTATE:
			return collectionReducerActions.setState<
				AnswerCollection,
				AnswerCollectionState
			>(state, action.payload.state);
		case actions.ANSWER_PATCHSTATE:
			return collectionReducerActions.patchState<
				AnswerCollection,
				AnswerCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
