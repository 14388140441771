// tslint:disable
/**
 * CONEXSYS Event Portal API
 * The API for the CONEXSYS Event Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { EventHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { EventEntity } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';

export enum EventApiOperation {
	readEvent = 'readEvent'
}

export interface ReadEventRequest {
	eventId: string;
}

/**
 * Get an event
 */
export async function readEvent(
	ctx: UseCtx<any>,
	requestParameters: ReadEventRequest
): Promise<EventEntity | undefined> {
	const operationId = EventApiOperation.readEvent;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<EventEntity, ReadEventRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entity: EventEntity | undefined = response.response.data;

	if (entity) {
		// set entity.__state.eventId to it's own id
		entity.__state = _.merge(entity.__state || {}, {
			eventId: requestParameters.eventId
		});

		// patch entity with an api success data for the operation
		setEntitiesApiOperationState<EventEntity, EventHelper>(
			ctx.virtual.event,
			entity,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.event.upsert(entity);
	}

	// return the entity to the caller for direct reference
	return entity;
}
