import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { resourceConfig } from '../../../config/virtual/Resource';
import {
	ResourceDocument,
	ResourceFile,
	ResourceImage,
	ResourceLink,
	ResourceType
} from '../models';

/**
 * Resource 'document' entity interface
 *
 * @export
 * @interface ResourceDocumentEntity
 * @extends {storage.Entity}
 */
export interface ResourceDocumentEntity
	extends storage.Entity,
		ResourceDocument {}

export const isResourceDocumentEntity = (
	object: ResourceEntity
): object is ResourceDocumentEntity => {
	return (object as ResourceDocumentEntity).type === ResourceType.Document;
};

/**
 * Resource 'file' entity interface
 *
 * @export
 * @interface ResourceFileEntity
 * @extends {storage.Entity}
 */
export interface ResourceFileEntity extends storage.Entity, ResourceFile {}

export const isResourceFileEntity = (
	object: ResourceEntity
): object is ResourceFileEntity => {
	return (object as ResourceFileEntity).type === ResourceType.File;
};

/**
 * Resource 'image' entity interface
 *
 * @export
 * @interface ResourceImageEntity
 * @extends {storage.Entity}
 */
export interface ResourceImageEntity extends storage.Entity, ResourceImage {}

export const isResourceImageEntity = (
	object: ResourceEntity
): object is ResourceImageEntity => {
	return (object as ResourceImageEntity).type === ResourceType.Image;
};

/**
 * Resource 'link' entity interface
 *
 * @export
 * @interface ResourceLinkEntity
 * @extends {storage.Entity}
 */
export interface ResourceLinkEntity extends storage.Entity, ResourceLink {}

export const isResourceLinkEntity = (
	object: ResourceEntity
): object is ResourceLinkEntity => {
	return (object as ResourceLinkEntity).type === ResourceType.Link;
};

/**
 * Resource entity type
 *
 * @export
 */
export type ResourceEntity =
	| ResourceDocumentEntity
	| ResourceFileEntity
	| ResourceImageEntity
	| ResourceLinkEntity;

/**
 * Resource entities array
 *
 * @export
 */
export type ResourceEntities = ResourceEntity[];

/**
 * Some resource entities: one entity or an array of entities
 *
 * @export
 */
export type ResourceEntity_Some = ResourceEntity | ResourceEntities;

/**
 * Resource entity patch interface.  Keeps 'id' required and all document keys optional
 *
 * @export
 * @interface ResourceEntityPatch
 * @extends {storage.EntityPatch<ResourceEntity>}
 */
export type ResourceEntityPatch = storage.EntityPatcher<ResourceEntity>;

/**
 * Resource entity patches array
 *
 * @export
 */
export type ResourceEntitiesPatch = ResourceEntityPatch[];

/**
 * Some resource entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ResourceEntityPatch_Some =
	| ResourceEntityPatch
	| ResourceEntitiesPatch;

/**
 * Resource entity id
 *
 * @export
 */
export type ResourceId = storage.EntityId;

/**
 * Resource entity ids
 *
 * @export
 */
export type ResourceIds = storage.EntityIds;

/**
 * Some resource entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ResourceId_Some = ResourceId | ResourceIds;

/**
 * Resource entities object by id
 *
 * @export
 * @interface ResourceEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ResourceEntitiesObject extends storage.EntitiesObject {
	[id: string]: ResourceEntity;
}

/**
 * Resource entity state
 *
 * @export
 * @interface ResourceEntityState
 * @extends {storage.EntityState}
 */
export interface ResourceEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Resource store collection
 *
 * @export
 * @interface ResourceStoreCollection
 * @extends {storage.Store}
 */
export interface ResourceStoreCollection extends storage.Store {
	resource: ResourceCollection;
}

/**
 * Resource collection properties
 *
 * @export
 * @interface ResourceCollection
 * @extends {Collection}
 */
export interface ResourceCollection extends storage.Collection {
	byIds: ResourceEntitiesObject;
	mutation: ResourceEntitiesObject;
	cache: ResourceEntitiesObject;
	state: ResourceCollectionState;
}

/**
 * Resource collection state
 *
 * @export
 * @interface ResourceCollectionState
 * @extends {storage.CollectionState}
 */
export interface ResourceCollectionState extends storage.CollectionState {}

// custom resource collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const resourceCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const resourceCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const resourceCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const resourceCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: resourceCollectionIdStringProps,
	idArrayProps: resourceCollectionIdArrayProps,
	idEntityProps: resourceCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Resource collection default values, initialize collection with resource entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const resourceCollectionDefault: ResourceCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ResourceCollection),
		...{
			activeId: resourceConfig.activeId || resourceConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	resourceConfig.entities
);

/**
 * Resource store collection default values
 *
 * @export
 * @constant
 */
export const resourceStoreCollectionDefault: ResourceStoreCollection = {
	resource: resourceCollectionDefault
};

/**
 * Resource store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getResourceCollection = (state: any) => state.virtual.resource;
