import {
	ScheduleContactId_Some,
	ScheduleContactEntity_Some,
	ScheduleContactEntityPatch_Some,
	ScheduleContactCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const SCHEDULECONTACT_MUTATE_ID = '@app/SCHEDULECONTACT_MUTATE_ID';
export const SCHEDULECONTACT_SET = '@app/SCHEDULECONTACT_SET';
export const SCHEDULECONTACT_TOGGLE = '@app/SCHEDULECONTACT_TOGGLE';
export const SCHEDULECONTACT_SELECT = '@app/SCHEDULECONTACT_SELECT';
export const SCHEDULECONTACT_DESELECT = '@app/SCHEDULECONTACT_DESELECT';
export const SCHEDULECONTACT_UPSERT = '@app/SCHEDULECONTACT_UPSERT';
export const SCHEDULECONTACT_PATCH = '@app/SCHEDULECONTACT_PATCH';
export const SCHEDULECONTACT_CHANGE = '@app/SCHEDULECONTACT_CHANGE';
export const SCHEDULECONTACT_APPLY_CHANGES =
	'@app/SCHEDULECONTACT_APPLY_CHANGES';
export const SCHEDULECONTACT_CANCEL_CHANGES =
	'@app/SCHEDULECONTACT_CANCEL_CHANGES';
export const SCHEDULECONTACT_DELETE = '@app/SCHEDULECONTACT_DELETE';
export const SCHEDULECONTACT_UNDELETE = '@app/SCHEDULECONTACT_UNDELETE';
export const SCHEDULECONTACT_SETSTATE = '@app/SCHEDULECONTACT_SETSTATE';
export const SCHEDULECONTACT_PATCHSTATE = '@app/SCHEDULECONTACT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ScheduleContactAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ScheduleContactAction_MutateId extends EntityAction_MutateId {
	type: typeof SCHEDULECONTACT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active scheduleContact
 *
 * @interface ScheduleContactAction_Set
 * @extends {EntityAction_Set}
 */
export interface ScheduleContactAction_Set extends EntityAction_Set {
	type: typeof SCHEDULECONTACT_SET;
	payload?: EntityId;
}

/**
 * Toggle active scheduleContact
 *
 * @interface ScheduleContactAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ScheduleContactAction_Toggle extends EntityAction_Toggle {
	type: typeof SCHEDULECONTACT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select scheduleContact entities
 *
 * @interface ScheduleContactAction_Select
 * @extends {EntityAction_Select}
 */
interface ScheduleContactAction_Select extends EntityAction_Select {
	type: typeof SCHEDULECONTACT_SELECT;
	payload: ScheduleContactId_Some;
}

/**
 * Deselect scheduleContact entities
 *
 * @interface ScheduleContactAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ScheduleContactAction_Deselect extends EntityAction_Deselect {
	type: typeof SCHEDULECONTACT_DESELECT;
	payload: ScheduleContactId_Some;
}

/**
 * Upsert scheduleContact entities
 *
 * @interface ScheduleContactAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ScheduleContactAction_Upsert extends EntityAction_Upsert {
	type: typeof SCHEDULECONTACT_UPSERT;
	payload: ScheduleContactEntity_Some;
}

/**
 * Patch scheduleContact entities
 *
 * @interface ScheduleContactAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ScheduleContactAction_Patch extends EntityAction_Patch {
	type: typeof SCHEDULECONTACT_PATCH;
	payload: ScheduleContactEntityPatch_Some;
}

/**
 * Change scheduleContact entities
 *
 * @interface ScheduleContactAction_Change
 * @extends {EntityAction_Change}
 */
interface ScheduleContactAction_Change extends EntityAction_Change {
	type: typeof SCHEDULECONTACT_CHANGE;
	payload: ScheduleContactEntityPatch_Some;
}

/**
 * Apply changes to scheduleContact entities
 *
 * @interface ScheduleContactAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ScheduleContactAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof SCHEDULECONTACT_APPLY_CHANGES;
	payload: ScheduleContactId_Some;
}

/**
 * Cancel changes to scheduleContact entities
 *
 * @interface ScheduleContactAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ScheduleContactAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof SCHEDULECONTACT_CANCEL_CHANGES;
	payload: ScheduleContactId_Some;
}

/**
 * Delete scheduleContact entities
 *
 * @interface ScheduleContactAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ScheduleContactAction_Delete extends EntityAction_Delete {
	type: typeof SCHEDULECONTACT_DELETE;
	payload: ScheduleContactId_Some;
}

/**
 * Undelete scheduleContact entities
 *
 * @interface ScheduleContactAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ScheduleContactAction_Undelete extends EntityAction_Undelete {
	type: typeof SCHEDULECONTACT_UNDELETE;
	payload: ScheduleContactId_Some;
}

/**
 * Set scheduleContact collection / entity state
 *
 * @interface ScheduleContactAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ScheduleContactAction_SetState extends EntityAction_SetState {
	type: typeof SCHEDULECONTACT_SETSTATE;
}

/**
 * PAtch scheduleContact collection / entity state
 *
 * @interface ScheduleContactAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ScheduleContactAction_PatchState extends EntityAction_PatchState {
	type: typeof SCHEDULECONTACT_PATCHSTATE;
}

/**
 * Export scheduleContact action types
 *
 * @export
 */
export type ScheduleContactActionTypes =
	| ScheduleContactAction_MutateId
	| ScheduleContactAction_MutateId
	| ScheduleContactAction_Set
	| ScheduleContactAction_Toggle
	| ScheduleContactAction_Select
	| ScheduleContactAction_Deselect
	| ScheduleContactAction_Upsert
	| ScheduleContactAction_Patch
	| ScheduleContactAction_Change
	| ScheduleContactAction_ApplyChanges
	| ScheduleContactAction_CancelChanges
	| ScheduleContactAction_Delete
	| ScheduleContactAction_Undelete
	| ScheduleContactAction_SetState
	| ScheduleContactAction_PatchState;

/**
 * ScheduleContact actions helper interface
 *
 * @export
 * @interface IScheduleContactActions
 * @extends {EntityActions}
 */
export interface IScheduleContactActions extends EntityActions {
	//customAction(ids: ScheduleContactId_Some): ScheduleContactAction_CustomAction;
}

/**
 * ScheduleContact actions helper
 *
 * @export
 * @class ScheduleContactActions
 * @implements {EntityBaseActions}
 */
export class ScheduleContactActions implements IScheduleContactActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ScheduleContactAction_MutateId {
		return { type: SCHEDULECONTACT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ScheduleContactAction_Set {
		return { type: SCHEDULECONTACT_SET, payload: id };
	}
	toggle(id?: EntityId): ScheduleContactAction_Toggle {
		return { type: SCHEDULECONTACT_TOGGLE, payload: id };
	}
	select(ids: ScheduleContactId_Some): ScheduleContactAction_Select {
		return {
			type: SCHEDULECONTACT_SELECT,
			payload: ids
		};
	}
	deselect(ids: ScheduleContactId_Some): ScheduleContactAction_Deselect {
		return {
			type: SCHEDULECONTACT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ScheduleContactEntity_Some): ScheduleContactAction_Upsert {
		return {
			type: SCHEDULECONTACT_UPSERT,
			payload: entities
		};
	}
	patch(
		entities: ScheduleContactEntityPatch_Some
	): ScheduleContactAction_Patch {
		return {
			type: SCHEDULECONTACT_PATCH,
			payload: entities
		};
	}
	change(
		entities: ScheduleContactEntityPatch_Some
	): ScheduleContactAction_Change {
		return {
			type: SCHEDULECONTACT_CHANGE,
			payload: entities
		};
	}
	applyChanges(
		ids: ScheduleContactId_Some
	): ScheduleContactAction_ApplyChanges {
		return {
			type: SCHEDULECONTACT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: ScheduleContactId_Some
	): ScheduleContactAction_CancelChanges {
		return {
			type: SCHEDULECONTACT_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ScheduleContactId_Some): ScheduleContactAction_Delete {
		return {
			type: SCHEDULECONTACT_DELETE,
			payload: ids
		};
	}
	undelete(ids: ScheduleContactId_Some): ScheduleContactAction_Undelete {
		return {
			type: SCHEDULECONTACT_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: ScheduleContactCollectionState
	): ScheduleContactAction_SetState {
		return {
			type: SCHEDULECONTACT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ScheduleContactCollectionState>
	): ScheduleContactAction_PatchState {
		return {
			type: SCHEDULECONTACT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the scheduleContact actions helper
 *
 * @export
 */
export const scheduleContactActions = new ScheduleContactActions();
