import uuid from './uuid';
import validator from 'validator';
import {
	ObjectType,
	ObjectHist,
	ObjectStatus,
	ObjectStoreBase,
	ObjectInfo
} from '../../virtual/store';
import moment from 'moment';
import _ from 'lodash';
import { UseCtx } from '../../config/hooks';
import { AppDevice, getAppDevice } from '../store';
import { Entity } from '../../storage/models';

const Uuid = uuid();

export interface EntityIdObject {
	id: string;
	type: ObjectType;
	date: Date;
}

export interface EntityIdOpts {
	id?: string;
	date?: Date;
}

export const generateEntityId = (
	type: ObjectType,
	opts: EntityIdOpts = {}
): string => generateEntityIdObject(type, opts).id;

export const generateEntityIdObject = (
	type: ObjectType,
	opts: EntityIdOpts = {}
): EntityIdObject => {
	//try {
	let entityIdObject: EntityIdObject = {
		id: opts.id && validator.isUUID(opts.id) ? opts.id : Uuid.new(),
		type, //type of id  object
		date: opts.date || new Date() //datetime of id creation
	};

	return entityIdObject;

	/*} catch (err) {
		throw 'Error generating id';
	}*/
};

export const getObjectHistNow = (ctx: UseCtx<any>, now?: Date): ObjectHist => {
	let appDevice: AppDevice = getAppDevice();
	let hist: ObjectHist = {
		dt: moment(now).toISOString()
	};
	return {
		...hist,
		..._.omitBy(
			{
				by: ctx.app.user.active()?.userId || undefined,
				on: appDevice.id || undefined,
				//for: ctx.app.service.active() || undefined,
				loc: appDevice.locale || undefined,
				tz: appDevice.timezone || undefined,
				iso: appDevice.country || undefined,
				ip: appDevice.ip || undefined,
				lat: appDevice.lat || undefined,
				lon: appDevice.lon || undefined
			},
			_.isNil
		)
	} as ObjectHist;
};

export const newEntity = (
	ctx: UseCtx<any>,
	type: ObjectType
): ObjectStoreBase => {
	let now = new Date();

	let entityIdObject: EntityIdObject = generateEntityIdObject(type, {
		date: now
	});

	let hist: ObjectHist = getObjectHistNow(ctx, now);

	return {
		id: entityIdObject.id,
		i_: {
			type,
			status: ObjectStatus.Active,
			created: hist,
			modified: hist
		}
	};
};

export const modifyEntity = <T extends ObjectStoreBase>(
	ctx: UseCtx<any>,
	entity: T
): T => {
	entity.i_.modified = getObjectHistNow(ctx);
	return entity;
};
