import { AnswerEntities } from '../../virtual/store/collections/Answer';

export interface AnswerConfig {
	entities: AnswerEntities;
	activeId?: string;
}

export const answerConfig: AnswerConfig = {
	entities: []
};
