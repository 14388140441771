import { RouteEntities } from '../../app/store/collections/Route';

export interface RouteConfig {
	entities: RouteEntities;
	activeId?: string;
}

export const routeConfig: RouteConfig = {
	entities: [
		{
			id: '/event/:eventId',
			view: 'virtual.LoginView',
			/*security: {
				deny: ['user'],
				denyRedirect: '/home'
			},*/
			header: {
				title: ''
			}
		},
		{
			id: '/auth/:eventId/:userName/:accessCode',
			view: 'virtual.AuthView',
			header: {
				title: ''
			}
		},
		{
			id: '/auth/:eventId/:userName/:accessCode/:page',
			view: 'virtual.AuthView',
			header: {
				title: ''
			}
		},
		{
			id: '/auth/:eventId/:userName/:accessCode/:page/:pageRecordId',
			view: 'virtual.AuthView',
			header: {
				title: ''
			}
		},
		{
			id: '/home',
			view: 'virtual.MainView',
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Home'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/agenda',
			view: 'virtual.AgendaView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Agenda'
			}
		},
		{
			id: '/event-schedule',
			view: 'virtual.EventScheduleView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Event Schedule'
			}
		},
		{
			id: '/session/:id',
			view: 'virtual.SessionView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Session'
			}
		},
		{
			id: '/speakers',
			view: 'virtual.SpeakersView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Speakers'
			}
		},
		{
			id: '/speaker/:id',
			view: 'virtual.SpeakerView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Speaker'
			}
		},
		{
			id: '/exhibit-hall',
			view: 'virtual.ExhibitHallView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Exhibit Hall'
			}
		},
		{
			id: '/exhibitor/:id',
			view: 'virtual.ExhibitorView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Exhibitor'
			}
		},
		{
			id: '/meetings',
			view: 'virtual.MeetingsView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'My Meetings'
			}
		},
		{
			id: '/meetings/:meetingId',
			view: 'virtual.MeetingsView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'My Meetings'
			}
		},
		{
			id: '/sponsors',
			view: 'virtual.SponsorsView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Sponsors'
			}
		},
		{
			id: '/about',
			view: 'virtual.AboutView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: ''
			}
		},
		{
			id: '/faq',
			view: 'virtual.FAQView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: ''
			}
		},
		{
			id: '/contact-us',
			view: 'virtual.ContactUsView',
			security: {
				allow: ['user']
			},
			menu: { main: { tags: ['default'] } },
			header: {
				title: ''
			}
		},
		{
			id: '/meetingaction/:eventId/:action/:scheduleContactId',
			view: 'virtual.MeetingActionView',
			header: {
				disabled: true
			}
		}
	]
};
