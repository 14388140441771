import React from 'react';

//import { initialState, State } from './state';
//import reducers from './reducers';
//import views from './views';

import { appConfig, AppConfig } from './app';
import { virtualConfig, VirtualConfig } from './virtual';

import { UserControl } from '../app/ui/components';

export interface Config {
	app: AppConfig;
	virtual: VirtualConfig;

	localStorage: string[];

	components: {
		[id: string]: React.FC<any | undefined>;
	};

	version: string;

	entityApiId: string;

	security: {
		default: string[];
	};

	//views: { [app: string]: { [view: string]: React.FC } };
	//reducers: any;
	//initialState: State;
}

export const config: Config = {
	app: appConfig,
	virtual: virtualConfig,

	localStorage: [
		'app.user',
		'virtual.attendee',
		'virtual.event',
		'virtual.answer',
		'virtual.question',
		'virtual.registrationGroup',
		'virtual.registrationtype',
		'virtual.contactRegistrationType',
		'virtual.fee'
	],

	components: {
		'app.UserControl': UserControl
	},

	version: '2.0.03',

	entityApiId: 'virtual-api',

	security: {
		default: [
			...appConfig.user.security.default,
			...virtualConfig.security.default
		]
	}

	//views,
	//reducers,
	//initialState
};

export default config;
