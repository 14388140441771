import { ProductEntities } from '../../virtual/store/collections/Product';

export interface ProductConfig {
	entities: ProductEntities;
	activeId?: string;
}

export const productConfig: ProductConfig = {
	entities: []
};
