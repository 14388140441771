import {
	RegistrationTypeId_Some,
	RegistrationTypeEntity_Some,
	RegistrationTypeEntityPatch_Some,
	RegistrationTypeCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const REGISTRATIONTYPE_MUTATE_ID = '@app/REGISTRATIONTYPE_MUTATE_ID';
export const REGISTRATIONTYPE_SET = '@app/REGISTRATIONTYPE_SET';
export const REGISTRATIONTYPE_TOGGLE = '@app/REGISTRATIONTYPE_TOGGLE';
export const REGISTRATIONTYPE_SELECT = '@app/REGISTRATIONTYPE_SELECT';
export const REGISTRATIONTYPE_DESELECT = '@app/REGISTRATIONTYPE_DESELECT';
export const REGISTRATIONTYPE_UPSERT = '@app/REGISTRATIONTYPE_UPSERT';
export const REGISTRATIONTYPE_PATCH = '@app/REGISTRATIONTYPE_PATCH';
export const REGISTRATIONTYPE_CHANGE = '@app/REGISTRATIONTYPE_CHANGE';
export const REGISTRATIONTYPE_APPLY_CHANGES =
	'@app/REGISTRATIONTYPE_APPLY_CHANGES';
export const REGISTRATIONTYPE_CANCEL_CHANGES =
	'@app/REGISTRATIONTYPE_CANCEL_CHANGES';
export const REGISTRATIONTYPE_DELETE = '@app/REGISTRATIONTYPE_DELETE';
export const REGISTRATIONTYPE_UNDELETE = '@app/REGISTRATIONTYPE_UNDELETE';
export const REGISTRATIONTYPE_SETSTATE = '@app/REGISTRATIONTYPE_SETSTATE';
export const REGISTRATIONTYPE_PATCHSTATE = '@app/REGISTRATIONTYPE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface RegistrationTypeAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface RegistrationTypeAction_MutateId extends EntityAction_MutateId {
	type: typeof REGISTRATIONTYPE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active registrationType
 *
 * @interface RegistrationTypeAction_Set
 * @extends {EntityAction_Set}
 */
export interface RegistrationTypeAction_Set extends EntityAction_Set {
	type: typeof REGISTRATIONTYPE_SET;
	payload?: EntityId;
}

/**
 * Toggle active registrationType
 *
 * @interface RegistrationTypeAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface RegistrationTypeAction_Toggle extends EntityAction_Toggle {
	type: typeof REGISTRATIONTYPE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select registrationType entities
 *
 * @interface RegistrationTypeAction_Select
 * @extends {EntityAction_Select}
 */
interface RegistrationTypeAction_Select extends EntityAction_Select {
	type: typeof REGISTRATIONTYPE_SELECT;
	payload: RegistrationTypeId_Some;
}

/**
 * Deselect registrationType entities
 *
 * @interface RegistrationTypeAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface RegistrationTypeAction_Deselect extends EntityAction_Deselect {
	type: typeof REGISTRATIONTYPE_DESELECT;
	payload: RegistrationTypeId_Some;
}

/**
 * Upsert registrationType entities
 *
 * @interface RegistrationTypeAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface RegistrationTypeAction_Upsert extends EntityAction_Upsert {
	type: typeof REGISTRATIONTYPE_UPSERT;
	payload: RegistrationTypeEntity_Some;
}

/**
 * Patch registrationType entities
 *
 * @interface RegistrationTypeAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface RegistrationTypeAction_Patch extends EntityAction_Patch {
	type: typeof REGISTRATIONTYPE_PATCH;
	payload: RegistrationTypeEntityPatch_Some;
}

/**
 * Change registrationType entities
 *
 * @interface RegistrationTypeAction_Change
 * @extends {EntityAction_Change}
 */
interface RegistrationTypeAction_Change extends EntityAction_Change {
	type: typeof REGISTRATIONTYPE_CHANGE;
	payload: RegistrationTypeEntityPatch_Some;
}

/**
 * Apply changes to registrationType entities
 *
 * @interface RegistrationTypeAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface RegistrationTypeAction_ApplyChanges
	extends EntityAction_ApplyChanges {
	type: typeof REGISTRATIONTYPE_APPLY_CHANGES;
	payload: RegistrationTypeId_Some;
}

/**
 * Cancel changes to registrationType entities
 *
 * @interface RegistrationTypeAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface RegistrationTypeAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof REGISTRATIONTYPE_CANCEL_CHANGES;
	payload: RegistrationTypeId_Some;
}

/**
 * Delete registrationType entities
 *
 * @interface RegistrationTypeAction_Delete
 * @extends {EntityAction_Delete}
 */
interface RegistrationTypeAction_Delete extends EntityAction_Delete {
	type: typeof REGISTRATIONTYPE_DELETE;
	payload: RegistrationTypeId_Some;
}

/**
 * Undelete registrationType entities
 *
 * @interface RegistrationTypeAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface RegistrationTypeAction_Undelete extends EntityAction_Undelete {
	type: typeof REGISTRATIONTYPE_UNDELETE;
	payload: RegistrationTypeId_Some;
}

/**
 * Set registrationType collection / entity state
 *
 * @interface RegistrationTypeAction_SetState
 * @extends {EntityAction_SetState}
 */
interface RegistrationTypeAction_SetState extends EntityAction_SetState {
	type: typeof REGISTRATIONTYPE_SETSTATE;
}

/**
 * PAtch registrationType collection / entity state
 *
 * @interface RegistrationTypeAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface RegistrationTypeAction_PatchState extends EntityAction_PatchState {
	type: typeof REGISTRATIONTYPE_PATCHSTATE;
}

/**
 * Export registrationType action types
 *
 * @export
 */
export type RegistrationTypeActionTypes =
	| RegistrationTypeAction_MutateId
	| RegistrationTypeAction_MutateId
	| RegistrationTypeAction_Set
	| RegistrationTypeAction_Toggle
	| RegistrationTypeAction_Select
	| RegistrationTypeAction_Deselect
	| RegistrationTypeAction_Upsert
	| RegistrationTypeAction_Patch
	| RegistrationTypeAction_Change
	| RegistrationTypeAction_ApplyChanges
	| RegistrationTypeAction_CancelChanges
	| RegistrationTypeAction_Delete
	| RegistrationTypeAction_Undelete
	| RegistrationTypeAction_SetState
	| RegistrationTypeAction_PatchState;

/**
 * RegistrationType actions helper interface
 *
 * @export
 * @interface IRegistrationTypeActions
 * @extends {EntityActions}
 */
export interface IRegistrationTypeActions extends EntityActions {
	//customAction(ids: RegistrationTypeId_Some): RegistrationTypeAction_CustomAction;
}

/**
 * RegistrationType actions helper
 *
 * @export
 * @class RegistrationTypeActions
 * @implements {EntityBaseActions}
 */
export class RegistrationTypeActions implements IRegistrationTypeActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): RegistrationTypeAction_MutateId {
		return { type: REGISTRATIONTYPE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): RegistrationTypeAction_Set {
		return { type: REGISTRATIONTYPE_SET, payload: id };
	}
	toggle(id?: EntityId): RegistrationTypeAction_Toggle {
		return { type: REGISTRATIONTYPE_TOGGLE, payload: id };
	}
	select(ids: RegistrationTypeId_Some): RegistrationTypeAction_Select {
		return {
			type: REGISTRATIONTYPE_SELECT,
			payload: ids
		};
	}
	deselect(ids: RegistrationTypeId_Some): RegistrationTypeAction_Deselect {
		return {
			type: REGISTRATIONTYPE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: RegistrationTypeEntity_Some): RegistrationTypeAction_Upsert {
		return {
			type: REGISTRATIONTYPE_UPSERT,
			payload: entities
		};
	}
	patch(
		entities: RegistrationTypeEntityPatch_Some
	): RegistrationTypeAction_Patch {
		return {
			type: REGISTRATIONTYPE_PATCH,
			payload: entities
		};
	}
	change(
		entities: RegistrationTypeEntityPatch_Some
	): RegistrationTypeAction_Change {
		return {
			type: REGISTRATIONTYPE_CHANGE,
			payload: entities
		};
	}
	applyChanges(
		ids: RegistrationTypeId_Some
	): RegistrationTypeAction_ApplyChanges {
		return {
			type: REGISTRATIONTYPE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: RegistrationTypeId_Some
	): RegistrationTypeAction_CancelChanges {
		return {
			type: REGISTRATIONTYPE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: RegistrationTypeId_Some): RegistrationTypeAction_Delete {
		return {
			type: REGISTRATIONTYPE_DELETE,
			payload: ids
		};
	}
	undelete(ids: RegistrationTypeId_Some): RegistrationTypeAction_Undelete {
		return {
			type: REGISTRATIONTYPE_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: RegistrationTypeCollectionState
	): RegistrationTypeAction_SetState {
		return {
			type: REGISTRATIONTYPE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<RegistrationTypeCollectionState>
	): RegistrationTypeAction_PatchState {
		return {
			type: REGISTRATIONTYPE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the registrationType actions helper
 *
 * @export
 */
export const registrationTypeActions = new RegistrationTypeActions();
