import React from 'react';

//UI
import { SpeakersList } from '../containers/SpeakersList';

const SpeakersView: React.FC = () => {
	return <SpeakersList />;
};

export default SpeakersView;
