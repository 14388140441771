import {
	FeeCollection,
	FeeCollectionState,
	FeeId,
	FeeEntity_Some,
	FeeEntityPatch_Some,
	FeeId_Some,
	feeCollectionDefault,
	feeCollectionMutateIdOpts
} from '../collections/Fee';
import * as actions from '../actions/Fee';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Fee reducer
 *
 * @export
 * @param {FeeCollection} [state=feeCollectionDefault]
 * @param {FeeActionTypes} action
 * @returns
 */
export function feeReducer(
	state: FeeCollection = feeCollectionDefault,
	action: actions.FeeActionTypes
) {
	switch (action.type) {
		case actions.FEE_MUTATE_ID:
			return collectionReducerActions.mutateId<FeeCollection>(
				state,
				action.payload,
				feeCollectionMutateIdOpts
			);
		case actions.FEE_SET:
			return collectionReducerActions.set<FeeCollection, FeeId>(
				state,
				action.payload
			);
		case actions.FEE_TOGGLE:
			return collectionReducerActions.toggle<FeeCollection, FeeId>(
				state,
				action.payload
			);
		case actions.FEE_SELECT:
			return collectionReducerActions.select<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_DESELECT:
			return collectionReducerActions.deselect<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_UPSERT:
			return collectionReducerActions.upsert<FeeCollection, FeeEntity_Some>(
				state,
				action.payload
			);
		case actions.FEE_PATCH:
			return collectionReducerActions.patch<FeeCollection, FeeEntityPatch_Some>(
				state,
				action.payload
			);
		case actions.FEE_CHANGE:
			return collectionReducerActions.change<
				FeeCollection,
				FeeEntityPatch_Some
			>(state, action.payload);
		case actions.FEE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_DELETE:
			return collectionReducerActions.delete<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_UNDELETE:
			return collectionReducerActions.undelete<FeeCollection, FeeId_Some>(
				state,
				action.payload
			);
		case actions.FEE_SETSTATE:
			return collectionReducerActions.setState<
				FeeCollection,
				FeeCollectionState
			>(state, action.payload.state);
		case actions.FEE_PATCHSTATE:
			return collectionReducerActions.patchState<
				FeeCollection,
				FeeCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
