// tslint:disable
/**
 * CONEXSYS Event App API
 * The API for the CONEXSYS Event App
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ObjectStoreBase } from './ObjectStoreBase';

/**
 * Object status
 * @export
 * @enum {string}
 */
export enum ObjectStatus {
	Active = 'active',
	Deactive = 'deactive',
	Deleted = 'deleted'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesObjectStatus = ['active', 'deactive', 'deleted'];

export const isObjectStatusActive = (object: ObjectStoreBase | undefined) =>
	object?.i_.status === ObjectStatus.Active;
export const isObjectStatusDeactive = (object: ObjectStoreBase | undefined) =>
	object?.i_.status === ObjectStatus.Deactive;
export const isObjectStatusDeleted = (object: ObjectStoreBase | undefined) =>
	object?.i_.status === ObjectStatus.Deleted;
