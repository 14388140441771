import { ProductCategoryEntities } from '../../virtual/store/collections/ProductCategory';

export interface ProductCategoryConfig {
	entities: ProductCategoryEntities;
	activeId?: string;
}

export const productCategoryConfig: ProductCategoryConfig = {
	entities: []
};
