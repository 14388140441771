import { ContactEntities } from '../../virtual/store/collections/Contact';

export interface ContactConfig {
	entities: ContactEntities;
	activeId?: string;
}

export const contactConfig: ContactConfig = {
	entities: []
};
