import {
	QuestionId_Some,
	QuestionEntity_Some,
	QuestionEntityPatch_Some,
	QuestionCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const QUESTION_MUTATE_ID = '@app/QUESTION_MUTATE_ID';
export const QUESTION_SET = '@app/QUESTION_SET';
export const QUESTION_TOGGLE = '@app/QUESTION_TOGGLE';
export const QUESTION_SELECT = '@app/QUESTION_SELECT';
export const QUESTION_DESELECT = '@app/QUESTION_DESELECT';
export const QUESTION_UPSERT = '@app/QUESTION_UPSERT';
export const QUESTION_PATCH = '@app/QUESTION_PATCH';
export const QUESTION_CHANGE = '@app/QUESTION_CHANGE';
export const QUESTION_APPLY_CHANGES = '@app/QUESTION_APPLY_CHANGES';
export const QUESTION_CANCEL_CHANGES = '@app/QUESTION_CANCEL_CHANGES';
export const QUESTION_DELETE = '@app/QUESTION_DELETE';
export const QUESTION_UNDELETE = '@app/QUESTION_UNDELETE';
export const QUESTION_SETSTATE = '@app/QUESTION_SETSTATE';
export const QUESTION_PATCHSTATE = '@app/QUESTION_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface QuestionAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface QuestionAction_MutateId extends EntityAction_MutateId {
	type: typeof QUESTION_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active question
 *
 * @interface QuestionAction_Set
 * @extends {EntityAction_Set}
 */
export interface QuestionAction_Set extends EntityAction_Set {
	type: typeof QUESTION_SET;
	payload?: EntityId;
}

/**
 * Toggle active question
 *
 * @interface QuestionAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface QuestionAction_Toggle extends EntityAction_Toggle {
	type: typeof QUESTION_TOGGLE;
	payload?: EntityId;
}

/**
 * Select question entities
 *
 * @interface QuestionAction_Select
 * @extends {EntityAction_Select}
 */
interface QuestionAction_Select extends EntityAction_Select {
	type: typeof QUESTION_SELECT;
	payload: QuestionId_Some;
}

/**
 * Deselect question entities
 *
 * @interface QuestionAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface QuestionAction_Deselect extends EntityAction_Deselect {
	type: typeof QUESTION_DESELECT;
	payload: QuestionId_Some;
}

/**
 * Upsert question entities
 *
 * @interface QuestionAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface QuestionAction_Upsert extends EntityAction_Upsert {
	type: typeof QUESTION_UPSERT;
	payload: QuestionEntity_Some;
}

/**
 * Patch question entities
 *
 * @interface QuestionAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface QuestionAction_Patch extends EntityAction_Patch {
	type: typeof QUESTION_PATCH;
	payload: QuestionEntityPatch_Some;
}

/**
 * Change question entities
 *
 * @interface QuestionAction_Change
 * @extends {EntityAction_Change}
 */
interface QuestionAction_Change extends EntityAction_Change {
	type: typeof QUESTION_CHANGE;
	payload: QuestionEntityPatch_Some;
}

/**
 * Apply changes to question entities
 *
 * @interface QuestionAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface QuestionAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof QUESTION_APPLY_CHANGES;
	payload: QuestionId_Some;
}

/**
 * Cancel changes to question entities
 *
 * @interface QuestionAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface QuestionAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof QUESTION_CANCEL_CHANGES;
	payload: QuestionId_Some;
}

/**
 * Delete question entities
 *
 * @interface QuestionAction_Delete
 * @extends {EntityAction_Delete}
 */
interface QuestionAction_Delete extends EntityAction_Delete {
	type: typeof QUESTION_DELETE;
	payload: QuestionId_Some;
}

/**
 * Undelete question entities
 *
 * @interface QuestionAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface QuestionAction_Undelete extends EntityAction_Undelete {
	type: typeof QUESTION_UNDELETE;
	payload: QuestionId_Some;
}

/**
 * Set question collection / entity state
 *
 * @interface QuestionAction_SetState
 * @extends {EntityAction_SetState}
 */
interface QuestionAction_SetState extends EntityAction_SetState {
	type: typeof QUESTION_SETSTATE;
}

/**
 * PAtch question collection / entity state
 *
 * @interface QuestionAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface QuestionAction_PatchState extends EntityAction_PatchState {
	type: typeof QUESTION_PATCHSTATE;
}

/**
 * Export question action types
 *
 * @export
 */
export type QuestionActionTypes =
	| QuestionAction_MutateId
	| QuestionAction_MutateId
	| QuestionAction_Set
	| QuestionAction_Toggle
	| QuestionAction_Select
	| QuestionAction_Deselect
	| QuestionAction_Upsert
	| QuestionAction_Patch
	| QuestionAction_Change
	| QuestionAction_ApplyChanges
	| QuestionAction_CancelChanges
	| QuestionAction_Delete
	| QuestionAction_Undelete
	| QuestionAction_SetState
	| QuestionAction_PatchState;

/**
 * Question actions helper interface
 *
 * @export
 * @interface IQuestionActions
 * @extends {EntityActions}
 */
export interface IQuestionActions extends EntityActions {
	//customAction(ids: QuestionId_Some): QuestionAction_CustomAction;
}

/**
 * Question actions helper
 *
 * @export
 * @class QuestionActions
 * @implements {EntityBaseActions}
 */
export class QuestionActions implements IQuestionActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): QuestionAction_MutateId {
		return { type: QUESTION_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): QuestionAction_Set {
		return { type: QUESTION_SET, payload: id };
	}
	toggle(id?: EntityId): QuestionAction_Toggle {
		return { type: QUESTION_TOGGLE, payload: id };
	}
	select(ids: QuestionId_Some): QuestionAction_Select {
		return {
			type: QUESTION_SELECT,
			payload: ids
		};
	}
	deselect(ids: QuestionId_Some): QuestionAction_Deselect {
		return {
			type: QUESTION_DESELECT,
			payload: ids
		};
	}
	upsert(entities: QuestionEntity_Some): QuestionAction_Upsert {
		return {
			type: QUESTION_UPSERT,
			payload: entities
		};
	}
	patch(entities: QuestionEntityPatch_Some): QuestionAction_Patch {
		return {
			type: QUESTION_PATCH,
			payload: entities
		};
	}
	change(entities: QuestionEntityPatch_Some): QuestionAction_Change {
		return {
			type: QUESTION_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: QuestionId_Some): QuestionAction_ApplyChanges {
		return {
			type: QUESTION_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: QuestionId_Some): QuestionAction_CancelChanges {
		return {
			type: QUESTION_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: QuestionId_Some): QuestionAction_Delete {
		return {
			type: QUESTION_DELETE,
			payload: ids
		};
	}
	undelete(ids: QuestionId_Some): QuestionAction_Undelete {
		return {
			type: QUESTION_UNDELETE,
			payload: ids
		};
	}
	setState(state: QuestionCollectionState): QuestionAction_SetState {
		return {
			type: QUESTION_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<QuestionCollectionState>
	): QuestionAction_PatchState {
		return {
			type: QUESTION_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the question actions helper
 *
 * @export
 */
export const questionActions = new QuestionActions();
