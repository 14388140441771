import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonLabel,
	IonList,
	IonItem,
	IonImg,
	IonBackButton,
	IonToolbar,
	IonButtons,
	IonContent,
	IonButton,
	IonBadge,
	IonModal,
	IonTitle
} from '@ionic/react';
import {
	logoTwitter,
	logoFacebook,
	logoLinkedin,
	mail,
	globe,
	download,
	documentOutline,
	calendarOutline
} from 'ionicons/icons';

import { Media } from '../../../app/ui/components';
import {
	ContactOrganizationEntity,
	ResourceEntity,
	ContactEntities,
	AttendeeHelper,
	ScheduleContactEntity,
	ScheduleContactEntities,
	ScheduleEntities,
	ScheduleType,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	Schedule,
	AttendeeEntity,
	ScheduleEntity
} from '../../store';

import './ExhibitorView.css';
import AppointmentBooking from '../containers/AppointmentBooking';
import { ScheduleMeetingButton } from '../components/ScheduleMeetingButton';
import { SendEmail } from '../containers/SendEmail';

const ExhibitorView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const currentEvent = ctx.virtual.event.active();
	const currentExhibitor:
		| ContactOrganizationEntity
		| undefined = ctx.virtual.contact.get_Exhibitor(
		viewProps.match?.params?.id ?? '',
		currentEvent?.id || ''
	);

	const [showBookAppointmentModal, setShowBookAppointmentModal] = useState(
		false
	);
	const [
		showSendExhibitorEmailModal,
		setShowSendExhibitorEmailModal
	] = useState(false);
	const [currentAttendee, setCurrentAttendee] = useState<AttendeeEntity>();

	useEffect(() => {
		let event = ctx.virtual.event.active();
		let attendee = ctx.virtual.attendee.active();
		setCurrentAttendee(attendee);
		ctx.virtual.contact.readById(
			ctx,
			{ ids: [viewProps.match?.params?.id ?? ''] },
			(entities: ContactEntities) => {
				//console.log(`${entities.length} Organizations Loaded`);
			}
		);

		if (event && attendee && currentExhibitor) {
			//Read all exhibitor scheduals and meetings
			ctx.virtual.scheduleContact.read(
				ctx,
				{
					contactId: currentExhibitor.id,
					role: ScheduleContactRoleType.Organizer
				},
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						let batchSize = 100;
						for (let i = 0; i < schedualIds.length; i += batchSize) {
							const batchSchedualIds = schedualIds.slice(i, i + batchSize);
							//read the meetings for the company
							if (batchSchedualIds.length > 0) {
								ctx.virtual.schedule.read(
									ctx,
									{
										ids: batchSchedualIds,
										type: ScheduleType.Meeting
									},
									(sEntities: ScheduleEntities) => {}
								);
							}
						}
					}
				}
			);

			ctx.virtual.scheduleContact.read(
				ctx,
				{ contactId: attendee.contactId },
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						let batchSize = 100;
						for (let i = 0; i < schedualIds.length; i += batchSize) {
							const batchSchedualIds = schedualIds.slice(i, i + batchSize);
							//read the meetings for the company
							if (batchSchedualIds.length > 0) {
								ctx.virtual.schedule.read(
									ctx,
									{
										ids: batchSchedualIds,
										type: ScheduleType.Meeting
									},
									(sEntities: ScheduleEntities) => {}
								);
							}
						}
					}
				}
			);

			ctx.virtual.attendee.AttendCompany(
				ctx,
				event,
				attendee,
				currentExhibitor
			);
		}

		setShowSendExhibitorEmailModal(false);
	}, []);

	if (!currentExhibitor) return <IonLabel>Exhibitor Not Found</IonLabel>;
	let logoObj = currentExhibitor.logo
		? JSON.parse(currentExhibitor.logo)
		: undefined;
	let logoPath = logoObj?.OriginalFilePath ?? logoObj?.originalFilePath;
	let bannerObj = currentExhibitor.banner
		? JSON.parse(currentExhibitor.banner)
		: undefined;
	let bannerPath = bannerObj?.OriginalFilePath ?? bannerObj?.originalFilePath;

	//Send Exhibitor an email
	const sendExhibitorEmail = function () {};

	//meeting information
	const hasMeetings: boolean =
		ctx.virtual.scheduleContact.all_ByOrganizerContact(currentExhibitor.id)
			.length > 0;

	const attendeeBookedMeeting: ScheduleEntity = ctx.virtual.schedule
		.all_ByAttendeeContactAndOrganizerContact(
			ctx,
			currentAttendee?.contactId || '',
			currentExhibitor?.id || '',
			[],
			[
				ScheduleContactStatusType.Accepted,
				ScheduleContactStatusType.Pending,
				ScheduleContactStatusType.Tentative
			]
		)
		.filter(y => y.start !== null && y.start !== undefined)[0];

	const atteneeScheduleContact:
		| ScheduleContactEntity
		| undefined = ctx.virtual.scheduleContact
		.all_ScheduleParticipants(
			[attendeeBookedMeeting?.id],
			[ScheduleContactRoleType.Attendee]
		)
		.find(x => x.contactId === currentAttendee?.contactId);

	//Get SessionDocuments - filter out banner and logo images
	let allDocuments: ResourceEntity[] = ctx.virtual.resource
		.all_By_Object(currentExhibitor.id)
		.filter(
			f =>
				f.name != logoObj?.Name &&
				f.name != bannerObj?.Name &&
				!f.path.toLowerCase().includes('.jpg') &&
				!f.path.toLowerCase().includes('.jpeg') &&
				!f.path.toLowerCase().includes('.png') &&
				!f.path.toLowerCase().includes('.bmp') &&
				!f.path.toLowerCase().includes('.gif')
		);

	const documentListItems = allDocuments.map(
		(
			document: ResourceEntity,
			index: number,
			documentList: ResourceEntity[]
		) => {
			return (
				<IonItem
					detail={false}
					key={'sessionDocument-' + document.id}
					button={true}
					onClick={() => {
						saveAs(
							'https://my.conexsys.com/Uploads/FileManager/' + document.path,
							document.name
						);
					}}
				>
					<IonIcon src={documentOutline} />
					<IonLabel class="ion-text-wrap">{document.name}</IonLabel>
					<IonIcon class="ion-float-right" src={download} />
				</IonItem>
			);
		}
	);

	return (
		<>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/exhibit-hall" />
				</IonButtons>
			</IonToolbar>
			<IonContent>
				<IonModal
					isOpen={showBookAppointmentModal}
					onDidDismiss={() => setShowBookAppointmentModal(false)}
				>
					<IonToolbar>
						<IonTitle>Meeting With</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => setShowBookAppointmentModal(false)}>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonContent>
						<AppointmentBooking
							exhibitorId={currentExhibitor.id}
							attendeeId={ctx.virtual.attendee.active()?.id}
						></AppointmentBooking>
					</IonContent>
				</IonModal>
				<IonModal
					isOpen={showSendExhibitorEmailModal}
					onDidDismiss={() => setShowSendExhibitorEmailModal(false)}
				>
					<IonToolbar>
						<IonTitle>Contact {currentExhibitor.name}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => setShowSendExhibitorEmailModal(false)}>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonContent>
						<SendEmail
							to={currentExhibitor.contactEmail}
							from={currentAttendee?.contact?.email}
							subject={
								'Information Request from ' + currentAttendee?.contact?.name
							}
							content={''}
						></SendEmail>
					</IonContent>
				</IonModal>
				<IonGrid>
					<IonRow>
						<IonCol size="12" sizeMd="12">
							<IonCard>
								<IonImg hidden={!bannerPath} src={bannerPath} />
								<IonCardHeader>
									<IonCardTitle>
										<IonGrid>
											<IonRow>
												<IonCol size="6" sizeMd="6">
													<img
														hidden={!logoPath}
														style={{ maxHeight: '100px' }}
														src={logoPath}
													/>
													<h1 hidden={logoPath}>{currentExhibitor?.name}</h1>
												</IonCol>
												<IonCol size="12" sizeMd="6">
													<IonLabel class="ion-float-md-right">
														<p
															hidden={
																!currentExhibitor.locations ||
																currentExhibitor.locations.length <= 0 ||
																(currentExhibitor.locations || '')
																	.toString()
																	.trim() === ''
															}
														>
															<b>Booth:</b>{' '}
															{(currentExhibitor.locations || '').toString()}
														</p>
														<p>
															{currentExhibitor?.address1}{' '}
															{currentExhibitor?.address2}
															<br />
															{currentExhibitor?.city}{' '}
															{currentExhibitor?.subdivision}{' '}
															{currentExhibitor?.postal}
														</p>
														<p
															hidden={
																!currentExhibitor.contactFirstName ||
																currentExhibitor.contactFirstName === '' ||
																!currentExhibitor.contactLastName ||
																currentExhibitor.contactLastName === ''
															}
														>
															{currentExhibitor?.contactFirstName}{' '}
															{currentExhibitor?.contactLastName}
														</p>
														<h2
															hidden={
																!currentExhibitor.contactEmail ||
																currentExhibitor.contactEmail === ''
															}
														>
															<a
																href={'mailto:' + currentExhibitor.contactEmail}
															>
																{currentExhibitor.contactEmail}
															</a>
														</h2>
														<h2
															hidden={
																!currentExhibitor.website ||
																currentExhibitor.website === ''
															}
														>
															<a
																href={currentExhibitor?.website}
																target="_blank"
															>
																{currentExhibitor?.website}
															</a>
														</h2>
													</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow
												hidden={(currentExhibitor?.videoUrl ?? '') === ''}
											>
												<IonCol>
													<Media
														class="exhibitorMediaCol"
														src={currentExhibitor?.videoUrl ?? ''}
														options={{ height: '20%' }}
													/>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<h2 hidden={(currentExhibitor?.description ?? '') === ''}>
										Description
									</h2>
									<p hidden={(currentExhibitor?.description ?? '') === ''}>
										<span
											dangerouslySetInnerHTML={{
												__html: currentExhibitor?.description ?? ''
											}}
										></span>
										<br />
									</p>
									<h2 hidden={(currentExhibitor?.keywords?.length || 0) <= 0}>
										Keywords
									</h2>
									<p hidden={(currentExhibitor?.keywords?.length || 0) <= 0}>
										{currentExhibitor?.keywords?.map((name, index) => {
											return (
												<IonBadge
													color="light"
													style={{ marginRight: '5px' }}
													key={'filterbadge-' + name}
												>
													{name.trim()}
												</IonBadge>
											);
										})}
									</p>
									<div hidden={!hasMeetings}>
										<br />
										<h2>Meetings</h2>
										<ScheduleMeetingButton
											schedule={attendeeBookedMeeting}
											scheduleContact={atteneeScheduleContact}
											hidden={!hasMeetings}
											onBtnClick={() => setShowBookAppointmentModal(true)}
										></ScheduleMeetingButton>
										<br />
										<br />
									</div>
									<IonList hidden={allDocuments && allDocuments.length <= 0}>
										<h2>Documents</h2>
										{documentListItems}
									</IonList>
									<br />
									<h2>Contact</h2>
									<IonList>
										<IonItem
											detail={false}
											hidden={
												!currentExhibitor?.contactEmail ||
												currentExhibitor?.contactEmail === ''
											}
											button={true}
											onClick={e => {
												setShowSendExhibitorEmailModal(true);
											}}
										>
											<IonLabel>Email </IonLabel>
											<IonIcon src={mail} />
										</IonItem>
										<IonItem
											detail={false}
											hidden={
												!currentExhibitor?.twitter ||
												currentExhibitor.twitter === ''
											}
											button={true}
											onClick={e => {
												window.open(currentExhibitor?.twitter, '_blank');
											}}
										>
											<IonLabel>Twitter</IonLabel>
											<IonIcon src={logoTwitter} />
										</IonItem>
										<IonItem
											detail={false}
											hidden={
												!currentExhibitor?.facebook ||
												currentExhibitor?.facebook === ''
											}
											button={true}
											onClick={e => {
												window.open(currentExhibitor?.facebook, '_blank');
											}}
										>
											<IonLabel>Facebook</IonLabel>
											<IonIcon src={logoFacebook} />
										</IonItem>
										<IonItem
											detail={false}
											hidden={
												!currentExhibitor?.linkedIn ||
												currentExhibitor?.linkedIn === ''
											}
											button={true}
											onClick={e => {
												window.open(currentExhibitor?.linkedIn, '_blank');
											}}
										>
											<IonLabel>LinkedIn</IonLabel>
											<IonIcon src={logoLinkedin} />
										</IonItem>
										<IonItem
											detail={false}
											hidden={
												!currentExhibitor?.website ||
												currentExhibitor?.website === ''
											}
											button={true}
											onClick={e => {
												window.open(currentExhibitor?.website, '_blank');
											}}
										>
											<IonLabel>Website</IonLabel>
											<IonIcon src={globe} />
										</IonItem>
									</IonList>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</>
	);
};

export default ExhibitorView;
