import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { registrationTypeConfig } from '../../../config/virtual/RegistrationType';
import { RegistrationType } from '../models';

/**
 * RegistrationType entity interface
 *
 * @export
 * @interface RegistrationTypeEntity
 * @extends {storage.Entity}
 */
export interface RegistrationTypeEntity
	extends storage.Entity,
		RegistrationType {}

/**
 * RegistrationType entities array
 *
 * @export
 */
export type RegistrationTypeEntities = RegistrationTypeEntity[];

/**
 * Some registrationType entities: one entity or an array of entities
 *
 * @export
 */
export type RegistrationTypeEntity_Some =
	| RegistrationTypeEntity
	| RegistrationTypeEntities;

/**
 * RegistrationType entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface RegistrationTypeEntityPatch
 * @extends {storage.EntityPatch<RegistrationTypeEntity>}
 */
export type RegistrationTypeEntityPatch = storage.EntityPatcher<
	RegistrationTypeEntity
>;

/**
 * RegistrationType entity patches array
 *
 * @export
 */
export type RegistrationTypeEntitiesPatch = RegistrationTypeEntityPatch[];

/**
 * Some registrationType entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type RegistrationTypeEntityPatch_Some =
	| RegistrationTypeEntityPatch
	| RegistrationTypeEntitiesPatch;

/**
 * RegistrationType entity id
 *
 * @export
 */
export type RegistrationTypeId = storage.EntityId;

/**
 * RegistrationType entity ids
 *
 * @export
 */
export type RegistrationTypeIds = storage.EntityIds;

/**
 * Some registrationType entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type RegistrationTypeId_Some = RegistrationTypeId | RegistrationTypeIds;

/**
 * RegistrationType entities object by id
 *
 * @export
 * @interface RegistrationTypeEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface RegistrationTypeEntitiesObject extends storage.EntitiesObject {
	[id: string]: RegistrationTypeEntity;
}

/**
 * RegistrationType entity state
 *
 * @export
 * @interface RegistrationTypeEntityState
 * @extends {storage.EntityState}
 */
export interface RegistrationTypeEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * RegistrationType store collection
 *
 * @export
 * @interface RegistrationTypeStoreCollection
 * @extends {storage.Store}
 */
export interface RegistrationTypeStoreCollection extends storage.Store {
	registrationType: RegistrationTypeCollection;
}

/**
 * RegistrationType collection properties
 *
 * @export
 * @interface RegistrationTypeCollection
 * @extends {Collection}
 */
export interface RegistrationTypeCollection extends storage.Collection {
	byIds: RegistrationTypeEntitiesObject;
	mutation: RegistrationTypeEntitiesObject;
	cache: RegistrationTypeEntitiesObject;
	state: RegistrationTypeCollectionState;
}

/**
 * RegistrationType collection state
 *
 * @export
 * @interface RegistrationTypeCollectionState
 * @extends {storage.CollectionState}
 */
export interface RegistrationTypeCollectionState
	extends storage.CollectionState {}

// custom registrationType collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const registrationTypeCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const registrationTypeCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const registrationTypeCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const registrationTypeCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: registrationTypeCollectionIdStringProps,
	idArrayProps: registrationTypeCollectionIdArrayProps,
	idEntityProps: registrationTypeCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * RegistrationType collection default values, initialize collection with registrationType entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const registrationTypeCollectionDefault: RegistrationTypeCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as RegistrationTypeCollection),
		...{
			activeId:
				registrationTypeConfig.activeId ||
				registrationTypeConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	registrationTypeConfig.entities
);

/**
 * RegistrationType store collection default values
 *
 * @export
 * @constant
 */
export const registrationTypeStoreCollectionDefault: RegistrationTypeStoreCollection = {
	registrationType: registrationTypeCollectionDefault
};

/**
 * RegistrationType store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getRegistrationTypeCollection = (state: any) =>
	state.virtual.registrationType;
