// tslint:disable
/**
 * CONEXSYS Virtual Schedule API
 *  # Overview CONEXSYS Virtual Schedule API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Schedule Status type
 * @export
 * @enum {string}
 */
export enum ScheduleStatusType {
	Available = 'available',
	Requested = 'requested',
	Pending = 'pending',
	Confirmed = 'confirmed',
	Declined = 'declined',
	Cancelled = 'cancelled'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesScheduleStatusType = [
	'available',
	'pending',
	'pending',
	'confirmed',
	'declined',
	'declined'
];
