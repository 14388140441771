import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { contactRegistrationTypeConfig } from '../../../config/virtual/ContactRegistrationType';
import { ContactRegistrationType } from '../models';

/**
 * ContactRegistrationType entity interface
 *
 * @export
 * @interface ContactRegistrationTypeEntity
 * @extends {storage.Entity}
 */
export interface ContactRegistrationTypeEntity
	extends storage.Entity,
		ContactRegistrationType {}

/**
 * ContactRegistrationType entities array
 *
 * @export
 */
export type ContactRegistrationTypeEntities = ContactRegistrationTypeEntity[];

/**
 * ContactRegistrationTypeExhibitor entity interface
 *
 * @export
 * @interface ContactRegistrationTypeExhibitorEntity
 * @extends {storage.Entity}
 */
export interface ContactRegistrationTypeExhibitorEntity
	extends storage.Entity,
		ContactRegistrationType {}

/**
 * ContactRegistrationTypeExhibitor entities array
 *
 * @export
 */
export type ContactRegistrationTypeExhibitorEntities = ContactRegistrationTypeExhibitorEntity[];

/**
 * ContactRegistrationTypeSponsor entity interface
 *
 * @export
 * @interface ContactRegistrationTypeSponsorEntity
 * @extends {storage.Entity}
 */
export interface ContactRegistrationTypeSponsorEntity
	extends storage.Entity,
		ContactRegistrationType {}

/**
 * ContactRegistrationTypeSponsor entities array
 *
 * @export
 */
export type ContactRegistrationTypeSponsorEntities = ContactRegistrationTypeSponsorEntity[];

/**
 * Some contactRegistrationType entities: one entity or an array of entities
 *
 * @export
 */
export type ContactRegistrationTypeEntity_Some =
	| ContactRegistrationTypeEntity
	| ContactRegistrationTypeEntities;

/**
 * ContactRegistrationType entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ContactRegistrationTypeEntityPatch
 * @extends {storage.EntityPatch<ContactRegistrationTypeEntity>}
 */
export type ContactRegistrationTypeEntityPatch = storage.EntityPatcher<
	ContactRegistrationTypeEntity
>;

/**
 * ContactRegistrationType entity patches array
 *
 * @export
 */
export type ContactRegistrationTypeEntitiesPatch = ContactRegistrationTypeEntityPatch[];

/**
 * Some contactRegistrationType entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ContactRegistrationTypeEntityPatch_Some =
	| ContactRegistrationTypeEntityPatch
	| ContactRegistrationTypeEntitiesPatch;

/**
 * ContactRegistrationType entity id
 *
 * @export
 */
export type ContactRegistrationTypeId = storage.EntityId;

/**
 * ContactRegistrationType entity ids
 *
 * @export
 */
export type ContactRegistrationTypeIds = storage.EntityIds;

/**
 * Some contactRegistrationType entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ContactRegistrationTypeId_Some =
	| ContactRegistrationTypeId
	| ContactRegistrationTypeIds;

/**
 * ContactRegistrationType entities object by id
 *
 * @export
 * @interface ContactRegistrationTypeEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ContactRegistrationTypeEntitiesObject
	extends storage.EntitiesObject {
	[id: string]: ContactRegistrationTypeEntity;
}

/**
 * ContactRegistrationType entity state
 *
 * @export
 * @interface ContactRegistrationTypeEntityState
 * @extends {storage.EntityState}
 */
export interface ContactRegistrationTypeEntityState
	extends storage.EntityState {}

/**
 * ContactRegistrationType store collection
 *
 * @export
 * @interface ContactRegistrationTypeStoreCollection
 * @extends {storage.Store}
 */
export interface ContactRegistrationTypeStoreCollection extends storage.Store {
	contactRegistrationType: ContactRegistrationTypeCollection;
}

/**
 * ContactRegistrationType collection properties
 *
 * @export
 * @interface ContactRegistrationTypeCollection
 * @extends {Collection}
 */
export interface ContactRegistrationTypeCollection extends storage.Collection {
	byIds: ContactRegistrationTypeEntitiesObject;
	mutation: ContactRegistrationTypeEntitiesObject;
	cache: ContactRegistrationTypeEntitiesObject;
	state: ContactRegistrationTypeCollectionState;
}

/**
 * ContactRegistrationType collection state
 *
 * @export
 * @interface ContactRegistrationTypeCollectionState
 * @extends {storage.CollectionState}
 */
export interface ContactRegistrationTypeCollectionState
	extends storage.CollectionState {}

// custom contactRegistrationType collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const contactRegistrationTypeCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const contactRegistrationTypeCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const contactRegistrationTypeCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const contactRegistrationTypeCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: contactRegistrationTypeCollectionIdStringProps,
	idArrayProps: contactRegistrationTypeCollectionIdArrayProps,
	idEntityProps: contactRegistrationTypeCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * ContactRegistrationType collection default values, initialize collection with contactRegistrationType entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const contactRegistrationTypeCollectionDefault: ContactRegistrationTypeCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ContactRegistrationTypeCollection),
		...{
			activeId:
				contactRegistrationTypeConfig.activeId ||
				contactRegistrationTypeConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	contactRegistrationTypeConfig.entities
);

/**
 * ContactRegistrationType store collection default values
 *
 * @export
 * @constant
 */
export const contactRegistrationTypeStoreCollectionDefault: ContactRegistrationTypeStoreCollection = {
	contactRegistrationType: contactRegistrationTypeCollectionDefault
};

/**
 * ContactRegistrationType store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getContactRegistrationTypeCollection = (state: any) =>
	state.virtual.contactRegistrationType;
