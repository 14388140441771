import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getAttendeeCollection,
	AttendeeId,
	AttendeeIds,
	AttendeeId_Some,
	AttendeeEntity,
	AttendeeEntities,
	AttendeeEntity_Some,
	AttendeeEntityPatch_Some,
	AttendeeCollection,
	AttendeeCollectionState,
	IAttendeeActions,
	attendeeActions,
	AttendeeActionTypes
} from '..';
import {
	AttendeeApiOperation,
	authenticateAttendee,
	AuthenticateAttendeeRequest
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactIndividualEntity,
	ContactOrganizationEntity,
	EventEntity,
	ProductEntity
} from '../collections';
import { isObjectStatusActive } from '../models';

/**
 * Attendee helper interface
 *
 * @export
 * @interface IAttendeeHelper
 * @extends {IEntityHelper}
 */
export interface IAttendeeHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Attendee helper options interface
 *
 * @export
 * @interface AttendeeHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface AttendeeHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const attendeeHelperOpts: AttendeeHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Attendee helper
 *
 * @export
 * @class AttendeeHelper
 * @extends {EntityHelper<AttendeeCollection, AttendeeActionTypes, AttendeeActions, AttendeeEntity, AttendeeEntities, AttendeeEntity_Some, AttendeeEntityPatch_Some, AttendeeId, AttendeeIds, AttendeeId_Some, AttendeeCollectionState, AttendeeHelperOpts>}
 * @implements {IAttendeeHelper}
 */
export class AttendeeHelper
	extends EntityHelper<
		AttendeeCollection,
		AttendeeActionTypes,
		IAttendeeActions,
		AttendeeEntity,
		AttendeeEntities,
		AttendeeEntity_Some,
		AttendeeEntityPatch_Some,
		AttendeeId,
		AttendeeIds,
		AttendeeId_Some,
		AttendeeCollectionState,
		AttendeeHelperOpts
	>
	implements IAttendeeHelper {
	constructor() {
		super(
			useSelector(getAttendeeCollection),
			attendeeActions,
			useDispatch(),
			attendeeHelperOpts
		);
		this.collection = useSelector(getAttendeeCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event answers
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: AttendeeApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async authenticate(
		ctx: UseCtx<any>,
		params: Partial<AuthenticateAttendeeRequest>,
		callback?: any
	): Promise<AttendeeEntity | undefined> {
		//if (!ctx.virtual.event.active()) return;
		let request: AuthenticateAttendeeRequest = {
			...params,
			...{
				eventId: params.eventId || '',
				username: params.username || '',
				accessCode: params.accessCode || ''
			}
		};
		if (
			request.eventId === '' ||
			request.username === '' ||
			request.accessCode === ''
		)
			return;

		let entity: AttendeeEntity | any = await authenticateAttendee(ctx, request)
			.then((entity: AttendeeEntity | undefined) => {
				if (entity && entity.id !== '') {
					let restrictUserAccessTags: string[] = JSON.parse(
						(ctx.virtual.event.active()?.properties as any)?.app?.virtual
							?.generalSettings?.security?.deny?.tags || '[]'
					);
					let restrictUserProducts: string[] =
						(ctx.virtual.event.active()?.properties as any)?.app?.virtual
							?.generalSettings?.security?.deny?.products || '[]';

					let contactTags: string[] = JSON.parse(
						(entity?.contact?.properties as any)?.myconexsys?.Tags || '[]'
					);

					//check restricted tags
					let restrictionMatchs = restrictUserAccessTags.filter(element =>
						contactTags.includes(element)
					);

					//check restricted products
					if (
						(!restrictUserProducts || restrictUserProducts.length <= 0) &&
						(!restrictionMatchs || restrictionMatchs.length <= 0)
					) {
						restrictionMatchs = restrictUserProducts.filter(element =>
							entity?.products?.find(e => e.productId === element)
						);
					}
					if (!restrictionMatchs || restrictionMatchs.length <= 0) {
						if (callback) callback(entity);
						return entity;
					} else {
						if (callback) callback(undefined);
						return [];
					}
				} else {
					if (callback) callback(undefined);
					return [];
				}
			})
			.catch(e => {
				if (callback) callback(undefined);
				return [];
			});

		return entity;
	}

	all_Active(): AttendeeEntities {
		return this.all().filter(isObjectStatusActive);
	}

	isVisitor(attendee: AttendeeEntity): boolean {
		return !(attendee.contact as ContactIndividualEntity)?.organizationId;
	}

	isCompanyRepresentative(attendee: AttendeeEntity): boolean {
		return (
			(attendee.contact as ContactIndividualEntity)?.organizationId !== null &&
			(attendee.contact as ContactIndividualEntity)?.organizationId !==
				undefined
		);
	}

	AttendEvent = async (
		ctx: UseCtx<any>,
		event: EventEntity,
		attendee: AttendeeEntity
	) => {
		if (
			(event?.properties as any)?.app?.virtual?.generalSettings
				?.attendanceTracking?.trackEventAttendance ??
			true === true
		) {
			console.log('AttendEvent');
			let eventId = event.i;
			let visitorId = attendee.i;
			let url =
				ctx.app.api.all()[0].url +
				'/attendee/attend?eventId=' +
				eventId +
				'&visitorId=' +
				visitorId +
				'&scannerTypeId=1&scanSourceId=2';
			await fetch(url);
		}
	};

	AttendProduct = async (
		ctx: UseCtx<any>,
		event: EventEntity,
		attendee: AttendeeEntity,
		product: ProductEntity
	) => {
		if (
			(event?.properties as any)?.app?.virtual?.generalSettings
				?.attendanceTracking?.trackProductAttendance ??
			true === true
		) {
			console.log('AttendProduct');
			let eventId = event.i;
			let visitorId = attendee.i;
			let productId = product.i;
			let url =
				ctx.app.api.all()[0].url +
				'/attendee/attend?eventId=' +
				eventId +
				'&visitorId=' +
				visitorId +
				'&productId=' +
				productId +
				'&scannerTypeId=1&scanSourceId=2';
			await fetch(url);
		}
	};

	AttendCompany = async (
		ctx: UseCtx<any>,
		event: EventEntity,
		attendee: AttendeeEntity,
		company: ContactOrganizationEntity
	) => {
		if (
			(event?.properties as any)?.app?.virtual?.generalSettings
				?.attendanceTracking?.trackCompanyBoothAttendance ??
			true === true
		) {
			console.log('AttendCompany');
			let eventId = event.i;
			let visitorId = attendee.i;
			let companyId = company.i;
			let url =
				ctx.app.api.all()[0].url +
				'/attendee/attend?eventId=' +
				eventId +
				'&visitorId=' +
				visitorId +
				'&companyId=' +
				companyId +
				'&scannerTypeId=1&scanSourceId=2';
			await fetch(url);
		}
	};
}
