import React from 'react';
import {
	IonLabel,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonBadge,
	IonIcon,
	IonButton
} from '@ionic/react';
import moment from 'moment';
import {
	CategoryEntity,
	ContactOrganizationEntity,
	ProductSessionEntity,
	WebinarEntity
} from '../../store';
import { playCircle } from 'ionicons/icons';

export interface EventScheduleListItemProps {
	session: ProductSessionEntity;
	sponsors?: ContactOrganizationEntity[];
	webinars?: WebinarEntity[];
	categories?: CategoryEntity[];
	isAttending?: boolean;
	allowAttendanceToAllSession?: boolean;
	hasAttened?: boolean;
	onWebinarLinkClick?: () => void;
	onWebinarWatchRecordingLinkClick?: (url?: string) => void;
}
export const EventScheduleListItem: React.FC<EventScheduleListItemProps> = props => {
	/*const sponsorListItems = props.sponsors?.map(
		(
			sponsor: ContactOrganizationEntity,
			index: number,
			sponsors: ContactOrganizationEntity[]
		) => {
			let logoObj = sponsor.logo ? JSON.parse(sponsor.logo) : undefined;
			let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
			return !logoPath || logoPath === '' ? (
				<IonLabel>{sponsor.name}</IonLabel>
			) : (
				<img style={{ height: '40px' }} src={logoPath} />
			);
		}
	);*/

	const hasRecording = props.webinars?.some((webinar: WebinarEntity) => {
		if (webinar && (props.isAttending || props.allowAttendanceToAllSession)) {
			let displayRecording: boolean = ((webinar.recordingStart &&
			webinar.recordingEnd
				? moment().isBetween(
						moment(webinar.recordingStart),
						moment(webinar.recordingEnd)
				  )
				: props.session.end && moment().isAfter(moment(props.session.end))) &&
				webinar?.recordingUrl &&
				webinar?.recordingUrl !== '') as boolean;

			return displayRecording;
		} else {
			return false;
		}
	});
	const webinarButtons = props.webinars?.map((webinar: WebinarEntity) => {
		if (webinar && (props.isAttending || props.allowAttendanceToAllSession)) {
			let displayLiveLink: boolean = ((webinar.start && webinar.end
				? moment().isBetween(moment(webinar.start), moment(webinar.end))
				: props.session.start &&
				  moment().isBetween(
						moment(props.session.start).subtract(10, 'minutes'),
						moment(props.session.start)
				  )) &&
				webinar?.url &&
				webinar?.url !== '') as boolean;

			let displayRecording: boolean = ((webinar.recordingStart &&
			webinar.recordingEnd
				? moment().isBetween(
						moment(webinar.recordingStart),
						moment(webinar.recordingEnd)
				  )
				: props.session.end && moment().isAfter(moment(props.session.end))) &&
				webinar?.recordingUrl &&
				webinar?.recordingUrl !== '') as boolean;

			return (
				<>
					<IonButton
						key={'listbtn_' + webinar.id}
						hidden={!displayLiveLink}
						color="success"
						onClick={() => {
							if (props.onWebinarLinkClick !== undefined) {
								props.onWebinarLinkClick();
							}
							window.open(webinar?.url, '_blank');
						}}
					>
						{' '}
						Join Now{' '}
					</IonButton>
					<IonButton
						hidden={!displayRecording}
						color="primary"
						onClick={() => {
							if (props.onWebinarWatchRecordingLinkClick !== undefined) {
								props.onWebinarWatchRecordingLinkClick(webinar?.recordingUrl);
							}
							//window.open(webinar?.recordingUrl, '_blank');
						}}
					>
						<IonIcon src={playCircle} />
						Watch Recording{' '}
					</IonButton>
				</>
			);
		}
	});

	let startingMessage: string = '';
	if (moment(props.session.end).isBefore(moment())) {
		startingMessage = 'Ended ' + moment().to(moment(props.session.end));
	} else if (
		moment(props.session.start).isBefore(moment().clone().add(1, 'second'))
	) {
		startingMessage = 'Started ' + moment().to(moment(props.session.start));
	} else {
		startingMessage = 'Begins ' + moment().to(moment(props.session.start));
	}

	return (
		<IonItem
			detail={true}
			key={props.session?.id}
			routerLink={'/session/' + props.session?.id}
			button
		>
			<IonGrid>
				<IonRow>
					<IonCol size="12" sizeMd="12">
						<IonLabel class="item-text-wrap">
							<h2
								style={{
									fontWeight: 500,
									fontSize: '17px'
								}}
							>
								{props.session?.name}
							</h2>
							<p>
								{props.session?.start
									? moment(props.session?.start).format('LT')
									: ''}
								{props.session?.end
									? ' - ' + moment(props.session?.end).format('LT')
									: ''}
							</p>
							{props.session.credits}
							<p
								hidden={
									!(props.session?.properties as any).myconexsys?.CourseType
								}
								dangerouslySetInnerHTML={{
									__html:
										((props.session?.properties as any).myconexsys
											?.CreditHours ?? '') +
										' ' +
										((props.session?.properties as any).myconexsys
											?.CourseType ?? '')
								}}
							></p>
						</IonLabel>

						<IonLabel>
							{webinarButtons}
							<p>
								<i>{startingMessage}</i>
							</p>
						</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={!props.isAttending || props.hasAttened}
							color="secondary"
						>
							Registered
						</IonBadge>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={
								!props.session?.end ||
								!moment().isAfter(moment(props.session?.end)) ||
								hasRecording
							}
							color="danger"
						>
							Concluded
						</IonBadge>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={
								!props.session?.end ||
								!moment().isAfter(moment(props.session?.end)) ||
								!hasRecording
							}
							color="primary"
						>
							On Demand
						</IonBadge>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={!props.hasAttened}
							color="primary"
						>
							Atteneded
						</IonBadge>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={
								!props.session?.start ||
								!moment().isBetween(
									moment(props.session?.start).subtract(1, 'hour'),
									moment(props.session?.start)
								)
							}
							color="warning"
						>
							Starting Soon
						</IonBadge>
						<IonBadge
							style={{
								marginRight: '3px'
							}}
							hidden={
								!props.session?.start ||
								!moment().isBetween(
									moment(props.session?.start),
									moment(props.session?.end)
								)
							}
							color="success"
						>
							In Progress
						</IonBadge>

						{props.categories?.map(
							(
								category: CategoryEntity,
								index: number,
								categoryList: CategoryEntity[]
							) => {
								return (
									<IonBadge
										style={{
											marginRight: '3px'
										}}
										color="medium"
									>
										{category.name}
									</IonBadge>
								);
							}
						)}
					</IonCol>
				</IonRow>
				<IonRow hidden={!props.sponsors || props.sponsors?.length <= 0}>
					<IonCol>
						<p
							style={{
								fontSize: '11px',
								color: '#666666',
								margin: '0px'
							}}
						>
							Sponsor By:
						</p>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
