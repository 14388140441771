import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getProductCategoryCollection,
	ProductCategoryId,
	ProductCategoryIds,
	ProductCategoryId_Some,
	ProductCategoryEntity,
	ProductCategoryEntities,
	ProductCategoryEntity_Some,
	ProductCategoryEntityPatch_Some,
	ProductCategoryCollection,
	ProductCategoryCollectionState,
	IProductCategoryActions,
	productCategoryActions,
	ProductCategoryActionTypes
} from '..';
import {
	readProductCategories,
	ReadProductCategoriesRequest,
	ProductCategoryApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as productCategory from '../productCategories/ProductCategory';

/**
 * ProductCategory helper interface
 *
 * @export
 * @interface IProductCategoryHelper
 * @extends {IEntityHelper}
 */
export interface IProductCategoryHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * ProductCategory helper options interface
 *
 * @export
 * @interface ProductCategoryHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ProductCategoryHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const productCategoryHelperOpts: ProductCategoryHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * ProductCategory helper
 *
 * @export
 * @class ProductCategoryHelper
 * @extends {EntityHelper<ProductCategoryCollection, ProductCategoryActionTypes, ProductCategoryActions, ProductCategoryEntity, ProductCategoryEntities, ProductCategoryEntity_Some, ProductCategoryEntityPatch_Some, ProductCategoryId, ProductCategoryIds, ProductCategoryId_Some, ProductCategoryCollectionState, ProductCategoryHelperOpts>}
 * @implements {IProductCategoryHelper}
 */
export class ProductCategoryHelper
	extends EntityHelper<
		ProductCategoryCollection,
		ProductCategoryActionTypes,
		IProductCategoryActions,
		ProductCategoryEntity,
		ProductCategoryEntities,
		ProductCategoryEntity_Some,
		ProductCategoryEntityPatch_Some,
		ProductCategoryId,
		ProductCategoryIds,
		ProductCategoryId_Some,
		ProductCategoryCollectionState,
		ProductCategoryHelperOpts
	>
	implements IProductCategoryHelper {
	constructor() {
		super(
			useSelector(getProductCategoryCollection),
			productCategoryActions,
			useDispatch(),
			productCategoryHelperOpts
		);
		this.collection = useSelector(getProductCategoryCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ProductCategoryApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadProductCategoriesRequest> = {},
		callback?: any
	): Promise<ProductCategoryEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ProductCategoryApiOperation.readProductCategories);
		let request: ReadProductCategoriesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ProductCategoryEntities = await readProductCategories(
			ctx,
			request
		)
			.then((entities: ProductCategoryEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): ProductCategoryEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_By_ProductId(productId: string): ProductCategoryEntity[] {
		return this.all_Active().filter(pw => pw.productId === productId);
	}
}
