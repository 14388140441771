import { ScheduleContactEntities } from '../../virtual/store/collections/ScheduleContact';

export interface ScheduleContactConfig {
	entities: ScheduleContactEntities;
	activeId?: string;
}

export const scheduleContactConfig: ScheduleContactConfig = {
	entities: []
};
