import {
	ProductCategoryId_Some,
	ProductCategoryEntity_Some,
	ProductCategoryEntityPatch_Some,
	ProductCategoryCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const PRODUCTCATEGORY_MUTATE_ID = '@app/PRODUCTCATEGORY_MUTATE_ID';
export const PRODUCTCATEGORY_SET = '@app/PRODUCTCATEGORY_SET';
export const PRODUCTCATEGORY_TOGGLE = '@app/PRODUCTCATEGORY_TOGGLE';
export const PRODUCTCATEGORY_SELECT = '@app/PRODUCTCATEGORY_SELECT';
export const PRODUCTCATEGORY_DESELECT = '@app/PRODUCTCATEGORY_DESELECT';
export const PRODUCTCATEGORY_UPSERT = '@app/PRODUCTCATEGORY_UPSERT';
export const PRODUCTCATEGORY_PATCH = '@app/PRODUCTCATEGORY_PATCH';
export const PRODUCTCATEGORY_CHANGE = '@app/PRODUCTCATEGORY_CHANGE';
export const PRODUCTCATEGORY_APPLY_CHANGES = '@app/PRODUCTCATEGORY_APPLY_CHANGES';
export const PRODUCTCATEGORY_CANCEL_CHANGES =
	'@app/PRODUCTCATEGORY_CANCEL_CHANGES';
export const PRODUCTCATEGORY_DELETE = '@app/PRODUCTCATEGORY_DELETE';
export const PRODUCTCATEGORY_UNDELETE = '@app/PRODUCTCATEGORY_UNDELETE';
export const PRODUCTCATEGORY_SETSTATE = '@app/PRODUCTCATEGORY_SETSTATE';
export const PRODUCTCATEGORY_PATCHSTATE = '@app/PRODUCTCATEGORY_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ProductCategoryAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ProductCategoryAction_MutateId extends EntityAction_MutateId {
	type: typeof PRODUCTCATEGORY_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active productCategory
 *
 * @interface ProductCategoryAction_Set
 * @extends {EntityAction_Set}
 */
export interface ProductCategoryAction_Set extends EntityAction_Set {
	type: typeof PRODUCTCATEGORY_SET;
	payload?: EntityId;
}

/**
 * Toggle active productCategory
 *
 * @interface ProductCategoryAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ProductCategoryAction_Toggle extends EntityAction_Toggle {
	type: typeof PRODUCTCATEGORY_TOGGLE;
	payload?: EntityId;
}

/**
 * Select productCategory entities
 *
 * @interface ProductCategoryAction_Select
 * @extends {EntityAction_Select}
 */
interface ProductCategoryAction_Select extends EntityAction_Select {
	type: typeof PRODUCTCATEGORY_SELECT;
	payload: ProductCategoryId_Some;
}

/**
 * Deselect productCategory entities
 *
 * @interface ProductCategoryAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ProductCategoryAction_Deselect extends EntityAction_Deselect {
	type: typeof PRODUCTCATEGORY_DESELECT;
	payload: ProductCategoryId_Some;
}

/**
 * Upsert productCategory entities
 *
 * @interface ProductCategoryAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ProductCategoryAction_Upsert extends EntityAction_Upsert {
	type: typeof PRODUCTCATEGORY_UPSERT;
	payload: ProductCategoryEntity_Some;
}

/**
 * Patch productCategory entities
 *
 * @interface ProductCategoryAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ProductCategoryAction_Patch extends EntityAction_Patch {
	type: typeof PRODUCTCATEGORY_PATCH;
	payload: ProductCategoryEntityPatch_Some;
}

/**
 * Change productCategory entities
 *
 * @interface ProductCategoryAction_Change
 * @extends {EntityAction_Change}
 */
interface ProductCategoryAction_Change extends EntityAction_Change {
	type: typeof PRODUCTCATEGORY_CHANGE;
	payload: ProductCategoryEntityPatch_Some;
}

/**
 * Apply changes to productCategory entities
 *
 * @interface ProductCategoryAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ProductCategoryAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof PRODUCTCATEGORY_APPLY_CHANGES;
	payload: ProductCategoryId_Some;
}

/**
 * Cancel changes to productCategory entities
 *
 * @interface ProductCategoryAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ProductCategoryAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof PRODUCTCATEGORY_CANCEL_CHANGES;
	payload: ProductCategoryId_Some;
}

/**
 * Delete productCategory entities
 *
 * @interface ProductCategoryAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ProductCategoryAction_Delete extends EntityAction_Delete {
	type: typeof PRODUCTCATEGORY_DELETE;
	payload: ProductCategoryId_Some;
}

/**
 * Undelete productCategory entities
 *
 * @interface ProductCategoryAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ProductCategoryAction_Undelete extends EntityAction_Undelete {
	type: typeof PRODUCTCATEGORY_UNDELETE;
	payload: ProductCategoryId_Some;
}

/**
 * Set productCategory collection / entity state
 *
 * @interface ProductCategoryAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ProductCategoryAction_SetState extends EntityAction_SetState {
	type: typeof PRODUCTCATEGORY_SETSTATE;
}

/**
 * PAtch productCategory collection / entity state
 *
 * @interface ProductCategoryAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ProductCategoryAction_PatchState extends EntityAction_PatchState {
	type: typeof PRODUCTCATEGORY_PATCHSTATE;
}

/**
 * Export productCategory action types
 *
 * @export
 */
export type ProductCategoryActionTypes =
	| ProductCategoryAction_MutateId
	| ProductCategoryAction_MutateId
	| ProductCategoryAction_Set
	| ProductCategoryAction_Toggle
	| ProductCategoryAction_Select
	| ProductCategoryAction_Deselect
	| ProductCategoryAction_Upsert
	| ProductCategoryAction_Patch
	| ProductCategoryAction_Change
	| ProductCategoryAction_ApplyChanges
	| ProductCategoryAction_CancelChanges
	| ProductCategoryAction_Delete
	| ProductCategoryAction_Undelete
	| ProductCategoryAction_SetState
	| ProductCategoryAction_PatchState;

/**
 * ProductCategory actions helper interface
 *
 * @export
 * @interface IProductCategoryActions
 * @extends {EntityActions}
 */
export interface IProductCategoryActions extends EntityActions {
	//customAction(ids: ProductCategoryId_Some): ProductCategoryAction_CustomAction;
}

/**
 * ProductCategory actions helper
 *
 * @export
 * @class ProductCategoryActions
 * @implements {EntityBaseActions}
 */
export class ProductCategoryActions implements IProductCategoryActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ProductCategoryAction_MutateId {
		return { type: PRODUCTCATEGORY_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ProductCategoryAction_Set {
		return { type: PRODUCTCATEGORY_SET, payload: id };
	}
	toggle(id?: EntityId): ProductCategoryAction_Toggle {
		return { type: PRODUCTCATEGORY_TOGGLE, payload: id };
	}
	select(ids: ProductCategoryId_Some): ProductCategoryAction_Select {
		return {
			type: PRODUCTCATEGORY_SELECT,
			payload: ids
		};
	}
	deselect(ids: ProductCategoryId_Some): ProductCategoryAction_Deselect {
		return {
			type: PRODUCTCATEGORY_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ProductCategoryEntity_Some): ProductCategoryAction_Upsert {
		return {
			type: PRODUCTCATEGORY_UPSERT,
			payload: entities
		};
	}
	patch(entities: ProductCategoryEntityPatch_Some): ProductCategoryAction_Patch {
		return {
			type: PRODUCTCATEGORY_PATCH,
			payload: entities
		};
	}
	change(
		entities: ProductCategoryEntityPatch_Some
	): ProductCategoryAction_Change {
		return {
			type: PRODUCTCATEGORY_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ProductCategoryId_Some): ProductCategoryAction_ApplyChanges {
		return {
			type: PRODUCTCATEGORY_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: ProductCategoryId_Some
	): ProductCategoryAction_CancelChanges {
		return {
			type: PRODUCTCATEGORY_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ProductCategoryId_Some): ProductCategoryAction_Delete {
		return {
			type: PRODUCTCATEGORY_DELETE,
			payload: ids
		};
	}
	undelete(ids: ProductCategoryId_Some): ProductCategoryAction_Undelete {
		return {
			type: PRODUCTCATEGORY_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: ProductCategoryCollectionState
	): ProductCategoryAction_SetState {
		return {
			type: PRODUCTCATEGORY_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ProductCategoryCollectionState>
	): ProductCategoryAction_PatchState {
		return {
			type: PRODUCTCATEGORY_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the productCategory actions helper
 *
 * @export
 */
export const productCategoryActions = new ProductCategoryActions();
