import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { productContactConfig } from '../../../config/virtual/ProductContact';
import { ProductContact } from '../models';

/**
 * ProductContact entity interface
 *
 * @export
 * @interface ProductContactEntity
 * @extends {storage.Entity}
 */
export interface ProductContactEntity extends storage.Entity, ProductContact {}

/**
 * ProductContact entities array
 *
 * @export
 */
export type ProductContactEntities = ProductContactEntity[];

/**
 * Some productContact entities: one entity or an array of entities
 *
 * @export
 */
export type ProductContactEntity_Some =
	| ProductContactEntity
	| ProductContactEntities;

/**
 * ProductContact entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ProductContactEntityPatch
 * @extends {storage.EntityPatch<ProductContactEntity>}
 */
export type ProductContactEntityPatch = storage.EntityPatcher<
	ProductContactEntity
>;

/**
 * ProductContact entity patches array
 *
 * @export
 */
export type ProductContactEntitiesPatch = ProductContactEntityPatch[];

/**
 * Some productContact entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ProductContactEntityPatch_Some =
	| ProductContactEntityPatch
	| ProductContactEntitiesPatch;

/**
 * ProductContact entity id
 *
 * @export
 */
export type ProductContactId = storage.EntityId;

/**
 * ProductContact entity ids
 *
 * @export
 */
export type ProductContactIds = storage.EntityIds;

/**
 * Some productContact entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ProductContactId_Some = ProductContactId | ProductContactIds;

/**
 * ProductContact entities object by id
 *
 * @export
 * @interface ProductContactEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ProductContactEntitiesObject extends storage.EntitiesObject {
	[id: string]: ProductContactEntity;
}

/**
 * ProductContact entity state
 *
 * @export
 * @interface ProductContactEntityState
 * @extends {storage.EntityState}
 */
export interface ProductContactEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * ProductContact store collection
 *
 * @export
 * @interface ProductContactStoreCollection
 * @extends {storage.Store}
 */
export interface ProductContactStoreCollection extends storage.Store {
	productContact: ProductContactCollection;
}

/**
 * ProductContact collection properties
 *
 * @export
 * @interface ProductContactCollection
 * @extends {Collection}
 */
export interface ProductContactCollection extends storage.Collection {
	byIds: ProductContactEntitiesObject;
	mutation: ProductContactEntitiesObject;
	cache: ProductContactEntitiesObject;
	state: ProductContactCollectionState;
}

/**
 * ProductContact collection state
 *
 * @export
 * @interface ProductContactCollectionState
 * @extends {storage.CollectionState}
 */
export interface ProductContactCollectionState
	extends storage.CollectionState {}

// custom productContact collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const productContactCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const productContactCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const productContactCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const productContactCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: productContactCollectionIdStringProps,
	idArrayProps: productContactCollectionIdArrayProps,
	idEntityProps: productContactCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * ProductContact collection default values, initialize collection with productContact entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const productContactCollectionDefault: ProductContactCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ProductContactCollection),
		...{
			activeId:
				productContactConfig.activeId || productContactConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	productContactConfig.entities
);

/**
 * ProductContact store collection default values
 *
 * @export
 * @constant
 */
export const productContactStoreCollectionDefault: ProductContactStoreCollection = {
	productContact: productContactCollectionDefault
};

/**
 * ProductContact store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getProductContactCollection = (state: any) =>
	state.virtual.productContact;
