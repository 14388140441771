import React from 'react';
import {
	IonSlides,
	IonSlide,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonList
} from '@ionic/react';
import { ContactOrganizationEntity } from '../../../virtual/store';

//STYLE
import './SponsorCarousel.css';

export interface SponsorCarouselProps {
	exhibitors: ContactOrganizationEntity[];
	slideSize?: number;
	autoplayDelay?: number;
}

export const SponsorCarousel: React.FC<SponsorCarouselProps> = props => {
	const sortSponsors = (
		a: ContactOrganizationEntity,
		b: ContactOrganizationEntity
	) => {
		return (a.name || '') > (b.name || '')
			? 1
			: (b.name || '') > (a.name || '')
			? -1
			: 0;
	};

	const slideSize: number = Math.round(props.slideSize || 3);
	const groupedExhibitors = props.exhibitors
		.sort(sortSponsors)
		.map((e, i) => {
			return i % slideSize === 0
				? props.exhibitors.sort(sortSponsors).slice(i, i + slideSize)
				: null;
		})
		.filter(e => {
			return e;
		});

	const featuredListItems = groupedExhibitors.map(
		(exhibitors: ContactOrganizationEntity[] | null, index: number) => {
			if (exhibitors === null) return <></>;

			let listItems = exhibitors.map(
				(exhibitor: ContactOrganizationEntity, index: number) => {
					let logoObj = exhibitor.logo ? JSON.parse(exhibitor.logo) : undefined;
					let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
					return (
						<IonItem
							class="sponsor-slide-list-item"
							key={'sessionSponsor-' + exhibitor.id}
							button={true}
							routerLink={'/exhibitor/' + exhibitor.id}
						>
							<IonGrid>
								<IonRow>
									<IonCol size="12">
										<IonLabel>
											<h3>{exhibitor.name}</h3>
										</IonLabel>
										<div className="ion-justify-content-center">
											<img style={{ height: '70px' }} src={logoPath} />
										</div>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					);
				}
			);

			return (
				<IonSlide class="sponsor-slide" key={'sponsorslide' + index}>
					<IonList class="sponsor-slide-list" key={'sponsorsList' + index}>
						{listItems}
					</IonList>
				</IonSlide>
			);
		}
	);

	const slideOpts = {
		speed: 400,
		loop: true,
		autoplay:
			props.exhibitors.length <= (props.slideSize || 3)
				? false
				: {
						delay: props.autoplayDelay || 5000
				  }
	};

	return (
		<IonSlides class="sponsor-slides" pager={true} options={slideOpts}>
			{featuredListItems}
		</IonSlides>
	);
};
