import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { scheduleContactConfig } from '../../../config/virtual/ScheduleContact';
import { ScheduleContact } from '../models';

/**
 * ScheduleContact entity interface
 *
 * @export
 * @interface ScheduleContactEntity
 * @extends {storage.Entity}
 */
export interface ScheduleContactEntity
	extends storage.Entity,
		ScheduleContact {}

/**
 * ScheduleContact entities array
 *
 * @export
 */
export type ScheduleContactEntities = ScheduleContactEntity[];

/**
 * Some scheduleContact entities: one entity or an array of entities
 *
 * @export
 */
export type ScheduleContactEntity_Some =
	| ScheduleContactEntity
	| ScheduleContactEntities;

/**
 * ScheduleContact entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ScheduleContactEntityPatch
 * @extends {storage.EntityPatch<ScheduleContactEntity>}
 */
export type ScheduleContactEntityPatch = storage.EntityPatcher<
	ScheduleContactEntity
>;

/**
 * ScheduleContact entity patches array
 *
 * @export
 */
export type ScheduleContactEntitiesPatch = ScheduleContactEntityPatch[];

/**
 * Some scheduleContact entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ScheduleContactEntityPatch_Some =
	| ScheduleContactEntityPatch
	| ScheduleContactEntitiesPatch;

/**
 * ScheduleContact entity id
 *
 * @export
 */
export type ScheduleContactId = storage.EntityId;

/**
 * ScheduleContact entity ids
 *
 * @export
 */
export type ScheduleContactIds = storage.EntityIds;

/**
 * Some scheduleContact entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ScheduleContactId_Some = ScheduleContactId | ScheduleContactIds;

/**
 * ScheduleContact entities object by id
 *
 * @export
 * @interface ScheduleContactEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ScheduleContactEntitiesObject extends storage.EntitiesObject {
	[id: string]: ScheduleContactEntity;
}

/**
 * ScheduleContact entity state
 *
 * @export
 * @interface ScheduleContactEntityState
 * @extends {storage.EntityState}
 */
export interface ScheduleContactEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * ScheduleContact store collection
 *
 * @export
 * @interface ScheduleContactStoreCollection
 * @extends {storage.Store}
 */
export interface ScheduleContactStoreCollection extends storage.Store {
	scheduleContact: ScheduleContactCollection;
}

/**
 * ScheduleContact collection properties
 *
 * @export
 * @interface ScheduleContactCollection
 * @extends {Collection}
 */
export interface ScheduleContactCollection extends storage.Collection {
	byIds: ScheduleContactEntitiesObject;
	mutation: ScheduleContactEntitiesObject;
	cache: ScheduleContactEntitiesObject;
	state: ScheduleContactCollectionState;
}

/**
 * ScheduleContact collection state
 *
 * @export
 * @interface ScheduleContactCollectionState
 * @extends {storage.CollectionState}
 */
export interface ScheduleContactCollectionState
	extends storage.CollectionState {}

// custom scheduleContact collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const scheduleContactCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const scheduleContactCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const scheduleContactCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const scheduleContactCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: scheduleContactCollectionIdStringProps,
	idArrayProps: scheduleContactCollectionIdArrayProps,
	idEntityProps: scheduleContactCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * ScheduleContact collection default values, initialize collection with scheduleContact entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const scheduleContactCollectionDefault: ScheduleContactCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ScheduleContactCollection),
		...{
			activeId:
				scheduleContactConfig.activeId || scheduleContactConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	scheduleContactConfig.entities
);

/**
 * ScheduleContact store collection default values
 *
 * @export
 * @constant
 */
export const scheduleContactStoreCollectionDefault: ScheduleContactStoreCollection = {
	scheduleContact: scheduleContactCollectionDefault
};

/**
 * ScheduleContact store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getScheduleContactCollection = (state: any) =>
	state.virtual.scheduleContact;
