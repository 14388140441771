import {
	EventId_Some,
	EventEntity_Some,
	EventEntityPatch_Some,
	EventCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const EVENT_MUTATE_ID = '@app/EVENT_MUTATE_ID';
export const EVENT_SET = '@app/EVENT_SET';
export const EVENT_TOGGLE = '@app/EVENT_TOGGLE';
export const EVENT_SELECT = '@app/EVENT_SELECT';
export const EVENT_DESELECT = '@app/EVENT_DESELECT';
export const EVENT_UPSERT = '@app/EVENT_UPSERT';
export const EVENT_PATCH = '@app/EVENT_PATCH';
export const EVENT_CHANGE = '@app/EVENT_CHANGE';
export const EVENT_APPLY_CHANGES = '@app/EVENT_APPLY_CHANGES';
export const EVENT_CANCEL_CHANGES = '@app/EVENT_CANCEL_CHANGES';
export const EVENT_DELETE = '@app/EVENT_DELETE';
export const EVENT_UNDELETE = '@app/EVENT_UNDELETE';
export const EVENT_SETSTATE = '@app/EVENT_SETSTATE';
export const EVENT_PATCHSTATE = '@app/EVENT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface EventAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface EventAction_MutateId extends EntityAction_MutateId {
	type: typeof EVENT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active event
 *
 * @interface EventAction_Set
 * @extends {EntityAction_Set}
 */
export interface EventAction_Set extends EntityAction_Set {
	type: typeof EVENT_SET;
	payload?: EntityId;
}

/**
 * Toggle active event
 *
 * @interface EventAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface EventAction_Toggle extends EntityAction_Toggle {
	type: typeof EVENT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select event entities
 *
 * @interface EventAction_Select
 * @extends {EntityAction_Select}
 */
interface EventAction_Select extends EntityAction_Select {
	type: typeof EVENT_SELECT;
	payload: EventId_Some;
}

/**
 * Deselect event entities
 *
 * @interface EventAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface EventAction_Deselect extends EntityAction_Deselect {
	type: typeof EVENT_DESELECT;
	payload: EventId_Some;
}

/**
 * Upsert event entities
 *
 * @interface EventAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface EventAction_Upsert extends EntityAction_Upsert {
	type: typeof EVENT_UPSERT;
	payload: EventEntity_Some;
}

/**
 * Patch event entities
 *
 * @interface EventAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface EventAction_Patch extends EntityAction_Patch {
	type: typeof EVENT_PATCH;
	payload: EventEntityPatch_Some;
}

/**
 * Change event entities
 *
 * @interface EventAction_Change
 * @extends {EntityAction_Change}
 */
interface EventAction_Change extends EntityAction_Change {
	type: typeof EVENT_CHANGE;
	payload: EventEntityPatch_Some;
}

/**
 * Apply changes to event entities
 *
 * @interface EventAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface EventAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof EVENT_APPLY_CHANGES;
	payload: EventId_Some;
}

/**
 * Cancel changes to event entities
 *
 * @interface EventAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface EventAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof EVENT_CANCEL_CHANGES;
	payload: EventId_Some;
}

/**
 * Delete event entities
 *
 * @interface EventAction_Delete
 * @extends {EntityAction_Delete}
 */
interface EventAction_Delete extends EntityAction_Delete {
	type: typeof EVENT_DELETE;
	payload: EventId_Some;
}

/**
 * Undelete event entities
 *
 * @interface EventAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface EventAction_Undelete extends EntityAction_Undelete {
	type: typeof EVENT_UNDELETE;
	payload: EventId_Some;
}

/**
 * Set event collection / entity state
 *
 * @interface EventAction_SetState
 * @extends {EntityAction_SetState}
 */
interface EventAction_SetState extends EntityAction_SetState {
	type: typeof EVENT_SETSTATE;
}

/**
 * PAtch event collection / entity state
 *
 * @interface EventAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface EventAction_PatchState extends EntityAction_PatchState {
	type: typeof EVENT_PATCHSTATE;
}

/**
 * Export event action types
 *
 * @export
 */
export type EventActionTypes =
	| EventAction_MutateId
	| EventAction_MutateId
	| EventAction_Set
	| EventAction_Toggle
	| EventAction_Select
	| EventAction_Deselect
	| EventAction_Upsert
	| EventAction_Patch
	| EventAction_Change
	| EventAction_ApplyChanges
	| EventAction_CancelChanges
	| EventAction_Delete
	| EventAction_Undelete
	| EventAction_SetState
	| EventAction_PatchState;

/**
 * Event actions helper interface
 *
 * @export
 * @interface IEventActions
 * @extends {EntityActions}
 */
export interface IEventActions extends EntityActions {
	//customAction(ids: EventId_Some): EventAction_CustomAction;
}

/**
 * Event actions helper
 *
 * @export
 * @class EventActions
 * @implements {EntityBaseActions}
 */
export class EventActions implements IEventActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): EventAction_MutateId {
		return { type: EVENT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): EventAction_Set {
		return { type: EVENT_SET, payload: id };
	}
	toggle(id?: EntityId): EventAction_Toggle {
		return { type: EVENT_TOGGLE, payload: id };
	}
	select(ids: EventId_Some): EventAction_Select {
		return {
			type: EVENT_SELECT,
			payload: ids
		};
	}
	deselect(ids: EventId_Some): EventAction_Deselect {
		return {
			type: EVENT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: EventEntity_Some): EventAction_Upsert {
		return {
			type: EVENT_UPSERT,
			payload: entities
		};
	}
	patch(entities: EventEntityPatch_Some): EventAction_Patch {
		return {
			type: EVENT_PATCH,
			payload: entities
		};
	}
	change(entities: EventEntityPatch_Some): EventAction_Change {
		return {
			type: EVENT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: EventId_Some): EventAction_ApplyChanges {
		return {
			type: EVENT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: EventId_Some): EventAction_CancelChanges {
		return {
			type: EVENT_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: EventId_Some): EventAction_Delete {
		return {
			type: EVENT_DELETE,
			payload: ids
		};
	}
	undelete(ids: EventId_Some): EventAction_Undelete {
		return {
			type: EVENT_UNDELETE,
			payload: ids
		};
	}
	setState(state: EventCollectionState): EventAction_SetState {
		return {
			type: EVENT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<EventCollectionState>
	): EventAction_PatchState {
		return {
			type: EVENT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the event actions helper
 *
 * @export
 */
export const eventActions = new EventActions();
