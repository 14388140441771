// tslint:disable
/**
 * CONEXSYS Event App API
 * The API for the CONEXSYS Event App
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Object type
 * @export
 * @enum {string}
 */
export enum ObjectType {
	Answer = 'answer',
	Attendee = 'attendee',
	Category = 'category',
	Contact = 'contact',
	ContactRegistrationType = 'contact-registration-type',
	Event = 'event',
	Fee = 'fee',
	Product = 'product',
	ProductCategory = 'product-category',
	ProductContact = 'product-contact',
	ProductWebinar = 'product-webinar',
	Question = 'question',
	RegistrationGroup = 'registration-group',
	RegistrationType = 'registration-type',
	Resource = 'resource',
	Schedule = 'schedule',
	ScheduleContact = 'schedule-contact',
	Webinar = 'webinar'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesObjectType = [
	'answer',
	'attendee',
	'category',
	'contact',
	'contact-registration-type',
	'event',
	'fee',
	'product',
	'product-category',
	'product-contact',
	'product-webinar',
	'question',
	'registration-group',
	'registration-type',
	'resource',
	'schedule',
	'schedule-contact',
	'webinar'
];
