import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getAuthCollection,
	AuthId,
	AuthIds,
	AuthId_Some,
	AuthEntity,
	AuthEntities,
	AuthEntity_Some,
	AuthEntityPatch_Some,
	AuthCollection,
	AuthCollectionState,
	IAuthActions,
	authActions,
	AuthActionTypes,
	UserEntity,
	OpenidAuthResponse,
	OpenidAuthResponseError,
	OpenidAuthResponseSuccess,
	OpenidAuthDigest
} from '..';

const AUTH_SUCCESS_CALLBACK = () => (window.location.href = '/main');
const AUTH_FAIL_CALLBACK = () => (window.location.href = '/home');

/**
 * Auth helper interface
 *
 * @export
 * @interface IAuthHelper
 */
export interface IAuthHelper extends IEntityHelper {
	auth?: AuthEntity;

	loginWithRedirect(): void;
	newLoginWithRedirect(): void;
	handleRedirectCallback(
		digestAuthUserCallback: (authDigest: OpenidAuthDigest) => UserEntity
	): any;
	digestAuthResponse(
		data: any | OpenidAuthResponse
	): [string | undefined, OpenidAuthDigest | undefined];
	digestSuccessResponse(
		response: any | OpenidAuthResponseSuccess
	): OpenidAuthDigest;
}

/**
 * Auth helper options interface
 *
 * @export
 * @interface AuthHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface AuthHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const authHelperOpts: AuthHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Auth helper
 *
 * @export
 * @class AuthHelper
 * @extends {EntityHelper<AuthCollection, AuthActionTypes, AuthActions, AuthEntity, AuthEntities, AuthEntity_Some, AuthEntityPatch_Some, AuthId, AuthIds, AuthId_Some, AuthCollectionState, AuthHelperOpts>}
 * @implements {IAuthHelper}
 */
export class AuthHelper
	extends EntityHelper<
		AuthCollection,
		AuthActionTypes,
		IAuthActions,
		AuthEntity,
		AuthEntities,
		AuthEntity_Some,
		AuthEntityPatch_Some,
		AuthId,
		AuthIds,
		AuthId_Some,
		AuthCollectionState,
		AuthHelperOpts
	>
	implements IAuthHelper {
	constructor(
		useAuthId?: AuthId // defaults to active auth entity if not explicityly provided
	) {
		super(
			useSelector(getAuthCollection),
			authActions,
			useDispatch(),
			authHelperOpts
		);
		this.collection = useSelector(getAuthCollection);
		this.dispatch = useDispatch();

		// defaults to active auth entity if not explicityly provided
		this.auth = useAuthId ? this.get(useAuthId) : this.active();
		if (this.auth) {
			let opts: auth0.AuthOptions = {
				domain: this.auth.domain,
				clientID: this.auth.id,
				redirectUri: this.auth.callbackUrl,
				responseType: this.auth.responseType,
				scope: this.auth.scope,
				audience: this.auth.audience
			};
		}
	}

	auth?: AuthEntity;

	async loginWithRedirect() {
		let opts: auth0.AuthorizeOptions = {};
	}

	async newLoginWithRedirect() {
		// logout the current session if one exists
		this.logout();

		let opts: auth0.AuthorizeOptions = {
			prompt: 'login'
		};
	}

	async login(email: string, password: string) {
		let opts: auth0.AuthorizeOptions = {};

		//run login code
		let authDigest: OpenidAuthDigest = {
			access_token: <string>'test',
			token_type: 'Bearer',
			expires_in: <number>50000,
			refresh_token: <string>'test',
			id_token: <string>'test',
			claims: <any>{}
		};

		let auth = this.digestAuthResponse(authDigest);
		if (auth[0]) {
			this.logout();
		} else {
			window.localStorage['jwt'] = auth[1]?.id_token;
			AUTH_SUCCESS_CALLBACK();
		}
	}

	async newLogint(email: string, password: string) {
		// logout the current session if one exists
		this.logout();
		return this.login(email, password);
	}

	async logout() {
		window.localStorage.removeItem('jwt');
	}

	async isActiveSession(activeSessionCallback: any) {
		if (this.auth) {
			//get token
			var token = window.localStorage['jwt'];
			if (!token) {
				this.logout();
				AUTH_FAIL_CALLBACK();
			}
		}
	}

	async handleRedirectCallback(digestAuthUserCallback: any) {}

	digestAuthResponse(
		data: any | OpenidAuthResponse
	): [string | undefined, OpenidAuthDigest | undefined] {
		if ('error' in data) return [data.error, undefined];
		return [undefined, this.digestSuccessResponse(data)];
	}

	digestSuccessResponse(
		response: any | OpenidAuthResponseSuccess
	): OpenidAuthDigest {
		return {
			access_token: response.access_token,
			token_type: response.token_type,
			expires_in: response.expires_in,
			refresh_token: response.refresh_token,
			id_token: response.id_token,
			claims: response.idTokenPayload
		};
	}
}
