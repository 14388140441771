import {
	ProductWebinarCollection,
	ProductWebinarCollectionState,
	ProductWebinarId,
	ProductWebinarEntity_Some,
	ProductWebinarEntityPatch_Some,
	ProductWebinarId_Some,
	productWebinarCollectionDefault,
	productWebinarCollectionMutateIdOpts
} from '../collections/ProductWebinar';
import * as actions from '../actions/ProductWebinar';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * ProductWebinar reducer
 *
 * @export
 * @param {ProductWebinarCollection} [state=productWebinarCollectionDefault]
 * @param {ProductWebinarActionTypes} action
 * @returns
 */
export function productWebinarReducer(
	state: ProductWebinarCollection = productWebinarCollectionDefault,
	action: actions.ProductWebinarActionTypes
) {
	switch (action.type) {
		case actions.PRODUCTWEBINAR_MUTATE_ID:
			return collectionReducerActions.mutateId<ProductWebinarCollection>(
				state,
				action.payload,
				productWebinarCollectionMutateIdOpts
			);
		case actions.PRODUCTWEBINAR_SET:
			return collectionReducerActions.set<
				ProductWebinarCollection,
				ProductWebinarId
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_TOGGLE:
			return collectionReducerActions.toggle<
				ProductWebinarCollection,
				ProductWebinarId
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_SELECT:
			return collectionReducerActions.select<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_DESELECT:
			return collectionReducerActions.deselect<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_UPSERT:
			return collectionReducerActions.upsert<
				ProductWebinarCollection,
				ProductWebinarEntity_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_PATCH:
			return collectionReducerActions.patch<
				ProductWebinarCollection,
				ProductWebinarEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_CHANGE:
			return collectionReducerActions.change<
				ProductWebinarCollection,
				ProductWebinarEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_DELETE:
			return collectionReducerActions.delete<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_UNDELETE:
			return collectionReducerActions.undelete<
				ProductWebinarCollection,
				ProductWebinarId_Some
			>(state, action.payload);
		case actions.PRODUCTWEBINAR_SETSTATE:
			return collectionReducerActions.setState<
				ProductWebinarCollection,
				ProductWebinarCollectionState
			>(state, action.payload.state);
		case actions.PRODUCTWEBINAR_PATCHSTATE:
			return collectionReducerActions.patchState<
				ProductWebinarCollection,
				ProductWebinarCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
