import {
	RouteId_Some,
	RouteEntity_Some,
	RouteEntityPatch_Some,
	RouteCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const ROUTE_MUTATE_ID = '@app/ROUTE_MUTATE_ID';
export const ROUTE_SET = '@app/ROUTE_SET';
export const ROUTE_TOGGLE = '@app/ROUTE_TOGGLE';
export const ROUTE_SELECT = '@app/ROUTE_SELECT';
export const ROUTE_DESELECT = '@app/ROUTE_DESELECT';
export const ROUTE_UPSERT = '@app/ROUTE_UPSERT';
export const ROUTE_PATCH = '@app/ROUTE_PATCH';
export const ROUTE_CHANGE = '@app/ROUTE_CHANGE';
export const ROUTE_APPLY_CHANGES = '@app/ROUTE_APPLY_CHANGES';
export const ROUTE_CANCEL_CHANGES = '@app/ROUTE_CANCEL_CHANGES';
export const ROUTE_DELETE = '@app/ROUTE_DELETE';
export const ROUTE_UNDELETE = '@app/ROUTE_UNDELETE';
export const ROUTE_SETSTATE = '@app/ROUTE_SETSTATE';
export const ROUTE_PATCHSTATE = '@app/ROUTE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface RouteAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface RouteAction_MutateId extends EntityAction_MutateId {
	type: typeof ROUTE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active route
 *
 * @interface RouteAction_Set
 * @extends {EntityAction_Set}
 */
export interface RouteAction_Set extends EntityAction_Set {
	type: typeof ROUTE_SET;
	payload?: EntityId;
}

/**
 * Toggle active route
 *
 * @interface RouteAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface RouteAction_Toggle extends EntityAction_Toggle {
	type: typeof ROUTE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select route entities
 *
 * @interface RouteAction_Select
 * @extends {EntityAction_Select}
 */
interface RouteAction_Select extends EntityAction_Select {
	type: typeof ROUTE_SELECT;
	payload: RouteId_Some;
}

/**
 * Deselect route entities
 *
 * @interface RouteAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface RouteAction_Deselect extends EntityAction_Deselect {
	type: typeof ROUTE_DESELECT;
	payload: RouteId_Some;
}

/**
 * Upsert route entities
 *
 * @interface RouteAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface RouteAction_Upsert extends EntityAction_Upsert {
	type: typeof ROUTE_UPSERT;
	payload: RouteEntity_Some;
}

/**
 * Patch route entities
 *
 * @interface RouteAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface RouteAction_Patch extends EntityAction_Patch {
	type: typeof ROUTE_PATCH;
	payload: RouteEntityPatch_Some;
}

/**
 * Change route entities
 *
 * @interface RouteAction_Change
 * @extends {EntityAction_Change}
 */
interface RouteAction_Change extends EntityAction_Change {
	type: typeof ROUTE_CHANGE;
	payload: RouteEntityPatch_Some;
}

/**
 * Apply changes to route entities
 *
 * @interface RouteAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface RouteAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof ROUTE_APPLY_CHANGES;
	payload: RouteId_Some;
}

/**
 * Cancel changes to route entities
 *
 * @interface RouteAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface RouteAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof ROUTE_CANCEL_CHANGES;
	payload: RouteId_Some;
}

/**
 * Delete route entities
 *
 * @interface RouteAction_Delete
 * @extends {EntityAction_Delete}
 */
interface RouteAction_Delete extends EntityAction_Delete {
	type: typeof ROUTE_DELETE;
	payload: RouteId_Some;
}

/**
 * Undelete route entities
 *
 * @interface RouteAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface RouteAction_Undelete extends EntityAction_Undelete {
	type: typeof ROUTE_UNDELETE;
	payload: RouteId_Some;
}

/**
 * Set route collection / entity state
 *
 * @interface RouteAction_SetState
 * @extends {EntityAction_SetState}
 */
interface RouteAction_SetState extends EntityAction_SetState {
	type: typeof ROUTE_SETSTATE;
}

/**
 * PAtch route collection / entity state
 *
 * @interface RouteAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface RouteAction_PatchState extends EntityAction_PatchState {
	type: typeof ROUTE_PATCHSTATE;
}

/**
 * Export route action types
 *
 * @export
 */
export type RouteActionTypes =
	| RouteAction_MutateId
	| RouteAction_MutateId
	| RouteAction_Set
	| RouteAction_Toggle
	| RouteAction_Select
	| RouteAction_Deselect
	| RouteAction_Upsert
	| RouteAction_Patch
	| RouteAction_Change
	| RouteAction_ApplyChanges
	| RouteAction_CancelChanges
	| RouteAction_Delete
	| RouteAction_Undelete
	| RouteAction_SetState
	| RouteAction_PatchState;

/**
 * Route actions helper interface
 *
 * @export
 * @interface IRouteActions
 * @extends {EntityActions}
 */
export interface IRouteActions extends EntityActions {
	//customAction(ids: RouteId_Some): RouteAction_CustomAction;
}

/**
 * Route actions helper
 *
 * @export
 * @class RouteActions
 * @implements {EntityBaseActions}
 */
export class RouteActions implements IRouteActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): RouteAction_MutateId {
		return { type: ROUTE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): RouteAction_Set {
		return { type: ROUTE_SET, payload: id };
	}
	toggle(id?: EntityId): RouteAction_Toggle {
		return { type: ROUTE_TOGGLE, payload: id };
	}
	select(ids: RouteId_Some): RouteAction_Select {
		return {
			type: ROUTE_SELECT,
			payload: ids
		};
	}
	deselect(ids: RouteId_Some): RouteAction_Deselect {
		return {
			type: ROUTE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: RouteEntity_Some): RouteAction_Upsert {
		return {
			type: ROUTE_UPSERT,
			payload: entities
		};
	}
	patch(entities: RouteEntityPatch_Some): RouteAction_Patch {
		return {
			type: ROUTE_PATCH,
			payload: entities
		};
	}
	change(entities: RouteEntityPatch_Some): RouteAction_Change {
		return {
			type: ROUTE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: RouteId_Some): RouteAction_ApplyChanges {
		return {
			type: ROUTE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: RouteId_Some): RouteAction_CancelChanges {
		return {
			type: ROUTE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: RouteId_Some): RouteAction_Delete {
		return {
			type: ROUTE_DELETE,
			payload: ids
		};
	}
	undelete(ids: RouteId_Some): RouteAction_Undelete {
		return {
			type: ROUTE_UNDELETE,
			payload: ids
		};
	}
	setState(state: RouteCollectionState): RouteAction_SetState {
		return {
			type: ROUTE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<RouteCollectionState>
	): RouteAction_PatchState {
		return {
			type: ROUTE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the route actions helper
 *
 * @export
 */
export const routeActions = new RouteActions();
