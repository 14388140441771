import {
	AuthCollection,
	AuthCollectionState,
	AuthId,
	AuthEntity_Some,
	AuthEntityPatch_Some,
	AuthId_Some,
	authCollectionDefault,
	authCollectionMutateIdOpts
} from '../collections/Auth';
import * as actions from '../actions/Auth';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Auth reducer
 *
 * @export
 * @param {AuthCollection} [state=authCollectionDefault]
 * @param {AuthActionTypes} action
 * @returns
 */
export function authReducer(
	state: AuthCollection = authCollectionDefault,
	action: actions.AuthActionTypes
) {
	switch (action.type) {
		case actions.AUTH_MUTATE_ID:
			return collectionReducerActions.mutateId<AuthCollection>(
				state,
				action.payload,
				authCollectionMutateIdOpts
			);
		case actions.AUTH_SET:
			return collectionReducerActions.set<AuthCollection, AuthId>(
				state,
				action.payload
			);
		case actions.AUTH_TOGGLE:
			return collectionReducerActions.toggle<AuthCollection, AuthId>(
				state,
				action.payload
			);
		case actions.AUTH_SELECT:
			return collectionReducerActions.select<AuthCollection, AuthId_Some>(
				state,
				action.payload
			);
		case actions.AUTH_DESELECT:
			return collectionReducerActions.deselect<AuthCollection, AuthId_Some>(
				state,
				action.payload
			);
		case actions.AUTH_UPSERT:
			return collectionReducerActions.upsert<AuthCollection, AuthEntity_Some>(
				state,
				action.payload
			);
		case actions.AUTH_PATCH:
			return collectionReducerActions.patch<
				AuthCollection,
				AuthEntityPatch_Some
			>(state, action.payload);
		case actions.AUTH_CHANGE:
			return collectionReducerActions.change<
				AuthCollection,
				AuthEntityPatch_Some
			>(state, action.payload);
		case actions.AUTH_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<AuthCollection, AuthId_Some>(
				state,
				action.payload
			);
		case actions.AUTH_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				AuthCollection,
				AuthId_Some
			>(state, action.payload);
		case actions.AUTH_DELETE:
			return collectionReducerActions.delete<AuthCollection, AuthId_Some>(
				state,
				action.payload
			);
		case actions.AUTH_UNDELETE:
			return collectionReducerActions.undelete<AuthCollection, AuthId_Some>(
				state,
				action.payload
			);
		case actions.AUTH_SETSTATE:
			return collectionReducerActions.setState<
				AuthCollection,
				AuthCollectionState
			>(state, action.payload.state);
		case actions.AUTH_PATCHSTATE:
			return collectionReducerActions.patchState<
				AuthCollection,
				AuthCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
