import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getRegistrationTypeCollection,
	RegistrationTypeId,
	RegistrationTypeIds,
	RegistrationTypeId_Some,
	RegistrationTypeEntity,
	RegistrationTypeEntities,
	RegistrationTypeEntity_Some,
	RegistrationTypeEntityPatch_Some,
	RegistrationTypeCollection,
	RegistrationTypeCollectionState,
	IRegistrationTypeActions,
	registrationTypeActions,
	RegistrationTypeActionTypes
} from '..';
import {
	readRegistrationTypes,
	ReadRegistrationTypesRequest,
	RegistrationTypeApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as registrationType from '../registrationTypes/RegistrationType';

/**
 * RegistrationType helper interface
 *
 * @export
 * @interface IRegistrationTypeHelper
 * @extends {IEntityHelper}
 */
export interface IRegistrationTypeHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * RegistrationType helper options interface
 *
 * @export
 * @interface RegistrationTypeHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface RegistrationTypeHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const registrationTypeHelperOpts: RegistrationTypeHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * RegistrationType helper
 *
 * @export
 * @class RegistrationTypeHelper
 * @extends {EntityHelper<RegistrationTypeCollection, RegistrationTypeActionTypes, RegistrationTypeActions, RegistrationTypeEntity, RegistrationTypeEntities, RegistrationTypeEntity_Some, RegistrationTypeEntityPatch_Some, RegistrationTypeId, RegistrationTypeIds, RegistrationTypeId_Some, RegistrationTypeCollectionState, RegistrationTypeHelperOpts>}
 * @implements {IRegistrationTypeHelper}
 */
export class RegistrationTypeHelper
	extends EntityHelper<
		RegistrationTypeCollection,
		RegistrationTypeActionTypes,
		IRegistrationTypeActions,
		RegistrationTypeEntity,
		RegistrationTypeEntities,
		RegistrationTypeEntity_Some,
		RegistrationTypeEntityPatch_Some,
		RegistrationTypeId,
		RegistrationTypeIds,
		RegistrationTypeId_Some,
		RegistrationTypeCollectionState,
		RegistrationTypeHelperOpts
	>
	implements IRegistrationTypeHelper {
	constructor() {
		super(
			useSelector(getRegistrationTypeCollection),
			registrationTypeActions,
			useDispatch(),
			registrationTypeHelperOpts
		);
		this.collection = useSelector(getRegistrationTypeCollection);
		this.dispatch = useDispatch();
	}

	all_Attendee(ctx: UseCtx<any>): RegistrationTypeEntities {
		let registrationGroupIds: string[] = ctx.virtual.registrationGroup
			.all_Attendee()
			.map(entity => entity.id);
		return this.all().filter(
			entity => registrationGroupIds.indexOf(entity.registrationGroupId) > -1
		);
	}

	all_Exhibitor(ctx: UseCtx<any>): RegistrationTypeEntities {
		let registrationGroupIds: string[] = ctx.virtual.registrationGroup
			.all_Exhibitor()
			.map(entity => entity.id);
		return this.all().filter(
			entity => registrationGroupIds.indexOf(entity.registrationGroupId) > -1
		);
	}

	all_Speaker(ctx: UseCtx<any>): RegistrationTypeEntities {
		let registrationGroupIds: string[] = ctx.virtual.registrationGroup
			.all_Speaker()
			.map(entity => entity.id);
		return this.all().filter(
			entity => registrationGroupIds.indexOf(entity.registrationGroupId) > -1
		);
	}

	all_Sponsor(ctx: UseCtx<any>): RegistrationTypeEntities {
		let registrationGroupIds: string[] = ctx.virtual.registrationGroup
			.all_Sponsor()
			.map(entity => entity.id);
		return this.all().filter(
			entity => registrationGroupIds.indexOf(entity.registrationGroupId) > -1
		);
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: RegistrationTypeApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadRegistrationTypesRequest> = {},
		callback?: any
	): Promise<RegistrationTypeEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(RegistrationTypeApiOperation.readRegistrationTypes);
		let request: ReadRegistrationTypesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: RegistrationTypeEntities = await readRegistrationTypes(
			ctx,
			request
		)
			.then((entities: RegistrationTypeEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): RegistrationTypeEntities {
		return this.all().filter(isObjectStatusActive);
	}
}
