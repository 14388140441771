import * as appHooks from '../../app/store/hooks';
import * as virtualHooks from '../../virtual/store/hooks';
import { userConfig } from '../app/User';
import { AppHelpers } from '../../app/store';
import { VirtualHelpers } from '../../virtual/store';

export interface UseCtx<ConfigTypes> {
	locale: string;
	config: ConfigTypes[];
	app: AppHelpers;
	virtual: VirtualHelpers;
}

export const useCtx = <ConfigTypes>(...configs: any[]): UseCtx<ConfigTypes> => {
	const appUser = appHooks.useUser();

	const locale =
		appUser.activeUser?.locale || userConfig.settings.default.locale || '';

	return {
		locale,
		config: getConfigs<ConfigTypes>(locale, ...configs),
		app: {
			api: appHooks.useApi().apiHelper,
			auth: appHooks.useAuth().authHelper,
			menu: appHooks.useMenu().menuHelper,
			route: appHooks.useRoute().routeHelper,
			user: appUser.userHelper
		},
		virtual: {
			answer: virtualHooks.useAnswer().answerHelper,
			attendee: virtualHooks.useAttendee().attendeeHelper,
			contact: virtualHooks.useContact().contactHelper,
			contactRegistrationType: virtualHooks.useContactRegistrationType()
				.contactRegistrationTypeHelper,
			event: virtualHooks.useEvent().eventHelper,
			fee: virtualHooks.useFee().feeHelper,
			product: virtualHooks.useProduct().productHelper,
			productContact: virtualHooks.useProductContact().productContactHelper,
			productWebinar: virtualHooks.useProductWebinar().productWebinarHelper,
			question: virtualHooks.useQuestion().questionHelper,
			registrationGroup: virtualHooks.useRegistrationGroup()
				.registrationGroupHelper,
			registrationType: virtualHooks.useRegistrationType()
				.registrationTypeHelper,
			resource: virtualHooks.useResource().resourceHelper,
			schedule: virtualHooks.useSchedule().scheduleHelper,
			scheduleContact: virtualHooks.useScheduleContact().scheduleContactHelper,
			webinar: virtualHooks.useWebinar().webinarHelper,
			category: virtualHooks.useCategory().categoryHelper,
			productCategory: virtualHooks.useProductCategory().productCategoryHelper
		}
	} as UseCtx<ConfigTypes>;
};

const getConfigs = <ConfigTypes>(
	locale: string,
	...configs: any[]
): ConfigTypes[] => {
	let config: ConfigTypes[] = [];

	for (let configArg of configs) {
		// get config for locale // else for first locale
		let cfg: ConfigTypes =
			configArg[locale ? locale : ''] ||
			(configArg[Object.keys(configArg)[0]] as ConfigTypes);
		if (cfg) config.push(cfg);
	}

	return config as ConfigTypes[];
};
