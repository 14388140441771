import {
	WebinarId_Some,
	WebinarEntity_Some,
	WebinarEntityPatch_Some,
	WebinarCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const WEBINAR_MUTATE_ID = '@app/WEBINAR_MUTATE_ID';
export const WEBINAR_SET = '@app/WEBINAR_SET';
export const WEBINAR_TOGGLE = '@app/WEBINAR_TOGGLE';
export const WEBINAR_SELECT = '@app/WEBINAR_SELECT';
export const WEBINAR_DESELECT = '@app/WEBINAR_DESELECT';
export const WEBINAR_UPSERT = '@app/WEBINAR_UPSERT';
export const WEBINAR_PATCH = '@app/WEBINAR_PATCH';
export const WEBINAR_CHANGE = '@app/WEBINAR_CHANGE';
export const WEBINAR_APPLY_CHANGES = '@app/WEBINAR_APPLY_CHANGES';
export const WEBINAR_CANCEL_CHANGES = '@app/WEBINAR_CANCEL_CHANGES';
export const WEBINAR_DELETE = '@app/WEBINAR_DELETE';
export const WEBINAR_UNDELETE = '@app/WEBINAR_UNDELETE';
export const WEBINAR_SETSTATE = '@app/WEBINAR_SETSTATE';
export const WEBINAR_PATCHSTATE = '@app/WEBINAR_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface WebinarAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface WebinarAction_MutateId extends EntityAction_MutateId {
	type: typeof WEBINAR_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active webinar
 *
 * @interface WebinarAction_Set
 * @extends {EntityAction_Set}
 */
export interface WebinarAction_Set extends EntityAction_Set {
	type: typeof WEBINAR_SET;
	payload?: EntityId;
}

/**
 * Toggle active webinar
 *
 * @interface WebinarAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface WebinarAction_Toggle extends EntityAction_Toggle {
	type: typeof WEBINAR_TOGGLE;
	payload?: EntityId;
}

/**
 * Select webinar entities
 *
 * @interface WebinarAction_Select
 * @extends {EntityAction_Select}
 */
interface WebinarAction_Select extends EntityAction_Select {
	type: typeof WEBINAR_SELECT;
	payload: WebinarId_Some;
}

/**
 * Deselect webinar entities
 *
 * @interface WebinarAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface WebinarAction_Deselect extends EntityAction_Deselect {
	type: typeof WEBINAR_DESELECT;
	payload: WebinarId_Some;
}

/**
 * Upsert webinar entities
 *
 * @interface WebinarAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface WebinarAction_Upsert extends EntityAction_Upsert {
	type: typeof WEBINAR_UPSERT;
	payload: WebinarEntity_Some;
}

/**
 * Patch webinar entities
 *
 * @interface WebinarAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface WebinarAction_Patch extends EntityAction_Patch {
	type: typeof WEBINAR_PATCH;
	payload: WebinarEntityPatch_Some;
}

/**
 * Change webinar entities
 *
 * @interface WebinarAction_Change
 * @extends {EntityAction_Change}
 */
interface WebinarAction_Change extends EntityAction_Change {
	type: typeof WEBINAR_CHANGE;
	payload: WebinarEntityPatch_Some;
}

/**
 * Apply changes to webinar entities
 *
 * @interface WebinarAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface WebinarAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof WEBINAR_APPLY_CHANGES;
	payload: WebinarId_Some;
}

/**
 * Cancel changes to webinar entities
 *
 * @interface WebinarAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface WebinarAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof WEBINAR_CANCEL_CHANGES;
	payload: WebinarId_Some;
}

/**
 * Delete webinar entities
 *
 * @interface WebinarAction_Delete
 * @extends {EntityAction_Delete}
 */
interface WebinarAction_Delete extends EntityAction_Delete {
	type: typeof WEBINAR_DELETE;
	payload: WebinarId_Some;
}

/**
 * Undelete webinar entities
 *
 * @interface WebinarAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface WebinarAction_Undelete extends EntityAction_Undelete {
	type: typeof WEBINAR_UNDELETE;
	payload: WebinarId_Some;
}

/**
 * Set webinar collection / entity state
 *
 * @interface WebinarAction_SetState
 * @extends {EntityAction_SetState}
 */
interface WebinarAction_SetState extends EntityAction_SetState {
	type: typeof WEBINAR_SETSTATE;
}

/**
 * PAtch webinar collection / entity state
 *
 * @interface WebinarAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface WebinarAction_PatchState extends EntityAction_PatchState {
	type: typeof WEBINAR_PATCHSTATE;
}

/**
 * Export webinar action types
 *
 * @export
 */
export type WebinarActionTypes =
	| WebinarAction_MutateId
	| WebinarAction_MutateId
	| WebinarAction_Set
	| WebinarAction_Toggle
	| WebinarAction_Select
	| WebinarAction_Deselect
	| WebinarAction_Upsert
	| WebinarAction_Patch
	| WebinarAction_Change
	| WebinarAction_ApplyChanges
	| WebinarAction_CancelChanges
	| WebinarAction_Delete
	| WebinarAction_Undelete
	| WebinarAction_SetState
	| WebinarAction_PatchState;

/**
 * Webinar actions helper interface
 *
 * @export
 * @interface IWebinarActions
 * @extends {EntityActions}
 */
export interface IWebinarActions extends EntityActions {
	//customAction(ids: WebinarId_Some): WebinarAction_CustomAction;
}

/**
 * Webinar actions helper
 *
 * @export
 * @class WebinarActions
 * @implements {EntityBaseActions}
 */
export class WebinarActions implements IWebinarActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): WebinarAction_MutateId {
		return { type: WEBINAR_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): WebinarAction_Set {
		return { type: WEBINAR_SET, payload: id };
	}
	toggle(id?: EntityId): WebinarAction_Toggle {
		return { type: WEBINAR_TOGGLE, payload: id };
	}
	select(ids: WebinarId_Some): WebinarAction_Select {
		return {
			type: WEBINAR_SELECT,
			payload: ids
		};
	}
	deselect(ids: WebinarId_Some): WebinarAction_Deselect {
		return {
			type: WEBINAR_DESELECT,
			payload: ids
		};
	}
	upsert(entities: WebinarEntity_Some): WebinarAction_Upsert {
		return {
			type: WEBINAR_UPSERT,
			payload: entities
		};
	}
	patch(entities: WebinarEntityPatch_Some): WebinarAction_Patch {
		return {
			type: WEBINAR_PATCH,
			payload: entities
		};
	}
	change(entities: WebinarEntityPatch_Some): WebinarAction_Change {
		return {
			type: WEBINAR_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: WebinarId_Some): WebinarAction_ApplyChanges {
		return {
			type: WEBINAR_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: WebinarId_Some): WebinarAction_CancelChanges {
		return {
			type: WEBINAR_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: WebinarId_Some): WebinarAction_Delete {
		return {
			type: WEBINAR_DELETE,
			payload: ids
		};
	}
	undelete(ids: WebinarId_Some): WebinarAction_Undelete {
		return {
			type: WEBINAR_UNDELETE,
			payload: ids
		};
	}
	setState(state: WebinarCollectionState): WebinarAction_SetState {
		return {
			type: WEBINAR_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<WebinarCollectionState>
	): WebinarAction_PatchState {
		return {
			type: WEBINAR_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the webinar actions helper
 *
 * @export
 */
export const webinarActions = new WebinarActions();
