import {
	ResourceId_Some,
	ResourceEntity_Some,
	ResourceEntityPatch_Some,
	ResourceCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const RESOURCE_MUTATE_ID = '@app/RESOURCE_MUTATE_ID';
export const RESOURCE_SET = '@app/RESOURCE_SET';
export const RESOURCE_TOGGLE = '@app/RESOURCE_TOGGLE';
export const RESOURCE_SELECT = '@app/RESOURCE_SELECT';
export const RESOURCE_DESELECT = '@app/RESOURCE_DESELECT';
export const RESOURCE_UPSERT = '@app/RESOURCE_UPSERT';
export const RESOURCE_PATCH = '@app/RESOURCE_PATCH';
export const RESOURCE_CHANGE = '@app/RESOURCE_CHANGE';
export const RESOURCE_APPLY_CHANGES = '@app/RESOURCE_APPLY_CHANGES';
export const RESOURCE_CANCEL_CHANGES = '@app/RESOURCE_CANCEL_CHANGES';
export const RESOURCE_DELETE = '@app/RESOURCE_DELETE';
export const RESOURCE_UNDELETE = '@app/RESOURCE_UNDELETE';
export const RESOURCE_SETSTATE = '@app/RESOURCE_SETSTATE';
export const RESOURCE_PATCHSTATE = '@app/RESOURCE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ResourceAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ResourceAction_MutateId extends EntityAction_MutateId {
	type: typeof RESOURCE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active resource
 *
 * @interface ResourceAction_Set
 * @extends {EntityAction_Set}
 */
export interface ResourceAction_Set extends EntityAction_Set {
	type: typeof RESOURCE_SET;
	payload?: EntityId;
}

/**
 * Toggle active resource
 *
 * @interface ResourceAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ResourceAction_Toggle extends EntityAction_Toggle {
	type: typeof RESOURCE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select resource entities
 *
 * @interface ResourceAction_Select
 * @extends {EntityAction_Select}
 */
interface ResourceAction_Select extends EntityAction_Select {
	type: typeof RESOURCE_SELECT;
	payload: ResourceId_Some;
}

/**
 * Deselect resource entities
 *
 * @interface ResourceAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ResourceAction_Deselect extends EntityAction_Deselect {
	type: typeof RESOURCE_DESELECT;
	payload: ResourceId_Some;
}

/**
 * Upsert resource entities
 *
 * @interface ResourceAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ResourceAction_Upsert extends EntityAction_Upsert {
	type: typeof RESOURCE_UPSERT;
	payload: ResourceEntity_Some;
}

/**
 * Patch resource entities
 *
 * @interface ResourceAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ResourceAction_Patch extends EntityAction_Patch {
	type: typeof RESOURCE_PATCH;
	payload: ResourceEntityPatch_Some;
}

/**
 * Change resource entities
 *
 * @interface ResourceAction_Change
 * @extends {EntityAction_Change}
 */
interface ResourceAction_Change extends EntityAction_Change {
	type: typeof RESOURCE_CHANGE;
	payload: ResourceEntityPatch_Some;
}

/**
 * Apply changes to resource entities
 *
 * @interface ResourceAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ResourceAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof RESOURCE_APPLY_CHANGES;
	payload: ResourceId_Some;
}

/**
 * Cancel changes to resource entities
 *
 * @interface ResourceAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ResourceAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof RESOURCE_CANCEL_CHANGES;
	payload: ResourceId_Some;
}

/**
 * Delete resource entities
 *
 * @interface ResourceAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ResourceAction_Delete extends EntityAction_Delete {
	type: typeof RESOURCE_DELETE;
	payload: ResourceId_Some;
}

/**
 * Undelete resource entities
 *
 * @interface ResourceAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ResourceAction_Undelete extends EntityAction_Undelete {
	type: typeof RESOURCE_UNDELETE;
	payload: ResourceId_Some;
}

/**
 * Set resource collection / entity state
 *
 * @interface ResourceAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ResourceAction_SetState extends EntityAction_SetState {
	type: typeof RESOURCE_SETSTATE;
}

/**
 * PAtch resource collection / entity state
 *
 * @interface ResourceAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ResourceAction_PatchState extends EntityAction_PatchState {
	type: typeof RESOURCE_PATCHSTATE;
}

/**
 * Export resource action types
 *
 * @export
 */
export type ResourceActionTypes =
	| ResourceAction_MutateId
	| ResourceAction_MutateId
	| ResourceAction_Set
	| ResourceAction_Toggle
	| ResourceAction_Select
	| ResourceAction_Deselect
	| ResourceAction_Upsert
	| ResourceAction_Patch
	| ResourceAction_Change
	| ResourceAction_ApplyChanges
	| ResourceAction_CancelChanges
	| ResourceAction_Delete
	| ResourceAction_Undelete
	| ResourceAction_SetState
	| ResourceAction_PatchState;

/**
 * Resource actions helper interface
 *
 * @export
 * @interface IResourceActions
 * @extends {EntityActions}
 */
export interface IResourceActions extends EntityActions {
	//customAction(ids: ResourceId_Some): ResourceAction_CustomAction;
}

/**
 * Resource actions helper
 *
 * @export
 * @class ResourceActions
 * @implements {EntityBaseActions}
 */
export class ResourceActions implements IResourceActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ResourceAction_MutateId {
		return { type: RESOURCE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ResourceAction_Set {
		return { type: RESOURCE_SET, payload: id };
	}
	toggle(id?: EntityId): ResourceAction_Toggle {
		return { type: RESOURCE_TOGGLE, payload: id };
	}
	select(ids: ResourceId_Some): ResourceAction_Select {
		return {
			type: RESOURCE_SELECT,
			payload: ids
		};
	}
	deselect(ids: ResourceId_Some): ResourceAction_Deselect {
		return {
			type: RESOURCE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ResourceEntity_Some): ResourceAction_Upsert {
		return {
			type: RESOURCE_UPSERT,
			payload: entities
		};
	}
	patch(entities: ResourceEntityPatch_Some): ResourceAction_Patch {
		return {
			type: RESOURCE_PATCH,
			payload: entities
		};
	}
	change(entities: ResourceEntityPatch_Some): ResourceAction_Change {
		return {
			type: RESOURCE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ResourceId_Some): ResourceAction_ApplyChanges {
		return {
			type: RESOURCE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ResourceId_Some): ResourceAction_CancelChanges {
		return {
			type: RESOURCE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ResourceId_Some): ResourceAction_Delete {
		return {
			type: RESOURCE_DELETE,
			payload: ids
		};
	}
	undelete(ids: ResourceId_Some): ResourceAction_Undelete {
		return {
			type: RESOURCE_UNDELETE,
			payload: ids
		};
	}
	setState(state: ResourceCollectionState): ResourceAction_SetState {
		return {
			type: RESOURCE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ResourceCollectionState>
	): ResourceAction_PatchState {
		return {
			type: RESOURCE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the resource actions helper
 *
 * @export
 */
export const resourceActions = new ResourceActions();
