import {
	ScheduleId_Some,
	ScheduleEntity_Some,
	ScheduleEntityPatch_Some,
	ScheduleCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const SCHEDULE_MUTATE_ID = '@app/SCHEDULE_MUTATE_ID';
export const SCHEDULE_SET = '@app/SCHEDULE_SET';
export const SCHEDULE_TOGGLE = '@app/SCHEDULE_TOGGLE';
export const SCHEDULE_SELECT = '@app/SCHEDULE_SELECT';
export const SCHEDULE_DESELECT = '@app/SCHEDULE_DESELECT';
export const SCHEDULE_UPSERT = '@app/SCHEDULE_UPSERT';
export const SCHEDULE_PATCH = '@app/SCHEDULE_PATCH';
export const SCHEDULE_CHANGE = '@app/SCHEDULE_CHANGE';
export const SCHEDULE_APPLY_CHANGES = '@app/SCHEDULE_APPLY_CHANGES';
export const SCHEDULE_CANCEL_CHANGES = '@app/SCHEDULE_CANCEL_CHANGES';
export const SCHEDULE_DELETE = '@app/SCHEDULE_DELETE';
export const SCHEDULE_UNDELETE = '@app/SCHEDULE_UNDELETE';
export const SCHEDULE_SETSTATE = '@app/SCHEDULE_SETSTATE';
export const SCHEDULE_PATCHSTATE = '@app/SCHEDULE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ScheduleAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ScheduleAction_MutateId extends EntityAction_MutateId {
	type: typeof SCHEDULE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active schedule
 *
 * @interface ScheduleAction_Set
 * @extends {EntityAction_Set}
 */
export interface ScheduleAction_Set extends EntityAction_Set {
	type: typeof SCHEDULE_SET;
	payload?: EntityId;
}

/**
 * Toggle active schedule
 *
 * @interface ScheduleAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ScheduleAction_Toggle extends EntityAction_Toggle {
	type: typeof SCHEDULE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select schedule entities
 *
 * @interface ScheduleAction_Select
 * @extends {EntityAction_Select}
 */
interface ScheduleAction_Select extends EntityAction_Select {
	type: typeof SCHEDULE_SELECT;
	payload: ScheduleId_Some;
}

/**
 * Deselect schedule entities
 *
 * @interface ScheduleAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ScheduleAction_Deselect extends EntityAction_Deselect {
	type: typeof SCHEDULE_DESELECT;
	payload: ScheduleId_Some;
}

/**
 * Upsert schedule entities
 *
 * @interface ScheduleAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ScheduleAction_Upsert extends EntityAction_Upsert {
	type: typeof SCHEDULE_UPSERT;
	payload: ScheduleEntity_Some;
}

/**
 * Patch schedule entities
 *
 * @interface ScheduleAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ScheduleAction_Patch extends EntityAction_Patch {
	type: typeof SCHEDULE_PATCH;
	payload: ScheduleEntityPatch_Some;
}

/**
 * Change schedule entities
 *
 * @interface ScheduleAction_Change
 * @extends {EntityAction_Change}
 */
interface ScheduleAction_Change extends EntityAction_Change {
	type: typeof SCHEDULE_CHANGE;
	payload: ScheduleEntityPatch_Some;
}

/**
 * Apply changes to schedule entities
 *
 * @interface ScheduleAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ScheduleAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof SCHEDULE_APPLY_CHANGES;
	payload: ScheduleId_Some;
}

/**
 * Cancel changes to schedule entities
 *
 * @interface ScheduleAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ScheduleAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof SCHEDULE_CANCEL_CHANGES;
	payload: ScheduleId_Some;
}

/**
 * Delete schedule entities
 *
 * @interface ScheduleAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ScheduleAction_Delete extends EntityAction_Delete {
	type: typeof SCHEDULE_DELETE;
	payload: ScheduleId_Some;
}

/**
 * Undelete schedule entities
 *
 * @interface ScheduleAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ScheduleAction_Undelete extends EntityAction_Undelete {
	type: typeof SCHEDULE_UNDELETE;
	payload: ScheduleId_Some;
}

/**
 * Set schedule collection / entity state
 *
 * @interface ScheduleAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ScheduleAction_SetState extends EntityAction_SetState {
	type: typeof SCHEDULE_SETSTATE;
}

/**
 * PAtch schedule collection / entity state
 *
 * @interface ScheduleAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ScheduleAction_PatchState extends EntityAction_PatchState {
	type: typeof SCHEDULE_PATCHSTATE;
}

/**
 * Export schedule action types
 *
 * @export
 */
export type ScheduleActionTypes =
	| ScheduleAction_MutateId
	| ScheduleAction_MutateId
	| ScheduleAction_Set
	| ScheduleAction_Toggle
	| ScheduleAction_Select
	| ScheduleAction_Deselect
	| ScheduleAction_Upsert
	| ScheduleAction_Patch
	| ScheduleAction_Change
	| ScheduleAction_ApplyChanges
	| ScheduleAction_CancelChanges
	| ScheduleAction_Delete
	| ScheduleAction_Undelete
	| ScheduleAction_SetState
	| ScheduleAction_PatchState;

/**
 * Schedule actions helper interface
 *
 * @export
 * @interface IScheduleActions
 * @extends {EntityActions}
 */
export interface IScheduleActions extends EntityActions {
	//customAction(ids: ScheduleId_Some): ScheduleAction_CustomAction;
}

/**
 * Schedule actions helper
 *
 * @export
 * @class ScheduleActions
 * @implements {EntityBaseActions}
 */
export class ScheduleActions implements IScheduleActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ScheduleAction_MutateId {
		return { type: SCHEDULE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ScheduleAction_Set {
		return { type: SCHEDULE_SET, payload: id };
	}
	toggle(id?: EntityId): ScheduleAction_Toggle {
		return { type: SCHEDULE_TOGGLE, payload: id };
	}
	select(ids: ScheduleId_Some): ScheduleAction_Select {
		return {
			type: SCHEDULE_SELECT,
			payload: ids
		};
	}
	deselect(ids: ScheduleId_Some): ScheduleAction_Deselect {
		return {
			type: SCHEDULE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ScheduleEntity_Some): ScheduleAction_Upsert {
		return {
			type: SCHEDULE_UPSERT,
			payload: entities
		};
	}
	patch(entities: ScheduleEntityPatch_Some): ScheduleAction_Patch {
		return {
			type: SCHEDULE_PATCH,
			payload: entities
		};
	}
	change(entities: ScheduleEntityPatch_Some): ScheduleAction_Change {
		return {
			type: SCHEDULE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ScheduleId_Some): ScheduleAction_ApplyChanges {
		return {
			type: SCHEDULE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ScheduleId_Some): ScheduleAction_CancelChanges {
		return {
			type: SCHEDULE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ScheduleId_Some): ScheduleAction_Delete {
		return {
			type: SCHEDULE_DELETE,
			payload: ids
		};
	}
	undelete(ids: ScheduleId_Some): ScheduleAction_Undelete {
		return {
			type: SCHEDULE_UNDELETE,
			payload: ids
		};
	}
	setState(state: ScheduleCollectionState): ScheduleAction_SetState {
		return {
			type: SCHEDULE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ScheduleCollectionState>
	): ScheduleAction_PatchState {
		return {
			type: SCHEDULE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the schedule actions helper
 *
 * @export
 */
export const scheduleActions = new ScheduleActions();
