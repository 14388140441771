import { QuestionEntities } from '../../virtual/store/collections/Question';

export interface QuestionConfig {
	entities: QuestionEntities;
	activeId?: string;
}

export const questionConfig: QuestionConfig = {
	entities: []
};
