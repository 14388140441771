import {
	AttendeeCollection,
	AttendeeCollectionState,
	AttendeeId,
	AttendeeEntity_Some,
	AttendeeEntityPatch_Some,
	AttendeeId_Some,
	attendeeCollectionDefault,
	attendeeCollectionMutateIdOpts
} from '../collections/Attendee';
import * as actions from '../actions/Attendee';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Attendee reducer
 *
 * @export
 * @param {AttendeeCollection} [state=attendeeCollectionDefault]
 * @param {AttendeeActionTypes} action
 * @returns
 */
export function attendeeReducer(
	state: AttendeeCollection = attendeeCollectionDefault,
	action: actions.AttendeeActionTypes
) {
	switch (action.type) {
		case actions.ATTENDEE_MUTATE_ID:
			return collectionReducerActions.mutateId<AttendeeCollection>(
				state,
				action.payload,
				attendeeCollectionMutateIdOpts
			);
		case actions.ATTENDEE_SET:
			return collectionReducerActions.set<AttendeeCollection, AttendeeId>(
				state,
				action.payload
			);
		case actions.ATTENDEE_TOGGLE:
			return collectionReducerActions.toggle<AttendeeCollection, AttendeeId>(
				state,
				action.payload
			);
		case actions.ATTENDEE_SELECT:
			return collectionReducerActions.select<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_DESELECT:
			return collectionReducerActions.deselect<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_UPSERT:
			return collectionReducerActions.upsert<
				AttendeeCollection,
				AttendeeEntity_Some
			>(state, action.payload);
		case actions.ATTENDEE_PATCH:
			return collectionReducerActions.patch<
				AttendeeCollection,
				AttendeeEntityPatch_Some
			>(state, action.payload);
		case actions.ATTENDEE_CHANGE:
			return collectionReducerActions.change<
				AttendeeCollection,
				AttendeeEntityPatch_Some
			>(state, action.payload);
		case actions.ATTENDEE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_DELETE:
			return collectionReducerActions.delete<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_UNDELETE:
			return collectionReducerActions.undelete<
				AttendeeCollection,
				AttendeeId_Some
			>(state, action.payload);
		case actions.ATTENDEE_SETSTATE:
			return collectionReducerActions.setState<
				AttendeeCollection,
				AttendeeCollectionState
			>(state, action.payload.state);
		case actions.ATTENDEE_PATCHSTATE:
			return collectionReducerActions.patchState<
				AttendeeCollection,
				AttendeeCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
