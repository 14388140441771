import { ScheduleEntities } from '../../virtual/store/collections/Schedule';

export interface ScheduleConfig {
	entities: ScheduleEntities;
	activeId?: string;
}

export const scheduleConfig: ScheduleConfig = {
	entities: []
};
