import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonToolbar,
	IonContent,
	IonButton
} from '@ionic/react';

import { MeetingsList } from '../containers/MeetingsList';
import { MeetingsListExhibitor } from '../containers/MeetingsListExhibitor';

const MeetingsView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const currentAttendee = ctx.virtual.attendee.active();

	return currentAttendee &&
		ctx.virtual.attendee.isCompanyRepresentative(currentAttendee) ? (
		<MeetingsListExhibitor></MeetingsListExhibitor>
	) : (
		<MeetingsList></MeetingsList>
	);
};

export default MeetingsView;
