import React, { useState, useEffect } from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	ContactOrganizationEntity,
	ProductSessionEntity,
	EventEntity,
	AttendeeEntity
} from '../../store';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonImg,
	IonList,
	IonItemDivider,
	IonLabel,
	IonButton,
	IonModal,
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons
} from '@ionic/react';
import { EventScheduleListItem } from '../components/EventScheduleListItem';
import { SponsorCarousel } from '../components/SponsorCarousel';
import { Media } from '../../../app/ui/components';

//LIB
import moment from 'moment';

const MainView: React.FC = () => {
	const {
		config: [config],
		activeUser
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const [showVideoModal, setShowVideoModal] = useState('');

	useEffect(() => {
		setShowVideoModal('');
	}, []);

	const currentEvent = ctx.virtual.event.active();
	const eventId = ctx.virtual.event.active()?.id;

	const currentAttendee = ctx.virtual.attendee.active();
	const attendeeProducts = currentAttendee?.products?.filter(
		ap => ap.i_.status === 'active'
	);
	const allowAttendanceToAllSession = (currentEvent?.properties as any)?.app
		?.virtual?.generalSettings?.allowAttendanceToAllSession;
	const hasAgendaPage = (currentEvent?.properties as any)?.app?.virtual
		?.mainMenu?.agenda?.visible;

	const allSessions: ProductSessionEntity[] = allowAttendanceToAllSession
		? ctx.virtual.product.all_Session(eventId || '')
		: ctx.virtual.product
				.all_Session(eventId || '')
				.filter(product =>
					attendeeProducts?.find(p => p.productId === product.id)
				);

	const sortSessionsStartDate = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return moment(a.start).toDate() > moment(b.start).toDate()
			? 1
			: moment(b.start).toDate() > moment(a.start).toDate()
			? -1
			: 0;
	};
	const sortSessionsEndDate = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return moment(a.end).toDate() > moment(b.end).toDate()
			? 1
			: moment(b.end).toDate() > moment(a.end).toDate()
			? -1
			: 0;
	};
	const sortSessionsName = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return (a.name || '') > (b.name || '')
			? 1
			: (b.name || '') > (a.name || '')
			? -1
			: 0;
	};

	const onWebinarLinkClick = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
		}
	};

	const onWebinarWatchRecordingLinkClicked = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined,
		url?: string
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
			setShowVideoModal(url || '');
		}
	};

	let comingUpNext: ProductSessionEntity[] = [];
	allSessions
		.sort(sortSessionsName)
		.sort(sortSessionsEndDate)
		.sort(sortSessionsStartDate)
		.forEach(v => {
			if (!comingUpNext || comingUpNext.length <= 2) {
				if (moment().isBefore(moment(v.end))) {
					comingUpNext?.push(v);
				}
			}
		});
	const sessionsListItems = comingUpNext
		.sort(sortSessionsName)
		.sort(sortSessionsEndDate)
		.sort(sortSessionsStartDate)
		.map(
			(
				session: ProductSessionEntity,
				index: number,
				sessionList: ProductSessionEntity[]
			) => {
				let mainGroup = '';
				let subGroup = '';

				let mainGroupDate = moment(session.start).format('dddd MMMM Do, YYYY');
				let subGroupTime =
					moment(session.start).format('LT') +
					' - ' +
					moment(session.end).format('LT');
				if (index > 0) {
					let prevDate = moment(sessionList[index - 1].start).format(
						'dddd MMMM Do, YYYY'
					);
					let prevTime =
						moment(sessionList[index - 1].start).format('LT') +
						' - ' +
						moment(sessionList[index - 1].end).format('LT');
					if ((mainGroupDate ?? '') != (prevDate ?? '')) {
						mainGroup = mainGroupDate ?? '';
					}
					if ((subGroupTime ?? '') != (prevTime ?? '')) {
						subGroup = subGroupTime ?? '';
					}
				} else {
					mainGroup = mainGroupDate ?? '';
					subGroup = subGroupTime ?? '';
				}

				let webinars = ctx.virtual.webinar.all_By_Product(ctx, session.id);
				return (
					<div key={'div_' + session.id}>
						{mainGroup !== '' ? (
							<IonItemDivider
								style={{ backgroundColor: '#FFFFFF' }}
								key={mainGroup}
								class="list-maingroup-header"
							>
								<IonLabel>{mainGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						{subGroup !== '' ? (
							<IonItemDivider key={subGroup} class="list-subgroup-header">
								<IonLabel>{subGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						<EventScheduleListItem
							key={session.id}
							session={session}
							isAttending={
								attendeeProducts?.find(p => p.productId === session.id) !==
								undefined
							}
							hasAttened={false}
							webinars={webinars}
							allowAttendanceToAllSession={allowAttendanceToAllSession}
							onWebinarLinkClick={() => {
								onWebinarLinkClick(currentEvent, currentAttendee, session);
							}}
							onWebinarWatchRecordingLinkClick={(url?: string) => {
								onWebinarWatchRecordingLinkClicked(
									currentEvent,
									currentAttendee,
									session,
									url
								);
							}}
						/>
					</div>
				);
			}
		);

	const pageSettings = (currentEvent?.properties as any)?.app?.virtual?.pages
		?.home;

	let headerObj =
		pageSettings?.en.headerImg && pageSettings?.en.headerImg !== ''
			? JSON.parse(pageSettings?.en.headerImg)
			: undefined;
	let headerPath = headerObj?.originalFilePath ?? headerObj?.OriginalFilePath;

	let featuredCompanyIds = pageSettings?.featuredSection?.featuredIds ?? [];
	let allFeatured: ContactOrganizationEntity[] = ctx.virtual.contact
		.all_Exhibitor(currentEvent?.id || '')
		.filter(e => featuredCompanyIds.includes(e.id));

	useEffect(() => {
		//  maybe reload/update event data
	}, []);

	return (
		<IonContent id="mainview">
			<IonModal
				isOpen={showVideoModal != ''}
				onDidDismiss={() => setShowVideoModal('')}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle></IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowVideoModal('');
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Media src={showVideoModal}></Media>
				</IonContent>
			</IonModal>
			<IonGrid>
				<IonRow>
					<IonCol style={{ padding: '0px' }}>
						<IonImg hidden={!headerPath} src={headerPath ?? ''}></IonImg>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Welcome, {activeUser?.firstName ?? ""}</IonCardTitle>
								<IonCardSubtitle>
									<div
										dangerouslySetInnerHTML={{
											__html: pageSettings?.en.welcomeMessage ?? ''
										}}
									></div>
								</IonCardSubtitle>
							</IonCardHeader>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol
						size="12"
						sizeMd="8"
						hidden={!pageSettings?.upcomingSection.visible}
					>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									{pageSettings?.upcomingSection.en.title}
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent key="mainViewSessionsCardContent">
								<IonList
									key="mainViewSessionsListItems"
									hidden={!comingUpNext || comingUpNext.length <= 0}
								>
									{sessionsListItems}
								</IonList>
								<IonButton
									key="mainViewSessionsViewAll"
									fill="clear"
									color="default"
									size="small"
									class="ion-float-right"
									routerLink={hasAgendaPage ? '/agenda' : '/event-schedule'}
									hidden={!comingUpNext || comingUpNext.length <= 0}
								>
									View All
								</IonButton>
								<div hidden={comingUpNext && comingUpNext.length > 0}>
									There are no upcoming activities
								</div>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol
						size="12"
						sizeMd="4"
						hidden={
							!pageSettings?.featuredSection.visible ||
							(pageSettings?.featuredSection.visible &&
								featuredCompanyIds.length <= 0)
						}
					>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									{pageSettings?.featuredSection.en.title}
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<SponsorCarousel
									exhibitors={allFeatured}
									slideSize={pageSettings?.featuredSection.pageSize}
								/>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default MainView;
