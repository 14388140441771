import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { productWebinarConfig } from '../../../config/virtual/ProductWebinar';
import { ProductWebinar } from '../models';

/**
 * ProductWebinar entity interface
 *
 * @export
 * @interface ProductWebinarEntity
 * @extends {storage.Entity}
 */
export interface ProductWebinarEntity extends storage.Entity, ProductWebinar {}

/**
 * ProductWebinar entities array
 *
 * @export
 */
export type ProductWebinarEntities = ProductWebinarEntity[];

/**
 * Some productWebinar entities: one entity or an array of entities
 *
 * @export
 */
export type ProductWebinarEntity_Some =
	| ProductWebinarEntity
	| ProductWebinarEntities;

/**
 * ProductWebinar entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ProductWebinarEntityPatch
 * @extends {storage.EntityPatch<ProductWebinarEntity>}
 */
export type ProductWebinarEntityPatch = storage.EntityPatcher<
	ProductWebinarEntity
>;

/**
 * ProductWebinar entity patches array
 *
 * @export
 */
export type ProductWebinarEntitiesPatch = ProductWebinarEntityPatch[];

/**
 * Some productWebinar entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ProductWebinarEntityPatch_Some =
	| ProductWebinarEntityPatch
	| ProductWebinarEntitiesPatch;

/**
 * ProductWebinar entity id
 *
 * @export
 */
export type ProductWebinarId = storage.EntityId;

/**
 * ProductWebinar entity ids
 *
 * @export
 */
export type ProductWebinarIds = storage.EntityIds;

/**
 * Some productWebinar entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ProductWebinarId_Some = ProductWebinarId | ProductWebinarIds;

/**
 * ProductWebinar entities object by id
 *
 * @export
 * @interface ProductWebinarEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ProductWebinarEntitiesObject extends storage.EntitiesObject {
	[id: string]: ProductWebinarEntity;
}

/**
 * ProductWebinar entity state
 *
 * @export
 * @interface ProductWebinarEntityState
 * @extends {storage.EntityState}
 */
export interface ProductWebinarEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * ProductWebinar store collection
 *
 * @export
 * @interface ProductWebinarStoreCollection
 * @extends {storage.Store}
 */
export interface ProductWebinarStoreCollection extends storage.Store {
	productWebinar: ProductWebinarCollection;
}

/**
 * ProductWebinar collection properties
 *
 * @export
 * @interface ProductWebinarCollection
 * @extends {Collection}
 */
export interface ProductWebinarCollection extends storage.Collection {
	byIds: ProductWebinarEntitiesObject;
	mutation: ProductWebinarEntitiesObject;
	cache: ProductWebinarEntitiesObject;
	state: ProductWebinarCollectionState;
}

/**
 * ProductWebinar collection state
 *
 * @export
 * @interface ProductWebinarCollectionState
 * @extends {storage.CollectionState}
 */
export interface ProductWebinarCollectionState
	extends storage.CollectionState {}

// custom productWebinar collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const productWebinarCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const productWebinarCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const productWebinarCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const productWebinarCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: productWebinarCollectionIdStringProps,
	idArrayProps: productWebinarCollectionIdArrayProps,
	idEntityProps: productWebinarCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * ProductWebinar collection default values, initialize collection with productWebinar entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const productWebinarCollectionDefault: ProductWebinarCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ProductWebinarCollection),
		...{
			activeId:
				productWebinarConfig.activeId || productWebinarConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	productWebinarConfig.entities
);

/**
 * ProductWebinar store collection default values
 *
 * @export
 * @constant
 */
export const productWebinarStoreCollectionDefault: ProductWebinarStoreCollection = {
	productWebinar: productWebinarCollectionDefault
};

/**
 * ProductWebinar store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getProductWebinarCollection = (state: any) =>
	state.virtual.productWebinar;
