import { Locales } from './';

export interface Translations {
	[key: string]: Locales;
}

export const translations: Translations = {
	appName: {
		en: 'CONEXSYS',
		fr: 'CONEXSYS'
	},
	virtual: {
		en: 'Virtual',
		fr: 'Virtual'
	},
	lead: {
		en: 'Lead',
		fr: 'Conduire'
	},
	leads: {
		en: 'Leads',
		fr: 'Pistes'
	},
	qualifier: {
		en: 'Qualifier',
		fr: 'Qualificatif'
	},
	qualifiers: {
		en: 'Qualifiers',
		fr: 'Qualificatifs'
	},
	response: {
		en: 'Response',
		fr: 'Réponse'
	},
	responses: {
		en: 'Responses',
		fr: 'Réponses'
	},

	// standard
	account: {
		en: 'Account',
		fr: 'Compte'
	},
	event: {
		en: 'Event',
		fr: 'Un événement'
	},
	events: {
		en: 'Events',
		fr: 'Événements'
	},
	filter: {
		en: 'Search',
		fr: 'Filtre'
	},
	filters: {
		en: 'Search',
		fr: 'Filtres'
	},
	home: {
		en: 'Home',
		fr: 'Accueil'
	},
	note: {
		en: 'Note',
		fr: 'Remarque'
	},
	notes: {
		en: 'Notes',
		fr: 'Remarques'
	},
	optional: {
		en: 'Optional',
		fr: 'Optionnel'
	},
	order: {
		en: 'Order',
		fr: 'Ordre'
	},
	orders: {
		en: 'Orders',
		fr: 'Ordres'
	},
	report: {
		en: 'Report',
		fr: 'Rapport'
	},
	reports: {
		en: 'Reports',
		fr: 'Rapports'
	},
	required: {
		en: 'Required',
		fr: 'Obligatoire'
	},
	search: {
		en: 'Search',
		fr: 'Chercher'
	},
	settings: {
		en: 'Settings',
		fr: 'Réglages'
	},
	service: {
		en: 'Service',
		fr: 'Un service'
	},
	services: {
		en: 'Services',
		fr: 'Services'
	},
	signIn: {
		en: 'Sign In',
		fr: "S'authentifier"
	},
	support: {
		en: 'Support',
		fr: 'Soutien'
	},
	ticket: {
		en: 'Ticket',
		fr: 'Billet'
	},
	tickets: {
		en: 'Tickets',
		fr: 'Des billets'
	},
	user: {
		en: 'User',
		fr: 'Un utilisateur'
	},
	users: {
		en: 'Users',
		fr: 'Les utilisateurs'
	},
	userAccount: {
		en: 'User Account',
		fr: "Compte d'utilisateur"
	},
	userProfile: {
		en: 'My Profile',
		fr: 'Mon profil'
	},
	value: {
		en: 'Value',
		fr: 'Valeur'
	},
	values: {
		en: 'Values',
		fr: 'Valeur'
	}
};
