import * as classes from './';

export * from './Api';
export * from './Auth';
export * from './Menu';
export * from './Route';
export * from './User';

export interface AppHelpers {
	api: classes.ApiHelper;
	auth: classes.AuthHelper;
	menu: classes.MenuHelper;
	route: classes.RouteHelper;
	user: classes.UserHelper;
}
