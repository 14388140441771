import { RegistrationGroupEntities } from '../../virtual/store/collections/RegistrationGroup';

export interface RegistrationGroupConfig {
	entities: RegistrationGroupEntities;
	activeId?: string;
}

export const registrationGroupConfig: RegistrationGroupConfig = {
	entities: []
};
