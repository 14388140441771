import {
	ProductContactCollection,
	ProductContactCollectionState,
	ProductContactId,
	ProductContactEntity_Some,
	ProductContactEntityPatch_Some,
	ProductContactId_Some,
	productContactCollectionDefault,
	productContactCollectionMutateIdOpts
} from '../collections/ProductContact';
import * as actions from '../actions/ProductContact';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * ProductContact reducer
 *
 * @export
 * @param {ProductContactCollection} [state=productContactCollectionDefault]
 * @param {ProductContactActionTypes} action
 * @returns
 */
export function productContactReducer(
	state: ProductContactCollection = productContactCollectionDefault,
	action: actions.ProductContactActionTypes
) {
	switch (action.type) {
		case actions.PRODUCTCONTACT_MUTATE_ID:
			return collectionReducerActions.mutateId<ProductContactCollection>(
				state,
				action.payload,
				productContactCollectionMutateIdOpts
			);
		case actions.PRODUCTCONTACT_SET:
			return collectionReducerActions.set<
				ProductContactCollection,
				ProductContactId
			>(state, action.payload);
		case actions.PRODUCTCONTACT_TOGGLE:
			return collectionReducerActions.toggle<
				ProductContactCollection,
				ProductContactId
			>(state, action.payload);
		case actions.PRODUCTCONTACT_SELECT:
			return collectionReducerActions.select<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_DESELECT:
			return collectionReducerActions.deselect<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_UPSERT:
			return collectionReducerActions.upsert<
				ProductContactCollection,
				ProductContactEntity_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_PATCH:
			return collectionReducerActions.patch<
				ProductContactCollection,
				ProductContactEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_CHANGE:
			return collectionReducerActions.change<
				ProductContactCollection,
				ProductContactEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_DELETE:
			return collectionReducerActions.delete<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_UNDELETE:
			return collectionReducerActions.undelete<
				ProductContactCollection,
				ProductContactId_Some
			>(state, action.payload);
		case actions.PRODUCTCONTACT_SETSTATE:
			return collectionReducerActions.setState<
				ProductContactCollection,
				ProductContactCollectionState
			>(state, action.payload.state);
		case actions.PRODUCTCONTACT_PATCHSTATE:
			return collectionReducerActions.patchState<
				ProductContactCollection,
				ProductContactCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
