import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getAnswerCollection,
	AnswerId,
	AnswerIds,
	AnswerId_Some,
	AnswerEntity,
	AnswerEntities,
	AnswerEntity_Some,
	AnswerEntityPatch_Some,
	AnswerCollection,
	AnswerCollectionState,
	IAnswerActions,
	answerActions,
	AnswerActionTypes
} from '..';
import { readAnswers, ReadAnswersRequest, AnswerApiOperation } from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as answer from '../answers/Answer';

/**
 * Answer helper interface
 *
 * @export
 * @interface IAnswerHelper
 * @extends {IEntityHelper}
 */
export interface IAnswerHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Answer helper options interface
 *
 * @export
 * @interface AnswerHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface AnswerHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const answerHelperOpts: AnswerHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Answer helper
 *
 * @export
 * @class AnswerHelper
 * @extends {EntityHelper<AnswerCollection, AnswerActionTypes, AnswerActions, AnswerEntity, AnswerEntities, AnswerEntity_Some, AnswerEntityPatch_Some, AnswerId, AnswerIds, AnswerId_Some, AnswerCollectionState, AnswerHelperOpts>}
 * @implements {IAnswerHelper}
 */
export class AnswerHelper
	extends EntityHelper<
		AnswerCollection,
		AnswerActionTypes,
		IAnswerActions,
		AnswerEntity,
		AnswerEntities,
		AnswerEntity_Some,
		AnswerEntityPatch_Some,
		AnswerId,
		AnswerIds,
		AnswerId_Some,
		AnswerCollectionState,
		AnswerHelperOpts
	>
	implements IAnswerHelper {
	constructor() {
		super(
			useSelector(getAnswerCollection),
			answerActions,
			useDispatch(),
			answerHelperOpts
		);
		this.collection = useSelector(getAnswerCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event answers
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: AnswerApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadAnswersRequest> = {},
		callback?: any
	): Promise<AnswerEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom || this.lastSuccess(AnswerApiOperation.readAnswers);
		let request: ReadAnswersRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: AnswerEntities = await readAnswers(ctx, request)
			.then((entities: AnswerEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): AnswerEntities {
		return this.all().filter(isObjectStatusActive);
	}
}
