import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { eventConfig } from '../../../config/virtual/Event';
import { Event } from '../models';

/**
 * Event entity interface
 *
 * @export
 * @interface EventEntity
 * @extends {storage.Entity}
 */
export interface EventEntity extends storage.Entity, Event {}

/**
 * Event entities array
 *
 * @export
 */
export type EventEntities = EventEntity[];

/**
 * Some event entities: one entity or an array of entities
 *
 * @export
 */
export type EventEntity_Some = EventEntity | EventEntities;

/**
 * Event entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface EventEntityPatch
 * @extends {storage.EntityPatch<EventEntity>}
 */
export type EventEntityPatch = storage.EntityPatcher<EventEntity>;

/**
 * Event entity patches array
 *
 * @export
 */
export type EventEntitiesPatch = EventEntityPatch[];

/**
 * Some event entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type EventEntityPatch_Some = EventEntityPatch | EventEntitiesPatch;

/**
 * Event entity id
 *
 * @export
 */
export type EventId = storage.EntityId;

/**
 * Event entity ids
 *
 * @export
 */
export type EventIds = storage.EntityIds;

/**
 * Some event entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type EventId_Some = EventId | EventIds;

/**
 * Event entities object by id
 *
 * @export
 * @interface EventEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface EventEntitiesObject extends storage.EntitiesObject {
	[id: string]: EventEntity;
}

/**
 * Event entity state
 *
 * @export
 * @interface EventEntityState
 * @extends {storage.EntityState}
 */
export interface EventEntityState extends storage.EntityState {}

/**
 * Event store collection
 *
 * @export
 * @interface EventStoreCollection
 * @extends {storage.Store}
 */
export interface EventStoreCollection extends storage.Store {
	event: EventCollection;
}

/**
 * Event collection properties
 *
 * @export
 * @interface EventCollection
 * @extends {Collection}
 */
export interface EventCollection extends storage.Collection {
	byIds: EventEntitiesObject;
	mutation: EventEntitiesObject;
	cache: EventEntitiesObject;
	state: EventCollectionState;
}

/**
 * Event collection state
 *
 * @export
 * @interface EventCollectionState
 * @extends {storage.CollectionState}
 */
export interface EventCollectionState extends storage.CollectionState {}

// custom event collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const eventCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const eventCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const eventCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const eventCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: eventCollectionIdStringProps,
	idArrayProps: eventCollectionIdArrayProps,
	idEntityProps: eventCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Event collection default values, initialize collection with event entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const eventCollectionDefault: EventCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as EventCollection),
		...{
			activeId: eventConfig.activeId || eventConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	eventConfig.entities
);

/**
 * Event store collection default values
 *
 * @export
 * @constant
 */
export const eventStoreCollectionDefault: EventStoreCollection = {
	event: eventCollectionDefault
};

/**
 * Event store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getEventCollection = (state: any) => state.virtual.event;
