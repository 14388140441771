import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { questionConfig } from '../../../config/virtual/Question';
import { Question } from '../models';

/**
 * Question entity interface
 *
 * @export
 * @interface QuestionEntity
 * @extends {storage.Entity}
 */
export interface QuestionEntity extends storage.Entity, Question {}

/**
 * Question entities array
 *
 * @export
 */
export type QuestionEntities = QuestionEntity[];

/**
 * Some question entities: one entity or an array of entities
 *
 * @export
 */
export type QuestionEntity_Some = QuestionEntity | QuestionEntities;

/**
 * Question entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface QuestionEntityPatch
 * @extends {storage.EntityPatch<QuestionEntity>}
 */
export type QuestionEntityPatch = storage.EntityPatcher<QuestionEntity>;

/**
 * Question entity patches array
 *
 * @export
 */
export type QuestionEntitiesPatch = QuestionEntityPatch[];

/**
 * Some question entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type QuestionEntityPatch_Some =
	| QuestionEntityPatch
	| QuestionEntitiesPatch;

/**
 * Question entity id
 *
 * @export
 */
export type QuestionId = storage.EntityId;

/**
 * Question entity ids
 *
 * @export
 */
export type QuestionIds = storage.EntityIds;

/**
 * Some question entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type QuestionId_Some = QuestionId | QuestionIds;

/**
 * Question entities object by id
 *
 * @export
 * @interface QuestionEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface QuestionEntitiesObject extends storage.EntitiesObject {
	[id: string]: QuestionEntity;
}

/**
 * Question entity state
 *
 * @export
 * @interface QuestionEntityState
 * @extends {storage.EntityState}
 */
export interface QuestionEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Question store collection
 *
 * @export
 * @interface QuestionStoreCollection
 * @extends {storage.Store}
 */
export interface QuestionStoreCollection extends storage.Store {
	question: QuestionCollection;
}

/**
 * Question collection properties
 *
 * @export
 * @interface QuestionCollection
 * @extends {Collection}
 */
export interface QuestionCollection extends storage.Collection {
	byIds: QuestionEntitiesObject;
	mutation: QuestionEntitiesObject;
	cache: QuestionEntitiesObject;
	state: QuestionCollectionState;
}

/**
 * Question collection state
 *
 * @export
 * @interface QuestionCollectionState
 * @extends {storage.CollectionState}
 */
export interface QuestionCollectionState extends storage.CollectionState {}

// custom question collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const questionCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const questionCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const questionCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const questionCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: questionCollectionIdStringProps,
	idArrayProps: questionCollectionIdArrayProps,
	idEntityProps: questionCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Question collection default values, initialize collection with question entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const questionCollectionDefault: QuestionCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as QuestionCollection),
		...{
			activeId: questionConfig.activeId || questionConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	questionConfig.entities
);

/**
 * Question store collection default values
 *
 * @export
 * @constant
 */
export const questionStoreCollectionDefault: QuestionStoreCollection = {
	question: questionCollectionDefault
};

/**
 * Question store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getQuestionCollection = (state: any) => state.virtual.question;
