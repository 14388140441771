import React from 'react';

//UI
import { ExhibitHallList } from '../containers/ExhibitHallList';
import { IonContent } from '@ionic/react';

const ExhibitHallView: React.FC = () => {
	return (
		<IonContent>
			<ExhibitHallList />
		</IonContent>
	);
};

export default ExhibitHallView;
