import {
	ScheduleCollection,
	ScheduleCollectionState,
	ScheduleId,
	ScheduleEntity_Some,
	ScheduleEntityPatch_Some,
	ScheduleId_Some,
	scheduleCollectionDefault,
	scheduleCollectionMutateIdOpts
} from '../collections/Schedule';
import * as actions from '../actions/Schedule';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Schedule reducer
 *
 * @export
 * @param {ScheduleCollection} [state=scheduleCollectionDefault]
 * @param {ScheduleActionTypes} action
 * @returns
 */
export function scheduleReducer(
	state: ScheduleCollection = scheduleCollectionDefault,
	action: actions.ScheduleActionTypes
) {
	switch (action.type) {
		case actions.SCHEDULE_MUTATE_ID:
			return collectionReducerActions.mutateId<ScheduleCollection>(
				state,
				action.payload,
				scheduleCollectionMutateIdOpts
			);
		case actions.SCHEDULE_SET:
			return collectionReducerActions.set<ScheduleCollection, ScheduleId>(
				state,
				action.payload
			);
		case actions.SCHEDULE_TOGGLE:
			return collectionReducerActions.toggle<ScheduleCollection, ScheduleId>(
				state,
				action.payload
			);
		case actions.SCHEDULE_SELECT:
			return collectionReducerActions.select<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_DESELECT:
			return collectionReducerActions.deselect<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_UPSERT:
			return collectionReducerActions.upsert<
				ScheduleCollection,
				ScheduleEntity_Some
			>(state, action.payload);
		case actions.SCHEDULE_PATCH:
			return collectionReducerActions.patch<
				ScheduleCollection,
				ScheduleEntityPatch_Some
			>(state, action.payload);
		case actions.SCHEDULE_CHANGE:
			return collectionReducerActions.change<
				ScheduleCollection,
				ScheduleEntityPatch_Some
			>(state, action.payload);
		case actions.SCHEDULE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_DELETE:
			return collectionReducerActions.delete<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_UNDELETE:
			return collectionReducerActions.undelete<
				ScheduleCollection,
				ScheduleId_Some
			>(state, action.payload);
		case actions.SCHEDULE_SETSTATE:
			return collectionReducerActions.setState<
				ScheduleCollection,
				ScheduleCollectionState
			>(state, action.payload.state);
		case actions.SCHEDULE_PATCHSTATE:
			return collectionReducerActions.patchState<
				ScheduleCollection,
				ScheduleCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
