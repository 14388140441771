import React, { useState } from 'react';

//Data
import { useLocaleConfig } from '../../../app/store';
import { UseCtx, useCtx } from '../../../config/hooks';
import { ContactIndividualEntity } from '../../store';

//UI
import {
	IonItem,
	IonLabel,
	IonItemDivider,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonModal,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonTitle,
	IonContent,
	IonRadioGroup,
	IonListHeader,
	IonRadio
} from '@ionic/react';
import { peopleOutline, optionsOutline } from 'ionicons/icons';
import { SpeakerListItem } from '../components/SpeakerListItem';
import { SearchBar } from '../../../app/ui/components/Search/SearchBar';

export interface SpeakersListProps {
	title?: string;
	content?: string;
}

export const SpeakersList: React.FC<SpeakersListProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});

	const [searchText, setSearchText] = useState('');
	const [showFilterModal, setShowFilterModal] = useState(false);

	const currentEvent = ctx.virtual.event.active();
	let allSpeakers: ContactIndividualEntity[] = ctx.virtual.contact.all_Speaker(
		ctx,
		currentEvent?.id || ''
	);

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	const sortSpeakers = (
		a: ContactIndividualEntity,
		b: ContactIndividualEntity
	) => {
		return (a.lastName?.toLocaleUpperCase() as string) >
			(b.lastName?.toLocaleUpperCase() as string)
			? 1
			: (b.lastName?.toLocaleUpperCase() as string) >
			  (a.lastName?.toLocaleUpperCase() as string)
			? -1
			: 0;
	};

	let totalSpeakers = allSpeakers.length;
	const speakersListItems = allSpeakers
		.sort(sortSpeakers)
		.filter(speaker => {
			let match = 0,
				matches = 0,
				keywords = searchText ?? '';
			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(speaker?.firstName &&
						speaker?.firstName.toLowerCase().trim().indexOf(keyword) > -1) ||
					(speaker?.lastName &&
						speaker?.lastName.toLowerCase().trim().indexOf(keyword) > -1) ||
					(speaker?.email &&
						speaker?.email.toLowerCase().trim().indexOf(keyword) > -1) ||
					(speaker?.title &&
						speaker?.title.toLowerCase().trim().indexOf(keyword) > -1)
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.map(
			(
				speaker: ContactIndividualEntity,
				index: number,
				speakerList: ContactIndividualEntity[]
			) => {
				let mainGroup = '';
				let subGroup = '';
				let mainGroupName = (
					(speaker.lastName ?? '').charAt(0) ?? ''
				).toUpperCase();
				if (index > 0) {
					let prevName = (
						(speakerList[index - 1].lastName ?? '').charAt(0) ?? ''
					).toUpperCase();
					if ((mainGroupName ?? '') != (prevName ?? '')) {
						mainGroup = mainGroupName ?? '';
					}
				} else {
					mainGroup = mainGroupName ?? '';
				}
				return (
					<>
						{mainGroup !== '' ? (
							<IonItemDivider style={{ background: 'none' }} key={mainGroup}>
								<IonLabel>
									<h1>{mainGroup}</h1>
								</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}

						<SpeakerListItem
							key={'ListItem-' + speaker.id}
							speakerContact={speaker}
						/>
					</>
				);
			}
		);

	return (
		<>
			<IonModal
				className="filter-modal"
				isOpen={showFilterModal}
				onDidDismiss={() => setShowFilterModal(false)}
			>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton>Clear</IonButton>
						</IonButtons>
						<IonTitle>Filter Sessions</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(false);
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList>
						<IonRadioGroup>
							<IonListHeader>
								<IonLabel>Sponsor Level</IonLabel>
							</IonListHeader>
							<IonItem>
								<IonLabel>All</IonLabel>
								<IonRadio></IonRadio>
							</IonItem>
							<IonItem>
								<IonLabel>Gold</IonLabel>
								<IonRadio></IonRadio>
							</IonItem>
							<IonItem>
								<IonLabel>Silver</IonLabel>
								<IonRadio></IonRadio>
							</IonItem>
							<IonItem>
								<IonLabel>Bronze</IonLabel>
								<IonRadio></IonRadio>
							</IonItem>
							<IonItem>
								<IonLabel>None</IonLabel>
								<IonRadio></IonRadio>
							</IonItem>
						</IonRadioGroup>
					</IonList>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonGrid>
					<IonRow>
						<IonCol size="10">
							<SearchBar onChange={onSerach} />
						</IonCol>
						<IonCol>
							<IonButton
								hidden={true}
								color="light"
								class="ion-float-right"
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(true);
								}}
							>
								<IonIcon icon={optionsOutline} />
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonToolbar>
			<IonContent>
				{totalSpeakers <= 0 ? (
					<IonLabel class="ion-text-center">
						<br />
						<h1>
							<IonIcon icon={peopleOutline} size="large" />
						</h1>
						<h2>No Speakers found.</h2>
					</IonLabel>
				) : (
					<IonList>{speakersListItems}</IonList>
				)}
			</IonContent>
		</>
	);
};
