import React, { useEffect, useState } from 'react';
import { IonLoading } from '@ionic/react';
import { useApi, updateAppDevice } from '../../store';
import { appApis, initApi, AppApi } from '../../api';
import { useCtx } from '../../../config/hooks';
import { EventEntity } from '../../../virtual/store';

export interface AppDataProps {}

export const AppData: React.FC<AppDataProps> = props => {
	const [message, setMessage] = useState('Loading...');

	const [initialized, setInitialized] = useState(false);
	const [initializing, setInitializing] = useState(false);

	const [eventInitialized, setEventInitialized] = useState('');
	const [eventInitializing, setEventInitializing] = useState('');

	const { apiHelper } = useApi();

	const ctx = useCtx();

	const activeApiId = ctx.app.api.active()?.id;

	// don't set active event until the app an api are initialized so that useEffect gets triggered after app and api are done
	const activeEventId =
		initialized && activeApiId ? ctx.virtual.event.active()?.id : undefined;

	useEffect(() => {
		if (!initialized) {
			init();
		} else if (activeApiId) {
			initEventData();
		}
	}, [initialized, activeApiId, activeEventId]);

	const init = async () => {
		setInitializing(true);

		// init device data
		//await updateAppDevice();

		// init apis
		await initApis();

		setInitialized(true);
		setInitializing(false);
	};

	const initApis = async () => {
		let apis = apiHelper.all();
		for (let api of apis) {
			let appApi: AppApi = await initApi(api);
			appApis[api.id] = appApi;
		}
	};

	const initEventData = async () => {
		if (eventInitializing === '') {
			if (activeEventId && activeEventId !== eventInitialized) {
				setEventInitializing(activeEventId);
				setEventInitialized('');

				ctx.virtual.event.readData(ctx);

				setEventInitialized(activeEventId);
				setEventInitializing('');
			}
		}
	};

	const syncMessage = async () => {
		let newNessage = initializing
			? 'Preparing...'
			: !activeApiId
			? 'Connecting...'
			: eventInitializing !== ''
			? 'Initializing Event...'
			: '';

		if (message != newNessage) setMessage(newNessage);
	};

	syncMessage();

	return (
		<>
			{message !== '' ? (
				<IonLoading
					cssClass="my-custom-class"
					isOpen={true}
					message={`<h2>${message}</h2>Please wait...`}
					duration={2000}
				/>
			) : (
				props.children
			)}
		</>
	);
};
