import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { scheduleConfig } from '../../../config/virtual/Schedule';
import { Schedule } from '../models';

/**
 * Schedule entity interface
 *
 * @export
 * @interface ScheduleEntity
 * @extends {storage.Entity}
 */
export interface ScheduleEntity extends storage.Entity, Schedule {}

/**
 * Schedule entities array
 *
 * @export
 */
export type ScheduleEntities = ScheduleEntity[];

/**
 * Some schedule entities: one entity or an array of entities
 *
 * @export
 */
export type ScheduleEntity_Some = ScheduleEntity | ScheduleEntities;

/**
 * Schedule entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ScheduleEntityPatch
 * @extends {storage.EntityPatch<ScheduleEntity>}
 */
export type ScheduleEntityPatch = storage.EntityPatcher<ScheduleEntity>;

/**
 * Schedule entity patches array
 *
 * @export
 */
export type ScheduleEntitiesPatch = ScheduleEntityPatch[];

/**
 * Some schedule entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ScheduleEntityPatch_Some =
	| ScheduleEntityPatch
	| ScheduleEntitiesPatch;

/**
 * Schedule entity id
 *
 * @export
 */
export type ScheduleId = storage.EntityId;

/**
 * Schedule entity ids
 *
 * @export
 */
export type ScheduleIds = storage.EntityIds;

/**
 * Some schedule entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ScheduleId_Some = ScheduleId | ScheduleIds;

/**
 * Schedule entities object by id
 *
 * @export
 * @interface ScheduleEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ScheduleEntitiesObject extends storage.EntitiesObject {
	[id: string]: ScheduleEntity;
}

/**
 * Schedule entity state
 *
 * @export
 * @interface ScheduleEntityState
 * @extends {storage.EntityState}
 */
export interface ScheduleEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Schedule store collection
 *
 * @export
 * @interface ScheduleStoreCollection
 * @extends {storage.Store}
 */
export interface ScheduleStoreCollection extends storage.Store {
	schedule: ScheduleCollection;
}

/**
 * Schedule collection properties
 *
 * @export
 * @interface ScheduleCollection
 * @extends {Collection}
 */
export interface ScheduleCollection extends storage.Collection {
	byIds: ScheduleEntitiesObject;
	mutation: ScheduleEntitiesObject;
	cache: ScheduleEntitiesObject;
	state: ScheduleCollectionState;
}

/**
 * Schedule collection state
 *
 * @export
 * @interface ScheduleCollectionState
 * @extends {storage.CollectionState}
 */
export interface ScheduleCollectionState extends storage.CollectionState {}

// custom schedule collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const scheduleCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const scheduleCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const scheduleCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const scheduleCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: scheduleCollectionIdStringProps,
	idArrayProps: scheduleCollectionIdArrayProps,
	idEntityProps: scheduleCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Schedule collection default values, initialize collection with schedule entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const scheduleCollectionDefault: ScheduleCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ScheduleCollection),
		...{
			activeId: scheduleConfig.activeId || scheduleConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	scheduleConfig.entities
);

/**
 * Schedule store collection default values
 *
 * @export
 * @constant
 */
export const scheduleStoreCollectionDefault: ScheduleStoreCollection = {
	schedule: scheduleCollectionDefault
};

/**
 * Schedule store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getScheduleCollection = (state: any) => state.virtual.schedule;
