import { CategoryEntities } from '../../virtual/store/collections/Category';

export interface CategoryConfig {
	entities: CategoryEntities;
	activeId?: string;
}

export const categoryConfig: CategoryConfig = {
	entities: []
};
