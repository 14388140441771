// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Event ObjectStatus type. setup, live, onsite, closed
 * @export
 * @enum {string}
 */
export enum EventStatusType {
	Setup = 'setup',
	Live = 'live',
	Onsite = 'onsite',
	Closed = 'closed'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesEventStatusType = ['setup', 'live', 'onsite', 'closed'];
