import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getProductContactCollection,
	ProductContactId,
	ProductContactIds,
	ProductContactId_Some,
	ProductContactEntity,
	ProductContactEntities,
	ProductContactEntity_Some,
	ProductContactEntityPatch_Some,
	ProductContactCollection,
	ProductContactCollectionState,
	IProductContactActions,
	productContactActions,
	ProductContactActionTypes,
	ProductContactType
} from '..';
import {
	readProductContacts,
	ReadProductContactsRequest,
	ProductContactApiOperation
} from '../apis';
import { useCtx, UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as productContact from '../productContacts/ProductContact';

/**
 * ProductContact helper interface
 *
 * @export
 * @interface IProductContactHelper
 * @extends {IEntityHelper}
 */
export interface IProductContactHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * ProductContact helper options interface
 *
 * @export
 * @interface ProductContactHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ProductContactHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const productContactHelperOpts: ProductContactHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * ProductContact helper
 *
 * @export
 * @class ProductContactHelper
 * @extends {EntityHelper<ProductContactCollection, ProductContactActionTypes, ProductContactActions, ProductContactEntity, ProductContactEntities, ProductContactEntity_Some, ProductContactEntityPatch_Some, ProductContactId, ProductContactIds, ProductContactId_Some, ProductContactCollectionState, ProductContactHelperOpts>}
 * @implements {IProductContactHelper}
 */
export class ProductContactHelper
	extends EntityHelper<
		ProductContactCollection,
		ProductContactActionTypes,
		IProductContactActions,
		ProductContactEntity,
		ProductContactEntities,
		ProductContactEntity_Some,
		ProductContactEntityPatch_Some,
		ProductContactId,
		ProductContactIds,
		ProductContactId_Some,
		ProductContactCollectionState,
		ProductContactHelperOpts
	>
	implements IProductContactHelper {
	constructor() {
		super(
			useSelector(getProductContactCollection),
			productContactActions,
			useDispatch(),
			productContactHelperOpts
		);
		this.collection = useSelector(getProductContactCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ProductContactApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadProductContactsRequest> = {},
		callback?: any
	): Promise<ProductContactEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(ProductContactApiOperation.readProductContacts);
		let request: ReadProductContactsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ProductContactEntities = await readProductContacts(
			ctx,
			request
		)
			.then((entities: ProductContactEntities) => {
				let contactIds = entities.map(
					productContact => productContact.contactId
				);
				// if there are product contact ids, then load them by id
				if (contactIds.length > 0) {
					ctx.virtual.contact.readById(
						ctx,
						{
							eventId: request.eventId,
							ids: contactIds
						},
						callback
					);
				} else {
					if (callback) callback(entities);
				}
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): ProductContactEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_By_ProductId(productId: string): ProductContactEntities | undefined {
		return this.all_Active().filter(c => c.productId === productId);
	}

	all_Speakers_By_ProductId(
		ctx: UseCtx<any>,
		productId: string
	): ProductContactEntities | undefined {
		return this.all_Active()
			.filter(c => c.productId === productId)
			.filter(s =>
				ctx.virtual.contact.get_Speaker(
					s.contactId,
					ctx.virtual.event.active()?.id || ''
				)
			);
	}

	all_ProductId_By_SpeakerId(
		speakerId: string
	): ProductContactEntities | undefined {
		return this.all_Active().filter(c => c.contactId === speakerId);
	}

	all_Sponsors_By_ProductId(
		ctx: UseCtx<any>,
		productId: string
	): ProductContactEntities | undefined {
		return this.all_Active()
			.filter(c => c.productId === productId)
			.filter(s =>
				ctx.virtual.contact.get_Exhibitor(
					s.contactId,
					ctx.virtual.event.active()?.id || ''
				)
			);
	}
}
