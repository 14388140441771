import React from 'react';
import { IonBadge } from '@ionic/react';
import { ScheduleContactStatusType, ScheduleStatusType } from '../../store';

export interface MeetingStatusBadgeProps {
	scheduleStatus: ScheduleStatusType;
	scheduleContactStatus: ScheduleContactStatusType;
	style?: { [key: string]: any };
}

export const MeetingStatusBadge: React.FC<MeetingStatusBadgeProps> = props => {
	const color: string =
		props.scheduleStatus === ScheduleStatusType.Pending ||
		props.scheduleStatus === ScheduleStatusType.Requested
			? 'warning'
			: props.scheduleStatus === ScheduleStatusType.Declined ||
			  props.scheduleStatus === ScheduleStatusType.Cancelled
			? 'danger'
			: props.scheduleStatus === ScheduleStatusType.Available
			? 'secondary'
			: 'success';
	const text: string =
		props.scheduleStatus === ScheduleStatusType.Pending ||
		props.scheduleStatus === ScheduleStatusType.Requested
			? 'Pending Approval'
			: props.scheduleStatus === ScheduleStatusType.Declined
			? 'Declined'
			: props.scheduleStatus === ScheduleStatusType.Cancelled
			? 'Cancelled'
			: props.scheduleStatus === ScheduleStatusType.Available
			? 'Available'
			: 'Approved';
	const style: { [key: string]: any } = props.style ?? { marginRight: '3px' };

	return (
		<IonBadge style={style} color={color}>
			{text}
		</IonBadge>
	);
};
