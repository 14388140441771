import React, { useState } from 'react';
import _ from 'lodash';
import {
	IonMenu,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonMenuToggle,
	IonItemDivider,
	IonItem,
	IonLabel,
	IonIcon,
	IonText,
	IonRouterLink,
	IonImg
} from '@ionic/react';

import { Component } from './';

import {
	useMenu,
	MenuEntity,
	MenuEntities,
	MenuDisplayType,
	MenuDisplaySideType,
	iconKeys,
	Icon,
	componentKeys
} from '../../store';

import { config } from '../../../config';
import { localize } from '../../../config/locales';
import { useCtx } from '../../../config/hooks';
import { AttendeeEntity } from '../../../virtual/store';

export interface MenuConfigProps {
	disabled?: boolean;
	title?: string;
	type?: MenuDisplayType;
	side?: MenuDisplaySideType;
	maxEdgeStart?: number;
	swipeGesture?: boolean;
	autoHide?: boolean;
	security?: string[];
	tags?: string[];
	alwaysTags?: string[];
	allowedTags?: string[];
	ignoreTags?: string[];
	icons?: Pick<Icon, 'slot' | 'color' | 'size'>;
	className?: string;
	collapse?: boolean;
}

export interface MenuProps {
	contentId: string;
	instanceId: string;
	defaultTags?: string[];
	id?: string;
	customization?: any;
	activeAttendee?: AttendeeEntity;
}

export const Menu: React.FC<MenuProps> = props => {
	const { menuHelper, activeUser, locale } = useMenu();

	// get them current menu instance state for the instanceId. Default to disabled if there is no state
	let instance: MenuConfigProps = menuHelper.state().instances
		? (menuHelper.state().instances?.[props.instanceId] as MenuConfigProps) || {
				disabled: true
		  }
		: {
				disabled: true
		  };

	// default tags if the menu instance doesn't have any
	if (props.defaultTags && !instance.tags) instance.tags = props.defaultTags;

	const collapsible = instance.collapse && activeUser?.settings?.menu?.collapse;

	const [collapsed, setCollapsed] = useState(true);

	const onEngaged = () => {
		if (collapsible) {
			if (collapsed) setCollapsed(false);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};
	const onDisengaged = () => {
		if (collapsible) {
			if (!collapsed) setCollapsed(true);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};

	const toggleEngaged = () => {
		if (collapsible) {
			setCollapsed(!collapsed);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};

	const checkMenuItemSecuritySetting = (menuItem: MenuEntity) => {
		let security: any = {};
		if (menuItem.id === 'home') {
			security = props.customization?.home?.security ?? {};
		} else if (menuItem.id === 'agenda') {
			security = props.customization?.agenda?.security ?? {};
		} else if (menuItem.id === 'event-schedule') {
			security = props.customization?.schedule?.security ?? {};
		} else if (menuItem.id === 'exhibit-hall') {
			security = props.customization?.exhibitHall?.security ?? {};
		} else if (menuItem.id === 'speakers') {
			security = props.customization?.speakers?.security ?? {};
		} else if (menuItem.id === 'sponsor-showcase') {
			security = props.customization?.sponsorShowcase?.security ?? {};
		} else if (menuItem.id === 'meetings') {
			security = props.customization?.meetings?.security ?? {};
		}

		//deny regtype
		let denyregtypes: string[] = security?.deny?.registrationTypes ?? [];
		return !denyregtypes.includes(
			props.activeAttendee?.registrationTypeId ?? ''
		);
	};

	const getMenuTitle = (menuItem: MenuEntity) => {
		let title = menuItem.title;
		if (menuItem.id === 'home') {
			title = props.customization?.home.en.title ?? menuItem.title;
		} else if (menuItem.id === 'agenda') {
			title = props.customization?.agenda.en.title ?? menuItem.title;
		} else if (menuItem.id === 'event-schedule') {
			title = props.customization?.schedule.en.title ?? menuItem.title;
		} else if (menuItem.id === 'exhibit-hall') {
			title = props.customization?.exhibitHall.en.title ?? menuItem.title;
		} else if (menuItem.id === 'speakers') {
			title = props.customization?.speakers.en.title ?? menuItem.title;
		} else if (menuItem.id === 'sponsor-showcase') {
			title = props.customization?.sponsorShowcase.en.title ?? menuItem.title;
		} else if (menuItem.id === 'meetings') {
			title = props.customization?.meetings?.en.title ?? menuItem.title;
		}

		return localize(locale, title ?? '');
	};

	let items: MenuEntities;

	//useEffect(() => {
	// return menu items which match the current menu instance state and the security state
	items = menuHelper.filter((item: MenuEntity) => {
		// if there are security scopes on the item, ensure the user has at least one of them
		if (item.security) {
			let authorized;
			if (activeUser?.security)
				item.security.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) authorized = true;
				});
			if (!authorized) return false;
		}

		//is menu item set to visible
		if (
			(item.id === 'home' && !props.customization?.home.visible) ||
			(item.id === 'agenda' && !props.customization?.agenda.visible) ||
			(item.id === 'event-schedule' &&
				!props.customization?.schedule.visible) ||
			(item.id === 'exhibit-hall' &&
				!props.customization?.exhibitHall.visible) ||
			(item.id === 'speakers' && !props.customization?.speakers.visible) ||
			(item.id === 'sponsor-showcase' &&
				!props.customization?.sponsorShowcase.visible) ||
			(item.id === 'meetings' && !props.customization?.meetings?.visible)
		) {
			return false;
		}

		if (!checkMenuItemSecuritySetting(item)) {
			return false;
		}

		if (item.tags) {
			// if there are ignore tags, ensure the item doesn't have one of them
			if (instance.ignoreTags) {
				let ignore;
				instance.ignoreTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) ignore = true;
				});
				if (ignore) return false;
			}
			// if there are allowed tags, ensure the item has at least one of them
			if (instance.allowedTags) {
				let allow;
				instance.allowedTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) allow = true;
				});
				if (!allow) return false;
			}
			// if there are always tags, ensure the item displays if it has at least one of them
			if (instance.alwaysTags) {
				let always;
				instance.alwaysTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) always = true;
				});
				if (always) return true;
			}
			// if there are menu instance tags, ensure the item has at least one of them
			if (instance.tags) {
				let match;
				instance.tags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) match = true;
				});
				if (match) return true;
			}
			return false;
		}

		return true;
	});

	let logoObj =
		props.customization?.branding?.logo &&
		props.customization?.branding?.logo !== ''
			? JSON.parse(props.customization?.branding?.logo)
			: undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
	const menuItems = items.map((item: MenuEntity) => {
		let title = getMenuTitle(item);
		return item.divider ? (
			<IonItemDivider
				key={item.id}
				{..._.pick(item.divider, ['slot', 'sticky', 'color'])}
			>
				{item.divider.label && (
					<IonLabel>{localize(locale, item.divider.label)}</IonLabel>
				)}
			</IonItemDivider>
		) : (
			<IonItem
				detail={false}
				key={item.id}
				{...(item.url
					? {
							routerLink: item.url,
							routerDirection: 'none'
					  }
					: {})}
			>
				{item.icon && (
					<IonIcon
						{..._.pick(
							{
								...item.icon,
								slot: item.icon.slot || instance.icons?.slot || 'start',
								color: item.icon.color || instance.icons?.color,
								size: item.icon.size || instance.icons?.size || 'large'
							},
							iconKeys
						)}
					/>
				)}
				<IonLabel className="menu--label">{title}</IonLabel>
				{item.component &&
					(item.component.id != 'test' ? (
						<IonText>{item.component.id}</IonText>
					) : (
						<Component
							id={item.component.id}
							{..._.pick(item.component, componentKeys)}
						/>
					))}
			</IonItem>
		);
	});

	return (
		<IonMenu
			contentId={props.contentId}
			menuId={props.instanceId}
			id={props.id}
			side={instance.side || 'start'}
			type={instance.type}
			maxEdgeStart={instance.maxEdgeStart}
			swipeGesture={instance.swipeGesture}
			disabled={instance.disabled || menuItems.length === 0 ? true : false}
			className={[
				'menu',
				'menu-' + props.instanceId,
				...(collapsible && collapsed
					? ['menu--collapsed', 'menu-' + props.instanceId + '--collapsed']
					: []),
				...(instance.className ? [instance.className] : [])
			].join(' ')}
			onMouseOver={onEngaged}
			onMouseOut={onDisengaged}
			onMouseLeave={onDisengaged}
		>
			<IonHeader onTouchStart={toggleEngaged}>
				<IonToolbar>
					<IonRouterLink routerLink="/home">
						<IonImg
							hidden={!logoPath}
							style={{ height: '55px' }}
							src={logoPath ?? ''}
						></IonImg>
					</IonRouterLink>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<IonMenuToggle autoHide={instance.autoHide}>
						{menuItems}
					</IonMenuToggle>
				</IonList>
			</IonContent>
			<div style={{ textAlign: 'center', paddingBottom: '10px' }}>
				<img
					onClick={() => {
						window.open('http://conexsys.com', '_blank');
					}}
					style={{
						paddingBottom: '10px',
						cursor: 'pointer'
					}}
					width="105px"
					src={'assets/img/logo/poweredbyConexsys_logo_color.png'}
				/>
				<br />
				<span style={{ fontSize: '11px' }}>VR {config.version}</span>
			</div>
		</IonMenu>
	);
};
