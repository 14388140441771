import React, { useEffect, useState, memo } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonCard,
	IonLabel,
	IonContent,
	IonButton,
	IonText,
	useIonAlert,
	IonSpinner,
	IonItem
} from '@ionic/react';
import {
	closeCircleOutline,
	timeOutline,
	checkmarkCircleOutline,
	calendarOutline
} from 'ionicons/icons';
import { MeetingAddToCalendar } from '../components/MeetingAddToCalendar';

import { Avatar } from '../../../app/ui/components/Avatar';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import {
	ObjectType,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	ScheduleContactEntity,
	ScheduleEntities,
	ScheduleEntity,
	ScheduleStatusType,
	ContactIndividualEntity,
	ScheduleContactEntities,
	ScheduleType,
	ContactEntities
} from '../../store';
import uuid from '../../../app/utils/uuid';
import { newEntity } from '../../../app/utils';
import { MeetingStatusBadge } from '../components/MeetingStatusBadge';

export interface AppointmentBookingProps {
	exhibitorId?: string;
	attendeeId?: string;
}

/*********************************************************/
/* AppointmentBooking */
/*********************************************************/
const AppointmentBooking: React.FC<AppointmentBookingProps> = props => {
	const ctx = useCtx<{}>({});

	/**********************************************************/
	/* State Objects */
	/*********************************************************/
	const [presentAlert] = useIonAlert();
	const [selectedDate, setSelectedDate] = useState<Date | undefined>();
	const [availableDays, setAvailableDays] = useState<Date[] | undefined>([
		moment().toDate()
	]);
	const [selectedTime, setSelectedTime] = useState<Date | undefined>();
	const [availableTimes, setAvailableTimes] = useState<Date[] | undefined>([
		moment().toDate()
	]);
	const [slotTimeFrame, setSlotTimeFrame] = useState<Number | undefined>(30);
	const [availableSlots, setAvailableSlots] = useState<
		ScheduleEntities | undefined
	>(undefined);
	const [availableContacts, setAvailableContacts] = useState<
		ScheduleContactEntities | undefined
	>(undefined);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState<
		ScheduleEntity | undefined
	>();
	const [selectedScheduleContact, setSelectedScheduleContact] = useState<
		ScheduleContactEntity | undefined
	>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingHosts, setIsLoadingHosts] = useState<boolean>(false);

	const currentEvent = ctx.virtual.event.active();
	const currentExhibitor = ctx.virtual.contact.get_Exhibitor(
		props?.exhibitorId || '',
		currentEvent?.id || ''
	);
	const currentAttendee = ctx.virtual.attendee.get(props?.attendeeId || '');
	const attendeeContact:
		| ContactIndividualEntity
		| undefined = ctx.virtual.contact.get_Individual(
		currentAttendee?.contactId || '',
		currentEvent?.id || ''
	);

	/*********************************************************/
	/* useEffect */
	/*********************************************************/

	useEffect(() => {
		setIsLoading(true);
		let event = ctx.virtual.event.active();
		let attendee = ctx.virtual.attendee.active();

		if (currentExhibitor && event && attendee) {
			//Read all exhibitor scheduals and meetings
			ctx.virtual.scheduleContact.read(
				ctx,
				{
					contactId: currentExhibitor.id,
					role: ScheduleContactRoleType.Organizer
				},
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						let batchSize = 100;
						for (let i = 0; i < schedualIds.length; i += batchSize) {
							const batchSchedualIds = schedualIds.slice(i, i + batchSize);
							//read the meetings for the company
							if (batchSchedualIds.length > 0) {
								ctx.virtual.schedule.read(
									ctx,
									{
										ids: batchSchedualIds,
										type: ScheduleType.Meeting
									},
									(sEntities: ScheduleEntities) => {
										setIsLoading(false);
									}
								);
							}
						}
					} else {
						setIsLoading(false);
					}
				}
			);
			//get all attendee meetings
			ctx.virtual.scheduleContact.read(
				ctx,
				{
					contactId: attendee.contactId
				},
				(entities: ScheduleContactEntities) => {
					if (entities && entities.length > 0) {
						let schedualIds = entities.map(obj => obj.scheduleId);
						let batchSize = 100;
						for (let i = 0; i < schedualIds.length; i += batchSize) {
							const batchSchedualIds = schedualIds.slice(i, i + batchSize);
							//read the meetings for the company
							if (batchSchedualIds.length > 0) {
								ctx.virtual.schedule.read(
									ctx,
									{
										ids: batchSchedualIds,
										type: ScheduleType.Meeting
									},
									(sEntities: ScheduleEntities) => {}
								);
							}
						}
					}
				}
			);
		} else {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		setSlotTimeFrame(15);

		let timeExhibitorMeetings: ScheduleEntities = ctx.virtual.schedule
			.all_ByOrganizerContact(ctx, currentExhibitor?.id ?? '')
			.filter(x => x.start !== null && x.start !== undefined);

		setAvailableSlots(timeExhibitorMeetings);
		timeExhibitorMeetings = timeExhibitorMeetings.sort(
			(a, b) => 0 - (a.start > b.start ? -1 : 1)
		);
		let days: Date[] = [];
		let times: Date[] = [];
		timeExhibitorMeetings.forEach((meeting: ScheduleEntity) => {
			if (
				!days?.find(d =>
					moment(meeting?.start).isSame(moment(d).toDate(), 'day')
				)
			) {
				days.push(moment(meeting?.start).toDate());
			}
			if (
				!times?.find(
					d =>
						moment(meeting?.start).isSame(moment(d).toDate(), 'day') &&
						moment(meeting?.start).format('HH:mm') === moment(d).format('HH:mm')
				)
			) {
				times.push(moment(meeting?.start).toDate());
			}
		});

		setAvailableContacts(
			ctx.virtual.scheduleContact.all_ScheduleParticipants(
				timeExhibitorMeetings.map(x => x.id),
				[ScheduleContactRoleType.Host]
			)
		);
		setAvailableDays(days);
		setAvailableTimes(times);
		setSelectedDate(moment(timeExhibitorMeetings[0]?.start).toDate());
		let attendeeMeetings = ctx.virtual.schedule
			.all_ByAttendeeContactAndOrganizerContact(
				ctx,
				currentAttendee?.contactId || '',
				currentExhibitor?.id || '',
				[],
				[
					ScheduleContactStatusType.Accepted,
					ScheduleContactStatusType.Pending,
					ScheduleContactStatusType.Tentative
				]
			)
			.filter(x => x.start !== null && x.start !== undefined);

		if (attendeeMeetings && attendeeMeetings.length > 0) {
			let attendeeMeetingContact = ctx.virtual.scheduleContact
				.all_ScheduleParticipants(
					[attendeeMeetings[0].id],
					[ScheduleContactRoleType.Attendee]
				)
				.find(x => x.contactId === currentAttendee?.contactId);
			if (attendeeMeetingContact) {
				setSelectedTimeSlot(attendeeMeetings[0]);
				setSelectedScheduleContact(attendeeMeetingContact);
			}
		}
	}, [
		ctx.virtual.schedule.all().length,
		ctx.virtual.scheduleContact.all().length
	]);

	if (!currentExhibitor) {
		return (
			<IonContent>
				<IonLabel>Exhibitor Not Found</IonLabel>
			</IonContent>
		);
	}

	/*********************************************************/
	/* Action Methods
	/*********************************************************/

	const selectedDateChanged = function (e: Date) {
		setSelectedTime(undefined);
		setSelectedTimeSlot(undefined);
		setSelectedDate(e);
	};

	const selectedTimeChanged = function (time: Date) {
		setSelectedTime(time);
		setSelectedTimeSlot(undefined);

		setIsLoadingHosts(true);
		let slotList = availableSlots?.filter(
			d =>
				moment(d?.start).isSame(moment(selectedDate).toDate(), 'day') &&
				moment(d?.start).format('HH:mm') === moment(time).format('HH:mm')
		);
		if (slotList && slotList.length > 0) {
			for (let x = 0; x < slotList.length; x++) {
				ctx.virtual.scheduleContact.read(
					ctx,
					{
						scheduleId: slotList[x].id,
						role: ScheduleContactRoleType.Host
					},
					(scEntities: ScheduleContactEntities) => {
						if (scEntities && scEntities.length > 0) {
							ctx.virtual.contact.readById(
								ctx,
								{
									ids: scEntities.map(obj => obj.contactId)
								},
								(contactEntities: ContactEntities) => {}
							);
						}
						setIsLoadingHosts(false);
					}
				);
			}
		}

		let selectableSlotList = availableSlots?.filter(
			d =>
				moment(d?.start).isSame(moment(selectedDate).toDate(), 'day') &&
				moment(d?.start).format('HH:mm') === moment(time).format('HH:mm') &&
				d?.status == ScheduleStatusType.Available
		);
		if (selectableSlotList && selectableSlotList.length > 0) {
			setSelectedTimeSlot(selectableSlotList[0]);
		}
	};

	const selectedSlotChanged = function (slot: ScheduleEntity) {
		setSelectedTimeSlot(slot);
	};

	const bookAppointmentSelected = function (
		schedule: ScheduleEntity,
		notes: string
	) {
		if (currentAttendee) {
			//Add Attendee contact to schedual
			let scheduleContact = {
				...newEntity(ctx, ObjectType.ScheduleContact),
				id: uuid.uuid(),
				role: ScheduleContactRoleType.Attendee,
				status: ScheduleContactStatusType.Pending,
				contactId: currentAttendee?.contactId || '',
				scheduleId: schedule.id,
				note: notes
			};
			scheduleContact.i_.created.by =
				currentAttendee.i?.toString() ?? undefined;
			ctx.virtual.scheduleContact.write(ctx, {
				scheduleContacts: [scheduleContact]
			});

			//update timeslot/schedule item
			schedule.status = ScheduleStatusType.Requested;
			schedule.name = '';
			ctx.virtual.schedule.write(ctx, {
				schedules: [schedule]
			});

			setSelectedTimeSlot(schedule);
			setSelectedScheduleContact(scheduleContact);

			if (currentEvent && currentAttendee) {
				ctx.virtual.scheduleContact.sendMeetingRequestEmail(
					ctx,
					currentEvent,
					schedule,
					scheduleContact,
					currentAttendee,
					currentExhibitor,
					currentExhibitor?.contactEmail ?? ''
				);
			}
		}
	};

	const cancelSelectedAppointment = function (
		schedule: ScheduleEntity | undefined,
		scheduleContact: ScheduleContactEntity | undefined
	) {
		if (schedule && scheduleContact) {
			//Add contact to schedual
			scheduleContact.status = ScheduleContactStatusType.Cancelled;
			ctx.virtual.scheduleContact.write(ctx, {
				scheduleContacts: [scheduleContact]
			});

			//update timeslot/schedule item
			schedule.status = ScheduleStatusType.Available;
			schedule.name = '';
			ctx.virtual.schedule.write(ctx, {
				schedules: [schedule]
			});

			setSelectedTimeSlot(undefined);
			setSelectedScheduleContact(undefined);

			if (currentEvent && currentAttendee && attendeeContact) {
				ctx.virtual.scheduleContact.sendMeetingCancellationEmail(
					ctx,
					currentEvent,
					schedule,
					scheduleContact,
					attendeeContact,
					currentExhibitor,
					currentExhibitor?.contactEmail ?? ''
				);
			}
		}
	};

	/**********************************************************/
	/* Static Objects */
	/*********************************************************/

	let logoObj = currentExhibitor.logo
		? JSON.parse(currentExhibitor.logo)
		: undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;

	const isMeetingBooked: boolean =
		selectedTimeSlot !== null &&
		selectedTimeSlot !== undefined &&
		selectedTimeSlot.status !== ScheduleStatusType.Available;

	const availableDaysList = availableDays?.map(
		(availableDay, index: number, slotList) => {
			return (
				<IonCol size="3" sizeMd="3">
					<IonCard>
						<IonItem
							button={true}
							lines="none"
							color={
								moment(availableDay).isSame(
									moment(selectedDate).toDate(),
									'day'
								)
									? 'success'
									: ''
							}
							onClick={() => {
								selectedDateChanged(moment(availableDay).toDate());
							}}
						>
							<IonLabel>
								<h1>{moment(availableDay).format('MMM')}</h1>
								<p>{moment(availableDay).format('DD')}</p>
							</IonLabel>
							<IonIcon
								src={
									moment(availableDay).isSame(moment(selectedDate).toDate())
										? checkmarkCircleOutline
										: calendarOutline
								}
							></IonIcon>
						</IonItem>
					</IonCard>
				</IonCol>
			);
		}
	);

	const timeSlotItems = availableTimes
		?.filter(time => moment(time).isSame(moment(selectedDate), 'date'))
		.map((time, index: number, slotList) => {
			let hasAnotherMeeting = ctx.virtual.schedule.all_ByAttendeeContact(
				ctx,
				currentAttendee?.contactId || '',
				[],
				[
					ScheduleContactStatusType.Accepted,
					ScheduleContactStatusType.Pending,
					ScheduleContactStatusType.Tentative
				]
			);

			let isSoldOut = availableSlots
				? availableSlots?.findIndex((value, i) => {
						return (
							moment(value?.start).isSame(moment(time).toDate(), 'day') &&
							moment(value?.start).format('HH:mm') ===
								moment(time).format('HH:mm') &&
							value.status == ScheduleStatusType.Available
						);
				  }) < 0
				: false;

			return (
				<IonCol key={'timeslot-' + index} size="6" sizeSm="3">
					<IonButton
						fill={
							moment(time).isSame(moment(selectedTime).toDate())
								? 'solid'
								: 'outline'
						}
						className="timeslot-btn"
						disabled={isSoldOut}
						color={
							moment(time).isSame(moment(selectedTime).toDate())
								? 'success'
								: 'dark'
						}
						style={{
							width: '100%'
						}}
						onClick={() => {
							selectedTimeChanged(time);
						}}
					>
						{moment(time).format('h:mm A')}
						<IonIcon
							hidden={!moment(time).isSame(moment(selectedTime))}
							src={checkmarkCircleOutline}
						></IonIcon>
					</IonButton>
				</IonCol>
			);
		});

	const availableContactsList = availableSlots
		?.filter(
			d =>
				moment(d?.start).isSame(moment(selectedDate).toDate(), 'day') &&
				moment(d?.start).format('HH:mm') ===
					moment(selectedTime).format('HH:mm')
		)
		.map((slot, index: number, slotList) => {
			let contact = ctx.virtual.contact.get_Individual(
				availableContacts?.find(c => c.scheduleId == slot.id)?.contactId || '',
				currentEvent?.id || ''
			);

			let isSelected = selectedTimeSlot?.id == slot.id;

			return (
				<IonCol size="4">
					<IonButton
						fill={isSelected ? 'solid' : 'outline'}
						className="availablecontact-btn"
						color={isSelected ? 'success' : 'dark'}
						disabled={
							slot.status != ScheduleStatusType.Available || isLoadingHosts
						}
						style={{
							width: '100%',
							fontSize: 10
						}}
						onClick={() => {
							selectedSlotChanged(slot);
						}}
					>
						<div>
							<IonLabel>
								{contact
									? contact?.firstName + ' ' + contact?.lastName
									: 'First Available Rep'}
							</IonLabel>
							<IonIcon
								hidden={!isSelected}
								src={checkmarkCircleOutline}
								slot="end"
							></IonIcon>
							<IonLabel hidden={!contact}>
								<br
									hidden={
										contact?.organization?.toLowerCase().trim() ==
										currentExhibitor?.name?.toLowerCase().trim()
									}
								/>
								<span
									hidden={
										contact?.organization?.toLowerCase().trim() ==
										currentExhibitor?.name?.toLowerCase().trim()
									}
									style={{ fontSize: '6px' }}
								>
									{contact ? contact?.organization : ''}
								</span>
							</IonLabel>
						</div>
					</IonButton>
				</IonCol>
			);
		});

	const meetingRequestText: string =
		selectedScheduleContact?.status === ScheduleContactStatusType.Tentative ||
		selectedScheduleContact?.status === ScheduleContactStatusType.Pending
			? 'Meeting Request Sent. Pending Approval'
			: selectedScheduleContact?.status ===
					ScheduleContactStatusType.Declined ||
			  selectedScheduleContact?.status === ScheduleContactStatusType.Rejected
			? 'Meeting Request Declined'
			: selectedScheduleContact?.status === ScheduleContactStatusType.Cancelled
			? 'Meeting Request Cancelled'
			: 'Meeting Scheduled';

	return (
		<IonCard>
			<IonGrid>
				<IonRow>
					<IonCol size="3">
						<Avatar name={currentExhibitor.name} src={logoPath} size="80px" />
					</IonCol>
					<IonCol>
						<h3>{currentExhibitor.name}</h3>
						<IonText hidden={true}>
							<IonIcon src={timeOutline}></IonIcon> {slotTimeFrame} min
							<br />
						</IonText>
						<IonText
							hidden={
								isMeetingBooked ||
								!availableSlots ||
								availableSlots.length <= 0 ||
								isLoading
							}
						>
							Request a meeting with <i>{currentExhibitor.name}</i>. Select a
							date and time slot you would like. Once selected your meeting
							request will be sent to the exhibitor for approval.
						</IonText>
						<IonText
							hidden={
								(availableSlots && availableSlots.length > 0) || isLoading
							}
						>
							<h2>No available meetings</h2>
						</IonText>
					</IonCol>
				</IonRow>
				{isLoading ? (
					<IonRow>
						<IonCol offset="2" size="8" sizeMd="8">
							<IonSpinner name="dots"></IonSpinner>
						</IonCol>
					</IonRow>
				) : (
					<>
						{!isMeetingBooked ? (
							<div hidden={(availableSlots?.length ?? 0) <= 0}>
								<IonRow>{availableDaysList}</IonRow>
								<IonRow>
									<IonCol size="12">
										<IonText>
											<h4 style={{ marginTop: '0px', marginBottom: '0px' }}>
												Time:
											</h4>
											time's are shown in{' '}
											{momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
										</IonText>
									</IonCol>
									{timeSlotItems}
								</IonRow>
								<IonRow hidden={!selectedTime}>
									<IonCol size="12">
										<IonText>
											<h4 style={{ marginTop: '0px', marginBottom: '0px' }}>
												With:
											</h4>
										</IonText>
									</IonCol>
									<IonCol size="12" hidden={!isLoadingHosts}>
										<IonSpinner name="dots"></IonSpinner>
									</IonCol>
									{availableContactsList}
								</IonRow>
								<IonRow>
									<IonCol size="12" style={{ fontStyle: 'italic' }}>
										<IonLabel hidden={!!selectedTime}>
											Please select a timeslot.
										</IonLabel>
										<IonLabel
											hidden={
												!selectedTime ||
												!availableContacts ||
												availableContacts.length <= 0
											}
										>
											Please select a who you would like to meet with.
										</IonLabel>
									</IonCol>
									<IonCol size="12">
										<IonButton
											disabled={!selectedTimeSlot || isLoadingHosts}
											onClick={() =>
												presentAlert({
													header: 'Request a Meeting',
													message:
														'Meet with: ' +
														currentExhibitor.name +
														'<br /> On: ' +
														moment(selectedTimeSlot?.start)
															.toDate()
															.toLocaleDateString() +
														'<br /> At: ' +
														moment(selectedTimeSlot?.start)
															.toDate()
															.toLocaleTimeString() + ' ' + momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr(),
													inputs: [
														{
															type: 'textarea',
															placeholder: 'Note for the exhibitor',
															label: 'Notes:',
															name: 'notes'
														}
													],
													buttons: [
														'Cancel',
														{
															text: 'Send',
															handler: e => {
																if (selectedTimeSlot) {
																	bookAppointmentSelected(
																		selectedTimeSlot,
																		e.notes
																	);
																}
															}
														}
													],

													onDidDismiss: e => console.log('did dismiss')
												})
											}
										>
											Book Meeting
										</IonButton>
									</IonCol>
								</IonRow>
							</div>
						) : (
							<IonRow
								hidden={
									!isMeetingBooked ||
									!availableSlots ||
									availableSlots.length <= 0 ||
									isLoading
								}
							>
								<IonCol size="12" sizeMd="12">
									<br /> <br /> <h2>{meetingRequestText}</h2>
									<h4>
										Status:
										<MeetingStatusBadge
											scheduleStatus={
												selectedTimeSlot?.status || ScheduleStatusType.Cancelled
											}
											scheduleContactStatus={
												selectedScheduleContact?.status ||
												ScheduleContactStatusType.Cancelled
											}
											style={{
												marginLeft: '10px'
											}}
										></MeetingStatusBadge>
									</h4>
									<span
										style={{
											fontSize: '16px',
											lineHeight: '24px'
										}}
									>
										Location: Booth {currentExhibitor.locations}
									</span>
									<br />{' '}
									<span
										style={{
											fontSize: '16px',
											lineHeight: '24px'
										}}
									>
										<IonIcon
											slot="start"
											style={{
												fontSize: '24px',
												color: '#0aa700',
												lineHeight: '36px'
											}}
											icon={timeOutline}
										></IonIcon>{' '}
										{moment(selectedTimeSlot?.start).format('LLLL') +
											' ' +
											momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
									</span>
									<br />{' '}
									<MeetingAddToCalendar
										event={currentEvent}
										schedule={selectedTimeSlot}
										attendeeContact={attendeeContact}
										exhibitorContact={currentExhibitor}
									></MeetingAddToCalendar>
								</IonCol>
								<IonCol
									size="12"
									sizeMd="12"
									style={{
										minHeight: '200px'
									}}
								>
									<br /> <br />{' '}
									<IonButton
										hidden={
											selectedScheduleContact?.status !==
												ScheduleContactStatusType.Accepted &&
											selectedScheduleContact?.status !==
												ScheduleContactStatusType.Pending &&
											selectedScheduleContact?.status !==
												ScheduleContactStatusType.Tentative
										}
										onClick={() =>
											presentAlert({
												cssClass: 'my-css',
												header: 'Cancel Meeting Request?',
												message:
													'Meeting with: ' +
													currentExhibitor.name +
													'<br /> On: ' +
													moment(selectedTimeSlot?.start)
														.toDate()
														.toLocaleDateString() +
													'<br /> At: ' +
													moment(selectedTimeSlot?.start)
														.toDate()
														.toLocaleTimeString(),
												buttons: [
													'No',
													{
														text: 'Yes',
														handler: e =>
															cancelSelectedAppointment(
																selectedTimeSlot,
																selectedScheduleContact
															)
													}
												],
												onDidDismiss: e => console.log('did dismiss')
											})
										}
										color="danger"
									>
										<IonIcon slot="end" icon={closeCircleOutline}></IonIcon>
										Cancel Meeting
									</IonButton>
								</IonCol>
							</IonRow>
						)}
					</>
				)}
			</IonGrid>
		</IonCard>
	);
};

export default memo(AppointmentBooking);
