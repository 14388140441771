import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { categoryConfig } from '../../../config/virtual/Category';
import { Category } from '../models';

/**
 * Category entity interface
 *
 * @export
 * @interface CategoryEntity
 * @extends {storage.Entity}
 */
export interface CategoryEntity extends storage.Entity, Category {}

/**
 * Category entities array
 *
 * @export
 */
export type CategoryEntities = CategoryEntity[];

/**
 * Some category entities: one entity or an array of entities
 *
 * @export
 */
export type CategoryEntity_Some = CategoryEntity | CategoryEntities;

/**
 * Category entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface CategoryEntityPatch
 * @extends {storage.EntityPatch<CategoryEntity>}
 */
export type CategoryEntityPatch = storage.EntityPatcher<CategoryEntity>;

/**
 * Category entity patches array
 *
 * @export
 */
export type CategoryEntitiesPatch = CategoryEntityPatch[];

/**
 * Some category entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type CategoryEntityPatch_Some =
	| CategoryEntityPatch
	| CategoryEntitiesPatch;

/**
 * Category entity id
 *
 * @export
 */
export type CategoryId = storage.EntityId;

/**
 * Category entity ids
 *
 * @export
 */
export type CategoryIds = storage.EntityIds;

/**
 * Some category entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type CategoryId_Some = CategoryId | CategoryIds;

/**
 * Category entities object by id
 *
 * @export
 * @interface CategoryEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface CategoryEntitiesObject extends storage.EntitiesObject {
	[id: string]: CategoryEntity;
}

/**
 * Category entity state
 *
 * @export
 * @interface CategoryEntityState
 * @extends {storage.EntityState}
 */
export interface CategoryEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Category store collection
 *
 * @export
 * @interface CategoryStoreCollection
 * @extends {storage.Store}
 */
export interface CategoryStoreCollection extends storage.Store {
	category: CategoryCollection;
}

/**
 * Category collection properties
 *
 * @export
 * @interface CategoryCollection
 * @extends {Collection}
 */
export interface CategoryCollection extends storage.Collection {
	byIds: CategoryEntitiesObject;
	mutation: CategoryEntitiesObject;
	cache: CategoryEntitiesObject;
	state: CategoryCollectionState;
}

/**
 * Category collection state
 *
 * @export
 * @interface CategoryCollectionState
 * @extends {storage.CollectionState}
 */
export interface CategoryCollectionState extends storage.CollectionState {}

// custom category collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const categoryCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const categoryCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const categoryCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const categoryCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: categoryCollectionIdStringProps,
	idArrayProps: categoryCollectionIdArrayProps,
	idEntityProps: categoryCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Category collection default values, initialize collection with category entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const categoryCollectionDefault: CategoryCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as CategoryCollection),
		...{
			activeId: categoryConfig.activeId || categoryConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	categoryConfig.entities
);

/**
 * Category store collection default values
 *
 * @export
 * @constant
 */
export const categoryStoreCollectionDefault: CategoryStoreCollection = {
	category: categoryCollectionDefault
};

/**
 * Category store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getCategoryCollection = (state: any) => state.virtual.category;
