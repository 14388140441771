import { RegistrationTypeEntities } from '../../virtual/store/collections/RegistrationType';

export interface RegistrationTypeConfig {
	entities: RegistrationTypeEntities;
	activeId?: string;
}

export const registrationTypeConfig: RegistrationTypeConfig = {
	entities: []
};
