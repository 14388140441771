import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { productCategoryConfig } from '../../../config/virtual/ProductCategory';
import { ProductCategory } from '../models';

/**
 * ProductCategory entity interface
 *
 * @export
 * @interface ProductCategoryEntity
 * @extends {storage.Entity}
 */
export interface ProductCategoryEntity extends storage.Entity, ProductCategory {}

/**
 * ProductCategory entities array
 *
 * @export
 */
export type ProductCategoryEntities = ProductCategoryEntity[];

/**
 * Some productCategory entities: one entity or an array of entities
 *
 * @export
 */
export type ProductCategoryEntity_Some =
	| ProductCategoryEntity
	| ProductCategoryEntities;

/**
 * ProductCategory entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ProductCategoryEntityPatch
 * @extends {storage.EntityPatch<ProductCategoryEntity>}
 */
export type ProductCategoryEntityPatch = storage.EntityPatcher<
	ProductCategoryEntity
>;

/**
 * ProductCategory entity patches array
 *
 * @export
 */
export type ProductCategoryEntitiesPatch = ProductCategoryEntityPatch[];

/**
 * Some productCategory entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ProductCategoryEntityPatch_Some =
	| ProductCategoryEntityPatch
	| ProductCategoryEntitiesPatch;

/**
 * ProductCategory entity id
 *
 * @export
 */
export type ProductCategoryId = storage.EntityId;

/**
 * ProductCategory entity ids
 *
 * @export
 */
export type ProductCategoryIds = storage.EntityIds;

/**
 * Some productCategory entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ProductCategoryId_Some = ProductCategoryId | ProductCategoryIds;

/**
 * ProductCategory entities object by id
 *
 * @export
 * @interface ProductCategoryEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ProductCategoryEntitiesObject extends storage.EntitiesObject {
	[id: string]: ProductCategoryEntity;
}

/**
 * ProductCategory entity state
 *
 * @export
 * @interface ProductCategoryEntityState
 * @extends {storage.EntityState}
 */
export interface ProductCategoryEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * ProductCategory store collection
 *
 * @export
 * @interface ProductCategoryStoreCollection
 * @extends {storage.Store}
 */
export interface ProductCategoryStoreCollection extends storage.Store {
	productCategory: ProductCategoryCollection;
}

/**
 * ProductCategory collection properties
 *
 * @export
 * @interface ProductCategoryCollection
 * @extends {Collection}
 */
export interface ProductCategoryCollection extends storage.Collection {
	byIds: ProductCategoryEntitiesObject;
	mutation: ProductCategoryEntitiesObject;
	cache: ProductCategoryEntitiesObject;
	state: ProductCategoryCollectionState;
}

/**
 * ProductCategory collection state
 *
 * @export
 * @interface ProductCategoryCollectionState
 * @extends {storage.CollectionState}
 */
export interface ProductCategoryCollectionState
	extends storage.CollectionState {}

// custom productCategory collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const productCategoryCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const productCategoryCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const productCategoryCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const productCategoryCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: productCategoryCollectionIdStringProps,
	idArrayProps: productCategoryCollectionIdArrayProps,
	idEntityProps: productCategoryCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * ProductCategory collection default values, initialize collection with productCategory entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const productCategoryCollectionDefault: ProductCategoryCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ProductCategoryCollection),
		...{
			activeId:
				productCategoryConfig.activeId || productCategoryConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	productCategoryConfig.entities
);

/**
 * ProductCategory store collection default values
 *
 * @export
 * @constant
 */
export const productCategoryStoreCollectionDefault: ProductCategoryStoreCollection = {
	productCategory: productCategoryCollectionDefault
};

/**
 * ProductCategory store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getProductCategoryCollection = (state: any) =>
	state.virtual.productCategory;
