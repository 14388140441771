import React from 'react';
import { useCtx } from '../../../config/hooks';
import { IonContent } from '@ionic/react';
const AboutView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const currentEvent = ctx.virtual.event.active();

	return (
		<IonContent>
			<iframe
				frameBorder="none"
				height="100%"
				width="100%"
				src={
					(currentEvent?.properties as any)?.app?.virtual?.topMenu?.aboutPage
						?.en?.url ?? 'https://www.conexsys.com/about'
				}
			></iframe>
		</IonContent>
	);
};

export default AboutView;
