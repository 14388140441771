// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of product either as a `registration`, `session` or `other` attendee option,
 * @export
 * @enum {string}
 */
export enum ProductType {
	Registration = 'registration',
	Session = 'session',
	Other = 'other'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesProductType = ['registration', 'session', 'other'];
