import React, { useEffect, useState } from 'react';

//UI
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonText,
	IonInput,
	IonTextarea,
	IonList,
	IonFooter,
	IonButton,
	IonSpinner,
	IonNote
} from '@ionic/react';

//DATA
import { useCtx } from '../../../config/hooks';

//LIB
import moment from 'moment';

export interface SendEmailFormValidation {
	to: {
		isValid: boolean;
		message: string;
	};
	from: {
		isValid: boolean;
		message: string;
	};
	subject: {
		isValid: boolean;
		message: string;
	};
	content: {
		isValid: boolean;
		message: string;
	};
}

export interface SendEmailProps {
	to?: string;
	from?: string;
	subject?: string;
	content?: string;
}

export const SendEmail: React.FC<SendEmailProps> = props => {
	const ctx = useCtx({}); // TO DO: create and set (config)
	const {
		config: [config]
	} = ctx;
	const currentEvent = ctx.virtual.event.active();
	const currentAttendee = ctx.virtual.attendee.active();

	const [to, setTo] = useState<string>('');
	const [from, setFrom] = useState<string>('');
	const [subject, setSubject] = useState<string>('');
	const [content, setContent] = useState<string>('');

	const [emailStatus, setEmailStatus] = useState<string>('');
	const [emailMessage, setEmailMessage] = useState<string>('');
	const [formValidation, setFormValidation] = useState<
		SendEmailFormValidation
	>();

	useEffect(() => {
		setTo(props.to ?? '');
		setFrom(props.from ?? '');
		setSubject(props.subject ?? '');
		setContent(props.content ?? '');

		//validateForm();
	}, []);

	const validateForm = () => {
		let isValid = true;

		let formVal: SendEmailFormValidation = {
			to: {
				isValid: true,
				message: ''
			},
			from: {
				isValid: true,
				message: ''
			},
			subject: {
				isValid: true,
				message: ''
			},
			content: {
				isValid: true,
				message: ''
			}
		};

		let regexp = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		if (!to || to.length <= 0) {
			formVal.to.isValid = false;
			formVal.to.message = 'e-mail is required';
			isValid = false;
		} else if (!regexp.test(to)) {
			formVal.to.isValid = false;
			formVal.to.message = 'e-mail is invalid';
			isValid = false;
		}
		if (!from || from.length <= 0) {
			formVal.from.isValid = false;
			formVal.from.message = 'e-mail is required';
			isValid = false;
		} else if (!regexp.test(from)) {
			formVal.from.isValid = false;
			formVal.from.message = 'e-mail is invalid';
			isValid = false;
		}

		if (!subject || subject.length <= 0) {
			formVal.subject.isValid = false;
			formVal.subject.message = 'subject is required';
			isValid = false;
		}

		if (!content || content.length <= 0) {
			formVal.content.isValid = false;
			formVal.content.message = 'content is required';
			isValid = false;
		}
		setFormValidation(formVal);

		return isValid;
	};

	const sendEmail = async (): Promise<any> => {
		setEmailStatus('sending');
		try {
			let cc = '';
			let bcc = '';
			const response = await ctx.app.api.sendEmail({
				from,
				to,
				cc,
				bcc,
				subject: subject,
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img width="650" src="https://myeventapp.conexsys.com/assets/img/MyMeetings2.png" />
          <br />
          <h3>
            ${currentEvent?.name}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
			<p>
              ${content}
			</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				setEmailStatus('error');
				setEmailMessage(`${response.status}: Email Send Invalid Response`);
			} else {
				setEmailStatus('sent');
				setEmailMessage(`Email was sent successfully`);
			}
		} catch (e) {
			console.error(e);
			setEmailStatus('error');
			setEmailMessage(`Error attempting to send email: ${e?.message || e}`);
		}
	};

	return emailStatus == 'sent' ? (
		<IonCard style={{ padding: '10%', minHeight: '180px' }}>
			<IonCardContent>
				<h1
					style={{
						fontFamily: "'Circular-AddEvent', Lato, 'Open Sans',sans-serif",
						fontSize: '28px',
						fontWeight: 700,
						lineHeight: '110%',
						color: '#1d3054'
					}}
				>
					Email Sent
				</h1>
				<br />{' '}
				<span style={{ fontSize: '16px', lineHeight: '24px' }}>
					Your email to {to} was sent successfully.
				</span>
			</IonCardContent>
		</IonCard>
	) : emailStatus == 'sending' ? (
		<IonCard style={{ padding: '10%', minHeight: '180px' }}>
			<IonCardContent>
				<IonSpinner name="dots"></IonSpinner>
			</IonCardContent>
		</IonCard>
	) : (
		<IonList>
			<IonItem>
				<IonLabel
					color={formValidation?.to.isValid ?? true ? 'dark' : 'danger'}
					position="stacked"
				>
					To
				</IonLabel>
				<IonInput
					color={formValidation?.to.isValid ?? true ? 'dark' : 'danger'}
					type="text"
					value={to}
					onIonInput={(e: any) => {
						setTo(e.target.value);
						validateForm();
					}}
					maxlength={255}
					readonly={true}
				></IonInput>
				<IonNote color={'danger'} hidden={formValidation?.to.isValid ?? true}>
					{formValidation?.to.message ?? ''}
				</IonNote>
			</IonItem>
			<IonItem>
				<IonLabel
					color={formValidation?.from.isValid ?? true ? 'dark' : 'danger'}
					position="stacked"
				>
					From
				</IonLabel>
				<IonInput
					color={formValidation?.from.isValid ?? true ? 'dark' : 'danger'}
					onIonInput={(e: any) => {
						setFrom(e.target.value);
						validateForm();
					}}
					type="text"
					value={from}
					maxlength={255}
					readonly={true}
				></IonInput>
				<IonNote color={'danger'} hidden={formValidation?.from.isValid ?? true}>
					{formValidation?.from.message ?? ''}
				</IonNote>
			</IonItem>
			<IonItem>
				<IonLabel
					color={formValidation?.subject.isValid ?? true ? 'dark' : 'danger'}
					position="stacked"
				>
					Subject
				</IonLabel>
				<IonInput
					color={formValidation?.subject.isValid ?? true ? 'dark' : 'danger'}
					onIonInput={(e: any) => {
						setSubject(e.target.value);
						validateForm();
					}}
					type="text"
					value={subject}
					maxlength={255}
				></IonInput>
				<IonNote
					color={'danger'}
					hidden={formValidation?.subject.isValid ?? true}
				>
					{formValidation?.subject.message ?? ''}
				</IonNote>
			</IonItem>
			<IonItem>
				<IonLabel
					color={formValidation?.content.isValid ?? true ? 'dark' : 'danger'}
					position="stacked"
				>
					Your Message
				</IonLabel>
				<IonTextarea
					color={formValidation?.content.isValid ?? true ? 'dark' : 'danger'}
					onIonInput={(e: any) => {
						setContent(e.target.value);
						validateForm();
					}}
					rows={8}
					maxlength={1000}
					value={content}
				></IonTextarea>
				<IonNote
					color={'danger'}
					hidden={formValidation?.content.isValid ?? true}
				>
					{formValidation?.content.message ?? ''}
				</IonNote>
			</IonItem>
			<IonItem>
				<IonButton
					size="default"
					onClick={() => {
						if (validateForm()) {
							sendEmail();
						}
					}}
				>
					Send Email
				</IonButton>
			</IonItem>
		</IonList>
	);
};
