import {
	ContactCollection,
	ContactCollectionState,
	ContactId,
	ContactEntity_Some,
	ContactEntityPatch_Some,
	ContactId_Some,
	contactCollectionDefault,
	contactCollectionMutateIdOpts
} from '../collections/Contact';
import * as actions from '../actions/Contact';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Contact reducer
 *
 * @export
 * @param {ContactCollection} [state=contactCollectionDefault]
 * @param {ContactActionTypes} action
 * @returns
 */
export function contactReducer(
	state: ContactCollection = contactCollectionDefault,
	action: actions.ContactActionTypes
) {
	switch (action.type) {
		case actions.CONTACT_MUTATE_ID:
			return collectionReducerActions.mutateId<ContactCollection>(
				state,
				action.payload,
				contactCollectionMutateIdOpts
			);
		case actions.CONTACT_SET:
			return collectionReducerActions.set<ContactCollection, ContactId>(
				state,
				action.payload
			);
		case actions.CONTACT_TOGGLE:
			return collectionReducerActions.toggle<ContactCollection, ContactId>(
				state,
				action.payload
			);
		case actions.CONTACT_SELECT:
			return collectionReducerActions.select<ContactCollection, ContactId_Some>(
				state,
				action.payload
			);
		case actions.CONTACT_DESELECT:
			return collectionReducerActions.deselect<
				ContactCollection,
				ContactId_Some
			>(state, action.payload);
		case actions.CONTACT_UPSERT:
			return collectionReducerActions.upsert<
				ContactCollection,
				ContactEntity_Some
			>(state, action.payload);
		case actions.CONTACT_PATCH:
			return collectionReducerActions.patch<
				ContactCollection,
				ContactEntityPatch_Some
			>(state, action.payload);
		case actions.CONTACT_CHANGE:
			return collectionReducerActions.change<
				ContactCollection,
				ContactEntityPatch_Some
			>(state, action.payload);
		case actions.CONTACT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ContactCollection,
				ContactId_Some
			>(state, action.payload);
		case actions.CONTACT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ContactCollection,
				ContactId_Some
			>(state, action.payload);
		case actions.CONTACT_DELETE:
			return collectionReducerActions.delete<ContactCollection, ContactId_Some>(
				state,
				action.payload
			);
		case actions.CONTACT_UNDELETE:
			return collectionReducerActions.undelete<
				ContactCollection,
				ContactId_Some
			>(state, action.payload);
		case actions.CONTACT_SETSTATE:
			return collectionReducerActions.setState<
				ContactCollection,
				ContactCollectionState
			>(state, action.payload.state);
		case actions.CONTACT_PATCHSTATE:
			return collectionReducerActions.patchState<
				ContactCollection,
				ContactCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
