import { AppStore, AppStoreDefault } from '../app/store';
import { VirtualStore, VirtualStoreDefault } from '../virtual/store';

/**
 * Stores state shape interface
 *
 * @export
 * @interface State
 */
export interface State {
	app: AppStore;
	virtual: VirtualStore;
}

/**
 * Stores state shape property map
 *
 * @export
 * @param {State} state
 */
export const mapStateToProps = (state: State) => {
	return {
		app: state.app,
		virtual: state.virtual
	};
};

/**
 * Default (initial) state of all stores
 *
 * @export
 * @constant
 */
export const initialState: State = {
	app: AppStoreDefault,
	virtual: VirtualStoreDefault
};
