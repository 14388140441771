import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { answerConfig } from '../../../config/virtual/Answer';
import { Answer } from '../models';

/**
 * Answer entity interface
 *
 * @export
 * @interface AnswerEntity
 * @extends {storage.Entity}
 */
export interface AnswerEntity extends storage.Entity, Answer {}

/**
 * Answer entities array
 *
 * @export
 */
export type AnswerEntities = AnswerEntity[];

/**
 * Some answer entities: one entity or an array of entities
 *
 * @export
 */
export type AnswerEntity_Some = AnswerEntity | AnswerEntities;

/**
 * Answer entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface AnswerEntityPatch
 * @extends {storage.EntityPatch<AnswerEntity>}
 */
export type AnswerEntityPatch = storage.EntityPatcher<AnswerEntity>;

/**
 * Answer entity patches array
 *
 * @export
 */
export type AnswerEntitiesPatch = AnswerEntityPatch[];

/**
 * Some answer entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type AnswerEntityPatch_Some = AnswerEntityPatch | AnswerEntitiesPatch;

/**
 * Answer entity id
 *
 * @export
 */
export type AnswerId = storage.EntityId;

/**
 * Answer entity ids
 *
 * @export
 */
export type AnswerIds = storage.EntityIds;

/**
 * Some answer entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type AnswerId_Some = AnswerId | AnswerIds;

/**
 * Answer entities object by id
 *
 * @export
 * @interface AnswerEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface AnswerEntitiesObject extends storage.EntitiesObject {
	[id: string]: AnswerEntity;
}

/**
 * Answer entity state
 *
 * @export
 * @interface AnswerEntityState
 * @extends {storage.EntityState}
 */
export interface AnswerEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Answer store collection
 *
 * @export
 * @interface AnswerStoreCollection
 * @extends {storage.Store}
 */
export interface AnswerStoreCollection extends storage.Store {
	answer: AnswerCollection;
}

/**
 * Answer collection properties
 *
 * @export
 * @interface AnswerCollection
 * @extends {Collection}
 */
export interface AnswerCollection extends storage.Collection {
	byIds: AnswerEntitiesObject;
	mutation: AnswerEntitiesObject;
	cache: AnswerEntitiesObject;
	state: AnswerCollectionState;
}

/**
 * Answer collection state
 *
 * @export
 * @interface AnswerCollectionState
 * @extends {storage.CollectionState}
 */
export interface AnswerCollectionState extends storage.CollectionState {}

// custom answer collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const answerCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const answerCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const answerCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const answerCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: answerCollectionIdStringProps,
	idArrayProps: answerCollectionIdArrayProps,
	idEntityProps: answerCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Answer collection default values, initialize collection with answer entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const answerCollectionDefault: AnswerCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as AnswerCollection),
		...{
			activeId: answerConfig.activeId || answerConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	answerConfig.entities
);

/**
 * Answer store collection default values
 *
 * @export
 * @constant
 */
export const answerStoreCollectionDefault: AnswerStoreCollection = {
	answer: answerCollectionDefault
};

/**
 * Answer store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getAnswerCollection = (state: any) => state.virtual.answer;
