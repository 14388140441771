import { WebinarEntities } from '../../virtual/store/collections/Webinar';

export interface WebinarConfig {
	entities: WebinarEntities;
	activeId?: string;
}

export const webinarConfig: WebinarConfig = {
	entities: []
};
