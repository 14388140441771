// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The registration status of the product will default to `open`, or your can explicitly set it to `soon` (Coming Soon), `open`, `closed` or `cancelled`.  This will control how the product is handled across all registration forms.
 * @export
 * @enum {string}
 */
export enum ProductRegistrationStatusType {
	Soon = 'soon',
	Open = 'open',
	Closed = 'closed',
	Cancelled = 'cancelled'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesProductRegistrationStatusType = [
	'soon',
	'open',
	'closed',
	'cancelled'
];
