import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getContactCollection,
	ContactId,
	ContactIds,
	ContactId_Some,
	ContactEntity,
	ContactEntities,
	ContactEntity_Some,
	ContactEntityPatch_Some,
	ContactCollection,
	ContactCollectionState,
	IContactActions,
	contactActions,
	ContactActionTypes
} from '..';
import {
	ContactApiOperation,
	readContactsById,
	ReadContactsByIdRequest,
	readContactsOrganizations,
	ReadContactsOrganizationsRequest
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactOrganizationEntity,
	isContactIndividualEntity,
	isContactOrganizationEntity,
	isContactOrganizationSponsorEntity
} from '../collections';
import { ContactIndividual, isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as contact from '../contacts/Contact';

/**
 * Contact helper interface
 *
 * @export
 * @interface IContactHelper
 * @extends {IEntityHelper}
 */
export interface IContactHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Contact helper options interface
 *
 * @export
 * @interface ContactHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ContactHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const contactHelperOpts: ContactHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Contact helper
 *
 * @export
 * @class ContactHelper
 * @extends {EntityHelper<ContactCollection, ContactActionTypes, ContactActions, ContactEntity, ContactEntities, ContactEntity_Some, ContactEntityPatch_Some, ContactId, ContactIds, ContactId_Some, ContactCollectionState, ContactHelperOpts>}
 * @implements {IContactHelper}
 */
export class ContactHelper
	extends EntityHelper<
		ContactCollection,
		ContactActionTypes,
		IContactActions,
		ContactEntity,
		ContactEntities,
		ContactEntity_Some,
		ContactEntityPatch_Some,
		ContactId,
		ContactIds,
		ContactId_Some,
		ContactCollectionState,
		ContactHelperOpts
	>
	implements IContactHelper {
	constructor() {
		super(
			useSelector(getContactCollection),
			contactActions,
			useDispatch(),
			contactHelperOpts
		);
		this.collection = useSelector(getContactCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event answers
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: ContactApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async readById(
		ctx: UseCtx<any>,
		params: Partial<ReadContactsByIdRequest> & { ids: string[] },
		callback?: any
	): Promise<ContactEntities> {
		if (!ctx.virtual.event.active()) return [];
		let request: ReadContactsByIdRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ContactEntities = await readContactsById(ctx, request)
			.then((entities: ContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readOrganizations(
		ctx: UseCtx<any>,
		params: Partial<ReadContactsOrganizationsRequest> = {},
		callback?: any
	): Promise<ContactEntities> {
		if (!ctx.virtual.event.active()) return [];
		let request: ReadContactsOrganizationsRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: ContactEntities = await readContactsOrganizations(
			ctx,
			request
		)
			.then((entities: ContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(eventId: string): ContactEntities {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(c => c.__state?.eventId === eventId);
	}

	all_Individual(eventId: string): ContactIndividual[] {
		return this.all_Active(eventId).filter(isContactIndividualEntity);
	}
	get_Individual(id: string, eventId: string): ContactIndividual | undefined {
		return this.all_Active(eventId)
			.filter(isContactIndividualEntity)
			.find(c => c.id === id);
	}

	all_Exhibitor(eventId: string): ContactOrganizationEntity[] {
		return this.all_Active(eventId).filter(isContactOrganizationEntity);
	}
	get_Exhibitor(
		id: string,
		eventId: string
	): ContactOrganizationEntity | undefined {
		return this.all_Active(eventId)
			.filter(isContactOrganizationEntity)
			.find(c => c.id === id);
	}

	get_ExhibitorCategories(eventId: string): string[] {
		let keyWords: string[] = [];

		this.all_Active(eventId)
			.filter(isContactOrganizationEntity)
			.forEach(e => {
				e.keywords?.forEach(kw => {
					if (kw.trim() !== '' && !keyWords.includes(kw.trim().toLowerCase())) {
						keyWords.push(kw.trim().toLowerCase());
					}
				});
			});

		return keyWords;
	}

	all_Sponsor(eventId: string): ContactOrganizationEntity[] {
		return this.all_Active(eventId).filter(isContactOrganizationSponsorEntity);
	}
	get_Sponsor(
		id: string,
		eventId: string
	): ContactOrganizationEntity | undefined {
		return this.all_Active(eventId)
			.filter(isContactOrganizationSponsorEntity)
			.find(c => c.id === id);
	}

	all_Speaker(ctx: UseCtx<any>, eventId: string): ContactIndividual[] {
		return this.all_Active(eventId)
			.filter(isContactIndividualEntity)
			.filter(
				p =>
					ctx.virtual.productContact.all_ProductId_By_SpeakerId(p.id)?.length ??
					0 > 0
			);
	}
	get_Speaker(id: string, eventId: string): ContactIndividual | undefined {
		return this.all_Active(eventId)
			.filter(isContactIndividualEntity)
			.find(c => c.id === id);
	}
}
