import React, { useEffect, useState, memo } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonCard,
	IonLabel,
	IonContent,
	IonButton,
	IonText,
	useIonAlert,
	IonSpinner,
	IonItem
} from '@ionic/react';
import {
	closeCircleOutline,
	timeOutline,
	checkmarkCircleOutline,
	calendarOutline,
	checkmarkCircle
} from 'ionicons/icons';
import { MeetingAddToCalendar } from '../components/MeetingAddToCalendar';

import { Avatar } from '../../../app/ui/components/Avatar';
import moment from 'moment';
import momenttimezone from 'moment-timezone';

import {
	ObjectType,
	ScheduleContactRoleType,
	ScheduleContactStatusType,
	ScheduleContactEntity,
	ScheduleEntities,
	ScheduleEntity,
	ScheduleStatusType,
	ContactIndividualEntity,
	ScheduleContactEntities,
	ScheduleType,
	ContactEntities,
	ContactOrganization
} from '../../store';
import uuid from '../../../app/utils/uuid';
import { newEntity } from '../../../app/utils';
import { MeetingStatusBadge } from '../components/MeetingStatusBadge';

export interface AppointmentDetailsProps {
	schedual: ScheduleEntity | undefined;
	attendeeId: string;
}

/*********************************************************/
/* AppointmentDetails */
/*********************************************************/
const AppointmentDetails: React.FC<AppointmentDetailsProps> = props => {
	const ctx = useCtx<{}>({});

	/**********************************************************/
	/* State Objects */
	/*********************************************************/
	const [presentAlert] = useIonAlert();

	const [currentMeeting, setCurrentMeeting] = useState<
		ScheduleEntity | undefined
	>();
	const [
		currentMeetingScheduleContacts,
		setCurrentMeetingScheduleContacts
	] = useState<ScheduleContactEntities | undefined>();

	const [currentMeetingOwner, setCurrentMeetingOwner] = useState<
		ContactOrganization | undefined
	>();

	const [
		currentMeetingAttendeeScheduleContact,
		setCurrentMeetingAttendeeScheduleContact
	] = useState<ScheduleContactEntity | undefined>();
	const [currentMeetingAttendee, setCurrentMeetingAttendee] = useState<
		ContactIndividualEntity | undefined
	>();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const currentEvent = ctx.virtual.event.active();

	/*const currentAttendee = ctx.virtual.attendee.get(props?.attendeeId || '');
	const attendeeContact:
		| ContactIndividualEntity
		| undefined = ctx.virtual.contact.get_Individual(
		currentAttendee?.contactId || '',
		currentEvent?.id || ''
	);*/

	/*********************************************************/
	/* useEffect */
	/*********************************************************/

	useEffect(() => {
		setIsLoading(true);
		let event = ctx.virtual.event.active();
		let attendee = ctx.virtual.attendee.active();

		if (event && attendee) {
			//read the meeting details
			ctx.virtual.schedule.read(
				ctx,
				{
					ids: [props.schedual?.id ?? ''],
					type: ScheduleType.Meeting
				},
				(sEntities: ScheduleEntities) => {
					if (sEntities && sEntities.length > 0) {
						//read all contacts from the meeting
						for (let x = 0; x < sEntities.length; x++) {
							ctx.virtual.scheduleContact.read(
								ctx,
								{
									scheduleId: sEntities[x].id
								},
								(scEntities: ScheduleContactEntities) => {
									if (scEntities && scEntities.length > 0) {
										ctx.virtual.contact.readById(
											ctx,
											{
												ids: scEntities.map(obj => obj.contactId)
											},
											(contactEntities: ContactEntities) => {}
										);
									}
								}
							);
						}
						setIsLoading(false);
					} else {
						setIsLoading(false);
					}
				}
			);
		} else {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		//Set Current Meeting Details
		setCurrentMeeting(ctx.virtual.schedule.get(props.schedual?.id ?? ''));
		setCurrentMeetingScheduleContacts(
			ctx.virtual.scheduleContact.all_ScheduleParticipants([
				props.schedual?.id ?? ''
			])
		);

		//Set Meeting attendee
		let attendeeScheduleContact =
			ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[props.schedual?.id ?? ''],
				[ScheduleContactRoleType.Attendee],
				[
					ScheduleContactStatusType.Accepted,
					ScheduleContactStatusType.Pending,
					ScheduleContactStatusType.Tentative
				]
			)[0] ?? undefined;

		setCurrentMeetingAttendeeScheduleContact(attendeeScheduleContact);
		if (attendeeScheduleContact) {
			setCurrentMeetingAttendee(
				ctx.virtual.contact.get_Individual(
					attendeeScheduleContact.contactId,
					currentEvent?.id || ''
				)
			);
		}

		//Set Meeting Owner
		let ownerScheduleContact =
			ctx.virtual.scheduleContact.all_ScheduleParticipants(
				[props.schedual?.id ?? ''],
				[ScheduleContactRoleType.Organizer]
			)[0] ?? undefined;

		if (ownerScheduleContact) {
			setCurrentMeetingOwner(
				ctx.virtual.contact.get_Exhibitor(
					ownerScheduleContact.contactId,
					currentEvent?.id || ''
				)
			);
		}
	}, [
		ctx.virtual.schedule.all().length,
		ctx.virtual.scheduleContact.all().length
	]);

	if (!currentMeeting) {
		return (
			<IonContent>
				<IonLabel>Meeting Not Found</IonLabel>
			</IonContent>
		);
	}

	/*********************************************************/
	/* Action Methods
	/*********************************************************/

	const cancelSelectedAppointment = function (
		schedule: ScheduleEntity | undefined,
		scheduleContact: ScheduleContactEntity | undefined
	) {
		if (schedule && scheduleContact) {
			//Add contact to schedual
			scheduleContact.status = ScheduleContactStatusType.Cancelled;
			ctx.virtual.scheduleContact.write(ctx, {
				scheduleContacts: [scheduleContact]
			});

			//update timeslot/schedule item
			schedule.status = ScheduleStatusType.Available;
			schedule.name = '';
			ctx.virtual.schedule.write(ctx, {
				schedules: [schedule]
			});

			if (currentEvent && currentMeetingAttendee && currentMeetingOwner) {
				ctx.virtual.scheduleContact.sendMeetingCancellationEmail(
					ctx,
					currentEvent,
					schedule,
					scheduleContact,
					currentMeetingAttendee,
					currentMeetingOwner,
					currentMeetingOwner?.contactEmail ?? ''
				);
			}
		}
	};

	const acceptSelectedAppointment = function (
		schedule: ScheduleEntity | undefined,
		scheduleContact: ScheduleContactEntity | undefined
	) {
		if (schedule && scheduleContact) {
			//Add contact to schedual
			scheduleContact.status = ScheduleContactStatusType.Accepted;
			ctx.virtual.scheduleContact.write(ctx, {
				scheduleContacts: [scheduleContact]
			});

			//update timeslot/schedule item
			schedule.status = ScheduleStatusType.Confirmed;
			schedule.name = '';
			ctx.virtual.schedule.write(ctx, {
				schedules: [schedule]
			});

			if (currentEvent && currentMeetingAttendee && currentMeetingOwner) {
				ctx.virtual.scheduleContact.sendMeetingApprovedEmail(
					ctx,
					currentEvent,
					schedule,
					scheduleContact,
					currentMeetingAttendee,
					currentMeetingOwner,
					currentMeetingOwner?.contactEmail ?? ''
				);
			}
		}
	};

	const declineSelectedAppointment = function (
		schedule: ScheduleEntity | undefined,
		scheduleContact: ScheduleContactEntity | undefined
	) {
		if (schedule && scheduleContact) {
			//Add contact to schedual
			scheduleContact.status = ScheduleContactStatusType.Declined;
			ctx.virtual.scheduleContact.write(ctx, {
				scheduleContacts: [scheduleContact]
			});

			//update timeslot/schedule item
			schedule.status = ScheduleStatusType.Available;
			schedule.name = '';
			ctx.virtual.schedule.write(ctx, {
				schedules: [schedule]
			});

			if (currentEvent && currentMeetingAttendee && currentMeetingOwner) {
				ctx.virtual.scheduleContact.sendMeetingDeclinedEmail(
					ctx,
					currentEvent,
					schedule,
					scheduleContact,
					currentMeetingAttendee,
					currentMeetingOwner,
					currentMeetingOwner?.contactEmail ?? ''
				);
			}
		}
	};

	/**********************************************************/
	/* Static Objects */
	/*********************************************************/

	/*let logoObj = currentExhibitor.logo
		? JSON.parse(currentExhibitor.logo)
		: undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;*/

	const meetingRequestText: string =
		currentMeeting?.status === ScheduleStatusType.Requested ||
		currentMeeting?.status === ScheduleStatusType.Pending
			? 'Meeting Request Sent. Pending Approval'
			: currentMeeting?.status === ScheduleStatusType.Declined
			? 'Meeting Request Declined'
			: currentMeeting?.status === ScheduleStatusType.Cancelled
			? 'Meeting Request Cancelled'
			: currentMeeting?.status === ScheduleStatusType.Available
			? 'No Meeting Booked'
			: 'Meeting Scheduled';

	return (
		<IonCard>
			<IonGrid>
				{isLoading ? (
					<IonRow>
						<IonCol offset="2" size="8" sizeMd="8">
							<IonSpinner name="dots"></IonSpinner>
						</IonCol>
					</IonRow>
				) : (
					<IonRow>
						<IonCol size="12" sizeMd="12">
							<br /> <br /> <h2>{meetingRequestText}</h2>
							<h4>
								Status:
								<MeetingStatusBadge
									scheduleStatus={
										currentMeeting?.status || ScheduleStatusType.Cancelled
									}
									scheduleContactStatus={ScheduleContactStatusType.Cancelled}
									style={{
										marginLeft: '10px'
									}}
								></MeetingStatusBadge>
							</h4>
							<span
								style={{
									fontSize: '16px',
									lineHeight: '24px'
								}}
							>
								Location: Booth
							</span>
							<br />{' '}
							<span
								style={{
									fontSize: '16px',
									lineHeight: '24px'
								}}
							>
								<IonIcon
									slot="start"
									style={{
										fontSize: '24px',
										color: '#0aa700',
										lineHeight: '36px'
									}}
									icon={timeOutline}
								></IonIcon>{' '}
								{moment(currentMeeting?.start).format('LLLL') +
									' ' +
									momenttimezone.tz(momenttimezone.tz.guess()).zoneAbbr()}
							</span>
							<br />{' '}
							<MeetingAddToCalendar
								event={currentEvent}
								schedule={currentMeeting}
								attendeeContact={currentMeetingAttendee}
								exhibitorContact={undefined}
							></MeetingAddToCalendar>
						</IonCol>
						<IonCol
							size="12"
							sizeMd="12"
							style={{
								minHeight: '200px'
							}}
						>
							<br /> <br />{' '}
							<IonButton
								hidden={currentMeeting?.status !== ScheduleStatusType.Confirmed}
								onClick={() =>
									presentAlert({
										cssClass: 'my-css',
										header: 'Cancel Meeting Request?',
										message:
											'Meeting with: ' +
											currentMeetingAttendee?.name +
											'<br /> On: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleDateString() +
											'<br /> At: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleTimeString(),
										buttons: [
											'No',
											{
												text: 'Yes',
												handler: e =>
													cancelSelectedAppointment(
														currentMeeting,
														currentMeetingAttendeeScheduleContact
													)
											}
										],
										onDidDismiss: e => console.log('did dismiss')
									})
								}
								color="danger"
							>
								<IonIcon slot="end" icon={closeCircleOutline}></IonIcon>
								Cancel Meeting
							</IonButton>
							<IonButton
								hidden={
									currentMeeting?.status != ScheduleStatusType.Pending &&
									currentMeeting?.status != ScheduleStatusType.Requested
								}
								onClick={() =>
									presentAlert({
										cssClass: 'my-css',
										header: 'Confirm Meeting Request?',
										message:
											'Meeting with: ' +
											currentMeetingAttendee?.name +
											'<br /> On: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleDateString() +
											'<br /> At: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleTimeString(),
										buttons: [
											'No',
											{
												text: 'Yes',
												handler: e =>
													acceptSelectedAppointment(
														currentMeeting,
														currentMeetingAttendeeScheduleContact
													)
											}
										],
										onDidDismiss: e => console.log('did dismiss')
									})
								}
								color="success"
							>
								<IonIcon slot="end" icon={checkmarkCircle}></IonIcon>
								Confirm Meeting
							</IonButton>
							<IonButton
								hidden={
									currentMeeting?.status != ScheduleStatusType.Pending &&
									currentMeeting?.status != ScheduleStatusType.Requested
								}
								onClick={() =>
									presentAlert({
										cssClass: 'my-css',
										header: 'Decline Meeting Request?',
										message:
											'Decline Meeting with: ' +
											currentMeetingAttendee?.name +
											'<br /> On: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleDateString() +
											'<br /> At: ' +
											moment(currentMeeting?.start)
												.toDate()
												.toLocaleTimeString(),
										buttons: [
											'No',
											{
												text: 'Yes',
												handler: e =>
													declineSelectedAppointment(
														currentMeeting,
														currentMeetingAttendeeScheduleContact
													)
											}
										],
										onDidDismiss: e => console.log('did dismiss')
									})
								}
								color="danger"
							>
								<IonIcon slot="end" icon={closeCircleOutline}></IonIcon>
								Decline Meeting
							</IonButton>
						</IonCol>
					</IonRow>
				)}
			</IonGrid>
		</IonCard>
	);
};

export default memo(AppointmentDetails);
