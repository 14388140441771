import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import {
	ScheduleContactEntity,
	ScheduleContactStatusType,
	ScheduleEntity
} from '../../store';

import { checkmarkCircleOutline, calendarOutline } from 'ionicons/icons';
import moment from 'moment';

export interface ScheduleMeetingButtonProps {
	hidden: boolean;
	schedule: ScheduleEntity | undefined;
	scheduleContact: ScheduleContactEntity | undefined;
	style?: { [key: string]: any };
	size?: 'small' | 'default' | 'large' | undefined;
	onBtnClick?: () => void;
}

export const ScheduleMeetingButton: React.FC<ScheduleMeetingButtonProps> = props => {
	const color: string =
		props.scheduleContact?.status === ScheduleContactStatusType.Pending ||
		props.scheduleContact?.status === ScheduleContactStatusType.Tentative
			? 'warning'
			: props.scheduleContact?.status === ScheduleContactStatusType.Declined ||
			  props.scheduleContact?.status === ScheduleContactStatusType.Rejected ||
			  props.scheduleContact?.status === ScheduleContactStatusType.Cancelled
			? 'danger'
			: props.scheduleContact?.status === ScheduleContactStatusType.Accepted
			? 'success'
			: 'primary';
	const text: string =
		props.scheduleContact?.status === ScheduleContactStatusType.Pending ||
		props.scheduleContact?.status === ScheduleContactStatusType.Tentative
			? 'is Pending Approval'
			: props.scheduleContact?.status === ScheduleContactStatusType.Declined ||
			  props.scheduleContact?.status === ScheduleContactStatusType.Rejected
			? 'is Declined'
			: props.scheduleContact?.status === ScheduleContactStatusType.Cancelled
			? 'is Cancelled'
			: props.scheduleContact?.status === ScheduleContactStatusType.Accepted
			? 'is Scheduled'
			: 'Schedule Meeting';

	const style: { [key: string]: any } = props.style ?? {
		marginRight: '3px'
	};

	const meetingButtonClicked = () => {
		if (props.onBtnClick !== undefined) {
			props.onBtnClick();
		}
	};
	const meetingDate: string = moment(props.schedule?.start).format('LLL');

	const size: 'small' | 'default' | 'large' | undefined =
		props.size ?? 'default';

	return (
		<IonButton
			size={size}
			hidden={props.hidden}
			onClick={meetingButtonClicked}
			color={color}
			style={style}
		>
			<IonIcon slot="start" src={calendarOutline}></IonIcon>
			<IonText hidden={!props.scheduleContact}>
				meeting on: {meetingDate}
				<br />
				{text}
			</IonText>
			<IonText hidden={props.scheduleContact !== undefined}>{text}</IonText>
			<IonIcon
				hidden={
					!props.scheduleContact ||
					props.scheduleContact.status !== ScheduleContactStatusType.Accepted
				}
				slot="end"
				src={checkmarkCircleOutline}
			></IonIcon>
		</IonButton>
	);
};
