import {
	UserCollection,
	UserCollectionState,
	UserId,
	UserEntity_Some,
	UserEntityPatch_Some,
	UserId_Some,
	userCollectionDefault,
	userCollectionMutateIdOpts
} from '../collections/User';
import * as actions from '../actions/User';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * User reducer
 *
 * @export
 * @param {UserCollection} [state=userCollectionDefault]
 * @param {UserActionTypes} action
 * @returns
 */
export function userReducer(
	state: UserCollection = userCollectionDefault,
	action: actions.UserActionTypes
) {
	switch (action.type) {
		case actions.USER_MUTATE_ID:
			return collectionReducerActions.mutateId<UserCollection>(
				state,
				action.payload,
				userCollectionMutateIdOpts
			);
		case actions.USER_SET:
			return collectionReducerActions.set<UserCollection, UserId>(
				state,
				action.payload
			);
		case actions.USER_TOGGLE:
			return collectionReducerActions.toggle<UserCollection, UserId>(
				state,
				action.payload
			);
		case actions.USER_SELECT:
			return collectionReducerActions.select<UserCollection, UserId_Some>(
				state,
				action.payload
			);
		case actions.USER_DESELECT:
			return collectionReducerActions.deselect<UserCollection, UserId_Some>(
				state,
				action.payload
			);
		case actions.USER_UPSERT:
			return collectionReducerActions.upsert<UserCollection, UserEntity_Some>(
				state,
				action.payload
			);
		case actions.USER_PATCH:
			return collectionReducerActions.patch<
				UserCollection,
				UserEntityPatch_Some
			>(state, action.payload);
		case actions.USER_CHANGE:
			return collectionReducerActions.change<
				UserCollection,
				UserEntityPatch_Some
			>(state, action.payload);
		case actions.USER_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<UserCollection, UserId_Some>(
				state,
				action.payload
			);
		case actions.USER_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				UserCollection,
				UserId_Some
			>(state, action.payload);
		case actions.USER_DELETE:
			return collectionReducerActions.delete<UserCollection, UserId_Some>(
				state,
				action.payload
			);
		case actions.USER_UNDELETE:
			return collectionReducerActions.undelete<UserCollection, UserId_Some>(
				state,
				action.payload
			);
		case actions.USER_SETSTATE:
			return collectionReducerActions.setState<
				UserCollection,
				UserCollectionState
			>(state, action.payload.state);
		case actions.USER_PATCHSTATE:
			return collectionReducerActions.patchState<
				UserCollection,
				UserCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
