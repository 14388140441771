import { AttendeeEntities } from '../../virtual/store/collections/Attendee';

export interface AttendeeConfig {
	security: {
		default: string[];
	};

	entities: AttendeeEntities;
	activeId?: string;
}

export const attendeeConfig: AttendeeConfig = {
	security: {
		default: ['attendee']
	},
	entities: []
};
