import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getCategoryCollection,
	CategoryId,
	CategoryIds,
	CategoryId_Some,
	CategoryEntity,
	CategoryEntities,
	CategoryEntity_Some,
	CategoryEntityPatch_Some,
	CategoryCollection,
	CategoryCollectionState,
	ICategoryActions,
	categoryActions,
	CategoryActionTypes
} from '..';
import {
	readCategories,
	ReadCategoriesRequest,
	CategoryApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive } from '../models';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as category from '../categories/Category';

/**
 * Category helper interface
 *
 * @export
 * @interface ICategoryHelper
 * @extends {IEntityHelper}
 */
export interface ICategoryHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Category helper options interface
 *
 * @export
 * @interface CategoryHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface CategoryHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const categoryHelperOpts: CategoryHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Category helper
 *
 * @export
 * @class CategoryHelper
 * @extends {EntityHelper<CategoryCollection, CategoryActionTypes, CategoryActions, CategoryEntity, CategoryEntities, CategoryEntity_Some, CategoryEntityPatch_Some, CategoryId, CategoryIds, CategoryId_Some, CategoryCollectionState, CategoryHelperOpts>}
 * @implements {ICategoryHelper}
 */
export class CategoryHelper
	extends EntityHelper<
		CategoryCollection,
		CategoryActionTypes,
		ICategoryActions,
		CategoryEntity,
		CategoryEntities,
		CategoryEntity_Some,
		CategoryEntityPatch_Some,
		CategoryId,
		CategoryIds,
		CategoryId_Some,
		CategoryCollectionState,
		CategoryHelperOpts
	>
	implements ICategoryHelper {
	constructor() {
		super(
			useSelector(getCategoryCollection),
			categoryActions,
			useDispatch(),
			categoryHelperOpts
		);
		this.collection = useSelector(getCategoryCollection);
		this.dispatch = useDispatch();
	}

	// delete / undelete entities that match the event
	cache(eventId?: string) {
		// delete non-event entities
		this.deleteFilter(entity => entity.__state?.eventId !== eventId);

		// undelete event entities
		this.undeleteFilter(entity => entity.__state?.eventId === eventId);
	}

	lastSuccess(operation: CategoryApiOperation) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operation]?.success?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operation]?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadCategoriesRequest> = {},
		callback?: any
	): Promise<CategoryEntities> {
		if (!ctx.virtual.event.active()) return [];
		params.modifiedFrom =
			params.modifiedFrom ||
			this.lastSuccess(CategoryApiOperation.readCategories);
		let request: ReadCategoriesRequest = {
			...params,
			...{
				eventId: ctx.virtual.event.active()?.id || ''
			}
		};
		if (request.eventId === '') return [];

		let entities: CategoryEntities = await readCategories(ctx, request)
			.then((entities: CategoryEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Active(): CategoryEntities {
		return this.all().filter(isObjectStatusActive);
	}

	all_By_Product(ctx: UseCtx<any>, productId: string): CategoryEntities {
		let productCategories = ctx.virtual.productCategory
			.all_By_ProductId(productId)
			?.map(x => x.categoryId);
		if (productCategories && productCategories.length > 0) {
			return this.all_Active().filter(w => productCategories.includes(w.id));
		} else {
			return [];
		}
	}
}
