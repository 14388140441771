import React, { useState } from 'react';
import {
	IonIcon,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonMenuButton,
	IonTitle,
	IonPopover,
	IonItem,
	IonLabel
} from '@ionic/react';

//Data
import { UserAvatar, UserMenu } from '../components';
import { useLocaleConfig } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import * as icons from 'ionicons/icons';
import { headerConfig } from '../../../config/app/components/Header';

export interface HeaderConfigProps {
	signIn: string;
}

export interface HeaderProps {
	title?: string;
	disabled?: boolean;
	hidden?: boolean;
	currentRoute?: string;
}

export const Header: React.FC<HeaderProps> = props => {
	const {
		config: [config],
		activeUser
	} = useLocaleConfig<HeaderConfigProps>(headerConfig);
	const ctx = useCtx<{}>({});

	const currentEvent = ctx.virtual.event.active();

	const login = () => {
		window.location.href = '/main';
	};

	const [showUserControlPopover, setShowUserControlPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	let logoObj =
		(currentEvent?.properties as any)?.app?.virtual?.mainMenu?.branding?.logo &&
		(currentEvent?.properties as any)?.app?.virtual?.mainMenu?.branding
			?.logo !== ''
			? JSON.parse(
					(currentEvent?.properties as any)?.app?.virtual?.mainMenu.branding
						.logo
			  )
			: undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;

	let pageTitle = props.title;
	if (props.currentRoute?.includes('home')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.home.en.title ??
			props.title;
	} else if (props.currentRoute?.includes('agenda')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.agenda.en
				.title ?? props.title;
	} else if (props.currentRoute?.includes('event-schedule')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.schedule.en
				.title ?? props.title;
	} else if (props.currentRoute?.includes('exhibit-hall')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.exhibitHall.en
				.title ?? props.title;
	} else if (props.currentRoute?.includes('exhibitor')) {
		if (currentEvent?.id === '01967ad4-db73-476d-a0f1-024e07a583ff') { //TODO: Remove hardcoded event id
			pageTitle = "Treatment"
		}
	} else if (props.currentRoute?.includes('speakers')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.speakers.en
				.title ?? props.title;
	} else if (props.currentRoute?.includes('sponsors')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.sponsorShowcase
				.en.title ?? props.title;
	} else if (props.currentRoute?.includes('meetings')) {
		pageTitle =
			(currentEvent?.properties as any)?.app?.virtual?.mainMenu.meetings?.en
				.title ?? props.title;
	}

	return props.disabled !== true ? (
		<IonHeader>
			{!activeUser ? (
				<IonToolbar>
					<IonButtons
						class="ion-justify-content-start"
						style={{ margin: '0px' }}
						slot="start"
					>
						<img
							hidden={!logoPath}
							style={{ height: '75px' }}
							src={logoPath ?? ''}
						></img>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{props.title}</IonTitle>
					<IonButtons slot="end">
						<IonButton
							hidden={
								!(
									(currentEvent?.properties as any)?.app?.virtual?.topMenu
										?.registerNow.visible ?? false
								)
							}
							onClick={() => {
								window.open(
									(currentEvent?.properties as any)?.app?.virtual?.topMenu
										?.registerNow.en.url ?? '',
									'_blank'
								);
							}}
						>
							<IonLabel>
								{(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.registerNow.en.title ?? 'Register Now'}
							</IonLabel>
						</IonButton>
						<IonButton hidden={true} onClick={() => login()}>
							<IonIcon
								className="ion-hide-sm-down"
								slot="end"
								icon={icons.logInOutline}
							/>
							<IonLabel>{config.signIn}</IonLabel>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			) : (
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{pageTitle}</IonTitle>

					<IonButtons slot="end">
						<IonButton
							class="ion-hide-sm-down"
							hidden={
								!(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.aboutPage?.visible ?? false
							}
							routerLink={
								((currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.aboutPage?.type || 'internal') === 'internal'
									? '/about'
									: undefined
							}
							onClick={() => {
								if (
									(currentEvent?.properties as any)?.app?.virtual?.topMenu
										?.aboutPage?.type === 'external'
								) {
									window.open(
										(currentEvent?.properties as any)?.app?.virtual?.topMenu
											?.aboutPage?.en?.url ?? 'https://www.conexsys.com/about',
										'_blank'
									);
								}
							}}
						>
							<IonLabel>
								{(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.aboutPage?.en?.title ?? 'About'}
							</IonLabel>
						</IonButton>
						<IonButton
							class="ion-hide-sm-down"
							hidden={
								!(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.FAQPage?.visible ?? false
							}
							routerLink={
								((currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.FAQPage?.type || 'internal') === 'internal'
									? '/faq'
									: undefined
							}
							onClick={() => {
								if (
									(currentEvent?.properties as any)?.app?.virtual?.topMenu
										?.FAQPage?.type === 'external'
								) {
									window.open(
										(currentEvent?.properties as any)?.app?.virtual?.topMenu
											?.FAQPage?.en?.url ?? 'https://www.conexsys.com/about',
										'_blank'
									);
								}
							}}
						>
							<IonLabel>
								{(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.FAQPage?.en?.title ?? 'FAQ'}
							</IonLabel>
						</IonButton>
						<IonButton
							class="ion-hide-sm-down"
							hidden={
								!(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.contactUsPage?.visible ?? false
							}
							routerLink={
								((currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.contactUsPage?.type || 'internal') === 'internal'
									? '/contact-us'
									: undefined
							}
							onClick={() => {
								if (
									(currentEvent?.properties as any)?.app?.virtual?.topMenu
										?.contactUsPage?.type === 'external'
								) {
									window.open(
										(currentEvent?.properties as any)?.app?.virtual?.topMenu
											?.contactUsPage?.en?.url ??
											'https://www.conexsys.com/about',
										'_blank'
									);
								}
							}}
						>
							<IonLabel>
								{(currentEvent?.properties as any)?.app?.virtual?.topMenu
									?.contactUsPage?.en?.title ?? 'Contact Us'}
							</IonLabel>
						</IonButton>
						<IonItem
							detail={false}
							button={true}
							lines="none"
							onClick={(e: any) =>
								setShowUserControlPopover({
									open: true,
									event: e.nativeEvent
								})
							}
						>
							<UserAvatar user={activeUser} />
						</IonItem>
					</IonButtons>
					<IonPopover
						key="user-menu-popover"
						isOpen={showUserControlPopover.open}
						event={showUserControlPopover.event}
						showBackdrop={false}
						onDidDismiss={() =>
							setShowUserControlPopover({ open: false, event: undefined })
						}
					>
						<UserMenu
							key="user-menu-item"
							finish={() =>
								setShowUserControlPopover({ open: false, event: undefined })
							}
						/>
					</IonPopover>
				</IonToolbar>
			)}
		</IonHeader>
	) : (
		<></>
	);
};
