import {
	CategoryId_Some,
	CategoryEntity_Some,
	CategoryEntityPatch_Some,
	CategoryCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const CATEGORY_MUTATE_ID = '@app/CATEGORY_MUTATE_ID';
export const CATEGORY_SET = '@app/CATEGORY_SET';
export const CATEGORY_TOGGLE = '@app/CATEGORY_TOGGLE';
export const CATEGORY_SELECT = '@app/CATEGORY_SELECT';
export const CATEGORY_DESELECT = '@app/CATEGORY_DESELECT';
export const CATEGORY_UPSERT = '@app/CATEGORY_UPSERT';
export const CATEGORY_PATCH = '@app/CATEGORY_PATCH';
export const CATEGORY_CHANGE = '@app/CATEGORY_CHANGE';
export const CATEGORY_APPLY_CHANGES = '@app/CATEGORY_APPLY_CHANGES';
export const CATEGORY_CANCEL_CHANGES = '@app/CATEGORY_CANCEL_CHANGES';
export const CATEGORY_DELETE = '@app/CATEGORY_DELETE';
export const CATEGORY_UNDELETE = '@app/CATEGORY_UNDELETE';
export const CATEGORY_SETSTATE = '@app/CATEGORY_SETSTATE';
export const CATEGORY_PATCHSTATE = '@app/CATEGORY_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface CategoryAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface CategoryAction_MutateId extends EntityAction_MutateId {
	type: typeof CATEGORY_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active category
 *
 * @interface CategoryAction_Set
 * @extends {EntityAction_Set}
 */
export interface CategoryAction_Set extends EntityAction_Set {
	type: typeof CATEGORY_SET;
	payload?: EntityId;
}

/**
 * Toggle active category
 *
 * @interface CategoryAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface CategoryAction_Toggle extends EntityAction_Toggle {
	type: typeof CATEGORY_TOGGLE;
	payload?: EntityId;
}

/**
 * Select category entities
 *
 * @interface CategoryAction_Select
 * @extends {EntityAction_Select}
 */
interface CategoryAction_Select extends EntityAction_Select {
	type: typeof CATEGORY_SELECT;
	payload: CategoryId_Some;
}

/**
 * Deselect category entities
 *
 * @interface CategoryAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface CategoryAction_Deselect extends EntityAction_Deselect {
	type: typeof CATEGORY_DESELECT;
	payload: CategoryId_Some;
}

/**
 * Upsert category entities
 *
 * @interface CategoryAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface CategoryAction_Upsert extends EntityAction_Upsert {
	type: typeof CATEGORY_UPSERT;
	payload: CategoryEntity_Some;
}

/**
 * Patch category entities
 *
 * @interface CategoryAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface CategoryAction_Patch extends EntityAction_Patch {
	type: typeof CATEGORY_PATCH;
	payload: CategoryEntityPatch_Some;
}

/**
 * Change category entities
 *
 * @interface CategoryAction_Change
 * @extends {EntityAction_Change}
 */
interface CategoryAction_Change extends EntityAction_Change {
	type: typeof CATEGORY_CHANGE;
	payload: CategoryEntityPatch_Some;
}

/**
 * Apply changes to category entities
 *
 * @interface CategoryAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface CategoryAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof CATEGORY_APPLY_CHANGES;
	payload: CategoryId_Some;
}

/**
 * Cancel changes to category entities
 *
 * @interface CategoryAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface CategoryAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof CATEGORY_CANCEL_CHANGES;
	payload: CategoryId_Some;
}

/**
 * Delete category entities
 *
 * @interface CategoryAction_Delete
 * @extends {EntityAction_Delete}
 */
interface CategoryAction_Delete extends EntityAction_Delete {
	type: typeof CATEGORY_DELETE;
	payload: CategoryId_Some;
}

/**
 * Undelete category entities
 *
 * @interface CategoryAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface CategoryAction_Undelete extends EntityAction_Undelete {
	type: typeof CATEGORY_UNDELETE;
	payload: CategoryId_Some;
}

/**
 * Set category collection / entity state
 *
 * @interface CategoryAction_SetState
 * @extends {EntityAction_SetState}
 */
interface CategoryAction_SetState extends EntityAction_SetState {
	type: typeof CATEGORY_SETSTATE;
}

/**
 * PAtch category collection / entity state
 *
 * @interface CategoryAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface CategoryAction_PatchState extends EntityAction_PatchState {
	type: typeof CATEGORY_PATCHSTATE;
}

/**
 * Export category action types
 *
 * @export
 */
export type CategoryActionTypes =
	| CategoryAction_MutateId
	| CategoryAction_MutateId
	| CategoryAction_Set
	| CategoryAction_Toggle
	| CategoryAction_Select
	| CategoryAction_Deselect
	| CategoryAction_Upsert
	| CategoryAction_Patch
	| CategoryAction_Change
	| CategoryAction_ApplyChanges
	| CategoryAction_CancelChanges
	| CategoryAction_Delete
	| CategoryAction_Undelete
	| CategoryAction_SetState
	| CategoryAction_PatchState;

/**
 * Category actions helper interface
 *
 * @export
 * @interface ICategoryActions
 * @extends {EntityActions}
 */
export interface ICategoryActions extends EntityActions {
	//customAction(ids: CategoryId_Some): CategoryAction_CustomAction;
}

/**
 * Category actions helper
 *
 * @export
 * @class CategoryActions
 * @implements {EntityBaseActions}
 */
export class CategoryActions implements ICategoryActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): CategoryAction_MutateId {
		return { type: CATEGORY_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): CategoryAction_Set {
		return { type: CATEGORY_SET, payload: id };
	}
	toggle(id?: EntityId): CategoryAction_Toggle {
		return { type: CATEGORY_TOGGLE, payload: id };
	}
	select(ids: CategoryId_Some): CategoryAction_Select {
		return {
			type: CATEGORY_SELECT,
			payload: ids
		};
	}
	deselect(ids: CategoryId_Some): CategoryAction_Deselect {
		return {
			type: CATEGORY_DESELECT,
			payload: ids
		};
	}
	upsert(entities: CategoryEntity_Some): CategoryAction_Upsert {
		return {
			type: CATEGORY_UPSERT,
			payload: entities
		};
	}
	patch(entities: CategoryEntityPatch_Some): CategoryAction_Patch {
		return {
			type: CATEGORY_PATCH,
			payload: entities
		};
	}
	change(entities: CategoryEntityPatch_Some): CategoryAction_Change {
		return {
			type: CATEGORY_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: CategoryId_Some): CategoryAction_ApplyChanges {
		return {
			type: CATEGORY_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: CategoryId_Some): CategoryAction_CancelChanges {
		return {
			type: CATEGORY_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: CategoryId_Some): CategoryAction_Delete {
		return {
			type: CATEGORY_DELETE,
			payload: ids
		};
	}
	undelete(ids: CategoryId_Some): CategoryAction_Undelete {
		return {
			type: CATEGORY_UNDELETE,
			payload: ids
		};
	}
	setState(state: CategoryCollectionState): CategoryAction_SetState {
		return {
			type: CATEGORY_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<CategoryCollectionState>
	): CategoryAction_PatchState {
		return {
			type: CATEGORY_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the category actions helper
 *
 * @export
 */
export const categoryActions = new CategoryActions();
