import {
	FeeId_Some,
	FeeEntity_Some,
	FeeEntityPatch_Some,
	FeeCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const FEE_MUTATE_ID = '@app/FEE_MUTATE_ID';
export const FEE_SET = '@app/FEE_SET';
export const FEE_TOGGLE = '@app/FEE_TOGGLE';
export const FEE_SELECT = '@app/FEE_SELECT';
export const FEE_DESELECT = '@app/FEE_DESELECT';
export const FEE_UPSERT = '@app/FEE_UPSERT';
export const FEE_PATCH = '@app/FEE_PATCH';
export const FEE_CHANGE = '@app/FEE_CHANGE';
export const FEE_APPLY_CHANGES = '@app/FEE_APPLY_CHANGES';
export const FEE_CANCEL_CHANGES = '@app/FEE_CANCEL_CHANGES';
export const FEE_DELETE = '@app/FEE_DELETE';
export const FEE_UNDELETE = '@app/FEE_UNDELETE';
export const FEE_SETSTATE = '@app/FEE_SETSTATE';
export const FEE_PATCHSTATE = '@app/FEE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface FeeAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface FeeAction_MutateId extends EntityAction_MutateId {
	type: typeof FEE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active fee
 *
 * @interface FeeAction_Set
 * @extends {EntityAction_Set}
 */
export interface FeeAction_Set extends EntityAction_Set {
	type: typeof FEE_SET;
	payload?: EntityId;
}

/**
 * Toggle active fee
 *
 * @interface FeeAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface FeeAction_Toggle extends EntityAction_Toggle {
	type: typeof FEE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select fee entities
 *
 * @interface FeeAction_Select
 * @extends {EntityAction_Select}
 */
interface FeeAction_Select extends EntityAction_Select {
	type: typeof FEE_SELECT;
	payload: FeeId_Some;
}

/**
 * Deselect fee entities
 *
 * @interface FeeAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface FeeAction_Deselect extends EntityAction_Deselect {
	type: typeof FEE_DESELECT;
	payload: FeeId_Some;
}

/**
 * Upsert fee entities
 *
 * @interface FeeAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface FeeAction_Upsert extends EntityAction_Upsert {
	type: typeof FEE_UPSERT;
	payload: FeeEntity_Some;
}

/**
 * Patch fee entities
 *
 * @interface FeeAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface FeeAction_Patch extends EntityAction_Patch {
	type: typeof FEE_PATCH;
	payload: FeeEntityPatch_Some;
}

/**
 * Change fee entities
 *
 * @interface FeeAction_Change
 * @extends {EntityAction_Change}
 */
interface FeeAction_Change extends EntityAction_Change {
	type: typeof FEE_CHANGE;
	payload: FeeEntityPatch_Some;
}

/**
 * Apply changes to fee entities
 *
 * @interface FeeAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface FeeAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof FEE_APPLY_CHANGES;
	payload: FeeId_Some;
}

/**
 * Cancel changes to fee entities
 *
 * @interface FeeAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface FeeAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof FEE_CANCEL_CHANGES;
	payload: FeeId_Some;
}

/**
 * Delete fee entities
 *
 * @interface FeeAction_Delete
 * @extends {EntityAction_Delete}
 */
interface FeeAction_Delete extends EntityAction_Delete {
	type: typeof FEE_DELETE;
	payload: FeeId_Some;
}

/**
 * Undelete fee entities
 *
 * @interface FeeAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface FeeAction_Undelete extends EntityAction_Undelete {
	type: typeof FEE_UNDELETE;
	payload: FeeId_Some;
}

/**
 * Set fee collection / entity state
 *
 * @interface FeeAction_SetState
 * @extends {EntityAction_SetState}
 */
interface FeeAction_SetState extends EntityAction_SetState {
	type: typeof FEE_SETSTATE;
}

/**
 * PAtch fee collection / entity state
 *
 * @interface FeeAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface FeeAction_PatchState extends EntityAction_PatchState {
	type: typeof FEE_PATCHSTATE;
}

/**
 * Export fee action types
 *
 * @export
 */
export type FeeActionTypes =
	| FeeAction_MutateId
	| FeeAction_MutateId
	| FeeAction_Set
	| FeeAction_Toggle
	| FeeAction_Select
	| FeeAction_Deselect
	| FeeAction_Upsert
	| FeeAction_Patch
	| FeeAction_Change
	| FeeAction_ApplyChanges
	| FeeAction_CancelChanges
	| FeeAction_Delete
	| FeeAction_Undelete
	| FeeAction_SetState
	| FeeAction_PatchState;

/**
 * Fee actions helper interface
 *
 * @export
 * @interface IFeeActions
 * @extends {EntityActions}
 */
export interface IFeeActions extends EntityActions {
	//customAction(ids: FeeId_Some): FeeAction_CustomAction;
}

/**
 * Fee actions helper
 *
 * @export
 * @class FeeActions
 * @implements {EntityBaseActions}
 */
export class FeeActions implements IFeeActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): FeeAction_MutateId {
		return { type: FEE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): FeeAction_Set {
		return { type: FEE_SET, payload: id };
	}
	toggle(id?: EntityId): FeeAction_Toggle {
		return { type: FEE_TOGGLE, payload: id };
	}
	select(ids: FeeId_Some): FeeAction_Select {
		return {
			type: FEE_SELECT,
			payload: ids
		};
	}
	deselect(ids: FeeId_Some): FeeAction_Deselect {
		return {
			type: FEE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: FeeEntity_Some): FeeAction_Upsert {
		return {
			type: FEE_UPSERT,
			payload: entities
		};
	}
	patch(entities: FeeEntityPatch_Some): FeeAction_Patch {
		return {
			type: FEE_PATCH,
			payload: entities
		};
	}
	change(entities: FeeEntityPatch_Some): FeeAction_Change {
		return {
			type: FEE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: FeeId_Some): FeeAction_ApplyChanges {
		return {
			type: FEE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: FeeId_Some): FeeAction_CancelChanges {
		return {
			type: FEE_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: FeeId_Some): FeeAction_Delete {
		return {
			type: FEE_DELETE,
			payload: ids
		};
	}
	undelete(ids: FeeId_Some): FeeAction_Undelete {
		return {
			type: FEE_UNDELETE,
			payload: ids
		};
	}
	setState(state: FeeCollectionState): FeeAction_SetState {
		return {
			type: FEE_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<FeeCollectionState>): FeeAction_PatchState {
		return {
			type: FEE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the fee actions helper
 *
 * @export
 */
export const feeActions = new FeeActions();
