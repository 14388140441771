import React from 'react';
import {
	IonText,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonButton,
	IonImg,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonBadge,
	IonIcon,
	IonFooter,
	useIonRouter
} from '@ionic/react';
import { Avatar } from '../../../app/ui/components/Avatar';

import { ContactOrganizationEntity } from '../../../virtual/store';
import { calendar } from 'ionicons/icons';

import './ExhibitHallListItem.css';

export interface ExhibitHallListItemProps {
	exhibitor: ContactOrganizationEntity;
	showMeetingButton?: boolean;
	onShowMeetingButtonClick?: (exhibitorid: string) => void;
}

export const ExhibitHallListItem: React.FC<ExhibitHallListItemProps> = props => {
	const router = useIonRouter();
	let logoObj = props.exhibitor.logo
		? JSON.parse(props.exhibitor.logo)
		: undefined;
	let logoPath = logoObj?.originalFilePath ?? logoObj?.OriginalFilePath;
	let bannerObj = props.exhibitor.banner
		? JSON.parse(props.exhibitor.banner)
		: undefined;
	let bannerPath = bannerObj?.originalFilePath ?? bannerObj?.OriginalFilePath;
	return (
		<IonCard
			key={'ExhibitHallListItem' + props.exhibitor.id}
			className="exhibit-hall-list-item"
			button={true}
			onClick={event => {
				router.push('/exhibitor/' + props.exhibitor.id);
			}}
		>
			<IonImg hidden={true} src={bannerPath} />
			<IonCardHeader>
				{!logoPath ? (
					<Avatar name={props.exhibitor.name} size="100%" />
				) : (
					<img style={{ height: '65px' }} src={logoPath} />
				)}
				<IonCardTitle>{props.exhibitor.name}</IonCardTitle>
				<IonCardSubtitle>
					<IonText
						hidden={
							!props.exhibitor.locations ||
							props.exhibitor.locations.length <= 0 ||
							(props.exhibitor.locations || '').toString().trim() === ''
						}
					>
						<b>Booth:</b> {(props.exhibitor.locations || '').toString()}
					</IonText>
				</IonCardSubtitle>
			</IonCardHeader>
			<IonCardContent style={{ height: '200px' }}>
				<IonGrid>
					<IonRow>
						<IonCol size="12">
							<IonText>
								<p>
									{(props.exhibitor.description ?? '')
										.replace(/<\/?[^>]+(>|$)/g, '')
										.substr(0, 130) +
										((props.exhibitor.description ?? '').replace(
											/<\/?[^>]+(>|$)/g,
											''
										).length > 130
											? ' ... '
											: '')}
								</p>
							</IonText>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							{props.exhibitor?.keywords?.slice(0, 4)?.map((name, index) => {
								return (
									<IonBadge
										color="light"
										style={{ marginRight: '5px' }}
										key={'filterbadge-' + name}
									>
										{name.trim()}
									</IonBadge>
								);
							})}
						</IonCol>
					</IonRow>
					<IonRow hidden={!props.showMeetingButton}>
						<IonCol size="12">
							<IonButton
								size="small"
								onClick={event => {
									event.stopPropagation();
									if (props.onShowMeetingButtonClick) {
										props.onShowMeetingButtonClick(props.exhibitor?.id);
									}
								}}
							>
								<IonIcon slot="start" icon={calendar}></IonIcon>
								Schedule Meeting
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
			<IonFooter class="ion-text-center">
				<IonButton fill="clear" size="small">
					More Info &gt;
				</IonButton>
			</IonFooter>
		</IonCard>
	);
};
