import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { webinarConfig } from '../../../config/virtual/Webinar';
import { Webinar } from '../models';

/**
 * Webinar entity interface
 *
 * @export
 * @interface WebinarEntity
 * @extends {storage.Entity}
 */
export interface WebinarEntity extends storage.Entity, Webinar {}

/**
 * Webinar entities array
 *
 * @export
 */
export type WebinarEntities = WebinarEntity[];

/**
 * Some webinar entities: one entity or an array of entities
 *
 * @export
 */
export type WebinarEntity_Some = WebinarEntity | WebinarEntities;

/**
 * Webinar entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface WebinarEntityPatch
 * @extends {storage.EntityPatch<WebinarEntity>}
 */
export type WebinarEntityPatch = storage.EntityPatcher<WebinarEntity>;

/**
 * Webinar entity patches array
 *
 * @export
 */
export type WebinarEntitiesPatch = WebinarEntityPatch[];

/**
 * Some webinar entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type WebinarEntityPatch_Some = WebinarEntityPatch | WebinarEntitiesPatch;

/**
 * Webinar entity id
 *
 * @export
 */
export type WebinarId = storage.EntityId;

/**
 * Webinar entity ids
 *
 * @export
 */
export type WebinarIds = storage.EntityIds;

/**
 * Some webinar entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type WebinarId_Some = WebinarId | WebinarIds;

/**
 * Webinar entities object by id
 *
 * @export
 * @interface WebinarEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface WebinarEntitiesObject extends storage.EntitiesObject {
	[id: string]: WebinarEntity;
}

/**
 * Webinar entity state
 *
 * @export
 * @interface WebinarEntityState
 * @extends {storage.EntityState}
 */
export interface WebinarEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Webinar store collection
 *
 * @export
 * @interface WebinarStoreCollection
 * @extends {storage.Store}
 */
export interface WebinarStoreCollection extends storage.Store {
	webinar: WebinarCollection;
}

/**
 * Webinar collection properties
 *
 * @export
 * @interface WebinarCollection
 * @extends {Collection}
 */
export interface WebinarCollection extends storage.Collection {
	byIds: WebinarEntitiesObject;
	mutation: WebinarEntitiesObject;
	cache: WebinarEntitiesObject;
	state: WebinarCollectionState;
}

/**
 * Webinar collection state
 *
 * @export
 * @interface WebinarCollectionState
 * @extends {storage.CollectionState}
 */
export interface WebinarCollectionState extends storage.CollectionState {}

// custom webinar collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const webinarCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const webinarCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const webinarCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const webinarCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: webinarCollectionIdStringProps,
	idArrayProps: webinarCollectionIdArrayProps,
	idEntityProps: webinarCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Webinar collection default values, initialize collection with webinar entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const webinarCollectionDefault: WebinarCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as WebinarCollection),
		...{
			activeId: webinarConfig.activeId || webinarConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	webinarConfig.entities
);

/**
 * Webinar store collection default values
 *
 * @export
 * @constant
 */
export const webinarStoreCollectionDefault: WebinarStoreCollection = {
	webinar: webinarCollectionDefault
};

/**
 * Webinar store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getWebinarCollection = (state: any) => state.virtual.webinar;
