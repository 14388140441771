import {
	RegistrationTypeCollection,
	RegistrationTypeCollectionState,
	RegistrationTypeId,
	RegistrationTypeEntity_Some,
	RegistrationTypeEntityPatch_Some,
	RegistrationTypeId_Some,
	registrationTypeCollectionDefault,
	registrationTypeCollectionMutateIdOpts
} from '../collections/RegistrationType';
import * as actions from '../actions/RegistrationType';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * RegistrationType reducer
 *
 * @export
 * @param {RegistrationTypeCollection} [state=registrationTypeCollectionDefault]
 * @param {RegistrationTypeActionTypes} action
 * @returns
 */
export function registrationTypeReducer(
	state: RegistrationTypeCollection = registrationTypeCollectionDefault,
	action: actions.RegistrationTypeActionTypes
) {
	switch (action.type) {
		case actions.REGISTRATIONTYPE_MUTATE_ID:
			return collectionReducerActions.mutateId<RegistrationTypeCollection>(
				state,
				action.payload,
				registrationTypeCollectionMutateIdOpts
			);
		case actions.REGISTRATIONTYPE_SET:
			return collectionReducerActions.set<
				RegistrationTypeCollection,
				RegistrationTypeId
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_TOGGLE:
			return collectionReducerActions.toggle<
				RegistrationTypeCollection,
				RegistrationTypeId
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_SELECT:
			return collectionReducerActions.select<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_DESELECT:
			return collectionReducerActions.deselect<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_UPSERT:
			return collectionReducerActions.upsert<
				RegistrationTypeCollection,
				RegistrationTypeEntity_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_PATCH:
			return collectionReducerActions.patch<
				RegistrationTypeCollection,
				RegistrationTypeEntityPatch_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_CHANGE:
			return collectionReducerActions.change<
				RegistrationTypeCollection,
				RegistrationTypeEntityPatch_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_DELETE:
			return collectionReducerActions.delete<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_UNDELETE:
			return collectionReducerActions.undelete<
				RegistrationTypeCollection,
				RegistrationTypeId_Some
			>(state, action.payload);
		case actions.REGISTRATIONTYPE_SETSTATE:
			return collectionReducerActions.setState<
				RegistrationTypeCollection,
				RegistrationTypeCollectionState
			>(state, action.payload.state);
		case actions.REGISTRATIONTYPE_PATCHSTATE:
			return collectionReducerActions.patchState<
				RegistrationTypeCollection,
				RegistrationTypeCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
