import React, { useState, useEffect } from 'react';

//DATA
import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	ContactOrganizationEntity,
	ContactEntities,
	ScheduleContactRoleType,
	ScheduleContactEntities,
	ScheduleType,
	ScheduleEntities
} from '../../../virtual/store';

//UI
import {
	IonItem,
	IonLabel,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonModal,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonTitle,
	IonContent,
	IonRadioGroup,
	IonListHeader,
	IonCheckbox,
	IonItemDivider,
	IonSpinner
} from '@ionic/react';
import { peopleOutline, optionsOutline, checkbox } from 'ionicons/icons';
import { ExhibitHallListItem } from '../components/ExhibitHallListItem';
import { SearchBar } from '../../../app/ui/components/Search/SearchBar';
import AppointmentBooking from './AppointmentBooking';

export interface ExhibitHallListProps {
	title?: string;
	content?: string;
}

export const ExhibitHallList: React.FC<ExhibitHallListProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<{}>({});
	const ctx = useCtx<{}>({});
	const [searchText, setSearchText] = useState('');
	const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
	const [showFilterModal, setShowFilterModal] = useState(false);

	const [selectedExhibitorId, setSelectedExhibitorId] = useState<string>();
	const [showBookAppointmentModal, setShowBookAppointmentModal] = useState(
		false
	);

	useEffect(() => {
		ctx.virtual.contact.readOrganizations(
			ctx,
			{},
			(entities: ContactEntities) => {
				//console.log(`${entities.length} Organizations Loaded`);
			}
		);
	}, []);

	const currentEvent = ctx.virtual.event.active();
	let allExhibitors: ContactOrganizationEntity[] = ctx.virtual.contact.all_Exhibitor(
		currentEvent?.id || ''
	);

	const onAppointmentBookingButtonClick = (value: string) => {
		if (value) {
			setSelectedExhibitorId(value);
			setShowBookAppointmentModal(true);
		} else {
			setSelectedExhibitorId('');
		}
	};

	const sortExhibitors = (
		a: ContactOrganizationEntity,
		b: ContactOrganizationEntity
	) => {
		return (a.name?.toUpperCase() as string) > (b.name?.toUpperCase() as string)
			? 1
			: (b.name?.toUpperCase() as string) > (a.name?.toUpperCase() as string)
			? -1
			: 0;
	};

	const sortExhibitorCategories = (a: string, b: string) => {
		return a.trim().toUpperCase() > b.trim().toUpperCase()
			? 1
			: b.trim().toUpperCase() > a.trim().toUpperCase()
			? -1
			: 0;
	};

	let totalExhibitors = allExhibitors.length;
	const exhibitorListItems = allExhibitors
		.sort(sortExhibitors)
		.filter(
			e => ((e.properties as any)?.myconexsys?.RegistrationStatusId ?? 1) == 1
		)
		.filter(exhibitor => {
			let keywords = (categoryFilter ?? []).map(name =>
				name.toLowerCase().trim()
			);
			if (keywords.length > 0) {
				return exhibitor.keywords?.some(val =>
					keywords.includes(val.toLowerCase().trim())
				);
			} else {
				return true;
			}
		})
		.filter(exhibitor => {
			let match = 0,
				matches = 0,
				keywords = searchText ?? '';
			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(exhibitor?.name &&
						exhibitor?.name.toLowerCase().trim().indexOf(keyword) > -1) ||
					(exhibitor?.keywords &&
						exhibitor?.keywords?.some(
							val => val.toLowerCase().trim() == keyword.toLowerCase().trim()
						))
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.map(
			(
				exhibitor: ContactOrganizationEntity,
				index: number,
				exhibitorList: ContactOrganizationEntity[]
			) => {
				let mainGroup = '';
				let mainGroupName = (
					(exhibitor.name ?? ' ').charAt(0) ?? ''
				).toUpperCase();
				if (index > 0) {
					let prevName = (
						(exhibitorList[index - 1].name ?? ' ').charAt(0) ?? ''
					).toUpperCase();
					if ((mainGroupName ?? '') != (prevName ?? '')) {
						mainGroup = mainGroupName ?? '';
					}
				} else {
					mainGroup = mainGroupName ?? '';
				}

				return [
					mainGroup !== '' ? (
						<IonItemDivider key={mainGroup}>
							<h1>{mainGroup}</h1>
						</IonItemDivider>
					) : (
						''
					),
					<IonCol
						key={'col-' + exhibitor.name}
						size="12"
						sizeMd="6"
						sizeLg="6"
						sizeXl="4"
					>
						<ExhibitHallListItem
							key={'ExhibitHallListItem-' + exhibitor.name}
							exhibitor={exhibitor}
							showMeetingButton={
								(currentEvent?.properties as any)?.app?.virtual?.mainMenu
									?.meetings?.visible ?? false
							}
							onShowMeetingButtonClick={onAppointmentBookingButtonClick}
						/>
					</IonCol>
				];
			}
		);

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};
	const onCategoryFilterChanged = (e: any) => {
		e.persist();
		let value = e.target.value;
		let isChecked = e.target.checked;
		let cFilter = [...categoryFilter];
		if (!isChecked) {
			let i = cFilter?.indexOf(value) ?? -1;
			if (i >= 0) cFilter?.splice(i);
		} else {
			cFilter?.push(value);
		}
		setCategoryFilter(cFilter);
	};

	return (
		<>
			<IonModal
				isOpen={showBookAppointmentModal}
				onDidDismiss={() => setShowBookAppointmentModal(false)}
			>
				<IonToolbar>
					<IonTitle>Meeting With</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => setShowBookAppointmentModal(false)}>
							Close
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent>
					<AppointmentBooking
						exhibitorId={selectedExhibitorId}
						attendeeId={ctx.virtual.attendee.active()?.id}
					></AppointmentBooking>
				</IonContent>
			</IonModal>
			<IonModal
				className="filter-modal"
				isOpen={showFilterModal}
				onDidDismiss={() => setShowFilterModal(false)}
			>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setCategoryFilter([]);
								}}
							>
								Clear
							</IonButton>
						</IonButtons>
						<IonTitle>Filters</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(false);
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList>
						<IonRadioGroup>
							<IonListHeader>
								<IonLabel>Categories</IonLabel>
							</IonListHeader>
							{ctx.virtual.contact
								.get_ExhibitorCategories(currentEvent?.id || '')
								.sort(sortExhibitorCategories)
								.map(ec => {
									return (
										<IonItem key={'category-' + ec}>
											<IonLabel>{ec}</IonLabel>
											<IonCheckbox
												key={ec}
												checked={categoryFilter.includes(ec)}
												value={ec}
												onClick={onCategoryFilterChanged}
											></IonCheckbox>
										</IonItem>
									);
								})}
						</IonRadioGroup>
					</IonList>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonGrid>
					<IonRow>
						<IonCol size="10" sizeSm="9">
							<SearchBar onChange={onSerach} />
						</IonCol>
						<IonCol
							size="2"
							sizeSm="3"
							hidden={
								ctx.virtual.contact.get_ExhibitorCategories(
									currentEvent?.id || ''
								).length <= 0
							}
						>
							<IonButton
								color={categoryFilter?.length <= 0 ? 'light' : 'primary'}
								class="ion-float-right"
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(true);
								}}
							>
								<IonIcon icon={optionsOutline} />
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow hidden={categoryFilter?.length <= 0}>
						<IonCol
							style={{ backgroundColor: '#3880ff', color: '#fff' }}
							size="12"
						>
							<IonButton
								color="light"
								onClick={(e: any) => {
									e.persist();
									setCategoryFilter([]);
								}}
							>
								Clear
							</IonButton>
							<IonLabel style={{ margin: '10px' }}>
								<b>Filter Applied:</b> Product Categories (
								{(categoryFilter ?? []).map((name, index) => {
									return (
										<span key={'filter-' + name}>
											{name.trim()}
											{index === categoryFilter.length - 1 ? '' : ', '}
										</span>
									);
								})}
								)
							</IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonToolbar>
			{totalExhibitors <= 0 ? (
				<IonLabel class="ion-text-center">
					<br />
					<h1>
						<IonSpinner></IonSpinner>
					</h1>
					<h2>Loading....</h2>
				</IonLabel>
			) : (
				<IonGrid>
					<IonRow>{exhibitorListItems}</IonRow>
				</IonGrid>
			)}
		</>
	);
};
