import { ContactRegistrationTypeEntities } from '../../virtual/store/collections/ContactRegistrationType';

export interface ContactRegistrationTypeConfig {
	entities: ContactRegistrationTypeEntities;
	activeId?: string;
}

export const contactRegistrationTypeConfig: ContactRegistrationTypeConfig = {
	entities: []
};
