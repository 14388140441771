import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { productConfig } from '../../../config/virtual/Product';
import {
	ProductOther,
	ProductRegistration,
	ProductSession,
	ProductType
} from '../models';

/**
 * Product 'other' entity interface
 *
 * @export
 * @interface ProductOtherEntity
 * @extends {storage.Entity}
 */
export interface ProductOtherEntity extends storage.Entity, ProductOther {}

export const isProductOtherEntity = (
	object: ProductEntity
): object is ProductOtherEntity => {
	return (object as ProductOtherEntity).type === ProductType.Other;
};

/**
 * Product 'registration' entity interface
 *
 * @export
 * @interface ProductRegistrationEntity
 * @extends {storage.Entity}
 */
export interface ProductRegistrationEntity
	extends storage.Entity,
		ProductRegistration {}

export const isProductRegistrationEntity = (
	object: ProductEntity
): object is ProductRegistrationEntity => {
	return (
		(object as ProductRegistrationEntity).type === ProductType.Registration
	);
};

/**
 * Product 'session' entity interface
 *
 * @export
 * @interface ProductSessionEntity
 * @extends {storage.Entity}
 */
export interface ProductSessionEntity extends storage.Entity, ProductSession {}

export const isProductSessionEntity = (
	object: ProductEntity
): object is ProductSessionEntity => {
	return (object as ProductSessionEntity).type === ProductType.Session;
};

export interface ProductSessionEntityFilter {
	keyWords?: string;
	startDates?: Date[];
	endDates?: Date[];
	speakers?: string[];
	categories?: string[];
	locations?: string[];
	viewingTypes?: string[];
}

/**
 * Product entity type
 *
 * @export
 */
export type ProductEntity =
	| ProductOtherEntity
	| ProductRegistrationEntity
	| ProductSessionEntity;

/**
 * Product entities array
 *
 * @export
 */
export type ProductEntities = ProductEntity[];

/**
 * Some product entities: one entity or an array of entities
 *
 * @export
 */
export type ProductEntity_Some = ProductEntity | ProductEntities;

/**
 * Product entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ProductEntityPatch
 * @extends {storage.EntityPatch<ProductEntity>}
 */
export type ProductEntityPatch = storage.EntityPatcher<ProductEntity>;

/**
 * Product entity patches array
 *
 * @export
 */
export type ProductEntitiesPatch = ProductEntityPatch[];

/**
 * Some product entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ProductEntityPatch_Some = ProductEntityPatch | ProductEntitiesPatch;

/**
 * Product entity id
 *
 * @export
 */
export type ProductId = storage.EntityId;

/**
 * Product entity ids
 *
 * @export
 */
export type ProductIds = storage.EntityIds;

/**
 * Some product entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ProductId_Some = ProductId | ProductIds;

/**
 * Product entities object by id
 *
 * @export
 * @interface ProductEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ProductEntitiesObject extends storage.EntitiesObject {
	[id: string]: ProductEntity;
}

/**
 * Product entity state
 *
 * @export
 * @interface ProductEntityState
 * @extends {storage.EntityState}
 */
export interface ProductEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Product store collection
 *
 * @export
 * @interface ProductStoreCollection
 * @extends {storage.Store}
 */
export interface ProductStoreCollection extends storage.Store {
	product: ProductCollection;
}

/**
 * Product collection properties
 *
 * @export
 * @interface ProductCollection
 * @extends {Collection}
 */
export interface ProductCollection extends storage.Collection {
	byIds: ProductEntitiesObject;
	mutation: ProductEntitiesObject;
	cache: ProductEntitiesObject;
	state: ProductCollectionState;
}

/**
 * Product collection state
 *
 * @export
 * @interface ProductCollectionState
 * @extends {storage.CollectionState}
 */
export interface ProductCollectionState extends storage.CollectionState {}

// custom product collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const productCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const productCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const productCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const productCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: productCollectionIdStringProps,
	idArrayProps: productCollectionIdArrayProps,
	idEntityProps: productCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Product collection default values, initialize collection with product entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const productCollectionDefault: ProductCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ProductCollection),
		...{
			activeId: productConfig.activeId || productConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	productConfig.entities
);

/**
 * Product store collection default values
 *
 * @export
 * @constant
 */
export const productStoreCollectionDefault: ProductStoreCollection = {
	product: productCollectionDefault
};

/**
 * Product store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getProductCollection = (state: any) => state.virtual.product;
