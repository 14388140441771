import React from 'react';
import {
	IonIcon,
	IonButton,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonText,
	IonCardSubtitle,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol
} from '@ionic/react';

import * as icons from 'ionicons/icons';
import _ from 'lodash';

import { UserAvatar } from '../../components';

import { useAuth, useLocaleConfig, insertParam } from '../../../store';

import { userMenuConfig } from '../../../../config/app/components/UserMenu';
import { useCtx } from '../../../../config/hooks';

export interface UserMenuConfigProps {
	signIn: string;
	manageUser: string;
	addUser: string;
	signoutAll: string;
	settings: string;
	viewAccount: string;
}

export interface UserMenuProps {
	finish: any;
}

export const UserMenu: React.FC<UserMenuProps> = props => {
	const {
		config: [config],
		activeUser,
		userHelper
	} = useLocaleConfig<UserMenuConfigProps>(userMenuConfig);
	const ctx = useCtx<{}>({});

	const { authHelper, activeAuth } = useAuth();

	let users = userHelper.all();

	const finish = () => {
		if (_.isFunction(props.finish)) props.finish();
	};

	const login = () => {
		window.location.href = '/home';
		if (activeAuth) {
			authHelper.loginWithRedirect();
		}
	};

	const addUser = () => {
		if (activeAuth) {
			// login with a new auth session, will logout the user first automaticallly
			authHelper.newLoginWithRedirect();
		}
	};

	const signoutAll = () => {
		if (activeAuth) {
			// logout the auth session for the current user
			authHelper.logout();
			// signout all user accounts from the app user collection
			ctx.virtual.attendee.set(undefined);
			userHelper.signout(userHelper.collection.allIds);
			userHelper.set(undefined);
			insertParam('resetLS', 'true');

			window.location.href =
				'event/' + ctx.virtual.event.active()?.id + '/?resetLS=true';
		}
	};

	return (
		<IonList key="user-menu">
			{users.length === 0 ? (
				<IonItem
					detail={false}
					key="sign-out-all-users-nologin"
					lines="none"
					className="ion-align-items-center"
				>
					<IonGrid key="sign-out-all-users-grid">
						<IonRow key="sign-out-all-users-row">
							<IonCol key="login-menu-center" className="ion-align-self-center">
								<IonButton
									key="user-menu-login"
									class="text-transform-none"
									color="medium"
									fill="outline"
									onClick={() => {
										login();
									}}
								>
									{config.signIn}
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			) : (
				<>
					{activeUser && (
						<>
							<IonItem detail={false} key="active-user" lines="full">
								<UserAvatar user={activeUser} />
								<IonLabel class="ion-padding-start ion-text-wrap">
									{activeUser.name}
									<p>
										{activeUser.email === activeUser.name ? (
											<br />
										) : (
											activeUser.email
										)}
									</p>
								</IonLabel>
							</IonItem>
							<IonItem
								detail={false}
								key="manage-attendee-record"
								lines="full"
								onClick={() => {
									window.open(
										'https://my.conexsys.com/EventRegistration/IdAuth?v=' +
											activeUser.attendeeId
									);
								}}
								button
							>
								<IonIcon slot="start" icon={icons.pencil} />
								<IonLabel color="dark">Manage Registration</IonLabel>
							</IonItem>
						</>
					)}
					{users.map(user => {
						return (
							<>
								{activeUser && activeUser.id !== user.id && (
									<IonItem
										lines="full"
										button
										key={user.id}
										onClick={() => {
											userHelper.set(user.id);
											finish();
										}}
									>
										<UserAvatar slot="start" user={user} />
										<IonLabel
											color={
												activeUser && activeUser.id !== user.id
													? 'primary'
													: 'secondary'
											}
										>
											<h2>
												<IonText color="dark">{user.name}</IonText>
											</h2>
											<h3>
												<IonText color="medium">{user.email}</IonText>
											</h3>
										</IonLabel>
									</IonItem>
								)}
							</>
						);
					})}
					<IonItem
						key="sign-out-all-users"
						className="ion-align-items-center"
						lines="full"
						onClick={() => {
							signoutAll();
							finish();
						}}
						button
					>
						<IonIcon slot="start" icon={icons.logOut} />
						<IonLabel color="dark">{config.signoutAll}</IonLabel>
					</IonItem>
				</>
			)}
		</IonList>
	);
};
