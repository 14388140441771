import React, { useState, useEffect } from 'react';

//UI
import {
	IonItem,
	IonLabel,
	IonItemDivider,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonToolbar,
	IonButton,
	IonListHeader,
	IonTitle,
	IonButtons,
	IonHeader,
	IonCheckbox,
	IonModal,
	IonContent
} from '@ionic/react';
import {
	peopleOutline,
	optionsOutline,
	closeOutline,
	playCircle
} from 'ionicons/icons';
import { EventScheduleListItem } from '../components/EventScheduleListItem';
import { SearchBar } from '../../../app/ui/components/Search/SearchBar';
import { Media } from '../../../app/ui/components';

//LIB
import moment from 'moment';

//STYLE
import './EventScheduleList.css';
import {
	AttendeeEntity,
	CategoryEntities,
	CategoryEntity,
	ContactIndividualEntity,
	EventEntity,
	ProductSessionEntity
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { ProductSessionEntityFilter } from '../../store/collections/Product';

export interface EventScheduleListProps {
	title?: string;
	content?: string;
	attendeeProductsOnly?: boolean;
}

export const EventScheduleList: React.FC<EventScheduleListProps> = props => {
	const [productSessionFilter, setProductSessionFilter] = useState<
		ProductSessionEntityFilter
	>({
		keyWords: '',
		startDates: [],
		categories: [],
		speakers: [],
		viewingTypes: []
	});
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showVideoModal, setShowVideoModal] = useState('');
	const ctx = useCtx({});
	const {
		config: [config]
	} = ctx;

	useEffect(() => {
		setShowVideoModal('');
		setShowFilterModal(false);
	}, []);

	const currentAttendee = ctx.virtual.attendee.active();
	const currentEvent = ctx.virtual.event.active();
	const attendeeProducts = currentAttendee?.products?.filter(
		ap => ap.i_.status === 'active'
	);
	const sortSessionsStartDate = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return moment(a.start).toDate() > moment(b.start).toDate()
			? 1
			: moment(b.start).toDate() > moment(a.start).toDate()
			? -1
			: 0;
	};
	const sortSessionsEndDate = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return moment(a.end).toDate() > moment(b.end).toDate()
			? 1
			: moment(b.end).toDate() > moment(a.end).toDate()
			? -1
			: 0;
	};
	const sortSessionsName = (
		a: ProductSessionEntity,
		b: ProductSessionEntity
	) => {
		return (a.name?.toLowerCase() || '') > (b.name?.toLowerCase() || '')
			? 1
			: (b.name?.toLowerCase() || '') > (a.name?.toLowerCase() || '')
			? -1
			: 0;
	};

	let totalSessions = ctx.virtual.product.all_Session(currentEvent?.id || '')
		.length;
	const allSessionDays: Date[] = ctx.virtual.product
		.all_Session(currentEvent?.id || '')
		.filter(
			(session, i, arr) =>
				arr.findIndex(t => moment(t.start).isSame(session.start, 'day')) === i
		)
		.map(x => moment(x.start).toDate());

	const allSpeakers: ContactIndividualEntity[] | undefined = []; //ctx.virtual.contact.all_Speaker(currentEvent?.id || '');
	const allProductCategories:
		| CategoryEntities
		| undefined = ctx.virtual.category.all_Active();

	const allowAttendanceToAllSession = (currentEvent?.properties as any)?.app
		?.virtual?.generalSettings?.allowAttendanceToAllSession;

	const onWebinarLinkClick = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
			setShowVideoModal('');
		}
	};

	const onWebinarWatchRecordingLinkClicked = (
		event: EventEntity | undefined,
		attendee: AttendeeEntity | undefined,
		session: ProductSessionEntity | undefined,
		url?: string
	) => {
		if (event && attendee && session) {
			ctx.virtual.attendee.AttendProduct(ctx, event, attendee, session);
			setShowVideoModal(url || '');
		}
	};

	const sessionsListItems = ctx.virtual.product
		.all_SessionByFilter(ctx, productSessionFilter, currentEvent?.id || '')
		.filter(
			product =>
				!props.attendeeProductsOnly ||
				attendeeProducts?.find(p => p.productId === product.id)
		)
		.sort(sortSessionsName)
		.sort(sortSessionsEndDate)
		.sort(sortSessionsStartDate)
		.map(
			(
				session: ProductSessionEntity,
				index: number,
				sessionList: ProductSessionEntity[]
			) => {
				let mainGroup = '';
				let subGroup = '';

				let mainGroupDate = moment(session.start).format('dddd MMMM Do, YYYY');
				let subGroupTime =
					moment(session.start).format('LT') +
					' - ' +
					moment(session.end).format('LT');
				if (index > 0) {
					let prevDate = moment(sessionList[index - 1].start).format(
						'dddd MMMM Do, YYYY'
					);
					let prevTime =
						moment(sessionList[index - 1].start).format('LT') +
						' - ' +
						moment(sessionList[index - 1].end).format('LT');
					if ((mainGroupDate ?? '') != (prevDate ?? '')) {
						mainGroup = mainGroupDate ?? '';
					}
					if ((subGroupTime ?? '') != (prevTime ?? '')) {
						subGroup = subGroupTime ?? '';
					}
				} else {
					mainGroup = mainGroupDate ?? '';
					subGroup = subGroupTime ?? '';
				}

				/*let allSponsorContactProducts:
					| ProductContactEntity[]
					| undefined = ctx.virtual.productContact.all_Sponsors_By_ProductId(
					session?.id
				);

				let sponsors:
					| ContactOrganizationEntity[]
					| undefined = ctx.virtual.contact
					.all_Sponsor()
					.filter(c =>
						allSponsorContactProducts?.find(sc => sc.contactId === c.id)
					);*/

				let categories = ctx.virtual.category.all_By_Product(ctx, session.id);
				let webinars = ctx.virtual.webinar.all_By_Product(ctx, session.id);
				return (
					<div key={'event-schedule-item-div-' + session.id}>
						{mainGroup !== '' ? (
							<IonItemDivider
								key={mainGroup}
								class="list-maingroup-header-event-schedule"
							>
								<IonLabel>{mainGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						{subGroup !== '' ? (
							<IonItemDivider key={subGroup} class="list-subgroup-header">
								<IonLabel>{subGroup}</IonLabel>
							</IonItemDivider>
						) : (
							''
						)}
						<EventScheduleListItem
							key={session.id}
							session={session}
							isAttending={
								attendeeProducts?.find(p => p.productId === session.id) !==
								undefined
							}
							hasAttened={false}
							categories={categories}
							webinars={webinars}
							allowAttendanceToAllSession={allowAttendanceToAllSession}
							onWebinarLinkClick={() => {
								onWebinarLinkClick(currentEvent, currentAttendee, session);
							}}
							onWebinarWatchRecordingLinkClick={(url?: string) => {
								onWebinarWatchRecordingLinkClicked(
									currentEvent,
									currentAttendee,
									session,
									url
								);
							}}
						/>
					</div>
				);
			}
		);

	//Filter
	const hasFilter =
		(productSessionFilter.startDates &&
			productSessionFilter.startDates.length > 0) ||
		(productSessionFilter.categories &&
			productSessionFilter.categories.length > 0);

	const resetFilter = () => {
		setProductSessionFilter(prevProductSessionFilter => ({
			...prevProductSessionFilter,
			startDates: [],
			speakers: [],
			categories: []
		}));
	};

	const onSerach = (value: string | undefined) => {
		setProductSessionFilter(prevProductSessionFilter => ({
			...prevProductSessionFilter,
			keyWords: value ? value : ''
		}));
	};

	const onDateFilterChanged = (e: any) => {
		e.persist();
		let dateValue = moment(e.target.value).toDate();
		let isChecked = e.target.checked;
		let dateFilter = productSessionFilter.startDates;
		if (!isChecked) {
			let i = dateFilter?.findIndex(v => moment(v).isSame(dateValue)) ?? -1;
			if (i >= 0) dateFilter?.splice(i);
		} else {
			dateFilter?.push(dateValue);
		}
		setProductSessionFilter(prevProductSessionFilter => ({
			...prevProductSessionFilter,
			startDates: dateFilter ?? []
		}));
	};

	const onSpeakerFilterChanged = (e: any) => {
		e.persist();
		let speakerValue = e.target.value;
		let isChecked = e.target.checked;
		let speakerFilter = productSessionFilter.speakers;
		if (!isChecked) {
			let i = speakerFilter?.findIndex(v => v === speakerValue) ?? -1;
			if (i >= 0) speakerFilter?.splice(i);
		} else {
			speakerFilter?.push(speakerValue);
		}
		setProductSessionFilter(prevProductSessionFilter => ({
			...prevProductSessionFilter,
			speakers: speakerFilter ?? []
		}));
	};

	const onCategoryFilterChanged = (e: any) => {
		e.persist();
		let categoryValue = e.target.value;
		let isChecked = e.target.checked;
		let categoryFilter = productSessionFilter.categories;
		if (!isChecked) {
			let i = categoryFilter?.findIndex(v => v === categoryValue) ?? -1;
			if (i >= 0) categoryFilter?.splice(i);
		} else {
			categoryFilter?.push(categoryValue);
		}
		setProductSessionFilter(prevProductSessionFilter => ({
			...prevProductSessionFilter,
			categories: categoryFilter ?? []
		}));
	};
	return (
		<>
			<IonModal
				isOpen={showVideoModal != ''}
				onDidDismiss={() => setShowVideoModal('')}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle></IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowVideoModal('');
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Media src={showVideoModal}></Media>
				</IonContent>
			</IonModal>
			<IonModal
				className="filter-modal"
				isOpen={showFilterModal}
				onDidDismiss={() => setShowFilterModal(false)}
			>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={resetFilter}>Clear</IonButton>
						</IonButtons>
						<IonTitle>Filter Sessions</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(false);
								}}
							>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList
						hidden={
							(!allSessionDays || allSessionDays.length <= 0) &&
							(!allSpeakers || allSpeakers.length <= 0)
						}
					>
						<IonListHeader
							hidden={!allSessionDays || allSessionDays.length <= 0}
						>
							<IonLabel>Days</IonLabel>
						</IonListHeader>
						{allSessionDays
							.sort((a: Date, b: Date) => {
								return a > b ? 1 : b > a ? -1 : 0;
							})
							.map(v => {
								return (
									<IonItem
										key={'sessiondays-' + moment(v).format('YYYY-MM-DD')}
									>
										<IonLabel>
											{moment(v).format('dddd Do MMMM, YYYY')}
										</IonLabel>
										<IonCheckbox
											key={moment(v).format('YYYY-MM-DD')}
											checked={
												(productSessionFilter?.startDates?.findIndex(i =>
													moment(i).isSame(
														moment(moment(v).format('YYYY-MM-DD')).toDate()
													)
												) ?? 0) >= 0
											}
											value={moment(v).format('YYYY-MM-DD')}
											onClick={onDateFilterChanged}
										></IonCheckbox>
									</IonItem>
								);
							})}

						<IonListHeader
							hidden={!allProductCategories || allProductCategories.length <= 0}
						>
							<IonLabel>Session Categories</IonLabel>
						</IonListHeader>
						{allProductCategories
							.sort((a: CategoryEntity, b: CategoryEntity) => {
								return (a.name ?? '') > (b.name ?? '')
									? 1
									: (b.name ?? '') > (a.name ?? '')
									? -1
									: 0;
							})
							.map(c => {
								return (
									<IonItem key={'category-filter-' + c.id}>
										<IonLabel>{c.name}</IonLabel>
										<IonCheckbox
											key={c.id}
											checked={productSessionFilter?.categories?.includes(c.id)}
											value={c.id}
											onClick={onCategoryFilterChanged}
										></IonCheckbox>
									</IonItem>
								);
							})}
						<IonListHeader hidden={!allSpeakers || allSpeakers.length <= 0}>
							<IonLabel>Speakers</IonLabel>
						</IonListHeader>
						{allSpeakers
							.sort(
								(a: ContactIndividualEntity, b: ContactIndividualEntity) => {
									return (a.lastName ?? '') > (b.lastName ?? '')
										? 1
										: (b.lastName ?? '') > (a.lastName ?? '')
										? -1
										: 0;
								}
							)
							.map(s => {
								return (
									<IonItem>
										<IonLabel>
											{s.salutation} {s.firstName} {s.lastName}
										</IonLabel>
										<IonCheckbox
											key={s.id}
											value={s.id}
											onClick={onSpeakerFilterChanged}
										></IonCheckbox>
									</IonItem>
								);
							})}
					</IonList>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonGrid>
					<IonRow>
						<IonCol sizeXs="8" size="10">
							<SearchBar onChange={onSerach} />
						</IonCol>
						<IonCol sizeXs="4" size="2">
							<IonButton
								color={'light'}
								class="ion-float-right"
								onClick={(e: any) => {
									e.persist();
									setShowFilterModal(true);
								}}
							>
								<IonIcon icon={optionsOutline} />
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow hidden={!hasFilter}>
						<IonCol
							style={{ backgroundColor: '#3880ff', color: '#fff' }}
							size="4"
							sizeLg="3"
						>
							<IonButton color="light" onClick={resetFilter}>
								Clear
							</IonButton>
							<IonLabel style={{ margin: '10px' }}>
								<b>Filter Applied:</b>
							</IonLabel>
						</IonCol>
						<IonCol
							style={{ backgroundColor: '#3880ff', color: '#fff' }}
							size="8"
							sizeLg="9"
						>
							<IonLabel
								style={{ display: 'block', margin: '5px' }}
								hidden={
									!productSessionFilter.startDates ||
									productSessionFilter.startDates.length <= 0
								}
							>
								Dates (
								{(productSessionFilter.startDates ?? []).map((date, index) => {
									return (
										<span>
											{moment(date).format('dddd Do MMMM, YYYY')}
											{index ===
											(productSessionFilter?.startDates?.length || 0) - 1
												? ''
												: ', '}
										</span>
									);
								})}
								)
							</IonLabel>
							<IonLabel
								style={{ display: 'block', margin: '5px' }}
								hidden={
									!productSessionFilter?.categories ||
									productSessionFilter?.categories?.length <= 0
								}
							>
								Categories (
								{(productSessionFilter?.categories ?? []).map((cId, index) => {
									return (
										<span>
											{allProductCategories?.find(c => c.id === cId)?.name}
											{index ===
											(productSessionFilter?.categories?.length || 0) - 1
												? ''
												: ', '}
										</span>
									);
								})}
								)
							</IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonToolbar>
			<IonContent>
				{totalSessions <= 0 ? (
					<IonLabel class="ion-text-center">
						<br />
						<h1>
							<IonIcon icon={peopleOutline} size="large" />
						</h1>
						<h2>No activities found.</h2>
					</IonLabel>
				) : (
					<IonList class="session-list" key="sessionsList">
						{sessionsListItems}
					</IonList>
				)}
			</IonContent>
		</>
	);
};
