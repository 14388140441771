import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getEventCollection,
	EventId,
	EventIds,
	EventId_Some,
	EventEntity,
	EventEntities,
	EventEntity_Some,
	EventEntityPatch_Some,
	EventCollection,
	EventCollectionState,
	IEventActions,
	eventActions,
	EventActionTypes
} from '..';
import { readEvent, ReadEventRequest } from '../apis';

import { UseCtx } from '../../../config/hooks';
import {
	AnswerEntities,
	ContactEntities,
	FeeEntities,
	ProductContactEntities,
	ProductEntities,
	ProductWebinarEntities,
	QuestionEntities,
	RegistrationGroupEntities,
	RegistrationTypeEntities,
	ResourceEntities,
	ScheduleEntities,
	WebinarEntities,
	ProductCategoryEntities,
	CategoryEntities
} from '../collections';
import moment from 'moment';
//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as event from '../events/Event';

/**
 * Event helper interface
 *
 * @export
 * @interface IEventHelper
 * @extends {IEntityHelper}
 */
export interface IEventHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Event helper options interface
 *
 * @export
 * @interface EventHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface EventHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const eventHelperOpts: EventHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Event helper
 *
 * @export
 * @class EventHelper
 * @extends {EntityHelper<EventCollection, EventActionTypes, EventActions, EventEntity, EventEntities, EventEntity_Some, EventEntityPatch_Some, EventId, EventIds, EventId_Some, EventCollectionState, EventHelperOpts>}
 * @implements {IEventHelper}
 */
export class EventHelper
	extends EntityHelper<
		EventCollection,
		EventActionTypes,
		IEventActions,
		EventEntity,
		EventEntities,
		EventEntity_Some,
		EventEntityPatch_Some,
		EventId,
		EventIds,
		EventId_Some,
		EventCollectionState,
		EventHelperOpts
	>
	implements IEventHelper {
	constructor() {
		super(
			useSelector(getEventCollection),
			eventActions,
			useDispatch(),
			eventHelperOpts
		);
		this.collection = useSelector(getEventCollection);
		this.dispatch = useDispatch();
	}

	// overload the set active event method to archive / unarchive cached event data
	set(id?: EntityId, ctx?: UseCtx<any>) {
		// don't set it if it is already active
		if (this.active()?.id === id) return;
		if (ctx) {
			ctx.virtual.answer.cache(id);
			ctx.virtual.attendee.cache(id);
			ctx.virtual.contact.cache(id);
			ctx.virtual.contactRegistrationType.cache(id);
			ctx.virtual.fee.cache(id);
			ctx.virtual.product.cache(id);
			ctx.virtual.productContact.cache(id);
			ctx.virtual.productWebinar.cache(id);
			ctx.virtual.question.cache(id);
			ctx.virtual.registrationGroup.cache(id);
			ctx.virtual.registrationType.cache(id);
			ctx.virtual.resource.cache(id);
			//ctx.virtual.schedule.cache(id);
			//ctx.virtual.scheduleContact.cache(id);
			ctx.virtual.webinar.cache(id);
		}

		this.dispatch(this.actions.set(id));
	}

	async read(
		ctx: UseCtx<any>,
		params: ReadEventRequest,
		callback?: any
	): Promise<EventEntity | undefined> {
		let entity: EventEntity | any = await readEvent(ctx, params || {})
			.then((entity: EventEntity | undefined) => {
				if (callback) callback(entity);
				return entity;
			})
			.catch(e => {
				console.log(e);
				if (callback) callback(e);
				return [];
			});
		return entity;
	}

	async readData(ctx: UseCtx<any>) {
		let eventId = this.active()?.id;
		if (!eventId) return;

		// make sure we have the latest event entity data
		this.read(ctx, { eventId });

		//console.log('Loading event data...');
		//return;
		// load schedule

		//ctx.virtual.schedule.read(
		//	ctx,
		//	{ eventId },
		//	(entities: ScheduleEntities) => {
		//console.log(`${entities.length} Schedules Loaded`);
		//	}
		//);
		//console.log(`Schedules Loading`);

		// load product
		ctx.virtual.product.read(ctx, {}, (entities: ProductEntities) => {
			//console.log(`${entities.length} Products Loaded`);
		});

		// load registration group
		ctx.virtual.registrationGroup.read(
			ctx,
			{},
			(entities: RegistrationGroupEntities) => {
				//console.log(`${entities.length} Registration Groups Loaded`);
			}
		);

		// load registration type
		ctx.virtual.registrationType.read(
			ctx,
			{},
			(entities: RegistrationTypeEntities) => {
				//console.log(`${entities.length} Registration Types Loaded`);
			}
		);

		// load question
		//ctx.virtual.question.read(ctx, {}, (entities: QuestionEntities) => {
		//console.log(`${entities.length} Questions Loaded`);
		//});

		// load answer
		//ctx.virtual.answer.read(ctx, {}, (entities: AnswerEntities) => {
		//console.log(`${entities.length} Answer Loaded`);
		//});

		// load fee
		//ctx.virtual.fee.read(ctx, { eventId }, (entities: FeeEntities) => {
		//console.log(`${entities.length} Fees Loaded`);
		//});

		// load product contact
		ctx.virtual.productContact.read(
			ctx,
			{},
			(entities: ProductContactEntities) => {
				//console.log(`${entities.length} Product Contacts Loaded`);
			}
		);

		// load product webinar
		ctx.virtual.productWebinar.read(
			ctx,
			{},
			(entities: ProductWebinarEntities) => {
				//console.log(`${entities.length} Product Webinars Loaded`);
			}
		);

		// load product webinar
		ctx.virtual.category.read(ctx, {}, (entities: CategoryEntities) => {
			//console.log(`${entities.length} Product Webinars Loaded`);
		});

		// load product webinar
		ctx.virtual.productCategory.read(
			ctx,
			{},
			(entities: ProductCategoryEntities) => {
				//console.log(`${entities.length} Product Webinars Loaded`);
			}
		);

		// load contact organizations
		/*ctx.virtual.contact.readOrganizations(
			ctx,
			{},
			(entities: ContactEntities) => {
				//console.log(`${entities.length} Organizations Loaded`);
			}
		);*/

		// load contact registration type: Exhibitor
		/*
		ctx.virtual.contactRegistrationType.read_Exhibitor(
			ctx,
			(entities: ContactRegistrationTypeExhibitorEntities) => {
				//console.log(`${entities.length} Exhibitors Loaded`);
			}
		);
		*/
		// load contact registration type: Sponsor
		/*
		ctx.virtual.contactRegistrationType.read_Sponsor(
			ctx,
			(entities: ContactRegistrationTypeSponsorEntities) => {
				//console.log(`${entities.length} Sponsor Loaded`);
			}
		);
		*/

		// load resource
		ctx.virtual.resource.read(ctx, {}, (entities: ResourceEntities) => {
			//console.log(`${entities.length} Resources Loaded`);
		});

		// load webinar
		ctx.virtual.webinar.read(ctx, {}, (entities: WebinarEntities) => {
			//console.log(`${entities.length} Webinars Loaded`);
		});
	}

	isActiveEventOpen() {
		let e = this.active();
		if (e) {
			let status = (e?.properties as any)?.app?.virtual?.generalSettings
				?.appStatus;
			if (status && status === 'closed') {
				return false;
			}
			let closingDate = (e?.properties as any)?.app?.virtual?.generalSettings
				?.appClosingDate;
			if (closingDate && moment(closingDate).isBefore(moment())) {
				return false;
			}
		}
		return true;
	}
}
