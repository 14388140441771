import { apiActions } from './Api';
import { authActions } from './Auth';
import { menuActions } from './Menu';
import { routeActions } from './Route';
import { userActions } from './User';

export * from './Api';
export * from './Auth';
export * from './Menu';
export * from './Route';
export * from './User';

export const appActions = {
	api: apiActions,
	auth: authActions,
	menu: menuActions,
	route: routeActions,
	user: userActions
};
