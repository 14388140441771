import { ProductContactEntities } from '../../virtual/store/collections/ProductContact';

export interface ProductContactConfig {
	entities: ProductContactEntities;
	activeId?: string;
}

export const productContactConfig: ProductContactConfig = {
	entities: []
};
