import { MenuEntities } from '../../app/store/collections/Menu';
import {
	MenuDisplayType,
	MenuDisplaySideType
} from '../../app/store/models/Menu';
import { MenuConfigProps } from '../../app/ui/components/Menu';
import { Localize, translations } from '../locales';
import * as icons from 'ionicons/icons';

export interface MenuConfig {
	instances: MenuConfigInstances;
	entities: MenuEntities;
	activeId?: string;
}

export interface MenuConfigInstancesRequired {
	main: MenuConfigInstanceTag;
}

export interface MenuConfigInstances extends MenuConfigInstancesRequired {
	[instanceId: string]: MenuConfigInstanceTag;
}

export interface MenuConfigInstanceTagRequired {
	default: Localize<MenuConfigProps>;
}

export interface MenuConfigInstanceTag extends MenuConfigInstanceTagRequired {
	[tag: string]: Localize<MenuConfigProps>;
}

const main_default: MenuConfigProps = {
	disabled: false,
	type: MenuDisplayType.Overlay,
	side: MenuDisplaySideType.Start,
	maxEdgeStart: 50,
	swipeGesture: true,
	autoHide: false,
	tags: ['default'],
	icons: { slot: 'start' },
	collapse: true
};
const main_virtualEvent = {
	...main_default,
	tags: ['default', 'virtualEvent']
};
const main_account = { ...main_default, tags: ['default', 'account'] };
const main_settings = { ...main_default, tags: ['default', 'settings'] };
const main_support = { ...main_default, tags: ['default', 'support'] };

export const menuConfig: MenuConfig = {
	instances: {
		main: {
			default: {
				en: { title: translations.appName.en, ...main_default },
				fr: { title: translations.appName.fr, ...main_default }
			},
			'virtual-event': {
				en: { title: translations.virtual.en, ...main_virtualEvent },
				fr: { title: translations.virtual.fr, ...main_virtualEvent }
			},
			account: {
				en: { title: translations.userAccount.en, ...main_account },
				fr: { title: translations.userAccount.fr, ...main_account }
			},
			settings: {
				en: { title: translations.settings.en, ...main_settings },
				fr: { title: translations.settings.fr, ...main_settings }
			},
			support: {
				en: { title: translations.support.en, ...main_support },
				fr: { title: translations.support.fr, ...main_support }
			}
		}
	},
	entities: [
		{
			id: 'home',
			title: 'Home',
			url: '/home',
			icon: { icon: icons.homeOutline },
			tags: ['default']
		},
		{
			id: 'agenda',
			title: 'My Agenda',
			url: '/agenda',
			icon: { icon: icons.listOutline },
			tags: ['default']
		},
		{
			id: 'meetings',
			title: 'My Meetings',
			url: '/meetings',
			icon: { icon: icons.peopleOutline },
			tags: ['default']
		},
		{
			id: 'event-schedule',
			title: 'Event Schedule',
			url: '/event-schedule',
			icon: { icon: icons.calendarOutline },
			tags: ['default']
		},
		{
			id: 'exhibit-hall',
			title: 'Exhibit Hall',
			url: '/exhibit-hall',
			icon: { icon: icons.businessOutline },
			tags: ['default']
		},
		{
			id: 'sponsor-showcase',
			title: 'Sponsor Showcase',
			url: '/sponsors',
			icon: { icon: icons.starOutline },
			tags: ['default']
		},
		{
			id: 'speakers',
			title: 'Speakers',
			url: '/speakers',
			icon: { icon: icons.school },
			tags: ['default']
		}
	]
};
