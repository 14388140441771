// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The questions value selection type as a `boolean`, `text` (string), `file` (path string), or predifined `single` / `multiple` select answers.
 * @export
 * @enum {string}
 */
export enum QuestionSelectionType {
	Boolean = 'boolean',
	Text = 'text',
	File = 'file',
	Single = 'single',
	Multiple = 'multiple'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesQuestionSelectionType = [
	'boolean',
	'text',
	'file',
	'single',
	'multiple'
];
