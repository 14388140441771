import {
	RegistrationGroupId_Some,
	RegistrationGroupEntity_Some,
	RegistrationGroupEntityPatch_Some,
	RegistrationGroupCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const REGISTRATIONGROUP_MUTATE_ID = '@app/REGISTRATIONGROUP_MUTATE_ID';
export const REGISTRATIONGROUP_SET = '@app/REGISTRATIONGROUP_SET';
export const REGISTRATIONGROUP_TOGGLE = '@app/REGISTRATIONGROUP_TOGGLE';
export const REGISTRATIONGROUP_SELECT = '@app/REGISTRATIONGROUP_SELECT';
export const REGISTRATIONGROUP_DESELECT = '@app/REGISTRATIONGROUP_DESELECT';
export const REGISTRATIONGROUP_UPSERT = '@app/REGISTRATIONGROUP_UPSERT';
export const REGISTRATIONGROUP_PATCH = '@app/REGISTRATIONGROUP_PATCH';
export const REGISTRATIONGROUP_CHANGE = '@app/REGISTRATIONGROUP_CHANGE';
export const REGISTRATIONGROUP_APPLY_CHANGES =
	'@app/REGISTRATIONGROUP_APPLY_CHANGES';
export const REGISTRATIONGROUP_CANCEL_CHANGES =
	'@app/REGISTRATIONGROUP_CANCEL_CHANGES';
export const REGISTRATIONGROUP_DELETE = '@app/REGISTRATIONGROUP_DELETE';
export const REGISTRATIONGROUP_UNDELETE = '@app/REGISTRATIONGROUP_UNDELETE';
export const REGISTRATIONGROUP_SETSTATE = '@app/REGISTRATIONGROUP_SETSTATE';
export const REGISTRATIONGROUP_PATCHSTATE = '@app/REGISTRATIONGROUP_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface RegistrationGroupAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface RegistrationGroupAction_MutateId extends EntityAction_MutateId {
	type: typeof REGISTRATIONGROUP_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active registrationGroup
 *
 * @interface RegistrationGroupAction_Set
 * @extends {EntityAction_Set}
 */
export interface RegistrationGroupAction_Set extends EntityAction_Set {
	type: typeof REGISTRATIONGROUP_SET;
	payload?: EntityId;
}

/**
 * Toggle active registrationGroup
 *
 * @interface RegistrationGroupAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface RegistrationGroupAction_Toggle extends EntityAction_Toggle {
	type: typeof REGISTRATIONGROUP_TOGGLE;
	payload?: EntityId;
}

/**
 * Select registrationGroup entities
 *
 * @interface RegistrationGroupAction_Select
 * @extends {EntityAction_Select}
 */
interface RegistrationGroupAction_Select extends EntityAction_Select {
	type: typeof REGISTRATIONGROUP_SELECT;
	payload: RegistrationGroupId_Some;
}

/**
 * Deselect registrationGroup entities
 *
 * @interface RegistrationGroupAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface RegistrationGroupAction_Deselect extends EntityAction_Deselect {
	type: typeof REGISTRATIONGROUP_DESELECT;
	payload: RegistrationGroupId_Some;
}

/**
 * Upsert registrationGroup entities
 *
 * @interface RegistrationGroupAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface RegistrationGroupAction_Upsert extends EntityAction_Upsert {
	type: typeof REGISTRATIONGROUP_UPSERT;
	payload: RegistrationGroupEntity_Some;
}

/**
 * Patch registrationGroup entities
 *
 * @interface RegistrationGroupAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface RegistrationGroupAction_Patch extends EntityAction_Patch {
	type: typeof REGISTRATIONGROUP_PATCH;
	payload: RegistrationGroupEntityPatch_Some;
}

/**
 * Change registrationGroup entities
 *
 * @interface RegistrationGroupAction_Change
 * @extends {EntityAction_Change}
 */
interface RegistrationGroupAction_Change extends EntityAction_Change {
	type: typeof REGISTRATIONGROUP_CHANGE;
	payload: RegistrationGroupEntityPatch_Some;
}

/**
 * Apply changes to registrationGroup entities
 *
 * @interface RegistrationGroupAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface RegistrationGroupAction_ApplyChanges
	extends EntityAction_ApplyChanges {
	type: typeof REGISTRATIONGROUP_APPLY_CHANGES;
	payload: RegistrationGroupId_Some;
}

/**
 * Cancel changes to registrationGroup entities
 *
 * @interface RegistrationGroupAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface RegistrationGroupAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof REGISTRATIONGROUP_CANCEL_CHANGES;
	payload: RegistrationGroupId_Some;
}

/**
 * Delete registrationGroup entities
 *
 * @interface RegistrationGroupAction_Delete
 * @extends {EntityAction_Delete}
 */
interface RegistrationGroupAction_Delete extends EntityAction_Delete {
	type: typeof REGISTRATIONGROUP_DELETE;
	payload: RegistrationGroupId_Some;
}

/**
 * Undelete registrationGroup entities
 *
 * @interface RegistrationGroupAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface RegistrationGroupAction_Undelete extends EntityAction_Undelete {
	type: typeof REGISTRATIONGROUP_UNDELETE;
	payload: RegistrationGroupId_Some;
}

/**
 * Set registrationGroup collection / entity state
 *
 * @interface RegistrationGroupAction_SetState
 * @extends {EntityAction_SetState}
 */
interface RegistrationGroupAction_SetState extends EntityAction_SetState {
	type: typeof REGISTRATIONGROUP_SETSTATE;
}

/**
 * PAtch registrationGroup collection / entity state
 *
 * @interface RegistrationGroupAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface RegistrationGroupAction_PatchState extends EntityAction_PatchState {
	type: typeof REGISTRATIONGROUP_PATCHSTATE;
}

/**
 * Export registrationGroup action types
 *
 * @export
 */
export type RegistrationGroupActionTypes =
	| RegistrationGroupAction_MutateId
	| RegistrationGroupAction_MutateId
	| RegistrationGroupAction_Set
	| RegistrationGroupAction_Toggle
	| RegistrationGroupAction_Select
	| RegistrationGroupAction_Deselect
	| RegistrationGroupAction_Upsert
	| RegistrationGroupAction_Patch
	| RegistrationGroupAction_Change
	| RegistrationGroupAction_ApplyChanges
	| RegistrationGroupAction_CancelChanges
	| RegistrationGroupAction_Delete
	| RegistrationGroupAction_Undelete
	| RegistrationGroupAction_SetState
	| RegistrationGroupAction_PatchState;

/**
 * RegistrationGroup actions helper interface
 *
 * @export
 * @interface IRegistrationGroupActions
 * @extends {EntityActions}
 */
export interface IRegistrationGroupActions extends EntityActions {
	//customAction(ids: RegistrationGroupId_Some): RegistrationGroupAction_CustomAction;
}

/**
 * RegistrationGroup actions helper
 *
 * @export
 * @class RegistrationGroupActions
 * @implements {EntityBaseActions}
 */
export class RegistrationGroupActions implements IRegistrationGroupActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): RegistrationGroupAction_MutateId {
		return { type: REGISTRATIONGROUP_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): RegistrationGroupAction_Set {
		return { type: REGISTRATIONGROUP_SET, payload: id };
	}
	toggle(id?: EntityId): RegistrationGroupAction_Toggle {
		return { type: REGISTRATIONGROUP_TOGGLE, payload: id };
	}
	select(ids: RegistrationGroupId_Some): RegistrationGroupAction_Select {
		return {
			type: REGISTRATIONGROUP_SELECT,
			payload: ids
		};
	}
	deselect(ids: RegistrationGroupId_Some): RegistrationGroupAction_Deselect {
		return {
			type: REGISTRATIONGROUP_DESELECT,
			payload: ids
		};
	}
	upsert(
		entities: RegistrationGroupEntity_Some
	): RegistrationGroupAction_Upsert {
		return {
			type: REGISTRATIONGROUP_UPSERT,
			payload: entities
		};
	}
	patch(
		entities: RegistrationGroupEntityPatch_Some
	): RegistrationGroupAction_Patch {
		return {
			type: REGISTRATIONGROUP_PATCH,
			payload: entities
		};
	}
	change(
		entities: RegistrationGroupEntityPatch_Some
	): RegistrationGroupAction_Change {
		return {
			type: REGISTRATIONGROUP_CHANGE,
			payload: entities
		};
	}
	applyChanges(
		ids: RegistrationGroupId_Some
	): RegistrationGroupAction_ApplyChanges {
		return {
			type: REGISTRATIONGROUP_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: RegistrationGroupId_Some
	): RegistrationGroupAction_CancelChanges {
		return {
			type: REGISTRATIONGROUP_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: RegistrationGroupId_Some): RegistrationGroupAction_Delete {
		return {
			type: REGISTRATIONGROUP_DELETE,
			payload: ids
		};
	}
	undelete(ids: RegistrationGroupId_Some): RegistrationGroupAction_Undelete {
		return {
			type: REGISTRATIONGROUP_UNDELETE,
			payload: ids
		};
	}
	setState(
		state: RegistrationGroupCollectionState
	): RegistrationGroupAction_SetState {
		return {
			type: REGISTRATIONGROUP_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<RegistrationGroupCollectionState>
	): RegistrationGroupAction_PatchState {
		return {
			type: REGISTRATIONGROUP_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the registrationGroup actions helper
 *
 * @export
 */
export const registrationGroupActions = new RegistrationGroupActions();
