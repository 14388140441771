import {
	ContactId_Some,
	ContactEntity_Some,
	ContactEntityPatch_Some,
	ContactCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Delete,
	EntityAction_Undelete,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const CONTACT_MUTATE_ID = '@app/CONTACT_MUTATE_ID';
export const CONTACT_SET = '@app/CONTACT_SET';
export const CONTACT_TOGGLE = '@app/CONTACT_TOGGLE';
export const CONTACT_SELECT = '@app/CONTACT_SELECT';
export const CONTACT_DESELECT = '@app/CONTACT_DESELECT';
export const CONTACT_UPSERT = '@app/CONTACT_UPSERT';
export const CONTACT_PATCH = '@app/CONTACT_PATCH';
export const CONTACT_CHANGE = '@app/CONTACT_CHANGE';
export const CONTACT_APPLY_CHANGES = '@app/CONTACT_APPLY_CHANGES';
export const CONTACT_CANCEL_CHANGES = '@app/CONTACT_CANCEL_CHANGES';
export const CONTACT_DELETE = '@app/CONTACT_DELETE';
export const CONTACT_UNDELETE = '@app/CONTACT_UNDELETE';
export const CONTACT_SETSTATE = '@app/CONTACT_SETSTATE';
export const CONTACT_PATCHSTATE = '@app/CONTACT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ContactAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ContactAction_MutateId extends EntityAction_MutateId {
	type: typeof CONTACT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active contact
 *
 * @interface ContactAction_Set
 * @extends {EntityAction_Set}
 */
export interface ContactAction_Set extends EntityAction_Set {
	type: typeof CONTACT_SET;
	payload?: EntityId;
}

/**
 * Toggle active contact
 *
 * @interface ContactAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ContactAction_Toggle extends EntityAction_Toggle {
	type: typeof CONTACT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select contact entities
 *
 * @interface ContactAction_Select
 * @extends {EntityAction_Select}
 */
interface ContactAction_Select extends EntityAction_Select {
	type: typeof CONTACT_SELECT;
	payload: ContactId_Some;
}

/**
 * Deselect contact entities
 *
 * @interface ContactAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ContactAction_Deselect extends EntityAction_Deselect {
	type: typeof CONTACT_DESELECT;
	payload: ContactId_Some;
}

/**
 * Upsert contact entities
 *
 * @interface ContactAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ContactAction_Upsert extends EntityAction_Upsert {
	type: typeof CONTACT_UPSERT;
	payload: ContactEntity_Some;
}

/**
 * Patch contact entities
 *
 * @interface ContactAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ContactAction_Patch extends EntityAction_Patch {
	type: typeof CONTACT_PATCH;
	payload: ContactEntityPatch_Some;
}

/**
 * Change contact entities
 *
 * @interface ContactAction_Change
 * @extends {EntityAction_Change}
 */
interface ContactAction_Change extends EntityAction_Change {
	type: typeof CONTACT_CHANGE;
	payload: ContactEntityPatch_Some;
}

/**
 * Apply changes to contact entities
 *
 * @interface ContactAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ContactAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof CONTACT_APPLY_CHANGES;
	payload: ContactId_Some;
}

/**
 * Cancel changes to contact entities
 *
 * @interface ContactAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ContactAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof CONTACT_CANCEL_CHANGES;
	payload: ContactId_Some;
}

/**
 * Delete contact entities
 *
 * @interface ContactAction_Delete
 * @extends {EntityAction_Delete}
 */
interface ContactAction_Delete extends EntityAction_Delete {
	type: typeof CONTACT_DELETE;
	payload: ContactId_Some;
}

/**
 * Undelete contact entities
 *
 * @interface ContactAction_Undelete
 * @extends {EntityAction_Undelete}
 */
interface ContactAction_Undelete extends EntityAction_Undelete {
	type: typeof CONTACT_UNDELETE;
	payload: ContactId_Some;
}

/**
 * Set contact collection / entity state
 *
 * @interface ContactAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ContactAction_SetState extends EntityAction_SetState {
	type: typeof CONTACT_SETSTATE;
}

/**
 * PAtch contact collection / entity state
 *
 * @interface ContactAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ContactAction_PatchState extends EntityAction_PatchState {
	type: typeof CONTACT_PATCHSTATE;
}

/**
 * Export contact action types
 *
 * @export
 */
export type ContactActionTypes =
	| ContactAction_MutateId
	| ContactAction_MutateId
	| ContactAction_Set
	| ContactAction_Toggle
	| ContactAction_Select
	| ContactAction_Deselect
	| ContactAction_Upsert
	| ContactAction_Patch
	| ContactAction_Change
	| ContactAction_ApplyChanges
	| ContactAction_CancelChanges
	| ContactAction_Delete
	| ContactAction_Undelete
	| ContactAction_SetState
	| ContactAction_PatchState;

/**
 * Contact actions helper interface
 *
 * @export
 * @interface IContactActions
 * @extends {EntityActions}
 */
export interface IContactActions extends EntityActions {
	//customAction(ids: ContactId_Some): ContactAction_CustomAction;
}

/**
 * Contact actions helper
 *
 * @export
 * @class ContactActions
 * @implements {EntityBaseActions}
 */
export class ContactActions implements IContactActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ContactAction_MutateId {
		return { type: CONTACT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ContactAction_Set {
		return { type: CONTACT_SET, payload: id };
	}
	toggle(id?: EntityId): ContactAction_Toggle {
		return { type: CONTACT_TOGGLE, payload: id };
	}
	select(ids: ContactId_Some): ContactAction_Select {
		return {
			type: CONTACT_SELECT,
			payload: ids
		};
	}
	deselect(ids: ContactId_Some): ContactAction_Deselect {
		return {
			type: CONTACT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ContactEntity_Some): ContactAction_Upsert {
		return {
			type: CONTACT_UPSERT,
			payload: entities
		};
	}
	patch(entities: ContactEntityPatch_Some): ContactAction_Patch {
		return {
			type: CONTACT_PATCH,
			payload: entities
		};
	}
	change(entities: ContactEntityPatch_Some): ContactAction_Change {
		return {
			type: CONTACT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ContactId_Some): ContactAction_ApplyChanges {
		return {
			type: CONTACT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ContactId_Some): ContactAction_CancelChanges {
		return {
			type: CONTACT_CANCEL_CHANGES,
			payload: ids
		};
	}
	delete(ids: ContactId_Some): ContactAction_Delete {
		return {
			type: CONTACT_DELETE,
			payload: ids
		};
	}
	undelete(ids: ContactId_Some): ContactAction_Undelete {
		return {
			type: CONTACT_UNDELETE,
			payload: ids
		};
	}
	setState(state: ContactCollectionState): ContactAction_SetState {
		return {
			type: CONTACT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ContactCollectionState>
	): ContactAction_PatchState {
		return {
			type: CONTACT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the contact actions helper
 *
 * @export
 */
export const contactActions = new ContactActions();
