// tslint:disable
/**
 * CONEXSYS Contact Portal API
 * The API for the CONEXSYS Contact Portal
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { virtualConfig as config } from '../../../config/virtual';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ContactHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ContactEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';

export enum ContactApiOperation {
	readContactsById = 'readContactsById',
	readContactsOrganizations = 'readContactsOrganizations'
}

export interface ReadContactsByIdRequest {
	eventId: string;
	ids: string[];
	modifiedFrom?: string;
}

export interface ReadContactsOrganizationsRequest {
	eventId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	ids?: string[];
}

/**
 * Get contacts by id
 */
export async function readContactsById(
	ctx: UseCtx<any>,
	requestParameters: ReadContactsByIdRequest
): Promise<ContactEntities> {
	const operationId = ContactApiOperation.readContactsById;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ContactEntities, ReadContactsByIdRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'ids'
	);

	let entities: ContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	if (entities.length > 0) {
		// set each entity.__state.eventId to the eventId from the params
		entities.forEach((entity, i, a) => {
			a[i].__state = _.merge(entity.__state || {}, {
				eventId: requestParameters.eventId
			});
		});

		// patch entities with an api success data for the operation
		setEntitiesApiOperationState<ContactEntities, ContactHelper>(
			ctx.virtual.contact,
			entities,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.contact.upsert(entities);
	}

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read organization contacts
 */
export async function readContactsOrganizations(
	ctx: UseCtx<any>,
	requestParameters: ReadContactsOrganizationsRequest
): Promise<ContactEntities> {
	const operationId = ContactApiOperation.readContactsOrganizations;

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		ContactEntities,
		ReadContactsOrganizationsRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: ContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	if (entities.length > 0) {
		// set each entity.__state.eventId to the eventId from the params
		entities.forEach((entity, i, a) => {
			a[i].__state = _.merge(entity.__state || {}, {
				eventId: requestParameters.eventId
			});
		});

		// patch entities with an api success data for the operation
		setEntitiesApiOperationState<ContactEntities, ContactHelper>(
			ctx.virtual.contact,
			entities,
			operationId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);
		// upsert the entities to the store
		ctx.virtual.contact.upsert(entities);
	}

	// return the entities to the caller for direct reference
	return entities;
}
