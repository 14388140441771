import { Store, State } from '../../storage';
import * as collections from './collections';

export * from './actions';
export * from './apis';
export * from './classes';
export * from './collections';
export * from './hooks';
export * from './models';
export * from './reducers';

/**
 * Virtual store interface
 *
 * @export
 * @interface VirtualStore
 */
export interface VirtualStore
	extends Store,
		collections.AnswerStoreCollection,
		collections.AttendeeStoreCollection,
		collections.ContactStoreCollection,
		collections.ContactRegistrationTypeStoreCollection,
		collections.EventStoreCollection,
		collections.FeeStoreCollection,
		collections.ProductStoreCollection,
		collections.ProductContactStoreCollection,
		collections.ProductWebinarStoreCollection,
		collections.QuestionStoreCollection,
		collections.RegistrationGroupStoreCollection,
		collections.RegistrationTypeStoreCollection,
		collections.ResourceStoreCollection,
		collections.ScheduleStoreCollection,
		collections.WebinarStoreCollection,
		collections.CategoryStoreCollection,
		collections.ProductCategoryStoreCollection{ }

/**
 * Virtual store default values
 *
 * @export
 * @constant
 */
export const VirtualStoreDefault: VirtualStore = {
	...collections.answerStoreCollectionDefault,
	...collections.attendeeStoreCollectionDefault,
	...collections.contactStoreCollectionDefault,
	...collections.contactRegistrationTypeStoreCollectionDefault,
	...collections.eventStoreCollectionDefault,
	...collections.feeStoreCollectionDefault,
	...collections.productStoreCollectionDefault,
	...collections.productContactStoreCollectionDefault,
	...collections.productWebinarStoreCollectionDefault,
	...collections.questionStoreCollectionDefault,
	...collections.registrationGroupStoreCollectionDefault,
	...collections.registrationTypeStoreCollectionDefault,
	...collections.resourceStoreCollectionDefault,
	...collections.scheduleStoreCollectionDefault,
	...collections.webinarStoreCollectionDefault,
	...collections.categoryStoreCollectionDefault,
	...collections.productCategoryStoreCollectionDefault
};
