import {
	ProductCategoryCollection,
	ProductCategoryCollectionState,
	ProductCategoryId,
	ProductCategoryEntity_Some,
	ProductCategoryEntityPatch_Some,
	ProductCategoryId_Some,
	productCategoryCollectionDefault,
	productCategoryCollectionMutateIdOpts
} from '../collections/ProductCategory';
import * as actions from '../actions/ProductCategory';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * ProductCategory reducer
 *
 * @export
 * @param {ProductCategoryCollection} [state=productCategoryCollectionDefault]
 * @param {ProductCategoryActionTypes} action
 * @returns
 */
export function productCategoryReducer(
	state: ProductCategoryCollection = productCategoryCollectionDefault,
	action: actions.ProductCategoryActionTypes
) {
	switch (action.type) {
		case actions.PRODUCTCATEGORY_MUTATE_ID:
			return collectionReducerActions.mutateId<ProductCategoryCollection>(
				state,
				action.payload,
				productCategoryCollectionMutateIdOpts
			);
		case actions.PRODUCTCATEGORY_SET:
			return collectionReducerActions.set<
				ProductCategoryCollection,
				ProductCategoryId
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_TOGGLE:
			return collectionReducerActions.toggle<
				ProductCategoryCollection,
				ProductCategoryId
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_SELECT:
			return collectionReducerActions.select<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_DESELECT:
			return collectionReducerActions.deselect<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_UPSERT:
			return collectionReducerActions.upsert<
				ProductCategoryCollection,
				ProductCategoryEntity_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_PATCH:
			return collectionReducerActions.patch<
				ProductCategoryCollection,
				ProductCategoryEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_CHANGE:
			return collectionReducerActions.change<
				ProductCategoryCollection,
				ProductCategoryEntityPatch_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_DELETE:
			return collectionReducerActions.delete<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_UNDELETE:
			return collectionReducerActions.undelete<
				ProductCategoryCollection,
				ProductCategoryId_Some
			>(state, action.payload);
		case actions.PRODUCTCATEGORY_SETSTATE:
			return collectionReducerActions.setState<
				ProductCategoryCollection,
				ProductCategoryCollectionState
			>(state, action.payload.state);
		case actions.PRODUCTCATEGORY_PATCHSTATE:
			return collectionReducerActions.patchState<
				ProductCategoryCollection,
				ProductCategoryCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
