import {
	ResourceCollection,
	ResourceCollectionState,
	ResourceId,
	ResourceEntity_Some,
	ResourceEntityPatch_Some,
	ResourceId_Some,
	resourceCollectionDefault,
	resourceCollectionMutateIdOpts
} from '../collections/Resource';
import * as actions from '../actions/Resource';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Resource reducer
 *
 * @export
 * @param {ResourceCollection} [state=resourceCollectionDefault]
 * @param {ResourceActionTypes} action
 * @returns
 */
export function resourceReducer(
	state: ResourceCollection = resourceCollectionDefault,
	action: actions.ResourceActionTypes
) {
	switch (action.type) {
		case actions.RESOURCE_MUTATE_ID:
			return collectionReducerActions.mutateId<ResourceCollection>(
				state,
				action.payload,
				resourceCollectionMutateIdOpts
			);
		case actions.RESOURCE_SET:
			return collectionReducerActions.set<ResourceCollection, ResourceId>(
				state,
				action.payload
			);
		case actions.RESOURCE_TOGGLE:
			return collectionReducerActions.toggle<ResourceCollection, ResourceId>(
				state,
				action.payload
			);
		case actions.RESOURCE_SELECT:
			return collectionReducerActions.select<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_DESELECT:
			return collectionReducerActions.deselect<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_UPSERT:
			return collectionReducerActions.upsert<
				ResourceCollection,
				ResourceEntity_Some
			>(state, action.payload);
		case actions.RESOURCE_PATCH:
			return collectionReducerActions.patch<
				ResourceCollection,
				ResourceEntityPatch_Some
			>(state, action.payload);
		case actions.RESOURCE_CHANGE:
			return collectionReducerActions.change<
				ResourceCollection,
				ResourceEntityPatch_Some
			>(state, action.payload);
		case actions.RESOURCE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_DELETE:
			return collectionReducerActions.delete<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_UNDELETE:
			return collectionReducerActions.undelete<
				ResourceCollection,
				ResourceId_Some
			>(state, action.payload);
		case actions.RESOURCE_SETSTATE:
			return collectionReducerActions.setState<
				ResourceCollection,
				ResourceCollectionState
			>(state, action.payload.state);
		case actions.RESOURCE_PATCHSTATE:
			return collectionReducerActions.patchState<
				ResourceCollection,
				ResourceCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
