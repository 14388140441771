import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { attendeeConfig } from '../../../config/virtual/Attendee';
import { Attendee } from '../models';

/**
 * Attendee entity interface
 *
 * @export
 * @interface AttendeeEntity
 * @extends {storage.Entity}
 */
export interface AttendeeEntity extends storage.Entity, Attendee {}

/**
 * Attendee entities array
 *
 * @export
 */
export type AttendeeEntities = AttendeeEntity[];

/**
 * Some attendee entities: one entity or an array of entities
 *
 * @export
 */
export type AttendeeEntity_Some = AttendeeEntity | AttendeeEntities;

/**
 * Attendee entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface AttendeeEntityPatch
 * @extends {storage.EntityPatch<AttendeeEntity>}
 */
export type AttendeeEntityPatch = storage.EntityPatcher<AttendeeEntity>;

/**
 * Attendee entity patches array
 *
 * @export
 */
export type AttendeeEntitiesPatch = AttendeeEntityPatch[];

/**
 * Some attendee entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type AttendeeEntityPatch_Some =
	| AttendeeEntityPatch
	| AttendeeEntitiesPatch;

/**
 * Attendee entity id
 *
 * @export
 */
export type AttendeeId = storage.EntityId;

/**
 * Attendee entity ids
 *
 * @export
 */
export type AttendeeIds = storage.EntityIds;

/**
 * Some attendee entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type AttendeeId_Some = AttendeeId | AttendeeIds;

/**
 * Attendee entities object by id
 *
 * @export
 * @interface AttendeeEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface AttendeeEntitiesObject extends storage.EntitiesObject {
	[id: string]: AttendeeEntity;
}

/**
 * Attendee entity state
 *
 * @export
 * @interface AttendeeEntityState
 * @extends {storage.EntityState}
 */
export interface AttendeeEntityState extends storage.EntityState {
	eventId?: string;
}

/**
 * Attendee store collection
 *
 * @export
 * @interface AttendeeStoreCollection
 * @extends {storage.Store}
 */
export interface AttendeeStoreCollection extends storage.Store {
	attendee: AttendeeCollection;
}

/**
 * Attendee collection properties
 *
 * @export
 * @interface AttendeeCollection
 * @extends {Collection}
 */
export interface AttendeeCollection extends storage.Collection {
	byIds: AttendeeEntitiesObject;
	mutation: AttendeeEntitiesObject;
	cache: AttendeeEntitiesObject;
	state: AttendeeCollectionState;
}

/**
 * Attendee collection state
 *
 * @export
 * @interface AttendeeCollectionState
 * @extends {storage.CollectionState}
 */
export interface AttendeeCollectionState extends storage.CollectionState {}

// custom attendee collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const attendeeCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const attendeeCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const attendeeCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const attendeeCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: attendeeCollectionIdStringProps,
	idArrayProps: attendeeCollectionIdArrayProps,
	idEntityProps: attendeeCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Attendee collection default values, initialize collection with attendee entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const attendeeCollectionDefault: AttendeeCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as AttendeeCollection),
		...{
			activeId: attendeeConfig.activeId || attendeeConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	attendeeConfig.entities
);

/**
 * Attendee store collection default values
 *
 * @export
 * @constant
 */
export const attendeeStoreCollectionDefault: AttendeeStoreCollection = {
	attendee: attendeeCollectionDefault
};

/**
 * Attendee store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getAttendeeCollection = (state: any) => state.virtual.attendee;
