import React from 'react';

//UI
import { SponsorShowcaseList } from '../containers/SponsorShowcaseList';
import { IonContent } from '@ionic/react';

const SponsorsView: React.FC = () => {
	return (
		<IonContent>
			<SponsorShowcaseList />
		</IonContent>
	);
};

export default SponsorsView;
