// tslint:disable
/**
 * CONEXSYS Virtual Event API
 *  # Overview CONEXSYS Virtual Event API
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The status of the contact.
 * @export
 * @enum {string}
 */
export enum ContactRegistrationTypeStatusType {
	Incomplete = 'incomplete',
	Pending = 'pending',
	Confirmed = 'confirmed',
	Cancelled = 'cancelled'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesContactRegistrationTypeStatusType = [
	'incomplete',
	'pending',
	'confirmed',
	'cancelled'
];
